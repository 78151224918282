import React, { useState } from "react";
import { GET, POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { InputBox } from "../../../../InputBox/InputBox";
import GifView from "../../../courseViews/GifView/GifView";
import { useSelector } from "react-redux";
import { CircularIndeterminate, FullScreenLoader } from "../../../../Loader/Loader";
import { useQueryParams } from "../../../../../utils";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { Box } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { Grid } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const AddGIF = ({
  type,
  updateOrder,
  block,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState([]);
  const [spin, setSpin] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const { setCourseOne } = useOutletContext();
  const [showPrevious, setShowPrevious] = useState(false);

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleInputChange = async (event) => {
    setSpin(true);
    setInputValue(event.target.value);
    //search GIFS by text
    await GET(`${Constants.searchAPI}/gifs?text=${inputValue}`)
      .then((response) => {
        setResult(response?.data);
        setSpin(false);
      })
      .catch((error) => console.log(error));
  };
  const handleAddGif = async (item) => {
    setOpen(true);
    let body = {
      user_id: UserData?.data?.user?.id,
      course: cid,
      container: mid,
      gif_embed: "https://media3.giphy.com/media/" + item?.id + "/giphy.mp4",
      gif_url: item?.url,
      height: "299",
      order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
      type: type,
    };
    //Add gif
    await POST(`${Constants.addBlock}/gif?_id=${block?._id}`, body)
      .then((response) => {
        setOpen(false);
        setCourseOne((prev) => ({ ...prev, published: false }));
        if (type === "edit") {
          setCourseBlocks &&
            setCourseBlocks((prev) => {
              const elIndex = prev.findIndex((el) => el._id === block?._id);
              const updatedBlocks = prev.map((el) => {
                if (el.order >= response?.data?.order) {
                  el.order += 1;
                }
                return el;
              });
              updatedBlocks.splice(elIndex, 1, response?.data);
              updatedBlocks.sort((a, b) => a?.order - b?.order);
              return updatedBlocks;
            });
        } else {
          if (blockModalOpen?.addBelow) {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                const elIndex = updatedBlocks.findIndex(
                  (el) => el._id === blockModalOpen?.currentBlockId,
                );
                updatedBlocks.splice(elIndex + 1, 0, response?.data);
                return updatedBlocks;
              });
          } else {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                prev.forEach((el) => {
                  el.order = el.order + 1;
                });
                return [response?.data, ...prev];
              });
          }
        }
        setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
        setblockModOpen(false);
      })
      .catch((error) => console.log(error));
  };

  const handleTogglePrevious = () => {
    setShowPrevious(!showPrevious);
  };

  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      {type !== "edit" && (
        <>
          <InputBox placeholder="Search for gifs" value={inputValue} onChange={handleInputChange} />

          {checked && (
            <Box my={2}>
              <strong>Source Text:</strong> {block?.srcTitleText}
            </Box>
          )}
        </>
      )}
      <br />

      {type === "edit" && (
        <>
          <InputBox placeholder="Search for gifs" value={inputValue} onChange={handleInputChange} />
          <br />
          <Tooltip
            TransitionComponent={Zoom}
            title={`${showPrevious ? "Hide Preview" : "Preview"}`}
            sx={{ margin: "0rem 0.5rem" }}
          >
            <IconButton color="default" onClick={handleTogglePrevious} className="actionIcons">
              {showPrevious ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
            </IconButton>
          </Tooltip>

          {checked && (
            <Box my={2}>
              <strong>Source Text:</strong> {block?.srcTitleText}
            </Box>
          )}

          {showPrevious && (
            <Grid item lg={50} height={"500px"}>
              <GifView gif={block?.gif} block={block} preview={showPrevious} />
            </Grid>
          )}
          <br />
        </>
      )}
      {spin ? (
        <CircularIndeterminate />
      ) : (
        <>
          {result?.data?.map((item, index) => (
            <img
              src={item?.images?.fixed_width?.url}
              key={index}
              alt="gif"
              height="150px"
              width="150px"
              style={{ marginRight: "0.5rem" }}
              onClick={() => handleAddGif(item)}
            />
          ))}
        </>
      )}

      <FullScreenLoader open={open} />
    </div>
  );
};

export default AddGIF;
