import React from "react";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import QR from "../../../assets/DummyQr.png";
import "./CertificatePreview.scss";
import moment from "moment";

const CertificateTemplate = ({ certData, signData, learnerName, certificateData }) => {
  return (
    <div className="container">
      <img
        src={
          cloudfrontUrl +
          "/" +
          (certData?.certificate_background_img ||
            "files/v2/2023/7/515d6ee4-e76e-46d1-8fda-3832a05490f4_Certificatebackground.png")
        }
        alt="Certificate bg"
        width="100%"
      />
      <div className="childContainer">
        <div className="headerContainer">
          <div className="box1">
            <div className="institutionName">{certData?.institution_name || "UNESCO MGIEP"}</div>
            <div className="institutionDesc">
              {certData?.institution_description ||
                "MAHATMA GANDHI INSTITUTE OF EDUCATION FOR PEACE AND SUSTAINABLE DEVELOPMENT"}
            </div>
          </div>
          {signData && Object.keys(signData).length !== 0 && (
            <>
              {signData?.institution_name && (
                <div>
                  <div className="institutionName">&nbsp; & &nbsp;</div>
                </div>
              )}

              <div className="box2">
                <div className="institutionName">{signData?.institution_name}</div>{" "}
                {signData?.institution_name && (
                  <div className="institutionDesc">{signData?.institution_description}</div>
                )}
              </div>
            </>
          )}
        </div>
        <div className="cert_text_1">
          {certData?.certificate_text_1 || "AWARDS THIS CERTIFCATE TO"}
        </div>
        <div className="learnerName">{learnerName ? learnerName : "Learners Name"}</div>
        <div className="cert_text_2">
          {certData?.certificate_text_2 || "FOR SUCCESSFULLY COMPLETING THE COURSE ON"}
        </div>
        <div className="courseName">
          {certificateData?.courseName || "Course Name"}
          {certificateData?.certificateSuffix && (
            <span className="certificateSuffix">,{certificateData?.certificateSuffix}</span>
          )}
        </div>
      </div>
      <div className="logoContainer">
        <div className="certificateImage" style={{ width: "28%" }}>
          <img
            src={
              cloudfrontUrl +
              "/" +
              (certData?.institute_signature_img ||
                "files/v2/2023/7/49ddd877-efa3-404a-a6cc-1105a82dcaea_ffff.png")
            }
            alt="Certificate signature"
          />
          <div className="signatoryName">{certData?.signatory_name || "Anantha K. Duraiappah"}</div>
          <div className="signatoryDesignation">
            {certData?.signatory_designation || "Founding Director, UNESCO MGIEP"}
          </div>
        </div>

        <div className="certificateImage" style={{ width: "28%" }}>
          {signData && Object.keys(signData).length !== 0 && (
            <>
              <img
                src={cloudfrontUrl + "/" + signData?.institute_signature_img}
                alt="signatory signature"
              />
              <div className="signatoryName">{signData?.signatory_name}</div>
              <div className="signatoryDesignation">{signData?.signatory_designation}</div>
            </>
          )}
        </div>

        <div className="certificateImage" style={{ width: "20%" }}>
          {signData && Object.keys(signData).length !== 0 && (
            <img
              src={cloudfrontUrl + "/" + signData?.institute_logo_img}
              alt="signatory logo"
              // width="130px"
            />
          )}
        </div>

        <div className="certificateImage" style={{ width: "20%" }}>
          {" "}
          <img
            src={
              cloudfrontUrl +
              "/" +
              (certData?.institute_logo_img ||
                "files/v2/2023/7/607f8639-9f00-46e6-a3e5-9a75e0aaa294_MGIEP_UNESCO_Completelogolockup(1).png")
            }
            alt="Certificate logo"
            // width="130px"
          />
        </div>
      </div>
      <div className="footerContainer">
        <div className="footer">
          {certificateData?.certificateVerificationCode && learnerName ? (
            <img
              src={`https://chart.googleapis.com/chart?chs=100x100&cht=qr&chl=${
                window.location.origin
              }/verifyCertificate?vcode=${
                certificateData?.certificateVerificationCode
              }&name=${encodeURIComponent(learnerName)}&choe=UTF-8&apiKey=YOUR_API_KEY`}
              alt="qr"
              height="30px"
              width="30px"
            />
          ) : (
            <img
              src={QR} // Replace with the path to your default QR image
              alt="default-qr"
              height="30px"
              width="30px"
            />
          )}

          <div className="msg1">
            Verification Code :{" "}
            {certificateData?.certificateVerificationCode
              ? certificateData?.certificateVerificationCode
              : "64d21e98-95a6-4044-a5ff-fb2e5d7d96cd"}{" "}
            <br /> Verification Link: https://framerspace.com/verifyCertificate
          </div>
        </div>
        <div className="msg2">
          Scan to verify OR visit the verification link with the verification code.
        </div>
        <div className="msg3">
          This certificate was generated on{" "}
          {certificateData?.certificateDate
            ? certificateData?.certificateDate
            : moment(new Date()).format("DD-MM-YYYY")}
        </div>
      </div>
    </div>
  );
};

export default CertificateTemplate;
