import React from "react";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import CertificateConfigLayout from "../../components/CertificateConfig/CertificateConfig";

const CertificateConfig = () => {
  return (
    <HeaderContainer>
      <CertificateConfigLayout />
    </HeaderContainer>
  );
};

export default CertificateConfig;
