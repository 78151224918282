import React from "react";
// import { useLocation } from "react-router-dom";

import CourseViewV2 from "../../components/courses/CourseViewV2";

const LearnerView = () => {
  return <CourseViewV2 />;
};

export default LearnerView;
