import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useState } from "react";
import "./Comment.scss";
import { LoadingMore } from "../Loader/Loader";
import FMModal from "../ModalMain/CourseModals/FMModal";
import { Grid, IconButton, InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { useSelector } from "react-redux";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import ButtonMain from "../ButtonMain";

const Comment = ({
  comment,
  setComments,
  getRepliesByParentId,
  type,
  handleReplySubmit,
  parentCreator,
  parentText,
  handleDelete,
  handleEdit,
  commentId,
}) => {
  const [loading, setLoading] = useState(false);
  const [replies, setReplies] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [isReplying, setIsReplying] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [inputActive, setInputActive] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [openConfModal, setOpenConfModal] = useState(false);

  const replyRef = useRef(null);
  const toastRef = useRef();
  let { user } = useSelector((state) => state.userLogin?.data) || {};

  const highlightedElementRef = useRef(null);

  useEffect(() => {
    if (commentId && commentId === comment?._id) {
      setTimeout(() => {
        highlightedElementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100); // Adjust the delay as needed
    }
  }, [commentId, comment]);

  useEffect(() => {
    if (inputActive && replyRef.current) {
      replyRef.current.focus();
    }
  }, [inputActive]);

  const open = Boolean(anchorEl);

  const handleEnterPress = async (e) => {
    if (e.key === "Enter" && e.keyCode === 13 && replyText.trimStart() !== "") {
      e.preventDefault();
      const data = await handleReplySubmit(
        type === "reply" ? comment?.root_comment_id : comment._id,
        comment._id,
        replyText,
      );
      setReplies((prev) => [data, ...prev]);
      setReplyText("");
      setIsReplying(!isReplying);
    }
  };
  const handleReply = async (e) => {
    const data = await handleReplySubmit(
      type === "reply" ? comment?.root_comment_id : comment._id,
      comment._id,
      replyText,
    );
    setReplies((prev) => [data, ...prev]);
    setReplyText("");
    setIsReplying(!isReplying);
  };

  const handleConfDelete = async () => {
    setAnchorEl(null);
    const response = await handleDelete(comment?._id);
    setComments((prevComments) =>
      prevComments.map((cmt) => {
        if (cmt._id === comment?._id) {
          // Update the comment's text with the newCommentText
          return {
            ...cmt,
            comment_text: response?.data?.comment?.comment_text,
            status: response?.data?.comment?.status,
          };
        }
        return cmt;
      }),
    );
    setOpenConfModal(false);
  };
  const handleConfirmEdit = async () => {
    setAnchorEl(null);
    const data = await handleEdit(comment?._id, commentText);
    data &&
      setComments((prevComments) =>
        prevComments.map((cmnt) => {
          if (cmnt._id === comment?._id) {
            // Update the comment's text with the edited text
            return {
              ...cmnt,
              comment_text: data?.updatedComment?.comment_text,
              edited: data?.updatedComment?.edited,
              updatedAt: data?.updatedComment?.updatedAt,
            };
          } else {
            return cmnt;
          }
        }),
      );
    data && toastRef.current.showToastMessage(`${data?.message}`, "success");
    setModalOpen(false);
  };

  const commentClassName = type === "reply" ? "comment replies" : "comment";
  const isHighlighted = commentId === comment?._id ? "highlightedComment" : "";

  return (
    <>
      <div className={`${commentClassName} ${isHighlighted}`} ref={highlightedElementRef}>
        <div className="comment-info">
          <div className="profile-icon">{comment?.creator[0]?.email?.charAt(0)?.toUpperCase()}</div>
          <div className="comment-meta">
            <div className="comment-user">{comment?.creator[0]?.email?.split("-")[3]}</div>
            <div>
              <b>&middot;</b>
            </div>
            <div className="comment-date">{moment(comment?.createdAt).fromNow()}</div>
          </div>
          <div className="reply-tag">
            {type === "reply" && "@" + parentCreator + " : " + parentText}
          </div>
        </div>
        <div
          className="comment-text"
          style={{ opacity: `${comment?.status === "inactive" && "0.7"}` }}
        >
          {comment?.status === "inactive" ? "Deleted Comment" : comment?.comment_text}
        </div>
        <div className="comment-actions">
          <button
            className="reply-button"
            onBlur={() => setInputActive(false)}
            disabled={comment?.status === "inactive"}
            onClick={() => {
              setIsReplying(!isReplying);
              setInputActive(true);
            }}
          >
            {isReplying ? "Cancel" : "Reply"}
          </button>
          <button
            className="reply-button"
            disabled={noMore || comment?.status === "inactive"}
            onClick={async () => {
              setLoading(true);
              const data = await getRepliesByParentId(comment?._id);
              !data.length && setNoMore(true);
              setReplies((prev) => {
                const uniqueReplies = [...prev, ...data].filter(
                  (reply, index, self) => index === self.findIndex((r) => r._id === reply._id),
                );
                return uniqueReplies;
              });
              setLoading(false);
            }}
          >
            View replies
          </button>
          {comment?.status === "active" && comment?.creator[0]?._id === user.id && (
            <div
              className="vertical-menu"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setCommentText(comment?.comment_text);
              }}
            >
              &middot;&middot;&middot;
            </div>
          )}
          {comment?.edited && (
            <div className="edited">Edited ({moment(comment?.updatedAt).fromNow()})</div>
          )}
        </div>
        {noMore && <p className="no-more-replies">No more replies to be loaded...</p>}
        {isReplying && (
          <div className="reply-input">
            <TextField
              label="Enter your reply here"
              fullWidth
              size="small"
              variant="outlined"
              inputRef={replyRef}
              value={replyText}
              onChange={(e) => setReplyText(e.target.value.trimStart())}
              onKeyDown={handleEnterPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled={!replyText}>
                      <SendIcon
                        sx={{ color: `${replyText ? "#1976D2" : "#d0cccc"}` }}
                        onClick={handleReply}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </div>
      {loading ? (
        <LoadingMore />
      ) : (
        <>
          {replies?.map((reply) => (
            <Comment
              key={reply?._id}
              comment={reply}
              setComments={setReplies}
              replyText={replyText}
              setReplyText={setReplyText}
              getRepliesByParentId={getRepliesByParentId}
              handleReplySubmit={handleReplySubmit}
              type={"reply"}
              parentCreator={comment?.creator[0]?.email?.split("-")[3]}
              parentText={
                comment?.comment_text?.length > 15
                  ? comment?.comment_text?.substring(0, 15) + "..."
                  : comment?.comment_text
              }
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          ))}{" "}
        </>
      )}
      <FMModal
        modalOpen={modalOpen}
        modalClose={() => {
          setModalOpen(false);
          setAnchorEl(false);
          setCommentText("");
        }}
        modalHeader={"Edit Comment"}
        className="commentEdit"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(rgb(161, 196, 253), rgb(194, 233, 251))",
        }}
      >
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Edit comment"
              size="small"
              variant="outlined"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
          </Grid>
          <Grid item>
            <ButtonMain
              className={"confirm_button relative"}
              onClick={handleConfirmEdit}
              disabled={commentText.trim() === comment?.comment_text}
            >
              Confirm
            </ButtonMain>
          </Grid>
          <Grid item>
            <ButtonMain
              className={"cancel_button"}
              onClick={() => {
                setModalOpen(false);
                setAnchorEl(false);
                setCommentText("");
              }}
            >
              Cancel
            </ButtonMain>
          </Grid>
        </Grid>
      </FMModal>
      <ToastNotify ref={toastRef} duration={4000} />
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => setModalOpen(true)}>Edit</MenuItem>
        <MenuItem onClick={() => setOpenConfModal(true)}>Delete</MenuItem>
      </Menu>
      <ConfirmModal
        handleConfirm={handleConfDelete}
        confirmText={"Once Deleted Can't be restored!"}
        headerText={"Delete Comment"}
        showConfirmButton={true}
        handleClose={() => {
          setOpenConfModal(false);
          setAnchorEl(null);
        }}
        confLoading={loading}
        confModal={openConfModal}
      />
    </>
  );
};

export default Comment;
