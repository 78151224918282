import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import { Container } from "@mui/material";

const AdminLayout = () => {
  const { isLoggedIn, user } = useSelector((state) => state.userLogin.data);

  if (isLoggedIn && user?.type !== "admin") {
    return "";
  }
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <Outlet />
      </HeaderContainer>
    </Container>
  );
};

export default AdminLayout;
