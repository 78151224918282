import React from "react";
import { Box, Fade, Menu, MenuItem } from "@mui/material";

const LearnerProperties = ({
  anchorEl,
  open,
  handleClose,
  obtainedMark,
  maxMark,
  attempts,
  blockType,
  keywords,
  correctAnswer,
  scoreType,
}) => {
  return (
    <Menu
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={handleClose} className="blockPropertiesWrapper">
        <Box>
          {" "}
          <strong>Block Type:</strong> {blockType}
        </Box>
        <Box>
          {" "}
          <strong>Attempts:</strong> {attempts || 0}
        </Box>
        <Box>
          {" "}
          <strong>Score Type:</strong> {scoreType}
        </Box>
        {blockType === "response" ? (
          <Box>
            {" "}
            <strong>Keywords:</strong>{" "}
            {keywords && keywords.length > 0 && keywords.map((k, i) => <span key={i}>{k}</span>)}
          </Box>
        ) : (
          blockType === "mcq" && (
            <Box>
              <strong>Correct Answer:</strong>{" "}
              {correctAnswer && correctAnswer.map((c, i) => <span key={i}>{c.text}</span>)}{" "}
            </Box>
          )
        )}
        <Box>
          {" "}
          <strong>Obtained Score:</strong> {obtainedMark || 0}
        </Box>
        <Box>
          {" "}
          <strong>Max Mark:</strong> {maxMark || 0}
        </Box>
      </MenuItem>
    </Menu>
  );
};

export default LearnerProperties;
