import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

import "./ScoreCard.scss";

import { ParagraphText } from "../Typography";
import EnrollmentInfoContainer from "./EnrollmentInfoContainer";
import DoughnutChart from "./DoughnutChart";
import ScoreBubble from "./ScoreBubble";

export default function SimpleTable({
  allScores,
  allModules,
  dateOfEnrollment,
  numberOfDaysSinceEnrollment,
}) {
  const RenderTableRows = ({ data }) => {
    return (
      <>
        {data.map((item, i) => (
          <>
            <TableRow key={i} sx={{ background: item.container === null ? "#ECF6FF" : "white" }}>
              <TableCell align={"left"}>
                <div style={{ fontSize: "16px" }}>
                  {item.container === null ? (
                    <span style={{ fontWeight: "bold", color: "#002B51" }}>{item.title}</span>
                  ) : (
                    <span style={{ marginLeft: "20px", textAlign: "center" }}>{item.title}</span>
                  )}
                </div>
              </TableCell>
              {allScores && !allScores.allModules[item._id]?.maxMarks ? (
                <TableCell align="center">-</TableCell>
              ) : (
                <TableCell align="right" style={{ fontSize: "16px" }} className="moduleScoreMain">
                  <ScoreBubble
                    obtainedMarks={allScores && allScores.allModules[item._id]?.obtainedMarks}
                    maxMarks={allScores && allScores.allModules[item._id]?.maxMarks}
                  />
                </TableCell>
              )}
            </TableRow>
            {item.children && <RenderTableRows data={item.children} />}
          </>
        ))}
      </>
    );
  };

  const chartData = [
    { value: allScores?.obtainedPercentage?.toFixed(2), label: "A", color: "rgb(74, 207, 64)" },
    {
      value: 100 - allScores?.obtainedPercentage?.toFixed(2),
      label: "B",
      color: "rgb(255, 240, 243)",
    },
  ];

  return (
    <Box width={"100%"} sx={{ gap: "1rem", display: "flex", flexDirection: "column" }}>
      <Box className="scoreBoardWrapper">
        <Box className="scoreBlock">
          <Box className="chartWrapper">
            <ParagraphText className="scoreHeadingText">Overall Percentage</ParagraphText>
            <DoughnutChart
              data={chartData}
              label={`${Math.round(allScores?.obtainedPercentage)}%`}
              labelColor={"white"}
            />
          </Box>
          <Box className="verticalLine" style={{ minHeight: "120px" }}></Box>
          <Box className="receivedAndMaxWrapper">
            <ParagraphText className="receivedAndMaxScore">
              {allScores.obtainedMarks} / {allScores.maxMarks}
            </ParagraphText>
            <ParagraphText>Your Score</ParagraphText>
          </Box>
        </Box>
        <EnrollmentInfoContainer
          enrollmentDate={dateOfEnrollment}
          daysFromEnrollment={numberOfDaysSinceEnrollment}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{ width: "100%", justifyContent: "center", display: "flex" }}
      >
        <Table sx={{ width: "100%", fontSize: "16px" }}>
          <TableHead sx={{ bgcolor: "#365082" }}>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "bold", fontSize: "16px", color: "white" }}>
                All Modules
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "white", width: "50%" }}
              >
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{allModules && <RenderTableRows data={allModules} />}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
