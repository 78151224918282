import { Box, Tab, Tabs } from '@mui/material';
import React from 'react'

import './tabs.scss'

const ManagedTabs =({tabs, tabsValue, handleTabChange})=>{

  return (
    <Box className='tabsMain'>
      <Tabs
        value={tabsValue}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
        
      >
        { tabs.map((item, i)=>(
        <Tab className='specific-tab' key={i} value={item.value} label={item.label}/>
        ))}
      </Tabs>
    </Box>
  );
}

export default ManagedTabs