import React from "react";
import { Box } from "@mui/system";
import { ParagraphText } from "../Typography";
import DoughnutChart from "./DoughnutChart";

const AdvanceScoreCard = ({ domain }) => {
  let chartData = [];

  if (domain?.obtainedPercentage) {
    chartData = [
      { value: domain?.obtainedPercentage?.toFixed(2), label: "A", color: "#328ffa" },
      {
        value: 100 - domain?.obtainedPercentage?.toFixed(2),
        label: "B",
        color: "#c1d9f5",
      },
    ];
  } else {
    chartData = [{ value: 100, label: "A", color: "#c1d9f5" }];
  }

  return (
    <Box className="advanceCard">
      <Box className={"advanceTitleText"}>{domain.domainTitle}</Box>
      <Box className="advanceScoreWrapper">
        <DoughnutChart
          data={chartData}
          label={`${Math.round(domain.obtainedPercentage)}%`}
          labelColor={"#435e7d"}
        />
        <Box className="verticalLine" style={{ minHeight: "120px" }}></Box>
        <Box className="advanceScoreContainer">
          <ParagraphText className={"advanceScoreHeading"}>Score</ParagraphText>
          {domain.maxMarks > 0 ? (
            <ParagraphText className={"advanceScoreText"}>
              {domain.obtainedMarks}/{domain.maxMarks}
            </ParagraphText>
          ) : (
            <ParagraphText className={"advanceScoreText"}>0/0</ParagraphText>
          )}
        </Box>
      </Box>
    </Box>
  );
  //   ));
};

export default AdvanceScoreCard;
