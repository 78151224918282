import { ThemeProvider } from "@emotion/react";
import { Box, IconButton, Tooltip, Typography, Zoom, createTheme } from "@mui/material";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import React, { useState } from "react";

import "./ExportCourseContent.scss";

const ContentJSONTable = ({ jsonList, title }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableHead: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              textAlign: "center",
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
      },
    });
  const columns = [
    {
      name: "Language",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={0} onClick={() => updateDirection(1)} className="json_table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <>
            <Typography variant="body2" textAlign={"center"}>
              {value}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "Generated At",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={2} onClick={() => updateDirection(2)} className="json_table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <>
            <Typography variant="body2" textAlign={"center"}>
              {value}
            </Typography>
          </>
        ),
      },
    },
    {
      name: "JSON Link",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={1} onClick={() => updateDirection(1)} className="json_table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => {
          return (
            <>
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <IconButton
                  onClick={() => {
                    window.open(value, "_blank");
                  }}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={`${isCopied ? "JSON link Copied" : "Copy JSON link"}`}
                >
                  <IconButton
                    color="default"
                    onClick={() => {
                      handleCopy(value);
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    filterType: "dropdown",
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    elevation: 0,
    dense: true,
  };

  const data = jsonList?.map((el) => {
    const generatedTime = moment(el?.generatedTime).format("DD MMM, YYYY");
    return [el?.lang, generatedTime, el?.link];
  });

  return (
    <>
      <Box mt={5}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable title={title} data={data} columns={columns} options={options} />
        </ThemeProvider>
      </Box>
    </>
  );
};

export default ContentJSONTable;
