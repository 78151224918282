import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenModal = ({ dialogTitle, children, open, setOpen }) => {
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        sx={{ "& .MuiDialog-paperFullScreen": { backgroundColor: "#eff6ff" } }}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "sticky", top: 0, bgcolor: "#111333" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {dialogTitle}
            </Typography>
            <Box
              sx={{
                "&:hover": {
                  bgcolor: "warning.main",
                },
              }}
            >
              <Button
                autoFocus
                color="inherit"
                variant="outlined"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        {children}
      </Dialog>
    </React.Fragment>
  );
};

export default FullScreenModal;
