import React from "react";
import PageHeader from "../PageHeader";

import { Box, Container, Grid, IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useEffect, useState } from "react";

import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import ManagedTabs from "../Tabs";
import { useNavigate } from "react-router-dom";
import ButtonMain from "../ButtonMain";
import CertificatePreview from "./CertificatePreview/CertificatePreview";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const CertificateListingLayout = () => {
  const [certificates, setCertificates] = useState([]);
  const [signatories, setSignatories] = useState([]);
  const [tabValue, setTabValue] = React.useState("certificate");
  const [modalOpen, setModalOpen] = useState(false);

  let navigate = useNavigate();

  let UserData = useSelector((state) => state.userLogin);
  let isLoggedIn = UserData?.data?.isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      if (UserData?.data?.user?.type !== "admin") {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  useEffect(() => {
    getAllCertificate();
  }, []);

  const getAllCertificate = async () => {
    //get all certificate configurations
    await GET(`${Constants.getAllCertificate}`)
      .then((res) => {
        setCertificates(res?.data?.certificates);
        setSignatories(res?.data?.signatories);
      })
      .catch((err) => console.log(err));
  };

  let tabs = [
    {
      value: "certificate",
      label: "Primary Certificate Configuration",
    },
    {
      value: "signatory",
      label: "Additional Signatory Configuration",
    },
  ];
  const handleDelete = async (e, item) => {
    e.stopPropagation();
    await swal({
      title: "Are you sure?",
      text: "Configuration, once deleted can't be restored.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let payload = { configId: item?._id };
        // delete certificate config if not associated with any course or cohort
        await POST(`${Constants.deleteCertificateConfig}`, payload)
          .then((response) => {
            getAllCertificate();
            swal("success", `${response?.data?.message}`, "success");
          })
          .catch((err) => swal("Oops!", `${err?.response?.data?.message}`, "error"));
      } else {
        swal.close();
      }
    });
  };
  if (!isLoggedIn) {
    return "";
  }
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <Grid className="aboutLayoutMain">
        <div className="headerArea">
          <PageHeader headerText="Certificate Dashboard" paragraphText="" />
          <ButtonMain
            className={"view_cert_button"}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Preview Certificate
          </ButtonMain>
        </div>
        <ManagedTabs tabs={tabs} handleTabChange={handleTabChange} tabsValue={tabValue} />
        {tabValue === "certificate" ? (
          <Box sx={{ mt: "2rem" }}>
            {certificates?.map((item) => (
              <Grid
                container
                className="courseCardMain enrolled_card"
                alignItems="center"
                key={item?._id}
              >
                <Grid item sm={6} xs={12}>
                  <Box variant="h6" fontWeight="bold">
                    {item?.configuration_title}
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12} className="enrolled_buttons_box">
                  <Tooltip title="Edit">
                    <IconButton>
                      <EditOutlinedIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/certificate-config", { state: item });
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton>
                      <DeleteOutlineOutlinedIcon onClick={(e) => handleDelete(e, item)} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Box>
        ) : (
          <Box sx={{ mt: "2rem" }}>
            {signatories?.map((item) => (
              <Grid
                container
                className="courseCardMain enrolled_card"
                alignItems="center"
                key={item?._id}
              >
                <Grid item sm={6} xs={12}>
                  <Box variant="h6" fontWeight="bold">
                    {item?.configuration_title}
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12} className="enrolled_buttons_box">
                  <Tooltip title="Edit">
                    <IconButton>
                      <EditOutlinedIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/certificate-config", {
                            state: { ...item, isSignatory: true },
                          });
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton>
                      <DeleteOutlineOutlinedIcon onClick={(e) => handleDelete(e, item)} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
        <CertificatePreview
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          certificates={certificates}
          signatories={signatories}
        />
      </Grid>
    </Container>
  );
};

export default CertificateListingLayout;
