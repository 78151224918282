import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Grid, Modal, TextField } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CloseIcon from "@mui/icons-material/Close";

import { HeaderText, ParagraphText } from "../../Typography";

import "./CourseModal.scss";
import ButtonMain from "../../ButtonMain";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useSelector } from "react-redux";
import { ToastNotify } from "../../SnackBar/ToastNotify";

const ShowUniqueID = ({ uniqueIDOpen, uniqueIDClose, uniqueID }) => {
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState({
    error: false,
    helperText: "",
    disabled: true,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  let UserData = useSelector((state) => state.userLogin);
  const toastRef = useRef();

  useEffect(() => {
    setEmail(UserData?.data?.user?.reset_email);
  }, [UserData]);
  const handleClipboard = async () => {
    try {
      await navigator.clipboard.writeText(uniqueID);
      setalertMessage("Unique Id Copied Successfully.");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleUpdateEmail = async () => {
    if (email) {
      const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailTest.test(email)) {
        setValidate((prev) => ({
          ...prev,
          error: true,
          helperText: "Please enter a valid email.",
        }));
      } else {
        setValidate((prev) => ({ ...prev, error: false, helperText: "" }));
        try {
          //update reset email
          const res = await GET(
            `${Constants.checkEmailAvailability}/${UserData?.data?.user?.id}/?reset_email=${email}`,
          );
          if (res?.data?.success) {
            const localUserData = JSON.parse(localStorage.getItem("userData"));
            localUserData.user.reset_email = res?.data?.data?.reset_email;
            localStorage.setItem("userData", JSON.stringify(localUserData));
            // const localUserData =  UserData
            // localUserData.user.reset_email =  res?.data?.data?.reset_email
            // dispatch(userLoginAction(localUserData))
            setalertMessage("Your email has been successfully updated.");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
            setEmail(res?.data?.data?.reset_email);
          }
        } catch (err) {
          toastRef.current.showToastMessage(err?.response?.data?.message, "error");
        }
      }
    } else {
      setValidate((prev) => ({ ...prev, error: true, helperText: "Email cannot be empty." }));
    }
  };
  return (
    <>
      <Modal
        open={uniqueIDOpen}
        onClose={() => {
          window.location.reload();
          uniqueIDClose();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="courseModalContainer"
      >
        <Grid container className="showUniqueID_Main">
          <Box className="uniquiID-headerContainer">
            <HeaderText className="uniqueID_header">Important!</HeaderText>
            <Box>
              {showAlert && (
                <Alert variant="filled" severity="success">
                  {alertMessage}
                </Alert>
              )}
            </Box>
            <Box className="uniqueIDiconClose">
              <CloseIcon
                onClick={() => {
                  window.location.reload();
                  uniqueIDClose();
                }}
              />
            </Box>
          </Box>
          <ParagraphText className="uniqueID_textMain">
            <span className="uniqueID_text" onClick={handleClipboard}>
              {uniqueID} &nbsp;
            </span>
            is your unique id. This will allow you to login on FramerSpace. Please note this id at a
            secure place. Note: You will lose the access of your account, in case you lose your id
            and password.
          </ParagraphText>
          <Box className="copyContainer">
            <Box>{uniqueID}</Box>
            <Box className="copyRound" onClick={handleClipboard}>
              <ContentCopyRoundedIcon sx={{ fill: "white" }} />
            </Box>
          </Box>
          <Box my={3} width="100%">
            <TextField
              fullWidth
              autoComplete={"false"}
              placeholder={"Email"}
              value={email}
              type="email"
              onChange={(e) => {
                setValidate((prev) => ({ ...prev, disabled: false }));
                setEmail(e.target.value);
              }}
              label="Email"
              error={validate.error}
              helperText={validate.helperText}
            />
          </Box>
          <ButtonMain
            className={"update_email_button"}
            onClick={handleUpdateEmail}
            // style={{ }}
            disabled={validate?.disabled}
          >
            Save Email
          </ButtonMain>
        </Grid>
      </Modal>
      <ToastNotify ref={toastRef} duration={3000} />
    </>
  );
};

export default ShowUniqueID;
