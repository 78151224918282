import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileVertical from "./MobileVertical";
import DesktopVertical from "./DesktopVertical";

export default function Vertical({
  setblockModOpen,
  setHeadingText,
  courseDetails,
  setCourseBlocks,
  courseOne,
  blockModalOpen,
  type,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile ? (
        <MobileVertical
          setblockModOpen={setblockModOpen}
          setHeadingText={setHeadingText}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          courseOne={courseOne}
          blockModalOpen={blockModalOpen}
          type={type}
        />
      ) : (
        <DesktopVertical
          setblockModOpen={setblockModOpen}
          setHeadingText={setHeadingText}
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          courseOne={courseOne}
          blockModalOpen={blockModalOpen}
          type={type}
        />
      )}
    </>
  );
}
