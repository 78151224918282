import { Backdrop, Box, CircularProgress, Grid, Paper, Skeleton } from "@mui/material";
import React from "react";
import "./loader.scss";

const CourseListingSkeleton = () => {
  return (
    <div className="course-loader-panel">
      <Paper elevation={3} id={"paperLight"}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} className="grid-loader-container">
              <div className="course-title">
                <Skeleton variant="text" height={50} width={"30%"} animation="pulse" />
              </div>
              <div className="course-tagline">
                <Skeleton variant="text" height={30} fullWidth animation="pulse" />
                <Skeleton variant="text" height={30} fullWidth animation="pulse" />
                <Skeleton variant="text" height={30} width={"20%"} animation="pulse" />
              </div>
              <div className="skeleton-tags">
                <Skeleton variant="text" height={50} width={"40%"} animation="pulse" />
              </div>
            </Grid>
            <Grid item xs={12} md={4} className="img-loader-container">
              <Skeleton
                variant="rectangular"
                width={400}
                height={230}
                className="course-loader-img"
                animation="pulse"
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};
const CourseDetailsSkeleton = () => {
  return (
    <div className="course-loader-panel">
      <Paper elevation={3} id={"paperLight"}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className="grid-loader-container">
              <div>
                <Skeleton variant="text" height={30} fullWidth />
                <Skeleton variant="text" height={30} fullWidth />
                <Skeleton variant="text" height={30} fullWidth />
                <Skeleton variant="text" height={30} width={"70%"} />{" "}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};
const EnrolledCoursesLoader = () => {
  return (
    <div className="course-loader-panel">
      <Paper elevation={3} id={"paperLight"}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className="grid-loader-container">
              <div>
                <Skeleton variant="text" height={30} fullWidth />
                <Skeleton variant="text" height={30} fullWidth />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

const CircularIndeterminate = ({ size }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

const FullScreenLoader = ({ open }) => {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const BouncingDotsLoader = ({ open }) => {
  return (
    <div className="bounce-loader">
      {open && (
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

const CourseNavigationLoader = () => {
  return (
    <Box display={"flex"} alignItems="center" p={2}>
      <Skeleton
        animation="wave"
        variant="circular"
        sx={{ mr: 2, flexShrink: 0 }}
        width={24}
        height={24}
      />
      <Skeleton variant="rectangular" animation="wave" width={"100%"} height={20} />
    </Box>
  );
};

const LoadingMore = () => {
  return (
    <div className="loading_more_container">
      <span className="loader_more"></span>
    </div>
  );
};

const TabbedLayoutLoader = () => {
  return (
    <div className="course-loader-panel">
      <Paper elevation={0} id={"paperLight"}>
        <Box>
          <Box>
            <Box sx={{ width: { xs: "75%", md: "50%" } }} className="grid-loader-container">
              <Skeleton variant="text" height={50} fullWidth />
              <Skeleton variant="text" height={100} fullWidth />
            </Box>
            <Box mx={3}>
              <Skeleton variant="text" height={25} fullWidth />
              <Skeleton variant="text" height={25} fullWidth />
              <Skeleton variant="text" height={25} fullWidth />
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export {
  CourseListingSkeleton,
  CourseDetailsSkeleton,
  CircularIndeterminate,
  FullScreenLoader,
  BouncingDotsLoader,
  EnrolledCoursesLoader,
  CourseNavigationLoader,
  LoadingMore,
  TabbedLayoutLoader,
};
