import React, { useContext, useState } from "react";

import { HeaderText } from "../Typography";

import "./modal.scss";
import { POST } from "../../config/Api";
import { validateEmail } from "../Validators";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Constants } from "../../config/Constants";
import ModalMain from "../ModalMain/ModalMain";
import { AuthContext } from "../../App";

const ForgotModal = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userEmailError, setUserEmailError] = useState("");
  const { authStates, setAuthStates } = useContext(AuthContext);

  const handleForgotPassword = () => {
    if (userEmail === "") {
      setUserEmailError("Please enter email");
    } else if (validateEmail(userEmail)) {
      let body = {
        email: userEmail,
      };
      //API for forgot password
      POST(Constants.forgotPassword, body)
        .then((res) => {
          setUserEmailError(res?.data?.successMessage);
        })
        .catch((err) => {
          setUserEmailError(err?.response?.data?.message);
        });
    } else {
      setUserEmailError("Please enter a valid email");
    }
  };
  return (
    <ModalMain
      modalOpen={authStates.modalOpen}
      modalClose={() => {
        setAuthStates((prev) => ({
          ...prev,
          modalOpen: false,
        }));
        setUserEmail("");
        setUserEmailError("");
      }}
      gradientLeft={{ backgroundImage: "linear-gradient(#ffffff, #ffffff)" }}
      gradientRight={{ backgroundImage: "linear-gradient(#C8B0F9, #D9C9F3)" }}
      imgUrl={`${Constants.imgPath}/graphic-forgot@2x.png`}
      rightBoxTitle="Remembered your password?"
      rightBoxBtn="Log in"
      onRightBoxBtnClick={() => {
        setAuthStates((prev) => ({
          ...prev,
          loginOpen: true,
          forgotOpen: false,
        }));
        setUserEmail("");
        setUserEmailError("");
      }}
    >
      <div className="loginLayoutMain">
        <Box display={"flex"} flexDirection={"column"} rowGap={2}>
          <HeaderText className="loginInputHeader">Retrieve account</HeaderText>
          <TextField
            type="text"
            label="Email address"
            placeholder="Email address"
            value={userEmail}
            onChange={(e) => {
              setUserEmail(e.target.value);
              setUserEmailError("");
            }}
            error={Boolean(userEmailError)}
            helperText={userEmailError}
          />
          <Box>
            <Typography fontSize={"14px"}>
              If an account exists, we will send you an email with your unique user id and a link to
              reset to your password. You may need to check your spam folder or wait for few
              minutes.
            </Typography>
          </Box>
        </Box>
        <div className="leftBtnMain">
          <Button
            variant="contained"
            fullWidth
            sx={{ textTransform: "none" }}
            onClick={handleForgotPassword}
          >
            Retrieve
          </Button>
        </div>
      </div>
    </ModalMain>
  );
};

export default ForgotModal;
