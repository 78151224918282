import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

const SourceTextSwitcher = ({ checked, handleChange, srcText, srcTitleText }) => {
  return (
    <>
      {!!srcText || !!srcTitleText ? (
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="View Source Text"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default SourceTextSwitcher;
