import * as React from "react";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Zoom from "@mui/material/Zoom";
import "./BackToTop.scss";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../utils";

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 40 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function BackToTop(props) {
  const { activeTab } = useQueryParams();

  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  // React.useEffect(() => {

  // }, [activeTab]);
  // if (activeTab === "discussion") {
  //   return "";
  // }
  return (
    <>
      {activeTab !== "discussion" && (
        <div id="back-to-top-anchor">
          <ScrollTop {...props}>
            <Fab
              color="secondary"
              size="small"
              aria-label="scroll back to top"
              className={isLoggedIn ? "post-back-to-top-icon" : "pre-back-to-top-icon"}
            >
              <KeyboardArrowUpIcon />
            </Fab>
            <Box
              sx={{
                padding: "1px",
                marginLeft: "-2rem",
                overflow: "hidden",
                clip: "rect(0, 0, 0, 0)",
                border: 0,
                color: "#010105",
                cursor: "pointer",
                // backgroundColor: "#166bda",
              }}
            >
              BACK TO TOP
            </Box>
          </ScrollTop>
        </div>
      )}
    </>
  );
}
