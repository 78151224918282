import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
export default function ColorPallete({ formData, setFormData }) {
  const [color, setColor] = useColor("hex", formData.selectedColor);
  const handleColorChange = (newColor) => {
    setColor(newColor); // Update color state
    setFormData({ ...formData, selectedColor: newColor.hex });
  };
  return (
    <div className="color-picker">
      <ColorPicker
        width={100}
        height={180}
        color={color}
        onChange={handleColorChange}
        hideHSV
        dark
      />
    </div>
  );
}
