import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ButtonMain from "../../../ButtonMain";
import BlockActions from "../BlockActions/BlockActions";
import "./LinkView.scss";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const LinkView = ({
  link,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);

  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(link?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const handleOpen = () => {
    window.open(`${link?.provider?.url}`, "_blank");
  };
  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && link?.is_hidden ? (
        ""
      ) : (
        <Grid
          container
          className={` link-block 
      ${isLoggedIn ? `${link?.theme} postBgColor` : `${link?.theme} defaultStyle`}
    `}
          style={{
            background: `${link?.theme_type === "color" ? link?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={link?._id}
              block={link}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}
          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          {link?.embed?.code && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent="center"
              p={2}
              width={"100%"}
              dangerouslySetInnerHTML={{ __html: link?.embed?.code }}
            ></Box>
          )}
          {!link?.embed?.code && link?.image?.m && (
            <Grid item lg={3} className="" sx={{ width: "100%" }}>
              <img
                src={`${link?.image?.m}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                alt=""
              />
            </Grid>
          )}
          <Grid
            item
            lg={link?.image?.m && !link?.embed?.code ? 9 : 12}
            p={2}
            sx={{ width: "100%" }}
          >
            <p className="block-title">{link?.title}</p>
            <p className="block-provider">
              <ButtonMain onClick={() => handleOpen()} className="block-provider-button">
                {link?.provider?.name}
              </ButtonMain>
              {/* {link?.size && <span>| {link?.size}</span>} */}
            </p>
            {link?.text && (
              <div className="block-text" dangerouslySetInnerHTML={{ __html: link?.text }}></div>
            )}
            <ArtView art={(block || link)?.art} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LinkView;
