import React from "react";
import { Box } from "@mui/material";
import { ParagraphText } from "../Typography";

const EnrollmentInfoThin = ({ enrollmentDate, daysFromEnrollment }) => {
  return (
    <Box className="enrollmentInfoThinWrapper">
      <Box className="enrollmentInfoThinDateWrapper">
        <ParagraphText className="enrollThinHeading">Enrollment Date: &nbsp;</ParagraphText>
        <ParagraphText className="enrollmentInfoThinDate">{enrollmentDate}</ParagraphText>
      </Box>
      <Box className="verticalLine"></Box>
      <Box className="enrollmentInfoThinDateWrapper">
        <ParagraphText className="enrollThinHeading">Days Passed: &nbsp;</ParagraphText>
        <ParagraphText className="enrollmentInfoThinDate">{daysFromEnrollment}</ParagraphText>
      </Box>
    </Box>
  );
};

export default EnrollmentInfoThin;
