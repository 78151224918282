import React, { useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Container } from "@mui/material";
import "./AddMembersModal.scss";
import SearchBar from "../SearchBar";
import { useEffect } from "react";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { useSelector } from "react-redux";
import ManagedTabs from "../Tabs";
import { InputBox } from "../InputBox/InputBox";
import ButtonMain from "../ButtonMain";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { useLocation } from "react-router-dom";
import FullScreenModal from "../courses/CourseModals/FullScreenModal";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

const AddMembersModal = ({
  showMembersModal,
  setShowMembersModal,
  addMembersData,
  courseMembersProps,
  reRenderList,
  setReRenderList,
  title,
}) => {
  let UserData = useSelector((state) => state.userLogin.data);
  const [tabValue, setTabValue] = React.useState("single");
  const [membersList, setMembersList] = useState([]);
  const [multipleMemberList, setmultipleMemberList] = useState([]);
  const [members, setMembers] = useState("");
  const [creatorData, setCreatorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMultiple, setLoadingMultiple] = useState(false);
  const [refetchMembers, setRefetchMembers] = useState(false);
  const [disable, setDisable] = useState(false);

  const toastRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    //get member list by course id and cohort id
    addMembersData?.courseId &&
      GET(`${Constants.cohort}/members/${addMembersData?.courseId}/${addMembersData?.cohort_id}`)
        .then((res) => {
          setLoading(false);

          setMembersList(res.data.members);
          setCreatorData(res.data.creator);
        })
        .catch((err) => {
          setLoading(false);
        });
  }, [addMembersData, refetchMembers, multipleMemberList]);

  useEffect(() => {
    setLoading(true);
    //get member list by course id
    courseMembersProps?.course_id &&
      GET(`${Constants.course}/members/${courseMembersProps?.course_id}`)
        .then((res) => {
          setLoading(false);
          setMembersList(res.data.members);
          setCreatorData(res.data.creator);
        })
        .catch((err) => {
          setLoading(false);
        });
  }, [courseMembersProps, reRenderList]);

  const handleRemoveMember = (member_id) => {
    setLoading(true);
    setLoadingMultiple(true);
    let formData = new FormData();
    formData.append("member_id", member_id);
    formData.append("user_id", UserData?.user?.id);
    POST(
      //remove member from cohort and also remove moderator from course
      !courseMembersProps?.course_id
        ? `${Constants.cohort}/members/remove/${addMembersData?.cohort_id}`
        : `${Constants.courseManage}/removeModerator/${courseMembersProps?.course_id}`,
      formData,
    )
      .then((res) => {
        setLoading(false);
        setLoadingMultiple(false);
        toastRef.current.showToastMessage(res?.data?.message, "success");
        addMembersData?.cohort_id && setRefetchMembers(!refetchMembers);
        toastRef.current.showToastMessage(res?.data?.message, "success");
        addMembersData?.cohort_id && setRefetchMembers(!refetchMembers);
        courseMembersProps?.course_id && setReRenderList(!reRenderList);
      })
      .catch((err) => {
        setLoading(false);
        setLoadingMultiple(false);
        toastRef.current.showToastMessage("Could not remove member?.", "error");
        setRefetchMembers(!refetchMembers);
      });
  };

  const handleTabChange = (event, newTabValue) => {
    location.pathname !== "/managed-courses" && setTabValue(newTabValue);
  };

  let tabs = [
    {
      value: "single",
      label: `${location.pathname === "/managed-courses" ? "Add Moderators" : "Add Facilitators"}`,
    },
    {
      value: "multiple",
      label: `${location.pathname !== "/managed-courses" ? "Add Multiple Facilitators" : ""}`,
    },
  ];

  const handleMultipleMembers = async () => {
    if (members === "") {
      toastRef.current.showToastMessage("Please enter Unique Id's to add .", "error");
    } else {
      setDisable(true);
      setLoadingMultiple(true);
      try {
        let payload = {
          userId: UserData.user.id,
          cohortId: addMembersData?.cohort_id,
          courseId: addMembersData?.courseId,
          members: members?.replace(/\s/g, ""),
        };
        //add multiple member at a time
        const res = await POST(`${Constants.addMultipleMembers}`, payload);
        setMembers("");
        setLoadingMultiple(false);
        setDisable(false);
        setmultipleMemberList(res?.data?.members);
        toastRef.current.showToastMessage(`${res?.data?.message}`, "success");
      } catch (error) {
        toastRef.current.showToastMessage(`${error?.response?.data?.message}`, "error");
        setDisable(false);
      }
    }
  };

  return (
    <div>
      <FullScreenModal
        open={showMembersModal}
        setOpen={() => {
          setTabValue("single");
          setDisable(false);
          setMembersList([]);
          setMembers("");
          setShowMembersModal(false);
        }}
        dialogTitle={
          location.pathname === "/managed-courses" ? (
            <>
              <IconButton color="inherit">
                <GroupAddOutlinedIcon />
              </IconButton>
              Manage Moderators
            </>
          ) : (
            "Manage Facilitators"
          )
        }
      >
        {/* <FMModal
        modalOpen={showMembersModal}
        modalClose={() => {
          setTabValue("single");
          setDisable(false);
          setMembersList([]);
          setMembers("");
          setShowMembersModal(false);
        }}
        
        className="add_member_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(90deg, #E3EEFF, #F3E7E9)",
        }}
      > */}

        <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
          <ManagedTabs tabs={tabs} handleTabChange={handleTabChange} tabsValue={tabValue} />{" "}
          {tabValue === "single" ? (
            <Box sx={{ marginTop: "0.7rem" }}>
              {loading && (
                <Backdrop
                  sx={{ color: "#fff", zIndex: 999 }}
                  open={loading}
                  onClick={() => setLoading(false)}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <SearchBar
                addMembersData={addMembersData}
                showMembersModal={showMembersModal}
                reRenderList={reRenderList}
                setShowMembersModal={setShowMembersModal}
                setReRenderList={setReRenderList}
                course_id={courseMembersProps?.course_id}
                refetchMembers={refetchMembers}
                setRefetchMembers={setRefetchMembers}
              />
              <List>
                <ListItem className="margin-top-2" disablePadding>
                  <ListItemIcon>
                    <Box
                      height={40}
                      width={40}
                      display="flex"
                      alignItems={"center"}
                      justifyContent="center"
                      bgcolor={"#ffffff"}
                      borderRadius={"50%"}
                    >
                      {creatorData?.initials}
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary="Created By" secondary={`${creatorData?.email}`} />
                </ListItem>
              </List>
              <List
                sx={{ width: "100%", overflow: "auto", maxHeight: "400px" }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <Typography variant="body1" component={"strong"}>
                      {title || "Members"}
                    </Typography>
                  </ListSubheader>
                }
              >
                {membersList &&
                  membersList?.map((member) => {
                    return (
                      member?.permit_val !== "inactive" && (
                        <ListItem
                          key={member?.user?.email}
                          className="margin-top-2 bg-white"
                          secondaryAction={
                            <>
                              {!courseMembersProps?.course_id
                                ? member?.permit_val !== "moderator" && (
                                    <IconButton
                                      edge="end"
                                      color="default"
                                      aria-label="comments"
                                      onClick={() => {
                                        handleRemoveMember(member?._id);
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  )
                                : member?.user?._id !== UserData?.user?.id && (
                                    <IconButton
                                      edge="end"
                                      color="default"
                                      aria-label="comments"
                                      onClick={() => {
                                        handleRemoveMember(member?._id);
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  )}
                            </>
                          }
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              <Box
                                height={40}
                                width={40}
                                display="flex"
                                alignItems={"center"}
                                justifyContent="center"
                                bgcolor={"#f5f5f5"}
                                borderRadius={"50%"}
                              >{`${member?.user?.initials}`}</Box>
                            </ListItemIcon>
                            <ListItemText primary={`${member?.user?.email}`} />
                            {member?.permit_val === "moderator" ? (
                              <Chip
                                label="Moderator"
                                size="small"
                                className="moderator_badge"
                                color="info"
                                variant="contained"
                              />
                            ) : (
                              <Chip
                                label="Facilitator"
                                size="small"
                                className="facilitator_badge"
                                color="info"
                                variant="contained"
                              />
                            )}
                          </ListItemButton>
                        </ListItem>
                      )
                    );
                  })}
              </List>
            </Box>
          ) : (
            <Box sx={{ marginTop: "0.7rem", gap: "0.5rem" }}>
              {loadingMultiple && (
                <Backdrop
                  sx={{ color: "#fff", zIndex: 999 }}
                  open={loadingMultiple}
                  onClick={() => setLoadingMultiple(false)}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <List>
                <ListItem className="margin-top-2" disablePadding>
                  <InputBox
                    placeholder="Enter unique IDs separated by semicolon ( ; )"
                    value={members}
                    onChange={(e) => setMembers(e.target.value)}
                  />
                </ListItem>
              </List>
              <List>
                <ListItem className="margin-top-2" disablePadding>
                  <ButtonMain onClick={handleMultipleMembers} disabled={disable}>
                    {" "}
                    Add Facilitators
                  </ButtonMain>
                </ListItem>
              </List>
              <List>
                <ListItem className="margin-top-2" disablePadding>
                  <ListItemIcon>
                    <Box
                      height={40}
                      width={40}
                      display="flex"
                      alignItems={"center"}
                      justifyContent="center"
                      bgcolor={"#ffffff"}
                      borderRadius={"50%"}
                    >
                      {creatorData?.initials}
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary="Created By" secondary={`${creatorData?.email}`} />
                </ListItem>
              </List>

              <List
                sx={{ width: "100%", overflow: "auto", maxHeight: "400px" }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <Typography variant="body1" component={"strong"}>
                      {"Members"}
                    </Typography>
                  </ListSubheader>
                }
              >
                {membersList &&
                  membersList.map((member) => {
                    return (
                      member?.permit_val !== "inactive" && (
                        <ListItem
                          key={member?.email}
                          className="margin-top-2 bg-white"
                          secondaryAction={
                            <>
                              {!courseMembersProps?.course_id
                                ? member?.permit_val !== "moderator" && (
                                    <IconButton
                                      edge="end"
                                      color="default"
                                      aria-label="comments"
                                      onClick={() => {
                                        handleRemoveMember(member?._id);
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  )
                                : member?.user?._id !== UserData?.user?.id && (
                                    <IconButton
                                      edge="end"
                                      color="default"
                                      aria-label="comments"
                                      onClick={() => {
                                        handleRemoveMember(member?._id);
                                      }}
                                    >
                                      <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                  )}
                            </>
                          }
                          disablePadding
                        >
                          <ListItemButton dense>
                            <ListItemIcon>
                              <Box
                                height={40}
                                width={40}
                                display="flex"
                                alignItems={"center"}
                                justifyContent="center"
                                bgcolor={"#f5f5f5"}
                                borderRadius={"50%"}
                              >{`${member?.user?.initials}`}</Box>
                            </ListItemIcon>
                            <ListItemText primary={`${member?.user?.email}`} />
                            {member?.permit_val === "moderator" ? (
                              <Chip
                                label="Moderator"
                                size="small"
                                className="moderator_badge"
                                color="info"
                                variant="contained"
                              />
                            ) : (
                              <Chip
                                label="Facilitator"
                                size="small"
                                className="facilitator_badge"
                                color="info"
                                variant="contained"
                              />
                            )}
                          </ListItemButton>
                        </ListItem>
                      )
                    );
                  })}
              </List>
            </Box>
          )}
        </Container>
        <ToastNotify ref={toastRef} duration={4000} />
      </FullScreenModal>
    </div>
  );
};

export default AddMembersModal;
