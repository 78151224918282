import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Skeleton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import { GET } from "../../config/Api";
import { Constants, userRoles } from "../../config/Constants";
import ReviewCourseCard from "./ReviewCourseCard";
import { ParagraphText } from "../../components/Typography";
import "./review.scss";
import ButtonMain from "../../components/ButtonMain";
import { ToastNotify } from "../../components/SnackBar/ToastNotify";

const LearnerList = () => {
  const [loading, setLoading] = useState(false);
  const [enrolledCourses, setEnrolledCourses] = useState({});
  const location = useLocation();
  const [uniqueId, setUniqueId] = useState(location?.state?.userData?.uniqueId || "");
  const [userId, setUserId] = useState("");

  const navigate = useNavigate();
  const toastRef = useRef();

  let userData = {
    role: userRoles.REVIEWER,
    isLeanerView: true,
    token: "",
    uniqueId: uniqueId,
  };

  const findEnrolledCourses = async (learner_id) => {
    if (learner_id !== "") {
      try {
        setLoading(true);
        const enrolledCourseData = await GET(
          `${Constants.review}/coursesEnrolledbyUser/${learner_id}`,
        );

        const { data } = enrolledCourseData;

        if (data.success) {
          const { allEnrolledCourses, moderatedCourses, facilitatedCourses, user_id } = data;

          if (
            !(allEnrolledCourses.length || moderatedCourses.length || facilitatedCourses.length)
          ) {
            toastRef.current.showToastMessage("No courses found.", "success");
          }

          // Combine all courses into one array
          const combinedCourses = [
            ...allEnrolledCourses,
            ...moderatedCourses,
            ...facilitatedCourses,
          ];

          setEnrolledCourses(combinedCourses);
          setUserId(user_id);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        toastRef.current.showToastMessage(`${error?.response?.data?.message}`, "error");
        setLoading(false);
      }
    } else {
      toastRef.current.showToastMessage("Please enter a unique id.", "error");
    }
  };

  const handleReviewClick = (course) => {
    navigate(
      `/course/${course?.slug || course?.courseDetails?.slug}?cid=${
        course?.courseId || course?.courseDetails?._id
      }`,
      {
        state: {
          userData: {
            ...userData,
            user_id: userId,
          },
        },
      },
    );
  };

  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <ParagraphText className={"reviewPageHeaderText"}>Review Learner Progress</ParagraphText>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Learner unique id / email"
              variant="outlined"
              fullWidth
              size="small"
              value={uniqueId}
              onChange={(e) => setUniqueId(e.target.value)}
              style={{ marginBottom: "16px" }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ButtonMain variant="primary-blue" onClick={() => findEnrolledCourses(uniqueId)}>
              Find Enrollments
            </ButtonMain>
          </Grid>
        </Grid>
        {loading && (
          <Skeleton
            variant="rectangular"
            height={70}
            className="reviewSkeleton"
            animation="pulse"
          />
        )}

        {enrolledCourses && enrolledCourses.length > 0 && (
          <div>
            <ParagraphText className={"reviewHeaders"}>Enrolled Courses</ParagraphText>
            <ReviewCourseCard courses={enrolledCourses} onClick={handleReviewClick} />
          </div>
        )}
      </HeaderContainer>
      <ToastNotify ref={toastRef} duration={3000} />
    </Container>
  );
};

export default LearnerList;
