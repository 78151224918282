import { useRef } from "react";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { ToastNotify } from "../../SnackBar/ToastNotify";

import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";

export default function PaymentsModal({
  courseID,
  cohortID,
  userID,
  paymentsData,
  closePaymentsModal,
  handleFacilitated,
  handleSelfDirected,
}) {
  const toastRef = useRef();

  // Create order
  const handleCreateOrder = async () => {
    // console.log("paymentsData", paymentsData, cohortID);
    // return;
    const orderBody = {
      courseID: courseID,
      cohortID: cohortID || paymentsData?.cohortID,
      cohortType: paymentsData?.type,
      userID: userID,
      cart: [
        {
          name: "Course 1",
          quantity: 1,
        },
      ],
    };

    // console.log("orderBody", orderBody);
    // return;
    try {
      //create Order
      const response = await POST(`${Constants.createOrder}`, orderBody);
      return response?.data?.id;
    } catch (err) {
      toastRef.current.showToastMessage(`${err?.data?.message}`, "error");
    }
  };

  // Capture order on approval
  const handleOnApprove = async (data, actions) => {
    try {
      const orderToBeCaptured = {
        userID: userID,
        courseID: courseID,
        orderID: data.orderID,
      };
      //capture Payal Order
      const response = await POST(`${Constants.capturePayalOrder}`, orderToBeCaptured);

      const details = response?.data?.paymentDetails?.order;
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      // This example reads a v2/checkout/orders capture response, propagated from the server
      // You could use a different API or structure for your 'orderData'
      const errorDetail = Array.isArray(details.details) && details.details[0];

      if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
        return actions.restart();
        // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
      }

      if (errorDetail) {
        let msg = "Sorry, your transaction could not be processed.";
        msg += errorDetail.description ? " " + errorDetail.description : "";
        msg += details.debug_id ? " (" + details.debug_id + ")" : "";
        toastRef.current.showToastMessage(`${msg}`, "error");
      }

      // Successful capture! For demo purposes:
      // const transaction = details.purchase_units[0].payments.captures[0];

      toastRef.current.showToastMessage(
        "Your transaction has been successfully completed. Enrolling you now...",
        "success",
      );
      // Enrolling in SD cohort.
      const paymentID = response?.data?.paymentDetails?._id;
      paymentsData.type === "facilitated"
        ? handleFacilitated({ paymentID })
        : handleSelfDirected({ paymentID });
      // Once the learner is enrolled in the cohort then close the modal.
      closePaymentsModal();
    } catch (error) {
      toastRef.current.showToastMessage(`${error?.data?.message}`, "error");
      // Handle the error or display an appropriate error message to the user
    }
  };

  // Handle Payment Cancellation
  const handleOnCancel = async (data, actions) => {
    try {
      //payment cancellation
      const response = await POST(`${Constants.cancelPayment}`, data);
      toastRef.current.showToastMessage(`${response?.data?.message}`, "error");
    } catch (error) {
      console.log(error);
      toastRef.current.showToastMessage(`${error?.data?.message}`, "error");
    }
  };

  return (
    <div>
      <Dialog
        open={paymentsData.modalOpen}
        onClose={closePaymentsModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <Box p={5} pb={0}>
          <PayPalScriptProvider
            options={{
              clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
              // "AdCNhPVEzrFHlX7yBo22qeRDiUtVm_Ud8491qgOMZOpLESBe0NnZXdLaq4bnByjGrXiQjRomW3TCZSe2",
            }}
          >
            <PayPalButtons
              style={{
                color: "gold",
                label: "checkout",
              }}
              // Order is created on the server and the order id is returned
              createOrder={handleCreateOrder}
              // Finalize the transaction on the server after payer approval
              onApprove={handleOnApprove}
              //   onError={handleOnError}
              onCancel={handleOnCancel}
            />
          </PayPalScriptProvider>
        </Box>
      </Dialog>
      <ToastNotify ref={toastRef} duration={4000} />
    </div>
  );
}
