import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import ButtonMain from "../../../../ButtonMain";
import { InputBox, Dropdown } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { useQueryParams } from "../../../../../utils";
import { isValidUrl } from "../../../../Validators";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { Box, MenuItem } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const Button = ({
  type,
  block,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [formData, setFormData] = useState({
    text: block?.text || "",
    number: block?.button?.block || 1,
    url: block?.button?.url || "",
    isChecked: block?.button?.is_new_tab || false,
    checked: block?.is_hidden,
    alignment: block?.button?.alignment || "left", // Add alignment to the initial state
  });
  const [errText, setErrText] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setalertType] = useState("error");
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (formData.text === "") {
      setOpen(true);
      setErrText("Please Enter Text");
    } else if (formData.number === "") {
      setOpen(true);
      setErrText("Please Enter Number");
    } else if (!isValidUrl(formData.url)) {
      setOpen(true);
      setErrText("Please Enter valid URL");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        text: formData.text,
        button_block: formData.number,
        button_url: formData.url,
        is_new_tab: formData.isChecked,
        type: type,
        is_hidden: formData.checked,
        alignment: formData.alignment,
      };
      setDisable(true);
      // create button block
      await POST(`${Constants.addBlock}/button?_id=${block?._id}`, body)
        .then(async (response) => {
          setOpen(true);
          setalertType("success");
          setCourseOne((prev) => ({ ...prev, published: false }));
          setErrText(`Block ${type === "edit" ? "Updated" : "Created"} Successfully`);
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen &&
            setmodalOpen((prev) => ({
              ...prev,
              modalOpen: false,
              currentBlockId: "",
            }));
          setblockModOpen && setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <form onSubmit={handleSave}>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox
        name="text"
        placeholder="Button Text"
        value={formData.text}
        onChange={handleChange}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcText}
        </Box>
      )}
      <h4>Jump to block number:</h4>
      <InputBox
        name="number"
        type="number"
        placeholder="Button Number"
        value={formData.number}
        onChange={handleChange}
      />
      <h4>Or external URL:</h4>
      <InputBox name="url" placeholder="URL" value={formData.url} onChange={handleChange} />
      <h4>Button Alignment:</h4>
      <Dropdown
        name="alignment"
        value={formData.alignment}
        onChange={handleChange}
        renderValue={(selected) => {
          if (!selected) {
            return <span className="dropdownLabel">Select Alignment</span>;
          } else {
            return <span>{selected}</span>;
          }
        }}
        className="alignment-dropdown"
      >
        {["left", "center", "right"].map((alignment) => (
          <MenuItem key={alignment} value={alignment}>
            {alignment.charAt(0).toUpperCase() + alignment.slice(1)}
          </MenuItem>
        ))}
      </Dropdown>
      <Checkbox
        sx={{ paddingLeft: 0 }}
        name="isChecked"
        checked={formData.isChecked}
        onChange={handleChange}
      />
      Opens in new tab? <br />
      <ButtonMain className="buttonGreen" type="submit" disabled={disable}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </form>
  );
};

export default Button;
