import React from "react";
import { useSelector } from "react-redux";

import HomeLayout from "../../components/Home";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import LandingPage from "../../components/EnrolledCourseNew/LandingPage/LandingPage";

const Home = () => {
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  return isLoggedIn ? (
    <HeaderContainer footerView={true}>
      {/* <EnrolledLayoutComponent /> */}
      <LandingPage />
    </HeaderContainer>
  ) : (
    <HeaderContainer footerView={true}>
      <HomeLayout />
    </HeaderContainer>
  );
};

export default Home;
