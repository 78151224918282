import React, { useEffect, useState } from "react";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { HeaderText, ParagraphText } from "../Typography";

import "./Reset.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import PasswordInput from "../PasswordInput/PasswordInput";
import CloseIcon from "@mui/icons-material/Close";

const ResetPasswordLayout = () => {
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const params = useParams();
  let token = params.token;
  let navigate = useNavigate();

  useEffect(() => {
    if (token) {
      let body = {};
      //Reset password API
      GET(`${Constants.resetPassword}/${token}`, body)
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  }, [token]);

  const handleReset = () => {
    if (!passwords?.password) {
      setPasswordError({
        ...passwordError,
        password: "Password fields can't be empty.",
      });
    } else if (passwords?.password.length < 8) {
      setPasswordError({
        ...passwordError,
        password: "Password must be of 8 characters.",
      });
    } else if (!passwords.confirmPassword) {
      setPasswordError({
        ...passwordError,
        confirmPassword: "Confirm Password can't be empty.",
      });
    } else {
      if (passwords.password === passwords.confirmPassword) {
        let body = { password: passwords.password };
        //API to reset password
        POST(`${Constants.resetPassword}/${token}`, body)
          .then((res) => navigate("/"))
          .catch((err) => setError(err.response.data.message));
      } else {
        setPasswordError({
          ...passwordError,
          confirmPassword: "Passwords does not match.",
        });
      }
    }
  };

  const handleChangePassword = (e) => {
    setPasswords({ ...passwords, password: e.target.value });
    setPasswordError({
      ...passwordError,
      password: "",
    });
  };
  const handleChangeConfirmPassword = (e) => {
    setPasswords({ ...passwords, confirmPassword: e.target.value });
    setPasswordError({
      ...passwordError,
      confirmPassword: "",
    });
  };
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <div className="resetPassword">
        <div className="resetContainer">
          <Grid container spacing={1}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: { lg: "18rem", sm: "1rem" },
              }}
            >
              <HeaderText>Reset your password</HeaderText>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={() => navigate("/")} />
            </Box>
            <Grid item xs={12}>
              <ParagraphText className="resetText">
                Please enter a new password for your account. Must be at least 8 characters.
              </ParagraphText>
            </Grid>
            <Grid item xs={12} lg={12}>
              <PasswordInput
                placeholder="New Password"
                value={passwords.password}
                onChange={handleChangePassword}
                errorMsg={passwordError.password}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <PasswordInput
                placeholder="Confirm Password"
                value={passwords.confirmPassword}
                onChange={handleChangeConfirmPassword}
                errorMsg={passwordError.confirmPassword}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              {error && (
                <Typography variant="body2" color={"red"} mb={1}>
                  {error}
                </Typography>
              )}
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                fullWidth
                onClick={handleReset}
              >
                Reset password & login
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

export default ResetPasswordLayout;
