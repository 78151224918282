import { Box, Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQueryParams } from "../../utils";
import { setEntrySourceAction } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

const NewBreadcrumbs = ({ breadcrumbs, userDetails }) => {
  const { slug } = useParams();
  const { cid } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};

  const handleHomeClick = () => {
    if (location.pathname.includes("/course/createblock")) {
      navigate(`/course/createblock?cid=${cid}`, {
        state: {
          userData: userDetails,
        },
      });
    } else {
      navigate(`/course/${slug}?cid=${cid}`, {
        state: {
          userData: userDetails,
        },
      });
    }
  };
  return (
    <Box>
      <div role="presentation" className={"breadcrumb"}>
        <Breadcrumbs aria-label="breadcrumb" className={"post-breadcrumb-container-main"}>
          <Link
            variant="text"
            underline="hover"
            color="inherit"
            sx={{
              textTransform: "none",
              cursor: "pointer",
              color: "black",
            }}
            onClick={() => {
              handleHomeClick();
            }}
          >
            Course Home
          </Link>
          {breadcrumbs?.map((item, i) => (
            <Link
              key={i}
              variant="text"
              underline="hover"
              color="inherit"
              sx={{
                textTransform: "none",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => {
                isLoggedIn && dispatch(setEntrySourceAction("breadcrumb_clicked"));
                navigate(`/course/${slug}/${item?.slug}?cid=${cid}&mid=${item?._id}`, {
                  state: {
                    userData: userDetails,
                  },
                });
              }}
            >
              {item.title}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
    </Box>
  );
};

export default NewBreadcrumbs;
