import React, { useState } from 'react'
import FMModal from '../../ModalMain/CourseModals/FMModal'
import { Grid } from '@mui/material'
import { HeaderText } from '../../Typography'
import { CheckboxInput, InputBox } from '../../InputBox/InputBox'
import './CourseModal.scss'
import ButtonMain from '../../ButtonMain'

const CertificationModal = ({modalOpen, setOpenCertification}) => {
  const preview = false;
  const [additionalSignatory, setAdditionalSignatory] = useState(false)
  return (
    <FMModal
        modalOpen={modalOpen}
        modalClose={() => {
            setOpenCertification(false)
        }}
        modalHeader={"Certificate Configuration"}
        className="add_member_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(90deg, #E3EEFF, #F3E7E9)",
        }}
      >
        <Grid>
          <Grid>
            <HeaderText className="courseHeaderText">Certification:</HeaderText>
          </Grid>
          <Grid className="courseFlex">
            <CheckboxInput
            value={!additionalSignatory}
            onChange={()=> setAdditionalSignatory(!additionalSignatory)}
            label="Have an additional signatory"
            />
            <Grid className='flexImgContainer'>
              <Grid className='signatureContainer'>
                <input
                  accept='image/*'
                  type='file'
                  id='imgInp'
                  className='uploadSignature'
                  // onChange={onSelectFile}
                />
                {preview ? (
                  <img
                    src={preview}
                    className='courseImagePreview'
                    alt='preview'
                  />
                ) : (
                  <div>
                    <p>Choose certificate logo </p>
                    <p>(200px X 200px or a square image in PNG format)</p>
                  </div>
                )}
              </Grid>
              {
                additionalSignatory &&
              
              <Grid className='signatureContainer'>
                <input
                  accept='image/*'
                  type='file'
                  id='imgInp'
                  className='uploadSignature'
                  // onChange={onSelectFile}
                />
                {preview ? (
                  <img
                    src={preview}
                    className='courseImagePreview'
                    alt='preview'
                  />
                ) : (
                  <div>
                    <p>Choose certificate signature </p>
                    <p>(300px X 200px or a square image in PNG format)</p>
                  </div>
                )}
              </Grid>
              }
            </Grid>
            {
              additionalSignatory &&
            <Grid className='certificateConfigContainer'>
              <InputBox
              type="text"
              name="signatory_name"
              placeholder="Name of signatory"
              />
              <InputBox
                type="text"
                name="signatory"
                placeholder="Designation of signatory"
              />
            </Grid>
            }
            <ButtonMain
            className='certificateConfigBtn'
            variant='primary-blue'
            // onClick={handleNewCourse}
            //   disabled={loading}
            >Save</ButtonMain>
          </Grid>
        </Grid>

      </FMModal>
  )
}

export default CertificationModal