import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { getS3ApiJsonKey } from "../../../utils";
import FullScreenModal from "../../courses/CourseModals/FullScreenModal";
import { EnrolledCoursesLoader } from "../../Loader/Loader";
import ResponsiveTable from "./ResponsiveTable";
import { ToastNotify } from "../../SnackBar/ToastNotify";

const EventTrackingAdminLayout = () => {
  const [filteredCourses, setfilteredCourses] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const toastRef = useRef();

  useEffect(() => {
    getPublicCourses();
  }, []);

  useEffect(() => {
    if (open && selectedCourseId) {
      getCohortsByCourseId(selectedCourseId);
    }
  }, [open, selectedCourseId]);

  useEffect(() => {}, []);

  const getPublicCourses = async () => {
    try {
      const url = `${getS3ApiJsonKey}/${Constants.getS3PublicCourses}.json`;

      const response = await fetch(url);

      if (response.status === 200) {
        const data = await response.json();
        // const mergedDocs = data.reduce((acc, obj) => acc.concat(obj.docs), []);
        const mergedDocs = data.flatMap((obj) => obj.docs);
        setfilteredCourses([...new Map(mergedDocs.map((item) => [item._id, item])).values()]);
      } else {
        const publicCourseRes = await GET(`${Constants.getPublicCourses}`);
        const mergedDocs = publicCourseRes?.data?.data.flatMap((obj) => obj.docs);
        setfilteredCourses(
          JSON.parse([...new Map(mergedDocs.map((item) => [item._id, item])).values()]),
        );
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getCohortsByCourseId = async (courseID) => {
    try {
      const response = await GET(`${Constants.course}/cohort/${courseID}`);
      setCohorts(response.data.cohorts);
      setSelectedCohorts(
        response.data.cohorts.filter((item) => Boolean(item?.eventTracking)).map((i) => i._id),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenModal = (courseID) => {
    setSelectedCourseId(courseID);
    setOpen(true);
  };

  const handleCheckboxChange = (cohortId) => {
    setSelectedCohorts((prev) =>
      prev.includes(cohortId) ? prev.filter((id) => id !== cohortId) : [...prev, cohortId],
    );
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const allCohortIds = cohorts.map((cohort) => cohort._id);
      setSelectedCohorts(allCohortIds);
    } else {
      setSelectedCohorts([]);
    }
    setSelectAll(!selectAll);
  };

  const handleEnableTracking = async () => {
    try {
      let payload = {
        courseId: selectedCourseId,
        cohortIds: selectedCohorts,
      };
      const response = await POST(`${Constants.course}/cohort/updateCohort`, payload);
      if (response?.data?.success) {
        toastRef.current.showToastMessage(`${response.data.message}`, "success");
      } else {
        toastRef.current.showToastMessage(`${response.data.message}`, "error");
      }
    } catch (error) {
      console.log(error);
      toastRef.current.showToastMessage(`${error?.response?.data?.message}`, "error");
    }
  };

  const courseHeaders = ["ID", "Title", "Action"];
  const cohortHeaders = ["ID", "Title", "Action"];

  return (
    <>
      <ToastNotify ref={toastRef} duration={4000} />
      <Typography variant="h5" mb={2} fontWeight={"bold"}>
        Enable/Disable Event Tracking
      </Typography>
      <Typography variant="body2" mb={2} mt={2} fontWeight={"bold"}>
        List of public courses
      </Typography>

      {!filteredCourses?.length ? (
        <EnrolledCoursesLoader />
      ) : (
        <ResponsiveTable
          headers={courseHeaders}
          data={filteredCourses}
          renderRow={(item) => (
            <TableRow key={item._id}>
              <TableCell>{item._id}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none", minWidth: 100 }}
                  onClick={() => handleOpenModal(item._id)}
                >
                  Enable/Disable Tracking
                </Button>
              </TableCell>
            </TableRow>
          )}
        />
      )}

      <FullScreenModal
        dialogTitle={"List of cohorts"}
        open={open}
        setOpen={() => {
          setOpen(false);
          setCohorts([]);
          setSelectedCohorts([]);
          setSelectAll(false);
        }}
      >
        <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
          {!cohorts.length ? (
            <EnrolledCoursesLoader />
          ) : (
            <>
              <Alert variant="outlined" severity="info" sx={{ alignItems: "center" }}>
                Click on the checkbox and click save button to enable tracking of each cohort.
              </Alert>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <FormControlLabel
                  control={<Checkbox checked={selectAll} onChange={handleSelectAll} />}
                  label="Select All"
                />
              </Box>

              <ResponsiveTable
                headers={cohortHeaders}
                data={cohorts}
                renderRow={(item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item._id}</TableCell>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedCohorts.includes(item._id)}
                        onChange={() => handleCheckboxChange(item._id)}
                      />
                    </TableCell>
                  </TableRow>
                )}
              />

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button variant="contained" onClick={handleEnableTracking}>
                  Save
                </Button>
              </Box>
            </>
          )}
        </Container>
      </FullScreenModal>
    </>
  );
};

export default EventTrackingAdminLayout;
