import { Backdrop, CircularProgress, Grid, Typography, Button } from "@mui/material";
import React from "react";
import FMModal from "../ModalMain/CourseModals/FMModal";
import ButtonMain from "../ButtonMain";

const ConfirmModal = ({
  handleConfirm,
  confirmText,
  headerText,
  handleClose,
  confLoading,
  confModal,
  customComp,
  showConfirmButton,
}) => {
  return (
    <FMModal
      modalOpen={confModal}
      modalClose={handleClose}
      modalHeader={headerText}
      className="confirm_modal"
      headerTextColor={"header_text_black"}
      backgroundImage={{
        backgroundImage: headerText === "Note" ? "linear-gradient(#a1c4fd, #c2e9fb)" : "#fff",
      }}
    >
      {confModal && (
        <Backdrop sx={{ color: "#fff", zIndex: 50 }} open={confLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Typography variant="h6">
        {/* Your answers in this module will be unlocked once you press “Confirm”. */}
        {confirmText}
      </Typography>
      {customComp}

      {showConfirmButton ? (
        <Grid container spacing={2}>
          <Grid item>
            <ButtonMain
              className={"confirm_button relative"}
              onClick={handleConfirm}
              disabled={confLoading}
            >
              Confirm
            </ButtonMain>
          </Grid>

          <Grid item>
            <ButtonMain className={"cancel_button"} onClick={handleClose}>
              Cancel
            </ButtonMain>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="warning" onClick={handleClose} sx={{ mt: 2 }}>
              {" "}
              Ok
            </Button>
          </Grid>
        </Grid>
      )}
    </FMModal>
  );
};

export default ConfirmModal;
