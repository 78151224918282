import React, { useRef, useState, useEffect } from "react";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import "./AddImage.scss";
import { Constants } from "../../../../../config/Constants";
import { POST } from "../../../../../config/Api";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../../../../utils";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import UploadImageBox from "../../../../Image/UploadImageBox";

const AddImage = ({
  courseDetails,
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [text, setText] = useState(block?.title || "");
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [urlPath, seturlPath] = useState();
  const { setCourseOne } = useOutletContext();

  useEffect(() => {
    if (block?.image) {
      seturlPath(block?.image);
    }
  }, [block]);

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const fileInput = useRef(null);
  const toastRef = useRef();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];
    const extension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(extension)) {
      if (file.size >= 500000) {
        toastRef.current.showToastMessage("File Size too large.", "error");
      } else {
        uploadImage(file);
      }
    } else {
      toastRef.current.showToastMessage(
        "Please select a valid image file (JPEG, JPG, PNG, or GIF)",
        "error",
      );
    }
  };

  const uploadImage = async (file) => {
    setSelectedFile(file);
    const formData = new FormData();
    formData.append("image", file);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      seturlPath(response?.data?.image);

      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (text === "") {
      toastRef.current.showToastMessage("Please Enter text", "error");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        file: {
          ext: selectedFile?.name?.split(".").pop(),
          size: selectedFile?.size,
        },
        provider: {
          name: "Framerspace",
          url: urlPath?.l,
        },
        title: text,
        image: urlPath,
        type: type,
      };
      setDisable(true);
      //submit image block
      await POST(`${Constants.addBlock}/image?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="addImage">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox placeholder="Add Text" value={text} onChange={(e) => setText(e.target.value)} />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcText}
        </Box>
      )}
      <br />

      <UploadImageBox
        title={
          "Accepted file format JPEG, JPG, PNG, or GIF & file size must be equal or less than 500KB"
        }
        icon={true}
        initialImage={urlPath?.m}
        className={"UploadImageBox"}
        imgClassName={"imageBlock-imageUpload"}
        accept="image/*"
        onChange={handleFileChange}
        ref={fileInput}
      />

      <ButtonMain className="buttonGreen" onClick={handleSubmit} disabled={disable}>
        Save
      </ButtonMain>
      <ToastNotify ref={toastRef} duration={4000} />
    </div>
  );
};

export default AddImage;
