import { Box, Chip } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import "../../../courseViews/ToggleListView/ToggleView.scss";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
const ItemView = ({ toggle_list, theme, isRemove, setdata }) => {
  const [checked, setChecked] = useState(false);
  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};

  const [show, setShow] = useState([]);
  const toggle = (a, i) => {
    const arr = a.includes(i) ? a.filter((item) => !item) : [i];
    setShow(arr);
  };
  const handleRemoveItem = (i) => {
    setdata(toggle_list.filter((item) => i !== item));
  };

  return (
    <>
      {toggle_list.length !== 0 && (
        <div id="toggle-block" className="postBgColor">
          <div className="one-item one-toggle-item">
            {[...toggle_list]?.reverse().map((i) => (
              <Fragment key={i?._id}>
                <div className={`item-title ${isRemove && "isRemove"}`}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => toggle(show, i?._id || i)}
                  >
                    <button
                      className={`toggle-button ${
                        show.includes(i?._id || i)
                          ? isLoggedIn
                            ? "blue"
                            : "Active"
                          : isLoggedIn
                          ? "grey"
                          : "inActive"
                      }`}
                    ></button>{" "}
                    <Box>
                      <h4>{i.title}</h4>
                      {checked && (
                        <Box>
                          <strong>Source Text:</strong> {checked && i.srcTitleText}
                        </Box>
                      )}
                    </Box>
                  </div>
                  <Box>
                    <SourceTextSwitcher
                      checked={checked}
                      handleChange={(event) => setChecked(event.target.checked)}
                      srcText={i?.srcText}
                      srcTitleText={i?.srcTitleText}
                    />
                    {isRemove && <Chip label="Remove" onClick={() => handleRemoveItem(i)} />}
                  </Box>
                </div>
                {show.includes(i?._id || i) && (
                  <>
                    <div className="item-text" dangerouslySetInnerHTML={{ __html: i.text }}></div>
                    {checked && (
                      <Box className="item-text">
                        <strong>Source Text:</strong> {checked && i.srcText}
                      </Box>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default ItemView;
