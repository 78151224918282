import React, { useState } from "react";
import { Checkbox, Grid, InputBase, Select } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import "./InputBox.scss";
import styled from "@emotion/styled";

const InputBox = ({
  className,
  type,
  name,
  placeholder,
  onChange,
  value,
  errorMsg,
  disabled,
  sideLabel,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Grid container className="inputBoxMain">
      <div
        className={`inputBoxCont ${className} ${
          errorMsg ? "inputBoxContRed" : "inputBoxContGreen"
        }`}
      >
        <input
          type={type === "password" ? (showPassword ? "text" : "password") : type}
          name={name}
          className="inputTextBox"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          disabled={disabled}
          {...props}
        />

        {type === "password" ? (
          <label
            className={`passwordBtn ${showPassword ? "hidePasswordBtn" : "showPasswordBtn"}`}
            onClick={() => setShowPassword(!showPassword)}
          >
            show
          </label>
        ) : null}

        {sideLabel && <div className="side_label">{sideLabel}</div>}
      </div>
      {errorMsg && <div className="errorMsg" dangerouslySetInnerHTML={{ __html: errorMsg }}></div>}
    </Grid>
  );
};
const CustomInputFile = ({ title, icon, className, onChange, accept, ref }) => {
  return (
    <div className="inputFile">
      <label htmlFor={title} className={`custom-file-upload ${className}`}>
        <span>{icon && <FileUploadOutlinedIcon />}</span> <span>{title}</span>
      </label>
      <input
        id={title}
        type="file"
        onChange={onChange}
        accept={accept}
        className="file-upload"
        ref={ref}
      />
    </div>
  );
};
const CheckboxInput = ({ className, label, onChange, value, errorMsg, ...props }) => {
  return (
    <Grid className={`checkBoxInputMain ${className}`} onClick={onChange}>
      <Checkbox
        checked={!value}
        inputProps={{ "aria-label": "controlled" }}
        color="primary"
        {...props}
      />
      <label className="checkboxLabel">{label}</label>
    </Grid>
  );
};
const RadioBtn = ({ className, label, onChange, value, errorMsg }) => {
  return (
    <Grid className={`checkBoxInputMain ${className}`}>
      <input type="radio" onChange={onChange} value={value} />
      <label className="checkboxLabel">{label}</label>
    </Grid>
  );
};

const DropdownInputMainStyle = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    color: "black",
    padding: "1rem",
    // padding: ".5rem",
    paddingLeft: "1.2rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["'IBM Plex Sans', sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const SignupInputStyle = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    color: "black",
    padding: ".5rem",
    paddingLeft: "1rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["'IBM Plex Sans', sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const Dropdown = ({
  name,
  value,
  onChange,
  renderValue,
  errorMsg,
  children,
  disabled,
  style,
  className,
  styleType,
}) => {
  return (
    <>
      <Select
        name={name}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        displayEmpty
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        input={styleType === "signup" ? <SignupInputStyle /> : <DropdownInputMainStyle />}
        disabled={disabled}
        sx={{ width: "100%", ...style }}
        classes={className}
      >
        {children}
      </Select>
      {errorMsg && <label className="errorMsg">{errorMsg}</label>}
    </>
  );
};

export { InputBox, CustomInputFile, CheckboxInput, RadioBtn, Dropdown };
