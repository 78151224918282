import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Box,
  ListSubheader,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { debounce } from "lodash";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./SearchBar.scss";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import swal from "sweetalert";
import { InputBox } from "../InputBox/InputBox";
import { useSelector } from "react-redux";
const SearchBar = ({
  addMembersData,
  setRefetchMembers,
  refetchMembers,
  setReRenderList,
  reRenderList,
  setShowMembersModal,
  showMembersModal,
  course_id,
}) => {
  let UserData = useSelector((state) => state.userLogin.data);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchInputChange = debounce(async (value) => {
    setLoading(true);
    if (value.length >= 3) {
      //search user
      POST(`${Constants.userSearch}${value.replace(/\s/g, "")}`)
        .then((res) => {
          setLoading(false);
          setSuggestions(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: "Something went wrong.",
            text: "Please try again later.",
            icon: "warning",
            dangerMode: true,
          });
        });
    } else {
      setLoading(false);

      setSuggestions([]);
    }
  }, 500);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event?.target?.value !== "") {
      setSuggestions([]);
      event.target.value = "";
    }
  };

  const handleAddData = (addedUserId, uniqueId, resetEmail, item) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("member_id", addedUserId);
    formData.append("email", uniqueId);
    formData.append("reset_email", resetEmail);
    formData.append("name", item?.name);
    formData.append("addedBy", UserData.user.email.split("-")[0]);
    //API to add members to course and cohort
    POST(
      `${
        !course_id
          ? `${Constants.cohort}/members/add/${addMembersData.cohort_id}`
          : `${Constants.courseManage}/addModerator/${course_id}`
      }`,
      formData,
    )
      .then((res) => {
        swal({
          title: "Success",
          text: "Member Added Successfully.",
          icon: "success",
        });
        setSuggestions([]);
        setLoading(false);
        course_id && setShowMembersModal(!showMembersModal);
        course_id && setReRenderList(!reRenderList);
        setRefetchMembers(!refetchMembers);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Something went wrong",
          text: "Could not add member.",
          icon: "error",
          dangerMode: true,
        });
        setRefetchMembers(!refetchMembers);
        console.log(err);
      });
  };

  // Render Search Suggestions
  const renderSearchSuggestions = (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        position: "absolute",
        top: 55,
        boxShadow:
          "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;",
        zIndex: 10,
        overflow: "auto",
        maxHeight: 440,
        "& ul": { padding: 0 },
      }}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="body1" component={"strong"}>
            Your search results:
          </Typography>
        </ListSubheader>
      }
    >
      {suggestions &&
        suggestions?.length !== 0 &&
        suggestions.map((item, i) => (
          <ListItem
            key={item.email}
            className="margin-top-2"
            secondaryAction={
              <IconButton
                onClick={() => {
                  handleAddData(item?._id, item?.email, item?.reset_email, item);
                }}
                edge="end"
                color="default"
                aria-label="comments"
              >
                {/* Add */}
                <AddOutlinedIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton dense>
              <ListItemIcon>
                <Box
                  height={40}
                  width={40}
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  bgcolor={"#f5f5f5"}
                  borderRadius={"50%"}
                >{`${item?.initials}`}</Box>
              </ListItemIcon>
              <ListItemText primary={`${item?.email}`} />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );

  return (
    <>
      <div className="search_container">
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: 999 }}
            open={loading}
            onClick={() => setLoading(false)}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <InputBox
          id="filled-adornment-password"
          size="large"
          fullWidth
          placeholder="Search Users..."
          onChange={(e) => {
            handleSearchInputChange(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          startAdornment={
            <InputAdornment position="end">
              <IconButton disabled aria-label="Search Dropdown" edge="start">
                <SearchOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {suggestions && suggestions?.length > 0 && renderSearchSuggestions}
      </div>
    </>
  );
};

export default SearchBar;
