import { Chip, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import "./AddDivider.scss";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const Divider = ({
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [selected, setSelected] = useState([block?.divider?.name] || []);
  const [text, setText] = useState(block?.text || "");
  const [waitTime, setWaitTime] = useState(block?.divider?.time || null);
  const [errText, setErrText] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setsetalertType] = useState("error");
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleAnimationClick = (item) => {
    const isSelected = selected.includes(item);

    if (isSelected) {
      setSelected([]);
    } else {
      setSelected([item]);
    }
  };

  const handleSave = async () => {
    if (text === "") {
      setOpen(true);
      setErrText("Please Enter Text");
    } else {
      let dividerType = "empty";
      let animationArr = ["Wave", "Breathing", "Water"];
      let musicArr = ["Harmony", "Still", "Thoughts", "Äcceptance", "Moment"];

      if (animationArr?.includes(selected.toString())) {
        dividerType = "animation";
      } else if (musicArr?.includes(selected.toString())) {
        dividerType = "music";
      }
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        text: text,
        button_block: 2,
        type: type,
        divider_type: dividerType,
        divider_time: waitTime,
        divider_name: selected.toString(),
      };
      setDisable(true);
      //create divider block
      await POST(`${Constants.addBlock}/divider?_id=${block?._id}`, body)
        .then((response) => {
          setOpen(true);
          setsetalertType("success");
          setErrText(`Block ${type === "edit" ? "Updated" : "Created"} Successfully`);
          setDisable(false);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 }, width: "100%" }}>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox
        placeholder="Text"
        value={text}
        type="text"
        onChange={(e) => setText(e.target.value)}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcText}
        </Box>
      )}
      <br />
      <InputBox
        placeholder="Wait time in seconds"
        value={waitTime}
        type="number"
        onChange={(e) => setWaitTime(e.target.value)}
        sx={{ width: "100%", mb: 2 }}
      />{" "}
      <Box sx={{ mb: 2, mt: 2 }}>
        <b>Select animation:</b>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          {["Wave", "Breathing", "Water"]?.map((item, index) => (
            <Grid item key={index}>
              <Chip
                label={item}
                key={index}
                onClick={() => handleAnimationClick(item)}
                className={`${selected.find((id) => id === item) ? "selectedItem" : ""}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <b>Or select music</b> <br />
        <Grid container spacing={1} sx={{ mt: 1 }}>
          {["Harmony", "Still", "Thoughts", "Acceptance", "Moment"]?.map((item, index) => (
            <Grid item key={index}>
              <Chip
                label={item}
                key={index}
                onClick={() => handleAnimationClick(item)}
                className={`${selected.find((id) => id === item) ? "selectedItem" : ""}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <ButtonMain className="buttonGreen" onClick={handleSave} disabled={disable}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

export default Divider;
