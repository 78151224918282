import React from "react";
import { Box, Grid, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { HeaderText } from "../../Typography";

const FMModal = ({
  children,
  modalHeader,
  modalOpen,
  modalClose,
  backgroundImage,
  className,
  headerTextColor,
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          modalClose();
        }
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modalContainer"
      scroll="paper"
    >
      <Box>
        <Grid
          container
          className={`FMModalMain ${className}`}
          style={backgroundImage}
        >
          <div className="FMClose">
            <CloseIcon onClick={modalClose} />
          </div>
          <Grid item lg={12}>
            <HeaderText className="FMModalHeader">{modalHeader}</HeaderText>
            <Grid className="FMModalBody" item>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FMModal;
