import React from "react";
import { Snackbar } from "@mui/material";
import { Stack } from "@mui/system";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars = ({ AlertText, AlertType, open, onClose }) => {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={onClose}
          severity={AlertType}
          sx={{
            width: "100%",
          }}
        >
          {AlertText}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
export default CustomizedSnackbars;
