import React from 'react';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Popper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from './LightToolTip';

const AssessmentRule = ({ allData, handleRadio }) => {

  return (
    <Box py={1}>
      <FormControl>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          name='isAdvanceAssessment'
          value={String(allData.isAdvanceAssessment)}
          onChange={handleRadio}
        >
          <Box
            display={'flex'}
            alignItems='center'
            justifyContent={'flex-start'}
          >
            <FormControlLabel
              value='false'
              control={<Radio />}
              label='I wish to set a simple assessment rule.'
            />
            <LightTooltip
              disableFocusListener
              components={<Popper />}
              classes={{
                popper: 'assement_tooltip',
              }}
              title={
                <>
                  <Box>
                    <Typography variant='body2'>
                      In a simple assessment system, scores from all the
                      assessment questions in the course will be summed up, and
                      an aggregated score will be calculated. If this aggregated
                      obtained score is equal to or more than the m% (minimum
                      qualification criteria) of the maximum possible aggregated
                      score in the course, the learner would be allowed to
                      generate the certificate.
                    </Typography>
                  </Box>
                </>
              }
            >
              <IconButton color='default'>
                <InfoOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box
            display={'flex'}
            alignItems='center'
            justifyContent={'flex-start'}
          >
            <FormControlLabel
              // disabled
              value='true'
              control={<Radio />}
              label='I wish to set an advanced assessment rule'
            />
            <LightTooltip
              disableFocusListener
              components={<Popper />}
              classes={{
                popper: 'assement_tooltip',
              }}
              title={
                <>
                  <Box>
                    <Typography variant='body2'>
                      In an advanced assessment system, we categorize questions
                      using tags. Scores are aggregated within individual
                      categories, learner passes or fails in individual
                      categories, and the overall pass-fail decision for
                      certificate generation is based on a logical expression
                      ("assessment logic") that combines all the categories
                      using boolean operators AND and OR.
                    </Typography>
                  </Box>
                </>
              }
            >
              <IconButton color='default'>
                <InfoOutlinedIcon />
              </IconButton>
            </LightTooltip>
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default AssessmentRule;
