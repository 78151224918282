import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Box, IconButton, Tooltip, Container } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import background from "../../../assets/bg.jpg";
import "./landingPage.scss";

const UserCard = () => {
  const [isCopied, setIsCopied] = useState(false);

  let UserData = useSelector((state) => state.userLogin);
  const gridContainerStyle = {
    display: "flex",
    padding: "1rem 0rem",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "-2rem",
  };

  const borderedContainerStyle = {
    border: "1px solid #9a9a9a",
    padding: "1px",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
  };

  const copyIconStyle = {
    marginLeft: "10px",
    cursor: "pointer",
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(UserData?.data?.user?.email);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: "6rem",
      }}
    >
      <Container maxWidth="xl" sx={{ width: "97%" }}>
        <Grid container sx={gridContainerStyle}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              Hello,&nbsp;
              {UserData?.data?.user?.name
                ? UserData?.data?.user?.name.split(" ")[0]
                : UserData?.data?.user?.email?.split("-")?.[3]?.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexWrap: "wrap",
                justifyContent: "right",
              }}
              className="userCard"
            >
              <Box>Your Unique User Id :</Box>
              <Box style={borderedContainerStyle}>
                <Typography
                  variant="h6"
                  sx={{
                    marginLeft: "10px",
                    borderRight: "1px solid #9a9a9a",
                    paddingRight: "10px",
                    fontSize: "1rem",
                  }}
                >
                  {UserData?.data?.user?.email}
                </Typography>
                <Tooltip title={isCopied ? "Unique Id Copied" : "Copy Unique Id"}>
                  <IconButton
                    onClick={handleCopyClick}
                    color="inherit"
                    style={copyIconStyle}
                    size="small"
                  >
                    <FileCopyOutlinedIcon sx={{ color: "#333333" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default UserCard;
