import { useEffect, useState } from "react";

import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { GET } from "../../../config/Api";
import ButtonMain from "../../ButtonMain";

import "./PaymentsReceipt.scss";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Constants } from "../../../config/Constants";

export default function PaymentsReceipt() {
  const [paymentsData, setPaymentsData] = useState({});
  const { paymentID } = useParams();

  const validReferrers = [
    "http://localhost:3000/",
    "http://localhost:3003/",
    "https://staging.framerspace.com/",
    "https://www.framerspace.com/",
  ];
  let ref = document.referrer;

  useEffect(() => {
    getPaymentDetails(paymentID);
  }, []);

  const getPaymentDetails = async (paymentID) => {
    if (!validReferrers.includes(ref)) {
      return;
    }
    try {
      //get payment details by payment id
      const res = await GET(`${Constants.getPaymentDetails}/${paymentID}`);
      if (res.data.success) {
        setPaymentsData(res?.data);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  if (!validReferrers.includes(ref)) {
    return "";
  }
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      {paymentsData["paymentInfo"] && (
        <Box className="receipt">
          <Box className="receipt_content">
            {/* Receipt Header Begins */}
            <Box className="receipt_payment_box">
              <Box className="receipt_payment_check_box">
                <Box className="receipt_payment_check_box_inner">
                  <CheckRoundedIcon fontSize="medium" htmlColor="#fff" />
                </Box>
              </Box>
              <Typography variant="h4" textAlign={"center"} className="receipt_heading">
                Payment Success
              </Typography>
            </Box>
            <Divider sx={{ mx: 3 }} />
            {/* Receipt Header Ends  */}
            <Box className="receipt_thank_you_message_box">
              {/* Thank you message Begins */}
              <Typography variant="h4" className="receipt_thank_you_message">
                Thank you for being a part framerspace,{" "}
                <strong>{paymentsData?.paymentInfo?.user_id?.name}</strong>!
              </Typography>
              {/* Thank you message Ends  */}
            </Box>
            <Divider sx={{ mx: 3 }} />
            {/* Main Content Begins */}
            <Box className="receipt_main_content">
              <Grid container rowSpacing={2}>
                {/* Reference Row */}
                <Grid item xs={5}>
                  <Typography variant="body1" className="receipt_content_titles">
                    Reference No.
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" textAlign={"right"} whiteSpace="nowrap">
                    {paymentsData?.paymentInfo?.order?.id}
                  </Typography>
                </Grid>
                {/* Course Title Row Begins */}
                <Grid item xs={5}>
                  <Typography variant="body1" className="receipt_content_titles">
                    Payment Method
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" textAlign={"right"}>
                    Paypal
                  </Typography>
                </Grid>
                {/* Course Title Row Ends */}
                {/* Date of Purchase Row */}
                <Grid item xs={5}>
                  <Typography variant="body1" className="receipt_content_titles">
                    Date of Purchase
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" textAlign={"right"} whiteSpace="nowrap">
                    {moment(paymentsData?.paymentInfo?.updatedAt).format("Do MMM, YYYY")}
                  </Typography>
                </Grid>
                {/* Course Title Row Begins */}
                <Grid item xs={5}>
                  <Typography variant="body1" className="receipt_content_titles">
                    Course
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={"body1"} textAlign={"right"}>
                    {paymentsData?.paymentInfo?.course_id?.title}
                  </Typography>
                  <Typography variant="body2" fontStyle={"italic"} textAlign="right">
                    Cohort: {paymentsData?.paymentInfo?.cohort_id?.title}
                  </Typography>
                </Grid>
                {/* Course Title Row Ends  */}
                {/* User Row Begins */}
                <Grid item xs={5}>
                  <Typography variant="body1" className="receipt_content_titles">
                    User's Unique ID
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={"body1"} textAlign={"right"}>
                    {paymentsData?.paymentInfo?.user_id?.email}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Grid container>
                <Grid item xs={5}>
                  <Typography variant="h6" fontWeight={600}>
                    Amount
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h6" textAlign={"right"} fontWeight={600}>
                    $ {paymentsData?.paymentInfo?.price_id?.price}
                  </Typography>
                </Grid>
                {/* Total Row Ends */}
              </Grid>
              <Divider sx={{ my: 3 }} />
              <ButtonMain
                className={"download_receipt_button"}
                onClick={() => {
                  window.print();
                }}
              >
                Print Receipt
              </ButtonMain>
            </Box>
            {/* Main Content Ends */}
          </Box>
        </Box>
      )}
      {/* Main Content Ends */}
    </Container>
  );
}
