import React, { useContext, useState } from "react";
import { AuthContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./modal.scss";

import { HeaderText, ParagraphText } from "../Typography";
import { Constants } from "../../config/Constants";
import { POST } from "../../config/Api";
import { userLoginAction } from "../../redux/action";
import ModalMain from "../ModalMain/ModalMain";
import loginImage from "../../assets/Login_illustration.svg";
import { Button, TextField } from "@mui/material";
import PasswordInput from "../PasswordInput/PasswordInput";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let dispatch = useDispatch();
  const [disable, setdisable] = useState(false);
  const [loginData, setLoginData] = useState({
    uniqueID: "",
    password: "",
    rememberMe: true,
  });
  const [loginDataError, setLoginDataError] = useState({
    uniqueID: "",
    password: "",
  });

  const { authStates, setAuthStates } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    if (loginData.uniqueID === "") {
      setLoginDataError({
        ...loginDataError,
        uniqueID: "Please enter Unique ID",
      });
    } else if (loginData.password === "") {
      setLoginDataError({
        ...loginDataError,
        password: "Please enter your password",
      });
    } else if (loginData.password.length < 8) {
      setLoginDataError({
        ...loginDataError,
        password: "Password should be 8 character or more.",
      });
    } else {
      let body = {
        email: loginData.uniqueID,
        password: loginData.password,
      };
      setdisable(true);
      //API to User login
      POST(Constants.userLogin, body)
        .then(async (res) => {
          if (!res.data.success && res.data.message === "UniqueID / Email not found!") {
            setLoginDataError({
              ...loginDataError,
              uniqueID: res.data.message,
            });
            setdisable(false);
          } else if (!res.data.success && res.data.message === "Invalid Password!") {
            setLoginDataError({
              ...loginDataError,
              password: res.data.message,
            });
            setdisable(false);
          } else {
            dispatch(userLoginAction(res.data));
            //save user last activity
            await POST(`${Constants.userActivity}?activity=login`, { user: res.data.user });
            if (location.pathname === "/featured-courses" || location.pathname === "/") {
              navigate("/");
            } else {
              window.location.reload();
            }
            setAuthStates((prev) => ({
              ...prev,
              loginOpen: false,
              modalOpen: false,
              message: "",
              forceUpdate: false,
            }));
            setdisable(false);
          }
        })
        .catch((err) => {
          setLoginDataError({
            ...loginDataError,
            password: err?.response?.data?.message,
          });
          setdisable(false);
        });
    }
  };

  return (
    <>
      <ModalMain
        modalOpen={authStates.modalOpen}
        modalClose={() => {
          setdisable(false);
          setAuthStates((prev) => ({
            ...prev,
            modalOpen: false,
            message: "",
            forceUpdate: false,
          }));
          setLoginData("");
          setLoginDataError("");
        }}
        gradientLeft={{ backgroundColor: "#F2F4F8" }}
        gradientRight={{ backgroundColor: "#D6E9FA" }}
        // imgUrl={`${Constants.imgPath}/graphic-login@2x.png`}
        imgUrl={loginImage}
        rightBoxTitle="New to FramerSpace?"
        rightBoxBtn="Sign up"
        modalFor="login"
        onRightBoxBtnClick={() => {
          setAuthStates((prev) => ({
            ...prev,
            loginOpen: false,
            signupOpen: true,
          }));
          setLoginData("");
          setLoginDataError("");
        }}
      >
        {/* Rectangular box with message */}
        {authStates.message && authStates.forceUpdate && (
          <div className="rectangular-box">
            <p className="box-message">
              <InfoOutlinedIcon /> To fully engage with and complete the course, please log in!
            </p>
          </div>
        )}
        <form className="loginLayoutMainContainer">
          <div className="loginLayoutContainer">
            <HeaderText className="loginInputHeader">Welcome Back!</HeaderText>
            {/* <HeaderText className="loginInputHeader">{authStates.message}</HeaderText> */}
            <TextField
              type="text"
              label="Unique ID / Email"
              value={loginData.uniqueID}
              onChange={(e) => {
                setLoginData({ ...loginData, uniqueID: e.target.value });
                setLoginDataError("");
              }}
              error={!!loginDataError.uniqueID}
              helperText={loginDataError.uniqueID}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <PasswordInput
              placeholder="Password"
              value={loginData.password}
              onChange={(e) => {
                setLoginData({ ...loginData, password: e.target.value });
                setLoginDataError("");
              }}
              errorMsg={loginDataError.password}
            />

            <div className="forgotPasswordMain">
              <ParagraphText
                className="forgotPasswordText"
                onClick={() => {
                  setAuthStates((prev) => ({
                    ...prev,
                    forgotOpen: true,
                    loginOpen: false,
                    signupOpen: false,
                  }));
                  setLoginData("");
                }}
              >
                Forgot Unique ID / Password?
              </ParagraphText>
            </div>
          </div>
          <div className="leftBtnMain">
            <Button
              variant="contained"
              fullWidth
              sx={{ textTransform: "none" }}
              onClick={handleLogin}
              disabled={disable}
            >
              Login
            </Button>
          </div>
        </form>
      </ModalMain>
    </>
  );
};

export default Login;
