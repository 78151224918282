import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PageHeader from "../PageHeader";
import { Constants } from "../../config/Constants";
import "./UserProfile.scss";
import ButtonMain from "../ButtonMain";
import { Box } from "@mui/system";
import { GET, POST, PUT } from "../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/action";
import { useNavigate } from "react-router-dom";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { AuthContext } from "../../App";
import { country_and_states } from "../../utils/country-states";
import ImageUploader from "../Image/ImageUploader";

const UserProfile = () => {
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [org, setOrg] = useState("");
  const [about, setAbout] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [preferredLang, setPreferredLang] = useState({});
  const [nativeLang, setNativeLang] = useState({});
  const [languages, setLanguages] = useState([{}]);
  const [prefLanguages, setPrefLanguages] = useState([{}]);
  const [sex, setSex] = useState("");
  const [phone, setPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [dp, setDP] = useState({});
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [passwordDataError, setPasswordDataError] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let UserData = useSelector((state) => state.userLogin);
  const toastRef = useRef();
  const { setAuthStates } = useContext(AuthContext);

  useEffect(() => {
    UserData?.data?.isLoggedIn
      ? (async () => {
          try {
            //get user profile data
            const res = await GET(`${Constants.updateProfile}/${UserData?.data?.user?.id}`);
            if (res?.data?.success) {
              setName(res?.data?.data?.name);
              setJob(res?.data?.data?.job?.title);
              setOrg(res?.data?.data?.job?.org);
              setAbout(res?.data?.data?.about);
              setCity(res?.data?.data?.city);
              setSex(res?.data?.data?.sex);
              setPhone(res?.data?.data?.phone);
              setDP(res?.data?.data?.dp);
              setCountry(res?.data?.data?.country);
              setSelectedCountryCode(
                Object.entries(country_and_states.country).find(
                  (el) => el[1] === res?.data?.data?.country,
                )[0],
              );
              setState(res?.data?.data?.state);
            }
            try {
              //API to get all languages
              const langsRes = await GET(`${Constants.course}/languages/all`);
              if (langsRes?.data?.success) {
                const preflang = langsRes?.data?.languages?.find(
                  (el) => el._id === res?.data?.data?.preferredLanguage,
                );
                const nativelang = langsRes?.data?.languages?.find(
                  (el) => el._id === res?.data?.data?.nativeLanguage,
                );
                setPreferredLang(preflang);
                setNativeLang(nativelang);
                // UserData.data.user.preferredLanguage = lang._id;
                // localStorage.setItem("userData", JSON.stringify(UserData));
                setLanguages(langsRes?.data?.languages);
                setPrefLanguages(langsRes?.data?.languages?.filter((el) => el.courseLanguage));
              }
            } catch (error) {
              console.log(error);
            }
          } catch (error) {
            console.log(error);
          }
        })()
      : navigate("/");
  }, []);

  const updateProfile = async (formData) => {
    try {
      //update user profile
      const res = await PUT(`${Constants.updateProfile}/${UserData?.data?.user?.id}`, formData);
      if (res?.data?.success) {
        toastRef.current.showToastMessage(res?.data?.message, "success");
        setName(res?.data?.user?.name);
        setJob(res?.data?.user?.job?.title);
        setOrg(res?.data?.user?.job?.org);
        setAbout(res?.data?.user?.about);
        setCity(res?.data?.user?.city);
        setCountry(res?.data?.user?.country);
        setState(res?.data?.user?.state);
        setSex(res?.data?.user?.sex);
        setPhone(res?.data?.user?.phone);
        setDP(res?.data?.user?.dp);
        const lang = languages?.find((el) => el._id === res?.data?.user?.preferredLanguage);
        const nativelang = languages?.find((el) => el._id === res?.data?.user?.nativeLanguage);
        localStorage.setItem("preferredLanguage", JSON.stringify(lang));
        setAuthStates((prev) => ({ ...prev, preferredLanguage: lang }));
        setPreferredLang(lang);
        setNativeLang(nativelang);
        if (res?.data?.isPassChanged) {
          toastRef.current.showToastMessage(res?.data?.message, "success");
          setTimeout(() => {
            dispatch(logoutAction());
            navigate("/");
          }, 2000);
        }
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };
  const handleUpdateProfile = async () => {
    const formData = {
      name: name,
      job: job,
      org: org,
      about: about,
      city: city,
      country: country,
      state: state,
      sex: sex,
      phone: phone,
      dp: dp,
      preferredLanguage: preferredLang?._id,
      nativeLanguage: nativeLang?._id,
    };

    if (newPassword !== "") {
      if (newPassword.length >= 8) {
        setPasswordDataError((prev) => ({
          ...prev,
          newPassword: "",
        }));
        if (newPassword === confirmPassword) {
          setPasswordDataError((prev) => ({
            ...prev,
            confirmPassword: "",
          }));

          formData.password = newPassword;
          await updateProfile(formData);
        } else {
          setPasswordDataError((prev) => ({
            ...prev,
            confirmPassword: "Passwords do not match.",
          }));
        }
      } else {
        setPasswordDataError((prev) => ({
          ...prev,
          newPassword: "Password should contain minimum of 8 characters",
        }));
      }
    } else {
      setPasswordDataError((prev) => ({
        ...prev,
        newPassword: "",
      }));
      await updateProfile(formData);
    }
  };

  const handleFileChange = async (formData) => {
    setLoading(true);
    let header = "multipart/form-data";
    try {
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      setDP(response?.data?.image);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastRef.current.showToastMessage("Something went wrong.", "error");
    }
  };

  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <Grid className="aboutLayoutMain">
        <PageHeader headerText="Edit Profile" paragraphText="" />
        <Grid container spacing={2} py={3}>
          <Grid item className="fullwidth" xs={12}>
            <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
              {" "}
              Professional Details:
            </Typography>
          </Grid>
          <Grid container md={8} lg={9} p={2} spacing={2}>
            <Grid item className="fullwidth" sm={6}>
              <TextField
                fullWidth
                autoComplete={"false"}
                placeholder="Full Name"
                label="Full Name"
                value={name}
                onChange={(e) => {
                  /^[a-zA-Z ]*$/.test(e.target.value) && setName(e.target.value);
                }}
              />
            </Grid>
            <Grid item className="fullwidth" sm={6}>
              <TextField
                fullWidth
                autoComplete={"false"}
                placeholder="Class / Branch / Job Title"
                label="Class / Branch / Job Title"
                value={job}
                onChange={(e) => {
                  /^[a-zA-Z ]*$/.test(e.target.value) && setJob(e.target.value);
                }}
              />
            </Grid>
            <Grid item className="fullwidth" md={12}>
              <TextField
                fullWidth
                autoComplete={"false"}
                placeholder="School / University / Organisation"
                label="School / University / Organisation"
                value={org}
                onChange={(e) => {
                  /^[a-zA-Z ]*$/.test(e.target.value) && setOrg(e.target.value);
                }}
              />
            </Grid>
            <Grid item className="fullwidth" md={12}>
              <TextField
                placeholder="Tell something about yourself that we don't know"
                multiline
                fullWidth
                label="Tell something about yourself that we don't know"
                rows={6}
                variant="outlined"
                className="about_text"
                value={about}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4} lg={3} position="relative" p={2}>
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: 10,
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
            {/* {!dp?.m ? (
              <CustomInputFile
                title={"Choose Image (200X200px) to upload"}
                icon={true}
                className="fileUpload"
                onChange={handleFileChange}
                accept="image/*"
              />
            ) : ( */}
            <>
              <Box className="profileImageBox">
                <Typography variant="h6">Profile Picture</Typography>
                <ImageUploader
                  onCropped={handleFileChange}
                  initialImage={dp?.m}
                  title={"Update Profile Image"}
                  className={"dp"}
                  accept="image/*"
                  targetWidth="150"
                  targetHeight="200"
                  circularCrop
                  icon={true}
                />
                {/* {dp?.m && (
                  <div className="profileImagePreview">
                    <img
                      src={
                        dp?.m.startsWith(cloudfrontUrl) ? `${dp?.m}` : `${cloudfrontUrl}${dp?.m}`
                      }
                      alt="Profile"
                    />
                  </div>
                )} */}
              </Box>

              {/* <img
                    className="dp"
                    src={dp?.m.startsWith(cloudfrontUrl) ? `${dp?.m}` : `${cloudfrontUrl}${dp?.m}`}
                    alt=""
                  /> */}

              {/* <ButtonMain
                  className={"change_profile_photo"}
                  onClick={() => {
                    setDP({});
                  }}
                >
                  {" "}
                  Change Profile Photo
              </ButtonMain> */}
            </>
            {/* )} */}
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} py={3}>
          <Grid item className="fullwidth" xs={12}>
            <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
              {" "}
              Personal Details:
            </Typography>
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4}>
            <TextField
              fullWidth
              autoComplete={"false"}
              placeholder={"City"}
              value={city}
              onChange={(e) => {
                /^[a-zA-Z ]*$/.test(e.target.value) && setCity(e.target.value);
              }}
              label="City"
            />
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="country_dropdown">Country</InputLabel>
              <Select
                labelId="country_dropdown"
                id="country_dropdown_select"
                value={country}
                label="Country"
                onChange={(e) => {
                  setCountry(country_and_states.country[e.target.value]);
                  setState("");
                  setSelectedCountryCode(e.target.value);
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className="dropdownLabel">Select Country</span>;
                  } else {
                    return <span>{selected}</span>;
                  }
                }}
              >
                {/* {options && options.map((el) => <MenuItem value={el}>{el}</MenuItem>)} */}
                {Object.entries(country_and_states.country).map(([countryCode, countryName]) => (
                  <MenuItem value={countryCode} key={countryCode} sx={{ color: "black" }}>
                    {countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4} md={6}>
            <FormControl fullWidth>
              <InputLabel id="state_dropdown">State</InputLabel>
              <Select
                labelId="state_dropdown"
                id="state_dropdown_select"
                value={state}
                label="State"
                name="State"
                disabled={
                  country !== "" && selectedCountryCode !== "OTH" && selectedCountryCode !== "CBM"
                    ? false
                    : true
                }
                onChange={(e) => {
                  setState(e.target.value);
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <span className="dropdownLabel">Select State</span>;
                  } else {
                    return <span>{selected}</span>;
                  }
                }}
              >
                {selectedCountryCode !== "" &&
                  selectedCountryCode !== "OTH" &&
                  selectedCountryCode !== "CBM" &&
                  country_and_states.states[selectedCountryCode].map((item, i) => (
                    <MenuItem value={item.name} key={i} sx={{ color: "black" }}>
                      {item.name}
                    </MenuItem>
                  ))}
                <MenuItem value={"other"} key={"state_0"} sx={{ color: "black" }}>
                  {"Other"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sex</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sex}
                label="Sex"
                onChange={(e) => setSex(e.target.value)}
              >
                <MenuItem value={"F"}>Female</MenuItem>
                <MenuItem value={"M"}>Male</MenuItem>
                <MenuItem value={"O"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4} sx={{ position: "relative" }}>
            <TextField
              fullWidth
              autoComplete={"false"}
              placeholder={"Phone Number"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                ),
              }}
              type="text"
              value={phone}
              onChange={(e) => {
                e.target.value
                  ? /^\d+$/.test(e.target.value) && setPhone(e.target.value)
                  : setPhone("");
              }}
              label="Phone Number"
            />
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4} sx={{ position: "relative" }}>
            <Autocomplete
              disablePortal
              id="native_language"
              fullWidth
              options={languages}
              getOptionLabel={(option) => option.name + " " + option.nativeName}
              value={nativeLang}
              onChange={(e, newValue) => {
                setNativeLang(newValue);
              }}
              autoComplete={"false"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete={"false"}
                  name="native_language"
                  type="text"
                  autoFill="false"
                  label="Native Language"
                />
              )}
            />
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4} sx={{ position: "relative" }}>
            <Autocomplete
              disablePortal
              id="preferred_language"
              fullWidth
              options={prefLanguages}
              getOptionLabel={(option) => option.name + " " + option.nativeName}
              value={preferredLang}
              onChange={(e, newValue) => {
                setPreferredLang(newValue);
              }}
              autoComplete={"false"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete={"false"}
                  name="preferred_language"
                  type="text"
                  autoFill="false"
                  label="Preferred Language"
                />
              )}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} py={3}>
          <Grid item className="fullwidth" xs={12}>
            <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
              {" "}
              Change Password:
            </Typography>
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4}>
            <TextField
              fullWidth
              autoComplete="false"
              type={"password"}
              placeholder={"New Password"}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              label="New Password"
            />
            <label className="errorMsg">{passwordDataError.newPassword}</label>
          </Grid>
          <Grid item className="fullwidth" sm={6} md={4}>
            <TextField
              fullWidth
              autoComplete={"false"}
              type={"password"}
              placeholder={"Confirm Password"}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              label="Confirm Password"
            />
            <label className="errorMsg">{passwordDataError.confirmPassword}</label>
            {/* <InputBox type={"password"} placeholder={"New Password"}  value={newPassword} onChange={(e)=>{setNewPassword(e.target.value)}}/> */}
          </Grid>
        </Grid>
        <Typography variant="h2" fontSize={"16px"} fontWeight={500}>
          {" "}
          Note: If you change the password you will have to login again.
        </Typography>
        <ButtonMain className={"update_profile_button"} onClick={handleUpdateProfile}>
          {" "}
          Update Profile
        </ButtonMain>
        <ToastNotify ref={toastRef} duration={4000} />
      </Grid>
    </Container>
  );
};

export default UserProfile;
