import React from "react";
import "./KeywordsView.scss";

const KeywordsView = ({ keywords }) => {
  return (
    <div className="tag breadcrumbs">
      <>
        <span className="tags">
          {Object.keys(keywords).length > 0 && "Keywords: "}
        </span>
        {keywords?.tag?.core || keywords?.tags?.core
          ? `${keywords?.tag?.core || keywords?.tags?.core}; `
          : ""}
        {keywords?.tag?.sdg || keywords?.tags?.sdg
          ? `${keywords?.tag?.sdg || keywords?.tags?.sdg}; `
          : ""}
        {keywords?.tag?.sel || keywords?.tags?.sel
          ? `${keywords?.tag?.sel || keywords?.tags?.sel}; `
          : ""}
      </>
    </div>
  );
};

export default KeywordsView;
