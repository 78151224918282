import React from 'react';
import { Box, FormHelperText, Grid, IconButton, OutlinedInput, Popper, Typography } from '@mui/material';
import LightTooltip from './LightToolTip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ButtonMain from '../ButtonMain';

const AssessmentLogic = ({assessmentLogic, setAssessmentLogic, handleAssessmentLogicCheck, disabled}) => {

  const renderVerifyButtonLabel = () =>{
    if(disabled){
      return "Edit Logic"
    } else {
      return "Verify and Save"
    }
  }
  
  return (
    <Grid container>
      <Grid item lg={12} className='flex items-center justify-start'>
        <Typography variant='subtitle1' fontWeight={600}>
          Assessment logic
        </Typography>
        <LightTooltip
          disableFocusListener
          components={<Popper />}
          classes={{
            popper: 'assement_tooltip',
          }}
          title={
            <>
              <Box>
                <Typography variant='body2'>
                  Using logical operators "OR" and "AND", parentheses symbols "(
                  )", and the identifiers defined above (e.g., A, B, C, etc.)
                  type the overall certificate qualification criteria.
                </Typography>
              </Box>
            </>
          }
        >
          <IconButton color='default'>
            <InfoOutlinedIcon />
          </IconButton>
        </LightTooltip>
      </Grid>
      <Grid mb={2} item lg={12}>
        <OutlinedInput
          disabled={disabled}
          fullWidth
          classes={{
            root: 'assessment_logic_input',
            focused: 'assessment_logic_input_focused',
          }}
          value={assessmentLogic}
          onChange={(e) => {
            setAssessmentLogic(e.target.value);
          }}
          size='large'
          endAdornment={
            <ButtonMain
                onClick={handleAssessmentLogicCheck}
                className='verify_logic_button'
              >
                {renderVerifyButtonLabel()}
              </ButtonMain>
          }
        />
      </Grid>
    </Grid>
  );
};

export default AssessmentLogic;
