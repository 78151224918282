import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { GET } from "../../../config/Api";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Box, Button } from "@mui/material";
import { Constants } from "../../../config/Constants";
import { FullScreenLoader } from "../../Loader/Loader";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import ShareOnSocialMedia from "./ShareOnSocialMedia";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import SendEmailModal from "./SendEmailModal";
import clickEvent from "../../../utils/logging-event-utils";
import sendDataToFirehose from "../../../utils/aws-utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewCertificateModal = ({ certOpen, setCertOpen, name, setName, closeModal }) => {
  const [certificateData, setCertificateData] = useState({});
  const [open, setOpen] = useState(false);
  const [openSocialMedia, setOpenSocialMedia] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [downloadCertificateActions, setDownloadCertificateActions] = useState({});

  const { isLoggedIn, user } = useSelector((state) => state.userLogin.data);

  const { courseOne, completedModulesData, setCompletedModulesData } = useOutletContext();

  const toastRef = useRef();

  useEffect(() => {
    isLoggedIn &&
      certOpen &&
      courseOne?._id &&
      completedModulesData?.cohort_id &&
      getLatestConfiguration() &&
      getDownloadCertificateAction();
  }, [certOpen]);

  const getLatestConfiguration = async () => {
    try {
      //get latest certificate configuration
      const res = await GET(
        `${Constants.getLatestConfiguration}?course_id=${courseOne?._id}&cohort_id=${completedModulesData?.cohort_id}`,
      );
      setCertificateData(res.data.latestConfiguration);
    } catch (error) {
      console.log(error);
    }
  };
  const getDownloadCertificateAction = async () => {
    try {
      //get latest certificate configuration
      const res = await GET(`${Constants.getDownloadCertificateAction}`);
      setDownloadCertificateActions(res?.data?.result?.config_val[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadCertificate = async () => {
    setOpen(true);
    const token = localStorage.getItem("token");
    const sessionTokenHash = localStorage.getItem("sessionTokenHash");
    //download certificate
    fetch(`${Constants.generateCertificate}?action=downloadCertificate`, {
      method: "post",
      body: JSON.stringify({
        course_id: courseOne?._id,
        cohort_id: completedModulesData?.cohort_id,
        name: name,
        user_id: user?.id,
        courseName: courseOne?.title,
      }),
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Session-Token-Hash": sessionTokenHash,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch PDF"); // Handle errors
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a link element
        const link = document.createElement("a");

        // Set the href attribute to the object URL of the blob
        link.href = URL.createObjectURL(blob);

        // Specify the download attribute and filename
        link.download = `${courseOne?._id}.pdf`;

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
        setOpen(false);
        triggerUserEvent("download_certificate");
        toastRef.current.showToastMessage("Certificate Downloaded Successfully.", "success");
      })
      .catch((error) => {
        console.error("Error fetching or displaying PDF:", error);
        setOpen(false);
        // Handle errors gracefully, e.g., display an error message to the user
      });
  };

  const triggerUserEvent = (event) => {
    if (user?.email) {
      const eventData = new clickEvent();
      eventData.event = event;
      eventData.user_unique_id = user.email;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={certOpen}
        onClose={() => setCertOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "sticky", top: 0, bgcolor: "#111333" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Certificate Sharing
            </Typography>
            <Button
              color="warning"
              variant="contained"
              onClick={() => {
                setCompletedModulesData((prev) => ({
                  ...prev,
                  isCertificateGenerated: true,
                }));
                closeModal && closeModal();
                setCertOpen(false);
                setName && setName("");
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <Box sx={{ textAlign: "center", mt: "1rem" }}>
          <Typography variant="h4" color={"success"}>
            <Box>
              <EmojiEventsIcon sx={{ color: "#1976d2", fontSize: "10rem" }} />
            </Box>
            Certificate generation was successful.
          </Typography>
          <Box mt={6}>
            <Button
              variant="contained"
              onClick={handleDownloadCertificate}
              sx={{ textTransform: "none", minWidth: "200px" }}
              size="large"
            >
              Download Certificate
            </Button>
          </Box>
          <Box marginTop={1}>
            <Button
              variant="contained"
              sx={{ textTransform: "none", minWidth: "200px" }}
              size="large"
              onClick={() => setOpenEmailModal(true)}
            >
              Send Email
            </Button>
          </Box>
          <Box marginTop={1}>
            <Button
              variant="contained"
              size="large"
              sx={{ textTransform: "none", minWidth: "200px" }}
              onClick={() => setOpenSocialMedia(true)}
            >
              Share on Social Media
            </Button>
          </Box>
          <Typography mt={2} fontSize={"20px"}>
            Please help us improve FramerSpace by providing us feedback:{" "}
            <a
              href={downloadCertificateActions.surveyFormLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click Here!
            </a>
          </Typography>
        </Box>
        <FullScreenLoader open={open} />
        <ToastNotify ref={toastRef} duration={4000} />
        <ShareOnSocialMedia
          openSocialMedia={openSocialMedia}
          setOpenSocialMedia={setOpenSocialMedia}
          name={name}
          certificateData={certificateData}
        />
        <SendEmailModal
          openEmailModal={openEmailModal}
          setOpenEmailModal={setOpenEmailModal}
          name={name}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default PreviewCertificateModal;
