import React from "react";
import SupportLayout from "../../components/Support";

import "./supportPage.scss";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";

const Support = () => {
  return (
    <HeaderContainer footerView={true}>
      <SupportLayout />
    </HeaderContainer>
  );
};

export default Support;
