import React, { Fragment } from "react";
import "./ArtView.scss";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
const ArtView = ({ art }) => {
  return (
    <Fragment>
      {art && (
        <div
          className="block-art"
          style={{
            backgroundImage: `url(${
              art?.m?.startsWith(cloudfrontUrl) ? art.m : cloudfrontUrl + art.m
            })`,
            height: `${art?.bound}px`,
          }}
        ></div>
      )}
    </Fragment>
  );
};

export default ArtView;
