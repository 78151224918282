import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { embedTypes, useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import { Box, Typography } from "@mui/material";
import "./NativeEmbed.scss";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";

const AddNativeEmbed = ({
  type,
  block,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [formData, setFormData] = useState({
    title: block?.title || "",
    url: block?.nativeEmbed?.url || "",
    width: block?.embed?.width || null,
    height: block?.embed?.height || null,
    position_label: block?.nativeEmbed?.position_label || "",
  });

  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedEmbedType, setSelectedEmbedType] = useState(block?.nativeEmbed?.platform || "");
  const { setCourseOne } = useOutletContext();

  const UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const toastRef = useRef();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    if (formData.url === "") {
      toastRef.current.showToastMessage("Please Enter Embed URL.", "error");
    } else if (
      selectedEmbedType === "survey-monkey" &&
      (formData.position_label.trim() === "" ||
        formData.position_label === undefined ||
        formData.position_label === null)
    ) {
      toastRef.current.showToastMessage("Please Enter Position Label.", "error");
    } else {
      setDisable(true);

      const body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        platform_url: formData.url,
        platform: selectedEmbedType,
        title: formData.title,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        type: type,
        position_label: formData.position_label,
      };

      try {
        const response = await POST(`${Constants.addBlock}/native-embed?_id=${block?._id}`, body);
        setDisable(false);

        if (response.data.success === false) {
          toastRef.current.showToastMessage(response.data.message, "error");
        } else {
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
          toastRef.current.showToastMessage(response.data.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSelectEmbedType = (type) => {
    setSelectedEmbedType(type);
    setFormData({ ...formData, url: "", position_label: "" });
  };

  return (
    <div>
      <ToastNotify ref={toastRef} duration={4000} />
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox placeholder="Title" name="title" value={formData.title} onChange={handleChange} />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Box>
      )}
      <br />
      <Box className="embedBubbleWrapper">
        {embedTypes.map((em, i) => (
          <button
            className={`embedBubble ${
              selectedEmbedType === em.value ? "embedBubbleSelected" : "embedBubbleNotSelected"
            } `}
            key={i}
            onClick={() => handleSelectEmbedType(em.value)}
          >
            {em.name}
          </button>
        ))}
      </Box>
      {selectedEmbedType === "survey-monkey" && (
        <Typography mb={1}>
          You can choose to include the following custom variables in your SurveyMonkey form
          settings.
          <br /> "uniqueId", "courseId", "blockId", "positionLabels", "cohortInviteCode"
        </Typography>
      )}
      <InputBox
        placeholder="Enter URL"
        name="url"
        value={formData.url}
        onChange={(e) => setFormData({ ...formData, url: e.target.value })}
      />
      <Box mt={1}>
        {selectedEmbedType === "survey-monkey" && (
          <>
            <InputBox
              placeholder="Enter Position Label"
              name="position_label"
              value={formData.position_label}
              onChange={(e) => setFormData({ ...formData, position_label: e.target.value })}
            />
            <Typography mt={1}>
              You can choose to include the following custom variables in your SurveyMonkey form
              settings. "uniqueId", "courseId", "blockId", "positionLabels", "cohortInviteCode"
            </Typography>
          </>
        )}
      </Box>
      <br />
      <ButtonMain className="buttonGreen" disabled={disable} onClick={handleSave}>
        Save Block
      </ButtonMain>{" "}
      <br />
    </div>
  );
};

export default AddNativeEmbed;
