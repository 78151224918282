import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./ScormView.scss";
import ArtView from "../ArtView/ArtView";
import { executeScormFn, useQueryParams } from "../../../../utils";
import { Constants } from "../../../../config/Constants";
import { POST } from "../../../../config/Api";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const ScormView = ({
  scorm,
  courseDetails,
  blockUserActivities,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [launch, setLaunch] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  const { cid } = useQueryParams();

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  // useCallback(executeScormFn(user, handleScormSubmit),[])

  // useEffect(() => {
  //   console.log("FROM SCORM");

  //   console.log({ data });
  // }, [blockUserActivities]);

  //   useEffect(() => {
  //     if (blockUserActivities) {
  //       const data = blockUserActivities?.data?.find((item) => item.block_id === scorm._id);
  //       // if (data) {
  //       executeScormFn(user, handleScormSubmit, data?.scorm_data);
  //       // }
  //     }
  //   }, [blockUserActivities]);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  const handleLaunch = () => {
    setLaunch(true);
    const data = blockUserActivities?.data?.find((item) => item.block_id === scorm._id);
    executeScormFn(user, handleScormSubmit, data?.scorm_data);
  };

  const handleScormSubmit = async (data) => {
    if (isLoggedIn) {
      try {
        let body = {
          user_id: user.id,
          course_id: cid,
          activity: "scorm",
          scorm_data: data,
        };
        //submit scorm answer
        await POST(`${Constants.saveAndUpdateUserActivities}/${scorm?._id}`, body);
      } catch (error) {
        console.log({ error });
        // toastRef.current.showToastMessage("Something went wrong.", "error");
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
      // setAuthStates((prev) => ({
      //   ...prev,
      //   loginOpen: true,
      //   modalOpen: true,
      //   signupOpen: false,
      //   forgotOpen: false,
      // }));
    }
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <>
          <Box
            width={"100%"}
            p={3}
            my={2}
            boxShadow={"0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);"}
            borderRadius={2}
            className={` scorm-view 
    ${isLoggedIn ? `${scorm?.theme} postBgColor` : `${scorm?.theme} defaultStyle`}
  `}
            style={{
              background: `${scorm?.theme_type === "color" ? scorm?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            <ArtView art={(block || scorm)?.art} />
            {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
              <BlockActions
                blockId={scorm?._id}
                block={scorm}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}
            {isModerator !== -1 && block?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}

            {isMobile && (
              <MenuIconWrapper onClick={handleMenuClick}>
                <MoreVertIcon fontSize="large" color="action" />
              </MenuIconWrapper>
            )}

            <Grid container spacing={4}>
              <Grid item md={3}>
                <img
                  src={
                    scorm?.image?.m?.startsWith(cloudfrontUrl)
                      ? scorm?.image?.m
                      : cloudfrontUrl + scorm?.image?.m
                  }
                  style={{ height: "100%", width: "100%" }}
                  alt=""
                />
              </Grid>
              <Grid item md={9}>
                {scorm?.is_required && <span className="required">*</span>}

                <Typography variant="h5" fontWeight={"bold"}>
                  {scorm?.title}
                </Typography>
                <Typography mt={1} variant="body1">
                  {scorm?.text}
                </Typography>
                <Box mt={3}>
                  <Button variant="contained" onClick={handleLaunch}>
                    Launch SCORM
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={launch}
            onClose={() => {
              setLaunch(false);
            }}
          >
            <DialogTitle>SCORM</DialogTitle>
            <DialogContent>
              <iframe
                width={"100%"}
                height={550}
                src={scorm?.scorm?.url}
                title="SCORM IFRAME"
                allowFullScreen
              ></iframe>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setLaunch(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* {launch && (
        <div className="block-scorm">
          <iframe
            width={"100%"}
            height={"100%"}
            src={scorm?.scorm?.url}
            title="SCORM IFRAME"
            allowFullScreen
          ></iframe>
        </div>
      )} */}
          {/* <Box>
        <Button variant="contained" onClick={handleLaunch}>
          Launch SCORM
        </Button>
      </Box>
      <style>
        {`
          .block-scorm  iframe {
            min-height:inherit;
          }
          `}
      </style> */}
        </>
      )}
    </>
  );
};

export default ScormView;
