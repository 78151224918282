import React from "react";
import { Grid, Avatar, Typography, Card, CardContent, Box, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoveDownIcon from "@mui/icons-material/MoveDown";

import moment from "moment";
import { useState } from "react";
import { LoadingMore } from "../../Loader/Loader";
import "./CommentCard.scss";

const CommentCard = ({
  _id,
  creator,
  createdAt,
  comment_text,
  getRepliesByParentId,
  setTabValue,
  type,
  setCommentId
}) => {
  const [loading, setLoading] = useState(false);
  const [replies, setReplies] = useState([]);
  const [noMore, setNoMore] = useState(false);

  return (
    <Card
      className="comment__card"
      sx={{ marginBottom: "10px", boxShadow: `${type === "reply" && "none"}` }}
    >
      <CardContent sx={{ marginLeft: `${type === "reply" && "15px"}`, paddingBottom: "16px" }}>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          {/* Left Side */}
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Avatar
                  alt={creator && creator[0]?.email?.charAt(0)?.toUpperCase()}
                  src={creator && creator[0]?.email?.charAt(0)?.toUpperCase()}
                  sx={{ width: 20, height: 20, fontSize: "0.8rem" }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "12px", lineHeight: "10px", marginLeft: "8px" }}
                >
                  {creator && creator[0]?.email?.split("-")?.[3]}
                </Typography>
              </Grid>
              <Grid item>
                <Box sx={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
                  <AccessTimeIcon sx={{ marginRight: "4px" }} />
                  <Typography variant="caption" sx={{ fontSize: "12px", lineHeight: "10px" }}>
                    {moment(createdAt).fromNow()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Side */}
          <Grid item>
            {type === "root" && (
              <Tooltip title="Go to the thread">
                <MoveDownIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setCommentId(_id);
                    setTabValue("all");
                  }}
                />
              </Tooltip>
            )}
          </Grid>

          {/* Second Line */}
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontSize: "12px", lineHeight: "10px", marginLeft: "10px" }}
            >
              {comment_text}
            </Typography>
          </Grid>

          {/* Third Line */}
          <Grid item xs={12}>
            <button
              className="reply-button"
              disabled={noMore}
              onClick={async () => {
                setLoading(true);
                const data = await getRepliesByParentId(_id);
                !data.length && setNoMore(true);
                setReplies((prev) => {
                  const uniqueReplies = [...prev, ...data].filter(
                    (reply, index, self) => index === self.findIndex((r) => r._id === reply._id),
                  );
                  return uniqueReplies;
                });
                setLoading(false);
              }}
            >
              View Replies
            </button>
          </Grid>
        </Grid>
      </CardContent>
      {loading ? (
        <LoadingMore />
      ) : (
        <>
          {replies?.map((reply) => (
            <CommentCard
              key={reply?._id}
              _id={reply?._id}
              comment={reply}
              createdAt={reply?.createdAt}
              setComments={setReplies}
              creator={reply?.creator}
              comment_text={reply?.comment_text}
              getRepliesByParentId={getRepliesByParentId}
              type={"reply"}
              parentCreator={creator[0]?.email?.split("-")[3]}
              parentText={
                comment_text?.length > 15 ? comment_text?.substring(0, 15) + "..." : comment_text
              }
            />
          ))}
          {noMore && <p className="no-more-replies">No more replies to be loaded...</p>}
        </>
      )}
    </Card>
  );
};

export default CommentCard;
