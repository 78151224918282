import { Box } from "@mui/material";
import React from "react";
import { ParagraphText } from "../Typography";

import "./ScoreCard.scss";

const EnrollmentInfoContainer = ({ enrollmentDate, daysFromEnrollment }) => {
  return (
    <Box className="dateBlockWrapper">
      <Box className="enrollmentDateWrapper">
        <ParagraphText className="enrollMentDayText">Enrollment Date</ParagraphText>
        <ParagraphText className="scoreCardEnrollmentDateText">{enrollmentDate}</ParagraphText>
      </Box>
      <Box className="verticalLine" style={{ minHeight: "120px" }}></Box>
      <Box className="enrollmentDateWrapper">
        <ParagraphText className="enrollMentDayText">Days passed</ParagraphText>
        <ParagraphText className="numberOfDaysText">{daysFromEnrollment}</ParagraphText>
        {/* <ParagraphText className="scoreCardEnrollmentDateText">{daysDifference}</ParagraphText> */}
      </Box>
    </Box>
  );
};

export default EnrollmentInfoContainer;
