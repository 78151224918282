import React from "react";
import { Box, Breadcrumbs, Chip, Grid, Modal, emphasize } from "@mui/material";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ParagraphText } from "../../Typography";
import KeywordsView from "../../Keywords/KeywordsView";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import "./CohortModal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1400,
  bgcolor: "background.paper",
  boxShadow: 24,
  //   p: 4,
};
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = "#aaa6a6";
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: "black",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.2),
    },
  };
});

const CohortPreviewModal = ({
  cohortData,
  courseData,
  showPreviewModal,
  setShowPreviewModal,
}) => {
  return (
    <div>
      <Modal
        open={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ width: "95%", height: "92%", overflow: "auto" }}
        >
          <Box
            sx={{
              backgroundColor: "black",
              color: "#fff",
              padding: "5px",
              display: "flex",
              gap: "1rem",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }} className="preview-msg">
              Preview of the course page header that learners in this cohort
              will see.
            </Box>
            <CloseIcon
              onClick={() => setShowPreviewModal(false)}
              className="close-icon"
              sx={{
                float: "right",
                fontSize: "2rem",
                textAlign: "right",
              }}
            />
          </Box>
          <Box sx={{ padding: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={`${cloudfrontUrl}/fs-static/images/site/logo-dark.png`}
                alt="logo"
                height="50px"
                width="50px"
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Box sx={{ lineHeight: 0 }}>
                  <HelpOutlineOutlinedIcon
                    sx={{ height: "2rem", width: "2rem" }}
                  />
                </Box>
                <Box className="preview-profile-icon">A</Box>
              </Box>
            </Box>
            <Box sx={{ margin: "2rem 0rem" }}>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb component="a" label="Course" />
                <StyledBreadcrumb component="a" label={courseData?.title} />
              </Breadcrumbs>
              <h2 style={{ fontSize: "2rem" }}>{courseData?.title}</h2>
              <ParagraphText>{courseData?.tagline}</ParagraphText>
              <KeywordsView keywords={courseData} />
              <Box>
                <strong>Cohort title</strong> : {cohortData?.title}{" "}
              </Box>
              {cohortData?.customText && (
                <Grid
                  container
                  className="courseCardMain preview-card"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item md={6}>
                    <Grid item>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cohortData?.customText,
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box sx={{ textAlign: "center" }}>
              Course content goes here! <br /> <KeyboardDoubleArrowDownIcon />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "black",
              width: "100%",
              height: "2rem",
              position: "absolute",
              bottom: 0,
            }}
          ></Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CohortPreviewModal;
