import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import { CircularIndeterminate } from "../../../../Loader/Loader";
import { useOutletContext } from "react-router-dom";

const AddWebBookmark = ({
  courseDetails,
  type,
  block,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [url, setUrl] = useState("");
  const [searchResult, setsearchResult] = useState([]);
  const [spin, setSpin] = useState(false);
  const [imgurl, setimgurl] = useState("");
  const [disable, setdisable] = useState(false);
  const [errMsg, seterrMsg] = useState("");
  const [hide, setHide] = useState(false);
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);
  const { cid, mid } = useQueryParams();

  const getContent = async (e) => {
    if (url === "") {
      seterrMsg("Please Enter a URL.");
    } else {
      setSpin(true);
      seterrMsg("");
      setdisable(true);
      //get content by search text
      await POST(`${Constants.addBlock}/embedlink?url=${url}`)
        .then((response) => {
          setSpin(false);
          setdisable(false);
          setHide(true);
          setsearchResult(response?.data);
        })
        .catch((error) => console.log(error));
    }
  };

  const onImgClick = (item) => {
    setimgurl(item.url);
  };

  const handleSave = async () => {
    setdisable(true);
    let body = {
      user_id: UserData?.data?.user?.id,
      course: cid,
      container: mid,
      order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
      bound: 160,
      image: imgurl,
      linkdata: searchResult,
      type: type,
    };
    //create web bookmark block
    await POST(`${Constants.addBlock}/link?_id=${block?._id}`, body)
      .then((response) => {
        setdisable(false);
        setCourseOne((prev) => ({ ...prev, published: false }));
        if (type === "edit") {
          setCourseBlocks &&
            setCourseBlocks((prev) => {
              const elIndex = prev.findIndex((el) => el._id === block?._id);
              const updatedBlocks = prev.map((el) => {
                if (el.order >= response?.data?.order) {
                  el.order += 1;
                }
                return el;
              });
              updatedBlocks.splice(elIndex, 1, response?.data);
              updatedBlocks.sort((a, b) => a?.order - b?.order);
              return updatedBlocks;
            });
        } else {
          if (blockModalOpen?.addBelow) {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                const elIndex = updatedBlocks.findIndex(
                  (el) => el._id === blockModalOpen?.currentBlockId,
                );
                updatedBlocks.splice(elIndex + 1, 0, response?.data);
                return updatedBlocks;
              });
          } else {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                prev.forEach((el) => {
                  el.order = el.order + 1;
                });
                return [response?.data, ...prev];
              });
          }
        }
        setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
        setblockModOpen(false);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <InputBox
        placeholder="Type or paste a URL for embedding"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        errorMsg={errMsg}
      />
      <br />
      <ButtonMain onClick={getContent} className="buttonGreen" disabled={disable}>
        Get Content
      </ButtonMain>{" "}
      {spin ? (
        <CircularIndeterminate />
      ) : (
        <>
          <br />
          <br />
          {searchResult?.images?.map((item, index) => (
            <img
              src={item?.url}
              alt=""
              key={index}
              height="120px"
              width="120px"
              onClick={() => onImgClick(item)}
            />
          ))}
        </>
      )}
      <br />
      {hide && (
        <ButtonMain className="buttonGreen" onClick={handleSave} disabled={disable}>
          Save block
        </ButtonMain>
      )}
    </div>
  );
};

export default AddWebBookmark;
