import React from "react";

import { useSelector } from "react-redux";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import { Box, Typography } from "@mui/material";
import NavigateItem from "./NavigateItem";

import "./Navigate.scss";

export default function Navigate({ modDetails, open, setOpen, courseId, userDetails }) {
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  return (
    <>
      {isLoggedIn && (
        <Box className="navigate">
          {open && (
            <div className="">
              <List
                sx={{
                  width: { xs: "100%", sm: "300px" },
                  marginRight: "20px",
                  // border: "2px solid white",
                  borderRadius: "3px",
                  bgcolor: "#365082",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" sx={{ bgcolor: "#365082", color: "white", px: 3 }}>
                    <Typography component={"h4"} variant="body1" fontWeight={600}>
                      Course Navigation
                    </Typography>
                  </ListSubheader>
                }
              >
                {modDetails?.map((item, index) => (
                  <Box m={1}>
                    <NavigateItem
                      data={item}
                      setOpen={setOpen}
                      courseId={courseId}
                      userDetails={userDetails}
                    />
                  </Box>
                ))}
              </List>
            </div>
          )}
          {/* </Drawer> */}
        </Box>
      )}
    </>
  );
}
