import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";

const ImportCourseJSON = ({ openImportDialog, setOpenImportDialog }) => {
  const [jsonLoading, setJsonLoading] = useState(false);
  const [jsonLink, setJsonLink] = useState("");
  const toastRef = useRef();
  const { cid } = useQueryParams();

  const handleImportJSON = async () => {
    const isValidLink = /^https?:\/\/[^\s$.?#].[^\s]*\.json(?:\?.*)?$/.test(jsonLink);
    if (!isValidLink) {
      return toastRef.current.showToastMessage("Invalid JSON Link.", "error");
    }
    try {
      setJsonLoading(true);
      //Import JSON
      const response = await POST(`${Constants.courseManage}/createFromJSON/${cid}`, {
        courseJsonLink: jsonLink,
      });
      setJsonLoading(false);
      toastRef.current.showToastMessage(response?.data?.message, "success");
      setOpenImportDialog(false);
      window.location.href = `${`/course/createblock?cid=${cid}`}`;
    } catch (error) {
      setJsonLoading(false);
      toastRef.current.showToastMessage(error?.response?.data?.message, "error");
      setOpenImportDialog(false);
    }
  };

  return (
    <>
      <Dialog
        open={openImportDialog}
        onClose={() => {
          setOpenImportDialog(false);
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={jsonLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle fontWeight={600}>Import Course Content From JSON</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <Box>
            <Alert severity="error">
              <AlertTitle>Important Note:</AlertTitle>
              Existing blocks will be deleted and they will not be recovered.
            </Alert>
          </Box>
          {/* </DialogContentText> */}
          <TextField
            autoFocus
            required
            margin="dense"
            id="jsonLink"
            name="jsonLink"
            label="JSON Link"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setJsonLink(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} disabled={jsonLoading} onClick={handleImportJSON}>
            Submit
          </Button>
          <Button
            onClick={() => {
              setOpenImportDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastNotify ref={toastRef} duration={4000} />
    </>
  );
};

export default ImportCourseJSON;
