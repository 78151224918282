import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import ItemView from "./ItemView";
import { useOutletContext } from "react-router-dom";

const AddToggleList = ({
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [item, setItem] = useState({
    title: "",
    text: "",
  });
  const [data, setdata] = useState(block?.items || []);
  const [errText, setErrText] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const { setCourseOne } = useOutletContext();
  const [alertType, setalertType] = useState("error");

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleChange = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleAddItem = () => {
    if (item.title === "") {
      setOpen(true);
      setErrText("Please Enter Title");
    } else if (item.text === "") {
      setOpen(true);
      setErrText("Please Enter Text");
    } else {
      setdata((prevData) => [...prevData, item]);
      setItem({ title: "", text: "" });
    }
  };

  const handleSave = async () => {
    if (!data.length) {
      setOpen(true);
      setErrText("Please Click on the Add Item Button to proceed.");
    } else {
      setDisable(true);
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        text: data,
        type: type,
      };
      //create toggle list block
      await POST(`${Constants.addBlock}/toggle_list?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setOpen(true);
          setalertType("success");
          setErrText(`Block ${type ? "Updated" : "Created"} Successfully`);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div>
      <Box sx={{ margin: "1rem 0rem" }}>
        <InputBox
          name="title"
          placeholder="Text / Question"
          value={item.title}
          onChange={handleChange}
        />{" "}
        <br />
        <InputBox
          name="text"
          placeholder="Text / Answer"
          value={item.text}
          onChange={handleChange}
        />{" "}
        <br />
        <ButtonMain className="buttonGreen" onClick={handleAddItem}>
          Add Item
        </ButtonMain>{" "}
        <br />
        <br />
        {data.length ? (
          <>
            <b>Items:</b> <br />
            <ItemView toggle_list={data} setdata={setdata} isRemove={true} type={type} />
          </>
        ) : (
          ""
        )}
      </Box>
      {data.length !== 0 && (
        <ButtonMain className="buttonGreen" onClick={handleSave} disabled={disable}>
          Save Block
        </ButtonMain>
      )}
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddToggleList;
