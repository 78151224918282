import React, {useCallback} from 'react'
import { Box, Grid, Typography } from '@mui/material'
import ButtonMain from '../ButtonMain'
import AssessmentDomainForm from './AssessmentDomainForm'

const AdvancedAssessment = ({assessmentDomainData , handleDomain,handleDomainActions}) => {
    
    const renderAssessmentForm = useCallback(() => {
        return assessmentDomainData.map((domain, i) => (
            <AssessmentDomainForm
                key={i}
                domain={domain}
                handleDomainActions={handleDomainActions}
            />
        ))
    },[assessmentDomainData])
    
  return (
    <>
    <Grid
        container
        py={2}
        className={"flex items-center justify-center"}
        // style={{ overflowY: "scroll", maxHeight: "200px" }}
        >
            <Grid container direction="row" px={4} mb={2} justifyContent="space-between" style={{width: '100%'}}>
                <Grid item>
                    <Typography variant="subtitle1" fontWeight={600}>
                    Assessment Domain
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" fontWeight={600}>
                    Percentage Score Required
                    </Typography>
                </Grid>
            </Grid>
        {/* The Domain Form Begins Here */}
        {renderAssessmentForm()}
        {/* The Domain Form Ends Here */}
    </Grid>
    <Box mb={2}>
        <ButtonMain
            onClick={handleDomain}
            className="add_assessment_domain_button"
        >
            Add Assessment Domain
        </ButtonMain>
    </Box>
    </>
  )
}

export default AdvancedAssessment