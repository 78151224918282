import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import ButtonMain from "../../components/ButtonMain";

const ReviewCourseCard = ({ courses, onClick }) => {
  // State to handle pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };

  // Slice courses for pagination
  const paginatedCourses = courses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "500px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px",
                  fontSize: "16px",
                  width: "60%",
                }}
              >
                Course Title
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px",
                  fontSize: "16px",
                  width: "15%",
                  textAlign: "center",
                }}
              >
                Certificate Status
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textAlign: "center",
                  width: "12%",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCourses.map((course, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                  borderBottom: "1px solid #ddd",
                }}
              >
                <TableCell sx={{ fontSize: "16px" }}>
                  {course?.title || course?.courseDetails?.title}
                </TableCell>
                <TableCell sx={{ fontSize: "16px", textAlign: "center" }}>
                  <div style={{ color: course?.isCertificateGenerated ? "green" : "black" }}>
                    {course?.isCertificateGenerated ? "Yes" : "No"}
                    {course?.isCertificateGenerated && (
                      <div>{new Date(course?.certificateDateISO).toLocaleDateString()}</div>
                    )}
                  </div>
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <ButtonMain
                    variant="tangerine"
                    color="primary"
                    sx={{
                      backgroundColor: "#ff9800",
                      "&:hover": {
                        backgroundColor: "#e68900",
                      },
                      fontSize: "14px",
                    }}
                    onClick={() => onClick(course)}
                  >
                    Review Course
                  </ButtonMain>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <TablePagination
        component="div"
        count={courses.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Paper>
  );
};

export default ReviewCourseCard;
