import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import ManagedTabs from "../../Tabs";
import { useState } from "react";
import VerticalTabs from "../FeaturedCourses/FeaturedCourses";
import { AuthContext } from "../../../App";

const FeaturedCourses = () => {
  const [tabValue, setTabValue] = useState("allcourses");

  const { authStates } = useContext(AuthContext);

  let tabs = [
    {
      value: "allcourses",
      label: "All Courses",
    },
  ];

  if (authStates?.preferredLanguage?._id) {
    tabs.push({
      value: "preferred",
      label: `Courses in ${
        authStates?.preferredLanguage?.name +
        " " +
        "(" +
        authStates?.preferredLanguage?.nativeName +
        ")"
      }`,
    });
  }

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };
  return (
    <div>
      <Typography variant="h6" sx={{ fontSize: "1.2rem", fontWeight: 600, marginTop: "2rem" }}>
        UNESCO MGIEP Featured Courses
      </Typography>
      <ManagedTabs tabs={tabs} handleTabChange={handleTabChange} tabsValue={tabValue} />
      {tabValue === "allcourses" && (
        <VerticalTabs type="featured" headingText={" UNESCO MGIEP Featured Courses"} />
      )}
      {tabValue === "preferred" && authStates?.preferredLanguage?._id && (
        <Box>
          <VerticalTabs
            type="preferred"
            headingText={`UNESCO MGIEP Featured Courses in ${
              authStates?.preferredLanguage?.name + " " + authStates?.preferredLanguage?.nativeName
            }`}
          />
        </Box>
      )}
    </div>
  );
};

export default FeaturedCourses;
