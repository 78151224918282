import React from "react";
import "./CertificatePreview.scss";
import defaultCertBg from "../../../assets/DefaultCertificate.png";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import CertificateTemplate from "./CertificateTemplate";
import { FullScreenLoader } from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import FullScreenModal from "../../courses/CourseModals/FullScreenModal";

const CertificatePreview = ({ modalOpen, setModalOpen, certificates, signatories }) => {
  const [certValue, setCertValue] = useState("");
  const [signValue, setSignValue] = useState("");
  const [certData, setCertData] = useState({});
  const [signData, setSignData] = useState({});
  const [open, setOpen] = useState(false);

  let navigate = useNavigate();

  const getConfiguration = async (configId) => {
    setOpen(true);
    try {
      //get getCertificateConfig by config id
      let response = await POST(`${Constants.getCertificateConfig}/${configId}`);
      setOpen(false);
      return response?.data?.configuration;
    } catch (error) {
      setOpen(false);
      navigate("/404");
    }
  };

  const handleCertificateChange = async (e) => {
    setCertValue(e.target.value);
    const data = await getConfiguration(e.target.value);
    setCertData(data);
  };
  const handleSignatoryChange = async (e) => {
    setSignValue(e.target.value);
    if (e.target.value === "none") {
      setSignData({});
    } else {
      const data = await getConfiguration(e.target.value);
      setSignData(data);
    }
  };

  return (
    <div>
      <FullScreenModal
        open={modalOpen}
        setOpen={() => {
          setModalOpen(false);
        }}
        dialogTitle={"Certificate Preview "}
      >
        <Grid container p={2} spacing={2} sx={{ paddingRight: 0 }}>
          <Grid item className="fullwidth" sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Primary Certificate Configuration
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={certValue}
                label="Primary Certificate Configuration"
                onChange={handleCertificateChange}
              >
                {certificates?.map((certificate) => (
                  <MenuItem value={certificate?._id} key={certificate?._id}>
                    {certificate?.configuration_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className="fullwidth" sm={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Additional Signatory Configuration
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={signValue}
                label="Additional Signatory Configuration"
                onChange={handleSignatoryChange}
                disabled={!certValue}
              >
                <MenuItem value="none">None</MenuItem>
                {signatories?.map((signatory) => (
                  <MenuItem value={signatory?._id} key={signatory?._id}>
                    {signatory?.configuration_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className="fullwidth" xs={12} justifyContent={"center"} display="flex">
            {Object.keys(certData).length ? (
              <CertificateTemplate certData={certData} signData={signData} />
            ) : (
              <img src={defaultCertBg} alt="certBg" width="100%" />
            )}
          </Grid>
        </Grid>
        <FullScreenLoader open={open} />
      </FullScreenModal>
    </div>
  );
};

export default CertificatePreview;
