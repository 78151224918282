import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "./CourseListing.scss";
import { saveCourseDetailAction } from "../../../redux/action";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import ButtonMain from "../../ButtonMain";
import NewCourseModal from "../CourseModals/NewCourseModal";
import { ParagraphText } from "../../Typography";
import { CourseListingSkeleton } from "../../Loader/Loader";
import KeywordsView from "../../Keywords/KeywordsView";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import bookImage from "../../../assets/Book-new.svg";
import { getS3ApiJsonKey } from "../../../utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function stripHtmlTags(html) {
  return html.replace(/<[^>]*>/g, "");
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  const [filteredCourses, setfilteredCourses] = useState([]);

  useEffect(() => {
    getPublicCourses();
  }, []);

  // const getPublicCourses = async () => {
  //   try {
  //     const response = await GET(Constants.getPublicCourses);
  //     // const coursesArr = response?.data?.data;
  //     // const result = getCourseTerm(coursesArr, termObj?._id);
  //     setfilteredCourses(response?.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //get list of public courses
  const getPublicCourses = async () => {
    try {
      const url = `${getS3ApiJsonKey}/${Constants.getS3PublicCourses}.json`;

      const response = await fetch(url);

      if (response.status === 200) {
        const data = await response.json();
        setfilteredCourses(data);
      } else {
        const publicCourseRes = await GET(`${Constants.getPublicCourses}`);
        setfilteredCourses(JSON.parse(publicCourseRes?.data?.data));
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (item) => {
    navigate(`/course/${item?.slug}?cid=${item?._id}`);
    dispatch(saveCourseDetailAction(item));
  };

  return (
    <Box className="box">
      <Box className="newCourseBtnMain">
        <ParagraphText className="paragraph-text">
          Select the audience below to explore all our popular courses.
        </ParagraphText>
        {isLoggedIn && (
          <ButtonMain className="newCourseBtn" onClick={() => setModalOpen(true)}>
            New course
          </ButtonMain>
        )}
      </Box>
      <div className="course-tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ marginTop: "2rem" }}
          className={`course-container-tabs post-course-button-tab 
          }`}
        >
          {filteredCourses?.map((item, index) => (
            <Tab
              key={item?.term?._id}
              label={item?.term?.title}
              {...a11yProps(index)}
              className={`course-button-tab post-course-button-tab post-course-button-tab-blue 
              }`}
              // onClick={() => handleChangeTerm(item)}
            />
          ))}
        </Tabs>
      </div>
      <TabPanel value={value} index={value} className="tabPanel">
        <div>
          {filteredCourses[value]?.docs?.length ? (
            <>
              {filteredCourses[value]?.docs?.map((item) => (
                <Paper
                  key={item?._id}
                  onClick={() => handleClick(item)}
                  className="course_card"
                  elevation={3}
                  id={"paperLight"}
                  sx={{ overflow: "hidden" }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        sm={7}
                        sx={{ padding: "2.5rem" }}
                        className="grid-text-container"
                      >
                        <div className="course-title">{item?.title}</div>
                        <div className="course-tagline">
                          {item?.tagline ? (
                            <>
                              {stripHtmlTags(item.tagline)}
                              {stripHtmlTags(item.tagline).length > 400 && (
                                <strong className="read-more">... &nbsp;Read more.</strong>
                              )}
                            </>
                          ) : null}
                        </div>
                        <KeywordsView keywords={item} />
                      </Grid>
                      <Grid item xs={12} sm={5} lg={4} className="img-container">
                        {item?.image?.m ? (
                          <img
                            src={
                              item?.image?.m?.startsWith(cloudfrontUrl)
                                ? item?.image?.m
                                : cloudfrontUrl + "/" + item?.image?.m
                            }
                            alt="course images"
                            className="course-img"
                          />
                        ) : (
                          <img src={bookImage} alt="course images" className="course-img" />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              ))}
            </>
          ) : (
            <CourseListingSkeleton />
          )}
        </div>
      </TabPanel>
      {isLoggedIn && (
        <NewCourseModal modalOpen={modalOpen} modalClose={() => setModalOpen(false)} />
      )}
    </Box>
  );
}
