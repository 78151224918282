import * as React from "react";
import "./CreateBlockModal.scss";
import FullScreenModal from "../CourseModals/FullScreenModal";
import Vertical from "./CreateBlocks/Vertical";

export default function CreateBlockModal({
  blockModalOpen,
  setblockModOpen,
  courseDetails,
  setCourseBlocks,
  type,
}) {
  const [HeadingText, setHeadingText] = React.useState("RichText");
  return (
    <FullScreenModal
      open={blockModalOpen?.modalOpen}
      setOpen={setblockModOpen}
      dialogTitle={`Create ${HeadingText === "Paste a block" ? "Paste a" : HeadingText} block`}
    >
      <Vertical
        setblockModOpen={setblockModOpen}
        blockModalOpen={blockModalOpen}
        setHeadingText={setHeadingText}
        setCourseBlocks={setCourseBlocks}
        courseDetails={courseDetails}
        type={type}
      />
    </FullScreenModal>
  );
}
