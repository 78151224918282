import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CohortManagement.scss";
import HeaderContainer from "../HeaderContainer/HeaderContainer";
import { HeaderText } from "../Typography";

import ButtonMain from "../ButtonMain";
import { Button, Container, Grid } from "@mui/material";
import CohortDataTable from "./CohortDataTable";
import CohortModal from "./Modals/CohortModal";
import { Constants } from "../../config/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { GET, POST, PUT } from "../../config/Api";
import swal from "sweetalert";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddMembersModal from "../AddMembersModal";
import { useQueryParams } from "../../utils";
import { logoutAction } from "../../redux/action";
import LearnersModal from "../LearnersModal";
import InsightModal from "../ModalMain/InsightModal";
import CohortPreviewModal from "./Modals/CohortPreviewModal";

const CohortManagement = () => {
  let UserData = useSelector((state) => state.userLogin.data);
  let isLoggedIn = UserData?.isLoggedIn;

  const [openModal, setOpenModal] = useState(false);
  const [cohortList, setCohortList] = useState([]);
  const { slug: courseSlug } = useParams();
  const { cid } = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fetchUpdatedData, setFetchUpdatedData] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showLearnerModal, setShowLearnerModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [insightModal, setInsightModal] = useState(false);

  const [disable, setdisable] = useState(false);
  const [courseData, setCourseData] = useState();
  const editorRef = useRef(null);
  const [cohortDataError, setCohortDataError] = useState({
    title: "",
    learnerLimit: "",
    startDate: null,
    endDate: null,
    customText: "",
    joiningCode: "",
    certificateConfig: "",
  });

  const [cohortData, setCohortData] = useState({
    _id: "",
    title: "",
    startDate: null,
    endDate: null,
    learnerLimit: "",
    automaticApproval: false,
    customText: "",
    joiningCode: "",
    certificateSuffix: "",
    useCourseCertificateConfig: false,
    useCourseSignatoryConfig: false,
    certificateConfig: "",
    signatoryConfig: "",
    isPaid: false,
    type: "facilitated",
  });

  const [addMembersData, setAddMembersData] = useState({
    cohort_id: "",
    courseId: "",
    user_id: "",
  });

  const [addLearnersData, setAddLearnersData] = useState({
    cohort_id: "",
    courseId: "",
  });

  const [initialValue, setinitialValue] = useState("");

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      //get cohort list by course slug and user id
      GET(`${Constants.cohort}/${courseSlug}/${UserData?.user?.id}`)
        .then((res) => {
          setCohortList(res.data.cohort);
        })
        .catch((err) => console.log(err));
    }
  }, [fetchUpdatedData]);

  useEffect(() => {
    (async () => {
      //get public course details
      const response = await GET(`${Constants.getPublicCourseDetail}/${cid}`, {});
      setCourseData(response?.data?.data);
    })();
  }, []);

  const convertDateToISOString = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setCohortDataError((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    setCohortData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleCohortSubmit = (e) => {
    e.preventDefault();
    if (cohortData.title === "") {
      setCohortDataError({ ...cohortDataError, title: "Please enter a title" });
    } else if (cohortData.startDate == null) {
      setCohortDataError({
        ...cohortDataError,
        startDate: "Please select start date for this cohort.",
      });
    } else if (cohortData.learnerLimit <= 0) {
      setCohortDataError({
        ...cohortDataError,
        learnerLimit: "Please enter maximum number of members.",
      });
    } else if (cohortData.joiningCode === "") {
      setCohortDataError({
        ...cohortDataError,
        joiningCode: "Please enter joiningCode",
      });
    } else if (cohortData.isPaid && !cohortData.price) {
      setCohortDataError({ ...cohortDataError, price: "Cohort price cannot be empty or 0." });
    } else if (cohortData.useCourseCertificateConfig === true && !cohortData.certificateConfig) {
      setCohortDataError({
        ...cohortDataError,
        certificateConfig: "Please select certificate configuration.",
      });
    } else {
      setLoading(true);
      let formData = new FormData();
      formData.append("title", cohortData.title);
      cohortData?._id !== "" && formData.append("cohortId", cohortData._id);
      formData.append("startDate", convertDateToISOString(cohortData.startDate));
      formData.append("endDate", convertDateToISOString(cohortData.endDate));
      formData.append("learnerLimit", +cohortData.learnerLimit);
      formData.append("customText", cohortData?.customText);
      formData.append("certificateSuffix", cohortData?.certificateSuffix || "");
      formData.append(
        "useCourseCertificateConfig",
        cohortData?.useCourseCertificateConfig || false,
      );
      formData.append("useCourseSignatoryConfig", cohortData?.useCourseSignatoryConfig || false);
      formData.append("certificateConfig", cohortData?.certificateConfig || "");
      formData.append("signatoryConfig", cohortData?.signatoryConfig || "");
      formData.append("joiningCode", cohortData?.joiningCode?.toLocaleLowerCase());
      formData.append("autoAccessApproval", cohortData.automaticApproval);
      formData.append("isPaid", cohortData.isPaid);
      formData.append("price", cohortData.price);
      formData.append("courseID", cid);

      //create cohort
      cohortData?._id === ""
        ? POST(`${Constants.cohort}/${courseSlug}/${UserData?.user?.id}`, formData)
            .then((res) => {
              handleModalClose();
              swal(res.data.message, { icon: "success" });
              setLoading(false);
              setCohortData({
                _id: "",
                title: "",
                startDate: null,
                endDate: null,
                learnerLimit: "",
                automaticApproval: false,
                customText: "",
                joiningCode: "",
                price: "",
                isPaid: false,
              });
              setCohortDataError({
                title: "",
                learnerLimit: "",
                startDate: null,
                endDate: null,
                customText: "",
                joiningCode: "",
                certificateConfig: "",
                price: "",
                isPaid: false,
              });
              setinitialValue("");
              setFetchUpdatedData(!fetchUpdatedData);
            })
            .catch((err) => {
              console.log(err);
              setCohortDataError({
                ...cohortDataError,
                joiningCode: err?.response?.data?.message,
              });
              setLoading(false);
            })
        : //edit cohort
          PUT(`${Constants.cohort}/${courseSlug}/${UserData?.user?.id}`, formData)
            .then((res) => {
              handleModalClose();
              swal(res.data.message, { icon: "success" });
              setLoading(false);
              setCohortData({
                _id: "",
                title: "",
                startDate: null,
                endDate: null,
                learnerLimit: "",
                automaticApproval: false,
                customText: "",
                joiningCode: "",
                isPaid: false,
                price: "",
              });
              setinitialValue("");
              setFetchUpdatedData(!fetchUpdatedData);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
    }
  };

  const handleEditCohort = (
    _id,
    title,
    startDate,
    endDate,
    learnerLimit,
    customText,
    automaticApproval,
    aaa,
    bbb,
    ccc,
    joiningCode,
    certificateSuffix,
    certificateConfig,
    signatoryConfig,
    useCourseCertificateConfig,
    useCourseSignatoryConfig,
    isPaid,
    type,
  ) => {
    // console.log("certificateSuffix", certificateSuffix)
    setinitialValue(customText);
    setOpenModal(true);
    setdisable(true);
    setCohortData({
      _id: _id,
      title: title,
      startDate: startDate,
      endDate: endDate,
      learnerLimit: learnerLimit,
      customText: customText,
      automaticApproval: automaticApproval,
      joiningCode: joiningCode,
      certificateSuffix: certificateSuffix,
      certificateConfig: certificateConfig,
      signatoryConfig: signatoryConfig,
      useCourseCertificateConfig: useCourseCertificateConfig,
      useCourseSignatoryConfig: useCourseSignatoryConfig,
      isPaid: isPaid,
      type: type,
    });
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCohortData({
      _id: "",
      title: "",
      startDate: null,
      endDate: null,
      learnerLimit: "",
      automaticApproval: false,
      customText: "",
      joiningCode: "",
      certificateSuffix: "",
      useCourseCertificateConfig: false,
      useCourseSignatoryConfig: false,
      certificateConfig: "",
      signatoryConfig: "",
    });
    setinitialValue("");
  };

  const handleAddMembers = (
    _id,
    title,
    startDate,
    endDate,
    learnerLimit,
    customText,
    automaticApproval,
    creator,
    courseId,
  ) => {
    setAddMembersData({
      cohort_id: _id,
      courseId: courseId,
      user_id: creator,
    });
    setShowMembersModal(true);
  };

  const handleCohortPreviewModal = (
    _id,
    title,
    startDate,
    endDate,
    learnerLimit,
    customText,
    automaticApproval,
    aaa,
    bbb,
    ccc,
    joiningCode,
  ) => {
    setCohortData({
      _id: _id,
      title: title,
      startDate: startDate,
      endDate: endDate,
      learnerLimit: learnerLimit,
      customText: customText,
      automaticApproval: automaticApproval,
      joiningCode: joiningCode,
    });
    setShowPreviewModal(true);
  };

  const handleLearners = (
    _id,
    title,
    startDate,
    endDate,
    learnerLimit,
    customText,
    automaticApproval,
    creator,
    courseId,
  ) => {
    setAddLearnersData({
      cohort_id: _id,
      courseId: courseId,
    });
    setShowLearnerModal(true);
  };
  const handleInsights = (_id, title) => {
    setCohortData({ _id: _id });
    setInsightModal(true);
    console.log("VALUE", _id);
    console.log("tableMeta", title);
  };

  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <Grid container>
          <Grid item lg={10}>
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardBackspaceIcon />
            </Button>
            <HeaderText>{courseData?.title}</HeaderText>
            {/* <ParagraphText>Description of the Course Goes here.</ParagraphText> */}
          </Grid>
          <Grid item lg={2} mt={2} className="fullwidth flex-end">
            <ButtonMain
              variant="primary"
              onClick={() => {
                setOpenModal(true);
                setdisable(false);
              }}
            >
              Create Cohort
            </ButtonMain>
          </Grid>
        </Grid>
        <CohortDataTable
          cohortList={cohortList}
          handleEditCohort={handleEditCohort}
          handleAddMembers={handleAddMembers}
          handleLearners={handleLearners}
          handleInsightModal={handleInsights}
          handleCohortPreviewModal={handleCohortPreviewModal}
        />
      </HeaderContainer>

      {/* Cohort Create & Edit Modal Begins Here */}
      <CohortModal
        modalOpen={openModal}
        cohortData={cohortData}
        loading={loading}
        setCohortData={setCohortData}
        handleCohortSubmit={handleCohortSubmit}
        modalClose={handleModalClose}
        handleChange={handleChange}
        cohortDataError={cohortDataError}
        editorRef={editorRef}
        initialValue={initialValue}
        disable={disable}
        courseData={courseData}
        // data={editData}
      />
      {/* Add Members Modal Begins Here */}
      <AddMembersModal
        addMembersData={addMembersData}
        showMembersModal={showMembersModal}
        setShowMembersModal={setShowMembersModal}
      />
      <LearnersModal
        addLearnersData={addLearnersData}
        showLearnerModal={showLearnerModal}
        setShowLearnerModal={setShowLearnerModal}
      />
      <CohortPreviewModal
        cohortData={cohortData}
        courseData={courseData}
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
      />
      {insightModal && (
        <InsightModal
          cohortData={cohortData}
          insightOpen={insightModal}
          insightModalClose={() => setInsightModal(false)}
          insightHeader={"Cohort Insights"}
        />
      )}
    </Container>
  );
};

export default CohortManagement;
