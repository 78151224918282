import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ButtonMain from "../ButtonMain";
import { HeaderText, ParagraphText } from "../Typography";

import "./PageHeader.scss";

const PageHeader = ({ headerText, paragraphText }) => {
  let navigate = useNavigate();
  let location = useLocation();
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  return (
    <>
      <HeaderText className="pageHeaderName">{headerText}</HeaderText>
      <ParagraphText className="pageHeaderText">{paragraphText}</ParagraphText>
      {location.pathname !== "/featured-courses" && !isLoggedIn && (
        <ButtonMain
          variant="primary"
          style={{ marginTop: "1rem" }}
          onClick={() => navigate("/featured-courses")}
        >
          View Featured Courses
        </ButtonMain>
      )}
    </>
  );
};

export default PageHeader;
