import React, { useState } from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import PageHeader from "../PageHeader";
import "./CertificateConfig.scss";
import CertificatePage from "./CertificatePage";
import SignatoryPage from "./SignatoryPage";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { useRef } from "react";
import ButtonMain from "../ButtonMain";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMemo } from "react";
import { cloudfrontUrl } from "../../config/BaseUrl";
import { useSelector } from "react-redux";

const CertificateConfigLayout = () => {
  const [value, setValue] = useState("certificate");
  const [disable, setdisable] = useState(false);

  let location = useLocation();
  let toastRef = useRef();
  let navigate = useNavigate();

  let UserData = useSelector((state) => state.userLogin);
  let isLoggedIn = UserData?.data?.isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      if (UserData?.data?.user?.type !== "admin") {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);

  const [certificateData, setCertificateData] = useState({
    title: "",
    name: "",
    desc: "",
    text1: "",
    text2: "",
    signatoryName: "",
    signatoryDesignation: "",
    backgroundImage: null,
    signatureImage: null,
    logo: null,
  });
  const [signatoryData, setSignatoryData] = useState({
    title: "",
    name: "",
    desc: "",
    signatoryName: "",
    signatoryDesignation: "",
    signatureImage: null,
    logo: null,
  });
  const [previewCertificateImage, setPreviewCertificateImage] = useState({
    background: null,
    signature: null,
    logo: null,
  });
  const [previewSignatoryImage, setPreviewSignatoryImage] = useState({
    signature: null,
    logo: null,
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const stateData = useMemo(() => location.state, [location.state]);

  const handleSubmit = async () => {
    if (
      value === "certificate"
        ? certificateData.title.trimStart() === ""
        : signatoryData.title.trimStart() === ""
    ) {
      toastRef.current.showToastMessage("Please Enter Configuration Title", "error");
    } else if (value === "certificate" && certificateData.name.trimStart() === "") {
      toastRef.current.showToastMessage("Please Enter Institution Name", "error");
    } else if (value === "certificate" && certificateData.desc.trimStart() === "") {
      toastRef.current.showToastMessage("Please Enter Institution Description", "error");
    } else if (value === "certificate" && certificateData.text1.trimStart() === "") {
      toastRef.current.showToastMessage("Please Enter Certificate text 1", "error");
    } else if (value === "certificate" && certificateData.text2.trimStart() === "") {
      toastRef.current.showToastMessage("Please Enter Certificate text 2", "error");
    } else if (value === "certificate" && certificateData.signatoryName.trimStart() === "") {
      toastRef.current.showToastMessage("Please Enter Signatory Name", "error");
    } else if (value === "certificate" && certificateData.signatoryDesignation.trimStart() === "") {
      toastRef.current.showToastMessage("Please Enter Signatory Designation", "error");
    } else if (value === "certificate" && certificateData.backgroundImage === null) {
      toastRef.current.showToastMessage("Please Upload Institution Background Image", "error");
    } else if (value === "certificate" && certificateData.signatureImage === null) {
      toastRef.current.showToastMessage("Please Upload Institution Signature", "error");
    } else if (value === "certificate" && certificateData.logo === null) {
      toastRef.current.showToastMessage("Please Upload Institution Logo", "error");
    } else {
      setdisable(true);
      const formData = new FormData();
      formData.append("certificateType", value === "certificate" ? "certificate" : "signatory");
      formData.append("certificateId", stateData?._id);
      formData.append(
        "institution_name",
        value === "certificate" ? certificateData.name : signatoryData.name,
      );
      formData.append(
        "configuration_title",
        value === "certificate" ? certificateData.title : signatoryData.title,
      );
      formData.append(
        "institution_description",
        value === "certificate" ? certificateData.desc : signatoryData.desc,
      );
      formData.append("certificate_text_1", value === "certificate" ? certificateData.text1 : "");
      formData.append("certificate_text_2", value === "certificate" ? certificateData.text2 : "");
      formData.append(
        "signatory_name",
        value === "certificate" ? certificateData.signatoryName : signatoryData.signatoryName,
      );
      formData.append(
        "signatory_designation",
        value === "certificate"
          ? certificateData.signatoryDesignation
          : signatoryData.signatoryDesignation,
      );
      formData.append(
        "certificate_background_img",
        value === "certificate" ? certificateData.backgroundImage : "",
      );
      formData.append(
        "institute_signature_img",
        value === "certificate" ? certificateData.signatureImage : signatoryData.signatureImage,
      );
      formData.append(
        "institute_logo_img",
        value === "certificate" ? certificateData.logo : signatoryData.logo,
      );
      let header = "multipart/form-data";

      //create certificate config
      await POST(
        `${Constants.createCertificate}?type=${stateData?._id ? "" : "create"}`,
        formData,
        header,
      )
        .then((res) => {
          setdisable(false);
          toastRef.current.showToastMessage(`${res?.data?.message}`, "success");
          if (stateData?._id) {
            setCertificateData({
              title: certificateData?.title,
              name: certificateData?.name,
              desc: certificateData?.desc,
              text1: certificateData?.text1,
              text2: certificateData?.text2,
              signatoryName: certificateData?.signatoryName,
              signatoryDesignation: certificateData?.signatoryDesignation,
            });
            setSignatoryData({
              title: signatoryData?.title,
              name: signatoryData?.name,
              desc: signatoryData?.desc,
              signatoryName: certificateData?.signatoryName,
              signatoryDesignation: certificateData?.signatoryDesignation,
            });
            // navigate("/certificate-config/all", { replace: true });
          } else {
            setCertificateData({
              title: "",
              name: "",
              desc: "",
              text1: "",
              text2: "",
              signatoryName: "",
              signatoryDesignation: "",
              backgroundImage: null,
              signatureImage: null,
              logo: null,
            });
            setSignatoryData({
              title: "",
              name: "",
              desc: "",
              signatoryName: "",
              signatoryDesignation: "",
              backgroundImage: null,
              signatureImage: null,
              logo: null,
            });
            setPreviewCertificateImage({
              background: null,
              signature: null,
              logo: null,
            });
            setPreviewSignatoryImage({
              background: null,
              signature: null,
              logo: null,
            });
          }
        })
        .catch((err) => {
          setdisable(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (stateData?._id) {
      setCertificateData((pre) => ({
        ...pre,
        title: stateData.configuration_title,
        name: stateData.institution_name,
        desc: stateData.institution_description,
        text1: stateData.certificate_text_1,
        text2: stateData.certificate_text_2,
        signatoryName: stateData.signatory_name,
        signatoryDesignation: stateData.signatory_designation,
        backgroundImage: stateData.certificate_background_img,
        signatureImage: stateData.institute_signature_img,
        logo: stateData.institute_logo_img,
      }));
      setPreviewCertificateImage((pre) => ({
        ...pre,
        background: `${cloudfrontUrl}/${stateData.certificate_background_img}`,
        signature: `${cloudfrontUrl}/${stateData.institute_signature_img}`,
        logo: `${cloudfrontUrl}/${stateData.institute_logo_img}`,
      }));
      setSignatoryData((pre) => ({
        ...pre,
        title: stateData.configuration_title,
        name: stateData.institution_name,
        desc: stateData.institution_description,
        signatoryName: stateData.signatory_name,
        signatoryDesignation: stateData.signatory_designation,
        signatureImage: stateData.institute_signature_img,
        logo: stateData.institute_logo_img,
      }));
      setPreviewSignatoryImage((pre) => ({
        ...pre,
        signature: `${cloudfrontUrl}/${stateData.institute_signature_img}`,
        logo: `${cloudfrontUrl}/${stateData.institute_logo_img}`,
      }));
      setValue(stateData.isSignatory ? "signatory" : "certificate");
    }
  }, [stateData?._id]);

  if (!isLoggedIn) {
    return "";
  }
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <Grid className="aboutLayoutMain">
        <div className="headerArea">
          <PageHeader headerText="Certificate Configuration" paragraphText="" />
          <ButtonMain
            className={"view_cert_button"}
            onClick={() => navigate("/certificate-config/all")}
          >
            View All Certificates
          </ButtonMain>
        </div>
        <Grid container spacing={2} py={3}>
          <Grid
            item
            className="fullwidth responsiveGrid"
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography variant="h2" fontSize={"16px"} fontWeight={600}>
              {" "}
              Certificates Type :
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="certificate"
                  control={<Radio />}
                  label="Primary Certificate"
                  disabled={stateData?._id ? (stateData?.isSignatory ? true : false) : false}
                />
                <FormControlLabel
                  value="signatory"
                  control={<Radio />}
                  label="Additional Signatory"
                  disabled={stateData?._id ? (!stateData?.isSignatory ? true : false) : false}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {value === "certificate" ? (
            <CertificatePage
              certificateData={certificateData}
              setCertificateData={setCertificateData}
              handleSubmit={handleSubmit}
              disable={disable}
              toastRef={toastRef}
              previewCertificateImage={previewCertificateImage}
              setPreviewCertificateImage={setPreviewCertificateImage}
              stateData={stateData}
            />
          ) : (
            <SignatoryPage
              signatoryData={signatoryData}
              setSignatoryData={setSignatoryData}
              handleSubmit={handleSubmit}
              disable={disable}
              toastRef={toastRef}
              previewSignatoryImage={previewSignatoryImage}
              setPreviewSignatoryImage={setPreviewSignatoryImage}
              stateData={stateData}
            />
          )}
        </Grid>
      </Grid>
      <ToastNotify ref={toastRef} duration={4000} />
    </Container>
  );
};

export default CertificateConfigLayout;
