import React from "react";
import styled from "@emotion/styled";
import { PieChart, useDrawingArea } from "@mui/x-charts";

const DoughnutChart = ({ data, label, labelColor }) => {
  const StyledText = styled("text")(({ theme }) => ({
    fill: labelColor,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 28,
    fontWeight: "bold",
  }));
  const size = {
    width: 200,
    height: 200,
    margin: { right: 5 },
    legend: { hidden: true },
  };

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  return (
    <PieChart
      series={[{ data, innerRadius: 80, paddingAngle: 2 }]}
      {...size}
      sx={{ width: "140px !important", height: "150px !important" }}
    >
      <PieCenterLabel>{label}</PieCenterLabel>
    </PieChart>
  );
};

export default DoughnutChart;
