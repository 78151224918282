import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import AdvanceScoreCard from "./AdvanceScoreCard";
import EnrollmentInfoThin from "./EnrollmentInfoThin";
import ScoreBubble from "./ScoreBubble";

export default function AdvanceTable({
  allScores,
  allModules,
  assessmentDashboard,
  dateOfEnrollment,
  numberOfDaysSinceEnrollment,
}) {
  const RenderTableRows = ({ data, domains }) => {
    return (
      <>
        {data?.map((item, i) => (
          <React.Fragment key={i}>
            <TableRow sx={{ background: item.container === null ? "#ECF6FF" : "white" }}>
              <TableCell align={"left"}>
                <div style={{ fontSize: "16px" }}>
                  {item.container === null ? (
                    <span style={{ fontWeight: "bold", color: "#002B51" }}>{item.title}</span>
                  ) : (
                    <span style={{ marginLeft: "20px", textAlign: "center" }}>{item.title}</span>
                  )}
                </div>
              </TableCell>
              {domains?.map((domain, j) =>
                allScores?.allModules[item._id] &&
                allScores?.allModules[item._id][domain._id]?.maxMarks ? (
                  <TableCell key={j} align="center">
                    {allScores?.allModules[item._id] && (
                      <Box className="moduleScoreMain">
                        <ScoreBubble
                          obtainedMarks={allScores?.allModules[item._id][domain._id]?.obtainedMarks}
                          maxMarks={allScores?.allModules[item._id][domain._id]?.maxMarks}
                        />
                      </Box>
                    )}
                  </TableCell>
                ) : (
                  <TableCell key={j} align="center">
                    <Box>-</Box>
                  </TableCell>
                ),
              )}
            </TableRow>
            {item.children && <RenderTableRows data={item.children} domains={domains} />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box className="advanceMain">
      <EnrollmentInfoThin
        enrollmentDate={dateOfEnrollment}
        daysFromEnrollment={numberOfDaysSinceEnrollment}
      />
      <Box className="advanceScoreCardWrapper">
        {allScores?.advance?.finalEvaluation?.map((domain, i) => (
          <AdvanceScoreCard key={i} domain={domain} />
        ))}
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: "#365082", borderBottom: "2px solid #94a3b8" }}>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "bold", fontSize: "16px", color: "white" }}>
                {" "}
                <Box sx={{ fontWeight: "bold", fontSize: "16px", color: "white" }}>
                  All Modules
                </Box>{" "}
              </TableCell>
              {assessmentDashboard.assessmentDomain?.map((header, i) => (
                <TableCell align="center" key={i}>
                  <Box sx={{ fontWeight: "bold", fontSize: "16px", color: "white" }}>
                    {header.title}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allModules && (
              <RenderTableRows data={allModules} domains={assessmentDashboard.assessmentDomain} />
            )}
            <TableRow sx={{ fontWeight: "bold" }}>
              <TableCell sx={{ fontWeight: "bold", color: "#002B51" }}>Domain Total:</TableCell>
              {allScores.advance.finalEvaluation.map((domain, i) =>
                domain.maxMarks > 0 ? (
                  <TableCell key={i}>
                    <Box className="moduleScoreMain">
                      <ScoreBubble
                        obtainedMarks={domain.obtainedMarks}
                        maxMarks={domain.maxMarks}
                      />
                    </Box>
                  </TableCell>
                ) : (
                  <TableCell key={i} align="center">
                    -
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
