import React, { useEffect, useState, Fragment } from "react";
import "./ChatView.scss";
import { useQueryParams } from "../../utils";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SendIcon from "@mui/icons-material/Send";
import swal from "sweetalert";

import Comment from "./Comment";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import MyComment from "./MyComments/MyComments";
import { useLocation } from "react-router-dom";

const ChatView = ({ topicId }) => {
  const [comments, setComments] = useState([]);
  const [commentText, setcommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [noComments, setnoComments] = useState(false);
  const [noLastComment, setNoLastComment] = useState(false);
  const [commentId, setCommentId] = useState("");
  const [tabValue, setTabValue] = useState("all");
  const [scrollTo, setScrollTo] = useState("top");

  const { coid } = useQueryParams();
  const location = useLocation();

  let tabs = [
    {
      value: "all",
      label: "All Comments",
    },
    {
      value: "my",
      label: "My Comments",
    },
  ];
  useEffect(() => {
    commentId &&
      getPrevAndNextComments()
        .then((res) => {
          if (res.comment) {
            setComments([]);
            setnoComments(false);
            setComments(res.comment);
          }
        })
        .catch((err) => console.log(err));
  }, [commentId]);

  useEffect(() => {
    if (tabValue !== "my") {
      setComments([]);
      getAllComments(1)
        .then((res) => {
          res.comments && setComments((prev) => [...res.comments.reverse(), ...prev]);
          if (res.comments.length < 5) {
            setnoComments(true);
          }
        })
        .catch((err) => console.log(err));
      setPage(2);
      setnoComments(false);
    }
  }, [topicId, tabValue]);

  useEffect(() => {
    if (location.pathname.split("/")[1] !== "discussion-forum") {
      scrollTo === "top" ? scrollToTop() : scrollToBottom();
    }
  }, [scrollTo, comments]);

  const getAllComments = async (page) => {
    try {
      //get all comment
      const response = await GET(`${Constants.comment}/getComment/${topicId}?page=${page}&limit=5`);
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };
  const getPrevAndNextComments = async () => {
    try {
      //get prev and next comments
      const response = await GET(
        `${Constants.comment}/getPrevAndNextComments/${commentId}?topicId=${topicId}`,
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  const getOldComments = async () => {
    try {
      //get old comment
      const response = await GET(
        `${Constants.comment}/getOldComments/${comments[0]?._id}?topicId=${topicId}`,
      );
      return response.data.newComments;
    } catch (error) {
      return error?.response?.data;
    }
  };
  const getNewCommentsAfter = async () => {
    try {
      //get comment after specific comment id
      const response = await GET(
        `${Constants.comment}/getNewCommentsAfter/${
          comments[comments.length - 1]?._id
        }?topicId=${topicId}`,
      );
      return response.data.newComments;
    } catch (error) {
      return error?.response?.data;
    }
  };

  const handleReplySubmit = async (rootId, parentId, replyText) => {
    try {
      let payload = {
        comment_text: replyText,
        topic_id: topicId,
        parent_comment_id: parentId,
        root_comment_id: rootId,
        cohort_id: coid,
        type: "reply",
      };
      //create reply of comment
      const response = await POST(`${Constants.comment}/createComment`, payload);
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };
  const getRepliesByParentId = async (id) => {
    try {
      setLoading(true);
      //get replies by parent id
      const response = await GET(`${Constants.comment}/getReplies/${id}?page=${1}&limit=${10}`);
      setLoading(false);
      return response?.data?.replies;
    } catch (error) {
      setLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      //delete comment
      const response = await POST(`${Constants.comment}/deleteComment/${id}`);
      return response;
    } catch (error) {
      swal("Error", "An error occurred while deleting the topic.", "error");
    }
  };
  const handleEdit = async (id, commentText) => {
    try {
      //edit comment
      const response = await POST(`${Constants.comment}/updateComment/${id}`, {
        comment_text: commentText,
      });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  const createComment = async () => {
    try {
      let payload = {
        comment_text: commentText,
        topic_id: topicId,
        cohort_id: coid,
        type: "comment",
      };
      //create comment
      const response = await POST(`${Constants.comment}/createComment`, payload);
      setComments((comments) => [...comments, response.data]);
      setcommentText("");
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnterPress = async (e) => {
    if (e.key === "Enter" && commentText.trimStart() !== "") {
      e.preventDefault();
      await createComment();
    }
  };
  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const scrollToBottom = () => {
    document.getElementById("scroll-target").scrollIntoView({
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    document.getElementById("message__container").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      <div className="chatview__container">
        <Container sx={{ overflowY: "scroll", height: "70vh" }} className="child__container">
          <div className="tabs">
            <Box className="tabsMain">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="primary tabs example"
              >
                {tabs.map((item, i) => (
                  <Tab
                    className="specific-tab"
                    key={i}
                    value={item.value}
                    label={item.label}
                    onClick={() => setCommentId("")}
                  />
                ))}
              </Tabs>
            </Box>
          </div>
          <div className="message__container" id="message__container">
            {tabValue === "all" ? (
              <>
                <div className="load-more-container">
                  <div className="load-more-button">
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={noComments}
                      startIcon={noComments ? "" : <KeyboardArrowUpIcon />}
                      sx={{
                        textTransform: "none",
                        border: "none", // This removes the border
                        "&:hover": {
                          border: "none", // Remove border on hover as well
                        },
                      }}
                      onClick={async () => {
                        const comments = await getOldComments(page);
                        !comments.length && setnoComments(true);
                        if (comments) {
                          // Reverse the order of the comments before updating the state
                          setComments((prev) => [...comments.reverse(), ...prev]);
                          setScrollTo("top");
                        }
                      }}
                    >
                      {noComments ? "No older comments" : "Load older comments"}
                    </Button>
                  </div>
                </div>
                <div className="sent-messages">
                  {comments?.map((comment) => (
                    <Fragment key={comment?._id}>
                      <Comment
                        comment={comment}
                        setComments={setComments}
                        getRepliesByParentId={getRepliesByParentId}
                        type={"root"}
                        loading={loading}
                        handleReplySubmit={handleReplySubmit}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        commentId={commentId}
                      />
                    </Fragment>
                  ))}
                </div>
                {commentId && (
                  <div className="load-more-container">
                    <div className="load-more-button">
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={noLastComment}
                        startIcon={noLastComment ? "" : <KeyboardArrowDownOutlinedIcon />}
                        sx={{
                          textTransform: "none",
                          border: "none",
                          "&:hover": {
                            border: "none",
                          },
                        }}
                        onClick={async () => {
                          const comments = await getNewCommentsAfter(page);
                          !comments.length && setNoLastComment(true);
                          if (comments) {
                            // Reverse the order of the comments before updating the state
                            setComments((prev) => [...prev, ...comments]);
                            setScrollTo("bottom");
                          }
                        }}
                      >
                        {noLastComment ? "No new comments" : "Load new comments"}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="chat__input">
                  <TextField
                    autoComplete="false"
                    label="Write a comment..."
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="scroll-target"
                    // inputRef={textareaRef}
                    value={commentText}
                    onChange={(e) => setcommentText(e.target.value.trimStart())}
                    onKeyDown={handleEnterPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled={!commentText}>
                            <SendIcon
                              sx={{ color: `${commentText ? "#1976D2" : "#d0cccc"}` }}
                              onClick={createComment}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </>
            ) : (
              <MyComment
                topicId={topicId}
                getRepliesByParentId={getRepliesByParentId}
                loading={loading}
                setTabValue={setTabValue}
                setCommentId={setCommentId}
              />
            )}
          </div>
          <span id="scroll-target"></span>
        </Container>
      </div>
    </Fragment>
  );
};

export default ChatView;
