import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ParagraphText } from "../../Typography";
import FMModal from "../CourseModals/FMModal";

import "./terms.scss";

const TermsModal = ({ openTerms, modalClose }) => {
  let navigate = useNavigate();
  const collectedData = [
    "We collect and store the information you provide when you create an account with us, including your unique user ID, (optional) email address and (encrypted) password.",
    "We may also collect information about your use of our platform, such as the courses you have taken, your progress and the content you have created.",
    "We use this information to provide and improve our services, to communicate with you and to personalize your experience on our platform.",
    "We take appropriate technical and organizational measures to protect your data and to ensure that it is processed in accordance with the General Data Protection Regulation (GDPR).",
    "You have the right to request access to, correction or deletion of your personal data at any time.",
    "You can request to stop receiving communications from us at any time by updating your account settings or by contacting us.",
    "Our platform may contain links to third-party websites. We are not responsible for the privacy policies of these websites and we encourage you to review their terms and policies before using them.",
  ];

  const termsOfUse = [
    "By using our platform, you agree to comply with all applicable laws and regulations and our terms of use.",
    "You may use our platform for educational and non-commercial purposes only.",
    "You are responsible for keeping your account information and passwords confidential.",
    "You agree not to use our platform for any illegal or unauthorized purposes.",
  ];
  return (
    <FMModal
      modalOpen={openTerms}
      modalClose={modalClose}
      modalHeader="Terms and Data Policy Statement for FramerSpace"
      backgroundImage={{ backgroundColor: "#F2F4F8" }}
      className="termsModalMain"
    >
      <Box sx={{ maxHeight: "500px" }}>
        <Typography className="termsText termsTextMargin">
          Welcome to FramerSpace, an online learning platform where you can learn from and create
          courses. This statement outlines our terms and policies for using our platform and how we
          handle your data.
        </Typography>
        <ParagraphText className="termsText">Terms of Use:</ParagraphText>
        <ul>
          {termsOfUse.map((data, i) => (
            <li className="termsText" key={i}>
              {data}
            </li>
          ))}
        </ul>
        <ParagraphText className="termsText">Data Policy:</ParagraphText>
        <ol>
          {collectedData.map((data, i) => (
            <li className="termsText" key={i}>
              {data}
            </li>
          ))}
        </ol>
        <ParagraphText className="termsText termsTextMargin">
          By using our platform, you agree to our terms and data policy. We may update this
          statement from time to time, so please check back periodically. If you have any questions
          or concerns, please{" "}
          <span className="terms-contact-us" onClick={() => navigate("/support")}>
            contact us
          </span>
          .
        </ParagraphText>
      </Box>
    </FMModal>
  );
};

export default TermsModal;
