import React from 'react'
import ManagedCourseLayout from '../../components/ManagedCourses'
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer'

const ManagedCourses = () => {
  return (
    <HeaderContainer>
        <ManagedCourseLayout/>
    </HeaderContainer>
  )
}

export default ManagedCourses