import React from "react";
import Nothinghere from "../../assets/NothingHereYet.gif";
import "./NothingHere.scss";

const NothingHere = () => {
  return (
    <div className="nothingHereYet">
      <img src={Nothinghere} alt="Nothing here" width="60%" className="image" />
    </div>
  );
};

export default NothingHere;
