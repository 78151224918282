import React from "react";
import { Card, CardContent, Button, Typography, Box } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const SMFormCard = ({ form, setEditModal, handleChangeStatusOfForm, handleDelete, setOpen }) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          boxShadow: 3,
          mb: 1,
          mt: 1,
          cursor: "pointer",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "0.5rem",
            textAlign: "left",
            padding: "1rem",
          }}
          style={{ padding: "10px" }}
        >
          <Box>
            <Typography variant="h6" sx={{ lineHeight: "20px" }}>
              {form?.survey_id}
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: "20px" }}>
              {form?.title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{ textTransform: "none", minWidth: 100 }}
              startIcon={<EditOutlinedIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setEditModal((prev) => ({
                  ...prev,
                  open: true,
                  id: form?.survey_id,
                  title: form?.title,
                  status: form?.status,
                }));
              }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "none", minWidth: 100 }}
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setOpen((prev) => ({
                  ...prev,
                  surveyId: form?.survey_id,
                }));
                handleDelete();
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              sx={{ textTransform: "none", minWidth: 100 }}
              onClick={() => handleChangeStatusOfForm(form?.survey_id, form?.status)}
            >
              {form.status === "active" ? "Tracking Disable" : "Tracking Enable"}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(SMFormCard);
