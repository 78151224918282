import React from "react";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import { Container } from "@mui/material";
import SearchData from "./SearchData";

const SearchList = () => {
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <SearchData />
      </HeaderContainer>
    </Container>
  );
};

export default SearchList;
