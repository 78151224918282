import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import { Box } from "@mui/system";

const CustomAccordion = ({ first, second, third, fourth }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }}>
            Advance options:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", gap: "2rem", marginBottom: "0.7rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Scoring Plan
              <Tooltip
                title="Attempt: If a learner attempts this question, they will get the assigned credit.
  Score: If a learner answers this question correctly, they will get the assigned credit.
  None: Learner will not get any credit for this question."
                placement="right-start"
                arrow
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
            {first}
          </Box>
          <Box sx={{ display: "flex", gap: "1.5rem", marginBottom: "0.7rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Credits
              <Tooltip
                title="Select how much credit a learner can get from this question correctly."
                placement="right-start"
                arrow
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
            {second}
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              marginLeft: "-10px",
            }}
          >
            {third}
            <Tooltip
              title="Select if you want this question block to be highlighted immediately after the learner submits an incorrect response."
              placement="right-start"
              arrow
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Box> */}
          {fourth && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginLeft: "-10px",
              }}
            >
              {fourth}
              <Tooltip
                title="Select if you want that the learner should mandatorily respond to this question."
                placement="right-start"
                arrow
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
