import { Menu } from "@mui/material";
import React from "react";

const Dropdown = ({ className, open, anchorEl, handleClose, children }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      className={className}
      PaperProps={{
        elevation: 0,
        typography: {
          fontFamily: `'IBM Plex SansdropdownMain', sans-serif`,
          fontSize: 16,
          marginBottom: 5,
          maxWidth: 30,
        },
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          minWidth: "12rem",
          maxWidth: "20rem",
          minHeight: "10rem",
          fontWeight: "bold",
          fontSize: "14px",
          // p: "1rem",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </Menu>
  );
};

export default Dropdown;
