import React from "react";
import NoCoursesfound from "../../assets/No_results_search.svg";

const NoCoursesFound = () => {
  return (
    <div className="nothingHereYet">
      <img src={NoCoursesfound} alt="Nothing here" width="80%" className="image" />
    </div>
  );
};

export default NoCoursesFound;
