import React from "react";
import { FormControl, Grid } from "@mui/material";

import FMModal from "./CourseModals/FMModal";
import ButtonMain from "../ButtonMain";
import { ParagraphText } from "../Typography";

const ConfirmDialog = ({
  dialogOpen,
  dialogClose,
  dialogMessage,
  onConfirm,
  loading
}) => {

  return (
    <FMModal
      modalOpen={dialogOpen}
      modalClose={() => {
        dialogClose();
      }}
      backgroundImage={{ backgroundImage: 'linear-gradient(#a1c4fd, #c2e9fb)' }}
      modalHeader='Note'
      className='copyCourseContainer'
    >
      <FormControl className=''>
        <ParagraphText>{dialogMessage}</ParagraphText>
        <Grid container spacing={2} style={{marginTop: '1rem'}}>
          <Grid item>
            <ButtonMain
              className='copyButton'
              onClick={()=> onConfirm()}
              disabled={loading}
              variant='primary-blue'
            >
              Confirm
            </ButtonMain>
          </Grid>
          <Grid item>
            <ButtonMain
              className='copyButton'
              onClick={()=> dialogClose()}
              disabled={loading}
              variant='secondary'
            >
              Cancel
            </ButtonMain>
          </Grid>
        </Grid>
      </FormControl>
    </FMModal>
  );
};

export default ConfirmDialog;
