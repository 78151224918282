import {
  Alert,
  AlertTitle,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { GET, POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../PageHeader";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import ContentJSONTable from "./ContentJSONTable";
import { useSelector } from "react-redux";
import CopyCourseModal from "../../ModalMain/CopyCourseModal";

const languages = [
  { abbr: "as", name: "Assamese" },
  { abbr: "bn", name: "Bengali" },
  { abbr: "gu", name: "Gujarati" },
  { abbr: "hi", name: "Hindi" },
  { abbr: "ks", name: "Kashmiri" },
  { abbr: "kn", name: "Kannada" },
  { abbr: "ml", name: "Malayalam" },
  { abbr: "mr", name: "Marathi" },
  { abbr: "or", name: "Oriya" },
  { abbr: "pa", name: "Punjabi" },
  { abbr: "en", name: "English" },
  { abbr: "sa", name: "Sanskrit" },
  { abbr: "ta", name: "Tamil" },
  { abbr: "te", name: "Telugu" },
  { abbr: "ur", name: "Urdu" },
  { abbr: "sd", name: "Sindhi" },
  { abbr: "mni", name: "Manipuri" },
  { abbr: "ne", name: "Nepali" },
  { abbr: "brx", name: "Bodo" },
  { abbr: "doi", name: "Dogri" },
  { abbr: "mai", name: "Maithili" },
  { abbr: "sat", name: "Santhali" },
  { abbr: "kok", name: "Konkani" },
  { abbr: "af", name: "Afrikaans" },
  { abbr: "sq", name: "Albanian" },
  { abbr: "am", name: "Amharic" },
  { abbr: "ar", name: "Arabic" },
  { abbr: "hy", name: "Armenian" },
  { abbr: "az", name: "Azerbaijani" },
  { abbr: "eu", name: "Basque" },
  { abbr: "be", name: "Belarusian" },
  { abbr: "bs", name: "Bosnian" },
  { abbr: "bg", name: "Bulgarian" },
  { abbr: "ca", name: "Catalan" },
  { abbr: "zh", name: "Chinese (Simplified)" },
  { abbr: "zh-TW", name: "Chinese (Traditional)" },
  { abbr: "hr", name: "Croatian" },
  { abbr: "cs", name: "Czech" },
  { abbr: "da", name: "Danish" },
  { abbr: "nl", name: "Dutch" },
  { abbr: "et", name: "Estonian" },
  { abbr: "fi", name: "Finnish" },
  { abbr: "fr", name: "French" },
  { abbr: "ka", name: "Georgian" },
  { abbr: "de", name: "German" },
  { abbr: "el", name: "Greek" },
  { abbr: "ht", name: "Haitian Creole" },
  { abbr: "ha", name: "Hausa" },
  { abbr: "he", name: "Hebrew" },
  { abbr: "hu", name: "Hungarian" },
  { abbr: "is", name: "Icelandic" },
  { abbr: "id", name: "Indonesian" },
  { abbr: "it", name: "Italian" },
  { abbr: "ja", name: "Japanese" },
  { abbr: "ko", name: "Korean" },
  { abbr: "lv", name: "Latvian" },
  { abbr: "lt", name: "Lithuanian" },
  { abbr: "ms", name: "Malay" },
  { abbr: "mt", name: "Maltese" },
  { abbr: "no", name: "Norwegian" },
  { abbr: "fa", name: "Persian" },
  { abbr: "pl", name: "Polish" },
  { abbr: "pt", name: "Portuguese" },
  { abbr: "pa", name: "Punjabi" },
  { abbr: "ro", name: "Romanian" },
  { abbr: "ru", name: "Russian" },
  { abbr: "sm", name: "Samoan" },
  { abbr: "sr", name: "Serbian" },
  { abbr: "sk", name: "Slovak" },
  { abbr: "sl", name: "Slovenian" },
  { abbr: "es", name: "Spanish" },
  { abbr: "sv", name: "Swedish" },
  { abbr: "th", name: "Thai" },
  { abbr: "tr", name: "Turkish" },
  { abbr: "uk", name: "Ukrainian" },
  { abbr: "ur", name: "Urdu" },
  { abbr: "vi", name: "Vietnamese" },
];

const ExportCourseContent = () => {
  const [courseDetails, setCourseDetails] = useState({});
  const [copyModal, setCopyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [defaultJSONData, setDefaultJSONData] = useState([]);
  const [translateData, setTranslateData] = useState({});
  const { courseID } = useParams();
  const navigate = useNavigate();
  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};

  const toastRef = useRef();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/404");
    } else {
      courseID && isLoggedIn && getCourseDetails();
    }
  }, [courseID]);

  const getCourseDetails = async () => {
    try {
      setLoading(true);
      //get course details
      const response = await GET(`${Constants.course}/moderator/${courseID}`);

      setCourseDetails(response?.data?.course);
      if (response?.data?.course?.courseJson?.jsonLink) {
        const jsonData = {
          lang: "default",
          link: response?.data?.course?.courseJson?.jsonLink,
          genearatedTime: response?.data?.course?.courseJson?.generatedTime,
        };

        setDefaultJSONData([jsonData]);
      }
      setLoading(false);
    } catch (err) {
      // console.log({ err, courseID });
      navigate("/404");
    }
  };

  const exportCourseContent = async (e) => {
    e.stopPropagation();
    try {
      setBackdropOpen(true);
      //export course content as JSON
      const response = await POST(`${Constants.courseManage}/exportJson/${courseID}`);
      setCourseDetails((prev) => ({ ...prev, courseJson: response.data.courseJson }));
      if (response?.data?.courseJson?.jsonLink) {
        const jsonData = {
          lang: "default",
          link: response?.data?.courseJson?.jsonLink,
          genearatedTime: response?.data?.courseJson?.generatedTime,
        };
        setDefaultJSONData([jsonData]);
      }
      setBackdropOpen(false);
      toastRef.current.showToastMessage(response.data.message, "success");
    } catch (error) {
      setBackdropOpen(false);
      toastRef.current.showToastMessage(error.data.message, "error");
    }
  };

  const handleSubmiTranslation = async (e) => {
    e.stopPropagation();

    if (!translateData.sl) {
      return toastRef.current.showToastMessage("Please select source language.", "error");
    } else if (!translateData.tl) {
      return toastRef.current.showToastMessage("Please select target language.", "error");
    } else if (translateData.sl === translateData.tl)
      return toastRef.current.showToastMessage(
        "Source and target languages cannot be same.",
        "error",
      );

    try {
      setBackdropOpen(true);

      const payload = translateData;
      //translate course json
      const response = await POST(
        `${Constants.courseManage}/translateCourseJson/${courseID}`,
        payload,
      );
      setCourseDetails((prev) => ({ ...prev, courseJson: response.data.courseJson }));
      toastRef.current.showToastMessage(response.data.message, "success");
      setBackdropOpen(false);
    } catch (error) {
      setBackdropOpen(false);

      toastRef.current.showToastMessage(error.data.message, "error");
    }
  };

  if (!isLoggedIn) {
    return "";
  }
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <>
          {loading ? (
            <>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </>
          ) : (
            <>
              <Typography variant="h4" fontWeight={500} mb={3} mt={3}>
                Export Content Dashboard
              </Typography>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <Box>
                  <PageHeader
                    headerText={`${courseDetails?.title}`}
                    paragraphText={`Course Language: ${courseDetails?.courseLanguage?.name}`}
                  />
                </Box>
                <Box display={"flex"} alignItems={"center"} mt={{ xs: 2, sm: 0 }}>
                  <Button
                    variant="contained"
                    sx={{ mr: { xs: 1, sm: 2 } }}
                    onClick={exportCourseContent}
                  >
                    Export as JSON
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setCopyModal(true);
                    }}
                  >
                    Create Copy Course
                  </Button>
                </Box>
              </Box>
              {defaultJSONData?.length > 0 && (
                <ContentJSONTable title={"Default Course JSON"} jsonList={defaultJSONData} />
              )}

              <Box
                p={4}
                my={2}
                sx={{
                  width: "100%",
                  bgcolor: "rgb(255, 255, 255)",
                  boxShadow: "0px 8px 24px 0px rgba(140, 149, 159, 0.2);",
                }}
              >
                {!courseDetails?.courseJson?.jsonLink ? (
                  <Typography variant="h6" fontWeight={500}>
                    If you want to get translated json of the course to another language. Export it
                    to json first.
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h6" fontWeight={500}>
                      Translate Course To Another Language
                    </Typography>

                    <Grid container my={2} spacing={3}>
                      <Grid item xs={12} md={5}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          fullWidth
                          options={languages}
                          getOptionLabel={(option) => `${option.name} (${option.abbr})`}
                          value={translateData.sl}
                          onChange={(e, newValue) => {
                            console.log({ newValue });
                            setTranslateData((prev) => ({ ...prev, sl: newValue.abbr }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="translate_from_language"
                              type="text"
                              label="Translate From Language"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          fullWidth
                          options={languages}
                          getOptionLabel={(option) => `${option.name} (${option.abbr})`}
                          value={translateData.tl}
                          onChange={(e, newValue) => {
                            console.log({ newValue });
                            setTranslateData((prev) => ({ ...prev, tl: newValue.abbr }));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="translate_from_language"
                              type="text"
                              label="Translate Target Language"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          variant="contained"
                          onClick={handleSubmiTranslation}
                          fullWidth
                          size="large"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    <Box>
                      <Alert severity="warning">
                        <AlertTitle>Important Note:</AlertTitle>
                        Translating course from one language to another may take a while. We
                        appreciate your patience.
                      </Alert>
                    </Box>
                  </>
                )}
              </Box>
              {courseDetails?.courseJson?.translatedJsonLinks?.length > 0 && (
                <ContentJSONTable
                  title={"Translated Course JSONs"}
                  jsonList={courseDetails.courseJson.translatedJsonLinks}
                />
              )}
              <CopyCourseModal
                modalOpen={copyModal}
                modalClose={() => setCopyModal(false)}
                // setDataEdited={setDataEdited}
                courseId={courseID}
              />
              <ToastNotify ref={toastRef} duration={4000} />
            </>
          )}
        </>
      </HeaderContainer>
    </Container>
  );
};

export default ExportCourseContent;
