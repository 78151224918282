import React from "react";
import { useParams } from "react-router-dom";
import ChatView from "../ChatView";
import { useEffect } from "react";
import { useState } from "react";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { Box, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import "./DiscussionView.scss";

const DiscussionView = () => {
  const [topicDetails, settopicDetails] = useState({});

  const { topicId } = useParams();
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  useEffect(() => {
    isLoggedIn && getTopicDetails();
  }, [topicId]);

  const getTopicDetails = async () => {
    try {
      //get topic details by topic id
      const response = await GET(`${Constants.discussionTopic}/${topicId}`);
      settopicDetails(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <Box className="discussion_view">
          <Container>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {topicDetails?.title}
            </Typography>
            <Typography variant="body2">{topicDetails?.description}</Typography>
          </Container>
          <ChatView topicId={topicId} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "80vh",
          }}
        >
          <Typography variant="h5" textAlign={"center"}>
            You are not authorized to see the page.Please Log in to view discussions.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default DiscussionView;
