import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

const CardComponent = ({ image, title, description, color, textColor }) => {
  return (
    <Paper
      elevation={2}
      sx={{
        padding: "20px",
        backgroundColor: color,
        height: "100%",
        borderRadius: "15px",
        boxShadow: "0 0 6px 2px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid container spacing={2} display={"flex"} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <img src={image} alt="" style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Typography variant="h5" fontWeight={"bold"} color={textColor}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      <div dangerouslySetInnerHTML={{ __html: description }} style={{ fontSize: "18px" }}></div>
    </Paper>
  );
};

export default CardComponent;
