import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import "./FSComms.scss";
import { GET, POST } from "../../config/Api";
import { Constants } from "../../config/Constants";

export default function FSCommsModal({ fsCommsFor }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fsCommsData, setFSCommsData] = useState({});
  const [fsCommsUserActivityData, setFSCommsUserActivityData] = useState({});
  const fsCommsDisplayed = localStorage.getItem("fsCommsDisplayed");
  useEffect(() => {
    (async () => {
      //get Latest Active FSComms
      GET(`${Constants.fsComms}/getLatestActiveFSComms`)
        .then((res) => {
          //get fsComms User Activity
          GET(`${Constants.fsCommsUserActivity}/${res.data.fsComms[0]._id}`)
            .then((resp) => {
              if (resp?.data?.fsCommsUserActivity) {
                setFSCommsUserActivityData(resp?.data?.fsCommsUserActivity);
                if (!resp?.data?.fsCommsUserActivity?.dont_show_again) {
                  if (
                    resp?.data?.fsCommsUserActivity?.displayed_count <
                    res?.data?.fsComms[0]?.modal_freq
                  )
                    setFSCommsData(res.data.fsComms[0]);
                  setLoading(false);
                  setOpen(true);
                }
              } else {
                setFSCommsData(res.data.fsComms[0]);
                setLoading(false);
                setOpen(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => console.log(err));
    })();
  }, []);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    const body = {
      fsCommsUserActivityData,
      fsCommsData,
      user_actions: {
        btn_text: "Close",
        action_type: e.type,
      },
    };
    //fsComms User Activity
    POST(`${Constants.fsCommsUserActivity}/${fsCommsData._id}`, body)
      .then((res) => {
        console.log(res?.data);
        setOpen(false);
        localStorage.setItem("fsCommsDisplayed", true);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };

  const handleCTA = (e) => {
    const body = {
      fsCommsUserActivityData,
      fsCommsData,
      user_actions: {
        btn_text: fsCommsData?.cta_text,
        action_type: e.type,
      },
    };
    //handle CTA
    POST(`${Constants.fsCommsUserActivity}/${fsCommsData._id}`, body)
      .then((res) => {
        console.log(res?.data);
        setOpen(false);
        localStorage.setItem("fsCommsDisplayed", true);
        window.open(`${fsCommsData.cta_link}`, "_blank");
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };

  return (
    <>
      {loading
        ? ""
        : !fsCommsDisplayed &&
          fsCommsFor.includes(fsCommsData?.fsCommsFor) && (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              disableBackdropClick
              classes={{
                paper: "fsComms_paper",
              }}
            >
              <Box
                pt={3}
                display={"flex"}
                position={"relative"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  bgcolor={"#5194bb"}
                  position={"absolute"}
                  p={2}
                  top={"-30px"}
                  left={"-30px"}
                  borderRadius={"50%"}
                  height={"70px"}
                  width={"70px"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    // className="w-6 h-6"
                    style={{ height: "40px", width: "40px", color: "white" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                    />
                  </svg>
                </Box>
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{ pb: 0, color: "black" }}
                  fontSize={"32px"}
                >
                  {fsCommsData?.title}
                </DialogTitle>
                {/* <IconButton onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton> */}
              </Box>
              <DialogContent sx={{ pt: 0 }}>
                <DialogContentText id="alert-dialog-description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${fsCommsData?.description}`,
                    }}
                  ></div>
                </DialogContentText>
                {fsCommsData.dont_show_again_opt && (
                  <Box display={"flex"}>
                    <input
                      type="checkbox"
                      id="dont_show_again"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      // label="Add Call to Action"
                      value={fsCommsUserActivityData?.dont_show_again}
                      checked={fsCommsUserActivityData?.dont_show_again}
                      onChange={() =>
                        setFSCommsUserActivityData({
                          ...fsCommsUserActivityData,
                          dont_show_again: !fsCommsUserActivityData?.dont_show_again,
                        })
                      }
                    />
                    <label htmlFor="dont_show_again"> Don't show again</label>
                  </Box>
                )}
                <Box my={2}>
                  {fsCommsData?.is_cta && (
                    // <a href={`${fsCommsData?.cta_link}`} target="_blank">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#5194bb",
                        my: "10px",
                        ":hover": { backgroundColor: "#5194bb" },
                      }}
                      fullWidth
                      size="large"
                      onClick={handleCTA}
                    >
                      {fsCommsData?.cta_text}
                    </Button>
                    // </a>
                  )}
                  <Button
                    variant="text"
                    sx={{
                      mt: "10px",
                      backgroundColor: "#f1f5f9",
                      color: "#94a3b8",
                      ":hover": { backgroundColor: "#e2e8f0" },
                    }}
                    fullWidth
                    size="large"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          )}
    </>
  );
}
