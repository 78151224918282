import React, { useCallback, useEffect, useState, useRef } from "react";
import { Divider, Grid, Typography, IconButton } from "@mui/material";

import "./AssessmentDashboardModal.scss";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { useSelector } from "react-redux";
import { InputBox } from "../InputBox/InputBox";
import ButtonMain from "../ButtonMain";
import AssessmentRule from "./AssessmentRule";
import AdvancedAssessment from "./AdvancedAssessment";
import CompletenessConfig from "./CompletenessConfig";
import AssessmentLogic from "./AssessmentLogic";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { eliminateExtraSpaces } from "../Validators";
import FullScreenModal from "../courses/CourseModals/FullScreenModal";
import { Container } from "@mui/material";

const ALPHABETS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const AssessmentDashboardModal = ({ showAssessmentModal, setShowAssessmentModal, course_id }) => {
  let UserData = useSelector((state) => state.userLogin.data);
  const toastRef = useRef();

  const [assessmentLogic, setAssessmentLogic] = useState("");
  const [assessmentLogicVerified, setAssessmentLogicVerified] = useState(true);
  const [allData, setAllData] = useState("");
  const [assessmentDomainData, setAssessmentDomainData] = useState([]);

  const getAssessment = useCallback(() => {
    let payload = {
      user_id: UserData.user.id,
    }; //get assessments
    POST(`${Constants.assessment}/getAssessment/${course_id}`, payload)
      .then((res) => {
        setAllData(res.data.assessment);
        setAssessmentDomainData(res.data.assessment.assessmentDomain);
        setAssessmentLogic(res.data.assessment.assessmentLogic);
      })
      .catch((err) => console.log(err));
  }, [UserData.user.id, course_id]);

  useEffect(() => {
    getAssessment();
  }, [getAssessment]);

  const handleAssessmentLogicCheck = () => {
    if (assessmentLogicVerified) {
      setAssessmentLogicVerified(false);
    } else {
      let trimmedLogic = eliminateExtraSpaces(assessmentLogic).trim();
      setAssessmentLogic(trimmedLogic);
      let payload = {
        logic: trimmedLogic,
        course_id: course_id,
      };
      //logic validation for assessment
      POST(`${Constants.assessment}/validateLogic`, payload)
        .then((res) => {
          if (res.data.isVerified) {
            toastRef.current.showToastMessage(res.data.message, "success");
            setAssessmentLogicVerified(true);
            handleSave(null, null, true);
          } else {
            toastRef.current.showToastMessage(res.data.message, "error");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleDomain = () => {
    setAssessmentDomainData((prevState) => [
      ...prevState,
      {
        alphaNumericIndex: ALPHABETS[assessmentDomainData.length],
        numericIndex: assessmentDomainData.length + 1,
        title: `Assessment Domain ${assessmentDomainData.length + 1}`,
        score: 0,
      },
    ]);
  };

  const handleRadio = (e) => {
    const { name } = e.target;
    const value = e.target.value === "true";
    setAllData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "isAdvanceAssessment") {
      handleSave(e, value);
    }
    // setAllData({...allData, isAdvanceAssessment: value})
  };
  // const handleRadio = (e) => {
  //    //   const { name } = e.target;
  //   const value = e.target.value === "true";
  //   setAllData({...allData, isAdvanceAssessment: value})
  //   handleSave(e, value);
  // }

  const handleDomainActions = (type, domainData) => {
    if (Number(domainData.score) > 0) {
      let payload;
      type === "addDomain"
        ? (payload = {
            alphaNumericIndex: domainData.alphaNumericIndex,
            assessmentDashboardId: allData._id,
            is_active: true,
            numericIndex: domainData.numericIndex,
            score: Number(domainData.score),
            title: domainData.title,
            user_id: UserData.user.id,
          })
        : type === "updateDomain"
        ? (payload = {
            alphaNumericIndex: domainData.alphaNumericIndex,
            assessmentDashboardId: allData._id,
            is_active: true,
            numericIndex: domainData.numericIndex,
            score: Number(domainData.score),
            title: domainData.title,
            user_id: UserData.user.id,
            assessmentDomainId: domainData._id,
          })
        : (payload = {
            assessmentDashboardId: allData._id,
            assessmentDomainId: domainData._id,
            user_id: UserData.user.id,
          });
      //add and update assessment domain
      POST(`${Constants.assessment}/${type}/${course_id}`, payload)
        .then((res) => {
          toastRef.current.showToastMessage(
            res.data.message,
            res.data.success ? "success" : "error",
          );
          getAssessment();
        })
        .catch((err) => console.log(err));
    } else {
      toastRef.current.showToastMessage("Score must be greater than 0", "error");
    }
  };

  const handleScore = useCallback((e) => {
    const { name, value } = e.target;
    setAllData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSave = (e, advanced, verified) => {
    // e.preventDefault();
    // setAllData({...allData, user_id: UserData.user.id} )
    if (verified !== (null || undefined) ? verified : assessmentLogicVerified) {
      let payload = {
        user_id: UserData.user.id,
        assessmentDashboardId: allData._id,
        assessmentLogic: assessmentLogic,
        minScore: allData.minScore,
        blocksHighlight: allData.blocksHighlight,
        checkModuleCorrectness: allData.checkModuleCorrectness,
        isAdvanceAssessment:
          advanced !== (undefined || null) ? advanced : allData.isAdvanceAssessment,
        moduleBlocksHighlight: allData.moduleBlocksHighlight,
        unlockOnlyUnqualified: allData.unlockOnlyUnqualified,
      };
      // save assessment setting
      POST(`${Constants.assessment}/saveAssessment/${course_id}`, payload)
        .then((res) => {
          toastRef.current.showToastMessage(res.data.message, "success");
        })
        .catch((err) => console.log(err));
    } else {
      toastRef.current.showToastMessage("Please first verify assessment logic", "error");
    }
  };

  const handleSaves = (e, advanced, verified) => {
    // e.preventDefault();
    // setAllData({...allData, user_id: UserData.user.id} )
    if (verified !== (null || undefined) ? verified : assessmentLogicVerified) {
      let payload = {
        user_id: UserData.user.id,
        assessmentDashboardId: allData._id,
        assessmentLogic: assessmentLogic,
        minScore: allData.minScore,
        blocksHighlight: allData.blocksHighlight,
        checkModuleCorrectness: allData.checkModuleCorrectness,
        isAdvanceAssessment:
          advanced !== (undefined || null) ? advanced : allData.isAdvanceAssessment,
        moduleBlocksHighlight: allData.moduleBlocksHighlight,
        unlockOnlyUnqualified: allData.unlockOnlyUnqualified,
      };
      // save assessment settings
      POST(`${Constants.assessment}/saveAssessment/${course_id}`, payload)
        .then((res) => {
          toastRef.current.showToastMessage(res.data.message, "success");
          // below code is the only change between the above handleSave method,
          // will need to further implement both functionalities in the handleSave method.
          setTimeout(() => setShowAssessmentModal(false), 2000);
        })
        .catch((err) => console.log(err));
    } else {
      toastRef.current.showToastMessage("Please first verify assessment logic", "error");
    }
  };

  return (
    <div>
      <FullScreenModal
        open={showAssessmentModal}
        setOpen={() => {
          setShowAssessmentModal(false);
        }}
        dialogTitle={
          <>
            <IconButton color="inherit">
              <FeedOutlinedIcon />
            </IconButton>
            Assessment Configuration
          </>
        }
      >
        <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
          <AssessmentRule allData={allData} handleRadio={handleRadio} />

          <Divider />
          {!allData.isAdvanceAssessment ? (
            <Grid container py={2} className={"flex items-center justify-start"}>
              <Grid item lg={5}>
                <Typography variant="body1" className="flex-shrink-0">
                  Minimum score to generate certificate
                </Typography>
              </Grid>
              <Grid item lg={2} display="flex" alignItems={"center"}>
                <InputBox
                  className={"max-w-100"}
                  type={"number"}
                  name="minScore"
                  value={allData.minScore}
                  onChange={handleScore}
                />
                <Typography variant="body1">%</Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <AdvancedAssessment
                assessmentDomainData={assessmentDomainData}
                setAssessmentDomainData={setAssessmentDomainData}
                handleDomain={handleDomain}
                handleDomainActions={handleDomainActions}
              />
              <Divider />
              <AssessmentLogic
                assessmentLogic={assessmentLogic}
                setAssessmentLogic={setAssessmentLogic}
                handleAssessmentLogicCheck={handleAssessmentLogicCheck}
                disabled={assessmentLogicVerified}
              />
            </>
          )}
          <Divider />
          <CompletenessConfig allData={allData} handleRadio={handleRadio} />
          <ButtonMain className="save_cohort_button" onClick={handleSaves}>
            Save
          </ButtonMain>
        </Container>
      </FullScreenModal>
      <ToastNotify ref={toastRef} duration={2000} />
    </div>
  );
};

export default AssessmentDashboardModal;
