import React from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Popper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LightTooltip from "./LightToolTip";

const CompletenessConfig = ({ allData, handleRadio }) => {
  return (
    <Box py={2}>
      <Typography variant="subtitle1" fontWeight={600}>
        Completeness & Correctness Configuration
      </Typography>
      <Grid mb={1} spacing={1} container className="flex items-center justify-start">
        <Grid item lg={4} className="flex items-center justify-start">
          <Typography variant="body1">
            Behaviour after the click of the [Evaluate-for-Certificate] button
          </Typography>
          <LightTooltip
            disableFocusListener
            components={<Popper />}
            title={
              <>
                <Box>
                  <Typography variant="body2">
                    Choose whether you want to unlock all modules or just the poorly performed
                    modules; after the learner presses the "Evaluate for Certificate" button and has
                    not attained the minimum qualification score criteria.
                  </Typography>
                </Box>
              </>
            }
          >
            <IconButton color="default">
              <InfoOutlinedIcon />
            </IconButton>
          </LightTooltip>
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={7} className="flex items-center justify-end">
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="unlockOnlyUnqualified"
            value={String(allData.unlockOnlyUnqualified)}
            onChange={handleRadio}
          >
            <FormControlLabel
              value={"false"}
              control={<Radio />}
              label="Unlock all modules if the learner fails in the course evaluation"
            />
            <FormControlLabel
              value={"true"}
              control={<Radio />}
              label="Unlock only poorly performed modules if the learner fails in the course"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Divider />
      <Grid mb={1} spacing={1} container className="flex items-center justify-start">
        <Grid item lg={4} className="flex items-center justify-start">
          <Typography variant="body1"></Typography>
          {/* <LightTooltip
            disableFocusListener
            components={<Popper />}
            title={
              <>
                <Box>
                  <Typography variant="body2">
                    If you wish that incorrectly answered question blocks should
                    be highlighted after the learner presses the "Check for
                    certificate" button, select "Specify," else select "Do not
                    specify."
                  </Typography>
                </Box>
              </>
            }
          >
            <IconButton color="default">
              <InfoOutlinedIcon />
            </IconButton>
          </LightTooltip> */}
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={7} className="flex items-center">
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="blocksHighlight"
            value={String(allData.blocksHighlight)}
            onChange={handleRadio}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Highlight incorrectly responded questions across all modules"
            />
            <FormControlLabel value="false" control={<Radio />} label="Do not highlight" />
          </RadioGroup>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid mb={1} spacing={1} container className="flex items-center justify-start">
        <Grid item lg={4} className="flex items-center justify-start">
          <Typography variant="body1">
            Condition to lock a module after the click of the [Mark-as-Done] button
          </Typography>
          <LightTooltip
            disableFocusListener
            components={<Popper />}
            title={
              <>
                <Box>
                  <Typography variant="body2">
                    Select if you want the modules to be locked after the learner has pressed the
                    "Mark as done" button if the learner satisfied both the "Correctness and
                    Completeness" criteria or just the completeness criteria. Correctness criteria:
                    At the module level, the learner should achieve at least a minimum score as per
                    the assessment logic/criteria. Completeness criteria: At the module level, if
                    the learner should attempt all mandatory questions.
                  </Typography>
                </Box>
              </>
            }
          >
            <IconButton color="default">
              <InfoOutlinedIcon />
            </IconButton>
          </LightTooltip>
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item lg={7} className="flex items-center justify-end">
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="checkModuleCorrectness"
            value={String(allData.checkModuleCorrectness)}
            onChange={handleRadio}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="All mandatory activities in a module should be attempted"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="All mandatory activities in a module are attempted, and the learner has obtained a satisfactory score in the module"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      {allData.checkModuleCorrectness && (
        <>
          <Divider />
          <Grid mb={1} spacing={1} container className="flex items-center justify-start">
            <Grid item lg={4} className="flex items-center justify-start">
              <Typography variant="body1"></Typography>
              {/* <LightTooltip
                disableFocusListener
                components={<Popper />}
                title={
                  <>
                    <Box>
                      <Typography variant="body2">
                        If you wish that incorrectly answered question blocks
                        should be highlighted after the learner presses the
                        "Mark as done" button, select "Specify," else select "Do
                        not specify."
                      </Typography>
                    </Box>
                  </>
                }
              >
                <IconButton color="default">
                  <InfoOutlinedIcon />
                </IconButton>
              </LightTooltip> */}
            </Grid>
            <Grid item lg={1}></Grid>
            <Grid item lg={7} className="flex items-center justify-end">
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="moduleBlocksHighlight"
                value={String(allData.moduleBlocksHighlight)}
                onChange={handleRadio}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Highlight incorrectly responded questions in the module"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Do not highlight incorrectly responded questions in the module"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CompletenessConfig;
