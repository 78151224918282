import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";
import CustomizedBreadcrumbs from "../../Breadcrumb/CustomizedBreadcrumbs";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import KeywordsView from "../../Keywords/KeywordsView";
import { ParagraphText } from "../../Typography";
import CourseBlockView from "../CourseBlockView";
import { Container, Grid, Typography } from "@mui/material";
import { logoutAction } from "../../../redux/action";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";

const CohortView = () => {
  const [courseOne, setCourseOne] = useState({});
  const [cohortDetails, setCohortDetails] = useState({});
  const [courseBlocks, setCourseBlocks] = useState([]);
  const [blockLoading, setBlockLoading] = useState(false);
  const [enrollmentMsg, setenrollmentMsg] = useState(false);

  const dispatch = useDispatch();
  const savedCourseDetail = useSelector((state) => state.savedCourseDetail);
  let UserData = useSelector((state) => state.userLogin);
  let isLoggedIn = UserData?.data?.accessToken;

  const { cid, coid } = useQueryParams();
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(logoutAction());
      navigate("/");
    } else {
      if (!coid) {
        if (savedCourseDetail?.data) {
          setCourseOne(savedCourseDetail.data);
        }
      } else {
        (async () => {
          //get public course details
          const response = await GET(
            `${Constants.getPublicCourseDetail}/${cid}?cohort_id=${coid}`,
            {},
          );
          setCourseOne(response?.data?.data);
          setCohortDetails(response?.data?.cohort);
        })();
      }
    }
  }, [savedCourseDetail, cid, setCourseOne, setCohortDetails, coid, location.search]);

  useEffect(() => {
    if (courseOne?._id) {
      if (!isLoggedIn) {
        return navigate("/");
      } else {
        (async () => {
          //get user enrollment data
          const response = await GET(
            `${Constants.userEnrollment}/${UserData?.data?.user?.id}/${cid}/${coid}`,
          );
          if (response?.data?.success) {
            if (response?.data?.data?.status === "inactive") {
              setenrollmentMsg(true);
            } else {
              setenrollmentMsg(false);
            }
          } else {
            navigate(`/course/${courseOne?.slug}?cid=${courseOne?._id}`);
          }
        })();
      }
    }
  }, [cid, coid, isLoggedIn, navigate, UserData, courseOne, location.search]);

  useEffect(() => {
    (async () => {
      setBlockLoading(true);
      //get public course blocks
      const response = await GET(`${Constants.getPublicCourseBlock}/${cid}`);
      setCourseBlocks(response?.data?.data);
      setBlockLoading(false);
    })();
  }, [cid]);

  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <p className="breadcrumb">
          <CustomizedBreadcrumbs
            Item1={"Course"}
            Item2={courseOne?.title}
            href1={"/courses"}
            disabled={"true"}
          />
        </p>
        <h1>Course / {courseOne?.title}</h1>
        <ParagraphText>{courseOne?.tagline}</ParagraphText>
        <KeywordsView keywords={courseOne} />
        {cohortDetails && (
          <Grid
            container
            className="courseCardMain"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item md={6}>
              <Grid item>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cohortDetails?.customText,
                  }}
                ></div>
              </Grid>
            </Grid>
          </Grid>
        )}
        {enrollmentMsg ? (
          <Box
            p={3}
            sx={{ width: "100%", bgcolor: "rgb(229, 246, 253)" }}
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <InfoOutlinedIcon color="primary" fontSize="large" />
            <Typography textAlign={"center"} fontWeight={600} ml={2} color="rgb(1, 67, 97)">
              Your enrollment request has been submitted and waiting for the moderator's /
              facilitator's approval.
            </Typography>
          </Box>
        ) : (
          <CourseBlockView courseBlocks={courseBlocks} loading={blockLoading} />
        )}
      </HeaderContainer>
    </Container>
  );
};

export default CohortView;
