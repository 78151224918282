import React from "react";
import { Grid } from "@mui/material";

import Header from "../Header";
import Footer from "../../components/Footer/Footer";
import "./HeaderContainer.scss";

const HeaderContainer = ({ children, footerView = false, isReviewer, className }) => {
  return (
    <Grid className={`headerContainerMain ${className}`}>
      <Header isReviewer={isReviewer} />
      {children}
      {footerView ? <Footer /> : null}
    </Grid>
  );
};

export default HeaderContainer;
