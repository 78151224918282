import React from "react";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import CertificateListingLayout from "../../components/CertificateConfig/CertificateList";

const CertificateListing = () => {
  return (
    <HeaderContainer>
      <CertificateListingLayout />
    </HeaderContainer>
  );
};

export default CertificateListing;
