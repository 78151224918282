import React from "react";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import DiscussionForum from "../../components/Discussion/DiscussionForum";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";

const DiscussionForumLayout = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/create-discussion-forum" ? (
        <HeaderContainer>
          <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
            <DiscussionForum />
          </Container>
        </HeaderContainer>
      ) : (
        <DiscussionForum />
      )}
    </>
  );
};

export default DiscussionForumLayout;
