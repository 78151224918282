import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { InputLabel, OutlinedInput } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, rightOption, theme) {
  return {
    fontWeight:
      rightOption.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    color: "black",
  };
}

export default function DropDown({
  options,
  leftOptionId,
  onClick,
  selected,
  disableBlock,
  isLoggedIn,
}) {
  const theme = useTheme();
  const [rightOption, setrightOption] = useState([]);

  const handleChange = (event) => {
    setrightOption(event.target.value);
  };

  useEffect(() => {
    const selectedOptions = options?.filter((el) => selected?.includes(el?._id));
    setrightOption(selectedOptions);
  }, [selected]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label" sx={{ fontSize: "18px" }}>
          Select an answer.
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          fullWidth
          input={<OutlinedInput id="select-multiple-chip" label="Select an answer." />}
          value={rightOption}
          onChange={handleChange}
          disabled={disableBlock || !isLoggedIn}
          renderValue={(selected) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected?.map((value) => (
                  <Chip
                    key={value?._id}
                    label={value?.text}
                    sx={{ color: "black", fontSize: "1rem" }}
                  />
                ))}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {options?.map(
            (option) =>
              option?.is_optionb === true && (
                <MenuItem
                  sx={{ whiteSpace: "break-spaces" }}
                  key={option?._id}
                  value={option}
                  style={getStyles(option, rightOption, theme)}
                  onClick={() => onClick(leftOptionId, option?._id)}
                >
                  {option?.text}
                </MenuItem>
              ),
          )}
        </Select>
      </FormControl>
    </>
  );
}
