import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { useOutletContext } from "react-router-dom";

const AddPasteABlock = ({
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setdisable] = useState(false);
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleClick = async () => {
    if (text === "") {
      setOpen(true);
    } else {
      setdisable(true);
      // API
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        slug: text,
        type: type,
      };
      //copy block by slug
      await POST(`${Constants.addBlock}/copy?_id=$`, body)
        .then((response) => {
          setdisable(false);
          setblockModOpen(false);
          if (type === "edit") {
            setCourseOne((prev) => ({ ...prev, published: false }));
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div>
      <InputBox
        placeholder="Paste the copied script from other block"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <br />
      <ButtonMain className="buttonGreen" onClick={handleClick} disabled={disable}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText="Please Enter Slug!"
        AlertType="error"
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddPasteABlock;
