import { Autocomplete, Box, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ButtonMain from "../../ButtonMain";

import "./AssessmentDomainForm.scss";

const AssessmentDomainForm = ({ domain, handleDomainActions }) => {
  const [domainData, setDomainData] = useState({
    title: "",
    alphaNumericIndex: "",
    numericIndex: "",
    score: "",
    _id: "",
    isChanged: false,
  });

  React.useEffect(() => {
    setDomainData({
      title: domain.title || "Learning",
      alphaNumericIndex: domain.alphaNumericIndex || "A",
      numericIndex: domain.numericIndex || 1,
      score: domain.score || 0,
      _id: domain._id,
      isChanged: false,
    });
  }, [domain]);

  const domainDropdown = [
    { label: "Emotion" },
    { label: "Learning" },
    { label: "Compassion" },
    { label: "Empathy" },
    { label: "Critical Enquiry" },
    { label: "Mindfulness" },
    { label: "Engagement" },
    { label: "Interest" },
    { label: "Motivation" },
    { label: "Skills" },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5} display="flex" alignItems="center">
          <Typography variant="subtitle1" className="flex-shrink-0" fontWeight={600}>
            {domainData.alphaNumericIndex}
          </Typography>
          <Box ml={{ xs: 1, lg: 2 }} flexGrow={1}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              className="domain_auto_complete"
              options={domainDropdown}
              classes={{
                root: "assessment_logic_input",
              }}
              value={domainData.title}
              onChange={(e, val) => {
                setDomainData({ ...domainData, title: val?.label, isChanged: true });
              }}
              // inputValue={domainData.title}
              onInputChange={(e) => {
                if (e) {
                  setDomainData({ ...domainData, title: e.target.value, isChanged: true });
                }
              }}
              // isOptionEqualToValue={(option, value)=> console.log(option, value)}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Domain" />}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={7}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "flex-start", lg: "flex-end" }}
          flexWrap="wrap"
        >
          <Box mx={"20px"} mb={2} display="flex" alignItems="center">
            <OutlinedInput
              type={"number"}
              classes={{
                root: "assessment_logic_input width-50",
                focused: "assessment_logic_input_focused",
              }}
              value={domainData.score}
              // onChange={(e) => {

              //   setDomainData({...domainData, score: e.target.value, isChanged: true})
              // }}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue === "" || (Number(inputValue) >= 0 && Number(inputValue) <= 100)) {
                  setDomainData({ ...domainData, score: inputValue, isChanged: true });
                }
              }}
            />
            <span className="percent">%</span>
          </Box>
          <Box mx={1} display="flex" alignItems="center" flexWrap="wrap">
            <ButtonMain
              disabled={!domainData.isChanged}
              className="verify_logic_button mx-2"
              onClick={() => {
                if (domainData._id) {
                  handleDomainActions("updateDomain", domainData);
                } else handleDomainActions("addDomain", domainData);
              }}
            >
              Save
            </ButtonMain>
            <ButtonMain
              variant="outlined"
              className="flex-shrink-0"
              onClick={() => {
                handleDomainActions("removeDomain", domainData);
              }}
            >
              Remove
            </ButtonMain>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AssessmentDomainForm;
