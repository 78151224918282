import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import Drawer from "@mui/material/Drawer";
//icons
import TitleIcon from "@mui/icons-material/Title";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import GifBoxOutlinedIcon from "@mui/icons-material/GifBoxOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import TextRotateVerticalOutlinedIcon from "@mui/icons-material/TextRotateVerticalOutlined";
import SettingsVoiceOutlinedIcon from "@mui/icons-material/SettingsVoiceOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import PersonalVideoTwoToneIcon from "@mui/icons-material/PersonalVideoTwoTone";
import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import SourceIcon from "@mui/icons-material/Source";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import Box from "@mui/material/Box";
import TabContents from "./TabContents";

export default function MobileVertical({
  setblockModOpen,
  setHeadingText,
  courseDetails,
  setCourseBlocks,
  courseOne,
  blockModalOpen,
  type,
}) {
  const [openDrawers, setOpenDrawers] = React.useState({
    basic: false,
    media: false,
    input: false,
    advance: false,
  });

  const handleDrawerOpen = (drawer) => {
    setOpenDrawers((prev) => ({ ...prev, [drawer]: true }));
  };

  const handleDrawerClose = (drawer) => {
    setOpenDrawers((prev) => ({ ...prev, [drawer]: false }));
  };

  const [activeTab, setActiveTab] = React.useState("Rich Text");

  const handleActiveTab = (tab, drawer) => {
    setActiveTab(tab);
    setHeadingText(tab);
    handleDrawerClose(drawer);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column-reverse", height: "100%" }}>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          display: "flex",
          justifyContent: "center",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleDrawerOpen("basic")}>
          <ListItemText primary="Basic Blocks" />
        </ListItemButton>

        <Drawer
          anchor="bottom"
          open={openDrawers.basic}
          onClose={() => handleDrawerClose("basic")}
          sx={{ zIndex: 1300 }}
        >
          {" "}
          <List>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Container" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Container", "basic")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <Inventory2OutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Module Container" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Rich Text" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Rich Text", "basic")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <TitleIcon />
              </ListItemIcon>
              <ListItemText primary="Rich Text" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "List" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("List", "basic")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <FormatListBulletedOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="List" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Toggle List" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Toggle List", "basic")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <RadioButtonCheckedOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Toggle List" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Divider" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Divider", "basic")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <MoreHorizOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Divider" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Block Carousel" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Block Carousel", "basic")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <SourceOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Block Carousel" sx={{ margin: "4px" }} />
            </ListItemButton>
          </List>
        </Drawer>

        <Divider orientation="vertical" flexItem />

        <ListItemButton onClick={() => handleDrawerOpen("media")}>
          <ListItemText primary="Media Blocks" />
        </ListItemButton>
        <Drawer
          anchor="bottom"
          open={openDrawers.media}
          onClose={() => handleDrawerClose("media")}
          sx={{ zIndex: 1300 }}
        >
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Images" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Images", "media")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <PhotoOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Images" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Web Bookmarks" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Web Bookmarks", "media")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <InsertLinkOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Web Bookmarks" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Video" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Video", "media")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <PlayCircleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Video" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Audio" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Audio", "media")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <AudiotrackOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Audio" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "File" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("File", "media")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <AttachFileOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="File" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "GIF" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("GIF", "media")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <GifBoxOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="GIF" sx={{ margin: "4px" }} />
            </ListItemButton>
          </List>
        </Drawer>

        <Divider orientation="vertical" flexItem />
        {type !== "landing" && (
          <>
            <ListItemButton onClick={() => handleDrawerOpen("input")}>
              <ListItemText primary="User Input Blocks" />
            </ListItemButton>
            <Drawer
              anchor="bottom"
              open={openDrawers.input}
              onClose={() => handleDrawerClose("input")}
              sx={{ zIndex: 1300 }}
            >
              {" "}
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4, backgroundColor: activeTab === "MCQ" ? "#ccc" : "transparent" }}
                  onClick={() => handleActiveTab("MCQ", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <HelpOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="MCQ" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Fill in the blanks" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Fill in the blanks", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <IndeterminateCheckBoxOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fill in the blanks" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Match the following" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Match the following", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <DragHandleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Match the following" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Text Response" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Text Response", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <TextRotateVerticalOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Text Response" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Audio Recording" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Audio Recording", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <SettingsVoiceOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Audio Recording" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Video Recording" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Video Recording", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <VideoCameraBackOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Video Recording" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Upload File" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Upload File", "input")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <CloudDownloadOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Upload File" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "FS Form" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("FS Form")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <FormatAlignCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary="FS Form" sx={{ margin: "4px" }} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    pl: 4,
                    backgroundColor: activeTab === "Survey Monkey" ? "#ccc" : "transparent",
                  }}
                  onClick={() => handleActiveTab("Survey Monkey")}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                    <MoodBadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Survey Monkey" sx={{ margin: "4px" }} />
                </ListItemButton>
              </List>
            </Drawer>
            <Divider orientation="vertical" flexItem />
          </>
        )}

        <ListItemButton onClick={() => handleDrawerOpen("advance")}>
          <ListItemText primary="Advanced Blocks" sx={{ margin: "4px" }} />
        </ListItemButton>
        <Drawer
          anchor="bottom"
          open={openDrawers.advance}
          onClose={() => handleDrawerClose("advance")}
          sx={{ zIndex: 1300 }}
        >
          <List component="div" disablePadding>
            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Paste a block" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Paste a block", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <ContentCopyOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Paste a block" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Grid" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Grid", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <GridViewOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Grid" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Comic Strip" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Comic Strip", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <ForumOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Comic Strip" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Button" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Button", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <SmartButtonIcon />
              </ListItemIcon>
              <ListItemText primary="Button" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "native-embed" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("native-embed", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <PersonalVideoTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Native Embed" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{
                pl: 4,
                backgroundColor: activeTab === "Embeded Code" ? "#ccc" : "transparent",
              }}
              onClick={() => handleActiveTab("Embeded Code", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <CodeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Embeded Code" sx={{ margin: "4px" }} />
            </ListItemButton>

            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "SCORM" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("SCORM", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <SourceIcon />
              </ListItemIcon>
              <ListItemText primary="SCORM" sx={{ margin: "4px" }} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4, backgroundColor: activeTab === "Discussion" ? "#ccc" : "transparent" }}
              onClick={() => handleActiveTab("Discussion", "advance")}
            >
              <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                <MarkUnreadChatAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Discussion" sx={{ margin: "4px" }} />
            </ListItemButton>
          </List>
        </Drawer>
      </List>

      <Box sx={{ flex: 1, bgcolor: "#eff6ff", mx: 4, mt: 4, overflow: "auto" }}>
        <TabContents
          courseDetails={courseDetails}
          setCourseBlocks={setCourseBlocks}
          setblockModOpen={setblockModOpen}
          blockModalOpen={blockModalOpen}
          activeTab={activeTab}
        />
      </Box>
    </Box>
  );
}
