import React from 'react'
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer'
import UserProfile from '../../components/UserProfile/UserProfile';

const ProfilePage = () => {
  return (
    <HeaderContainer>
      <UserProfile />
    </HeaderContainer>
  )
}

export default ProfilePage