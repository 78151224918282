import { Box, Button, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useRef, useState } from "react";
import ManagedTabs from "../../Tabs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import { GET, POST, PUT } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import ActiveSMForms from "./ActiveSMForms";
import InActiveSMForms from "./InActiveSMForms";
import EditSMFormModal from "../../ModalMain/EditSMFormModal";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";

const SMAdminLayout = () => {
  const [tabValue, setTabValue] = useState("active");
  const [surveyId, setSurveyId] = useState("");
  const [title, setTitle] = useState("");
  const [activeForms, setActiveForms] = useState([]);
  const [inActiveForms, setInActiveForms] = useState([]);
  const [editModal, setEditModal] = useState({
    open: false,
    id: "",
    title: "",
  });
  const [open, setOpen] = useState({
    modal: false,
    surveyId: "",
  });
  const [loading, setLoading] = useState(false);
  const [confLoading, setConfLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.userLogin.data);
  const navigate = useNavigate();
  const toastRef = useRef();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    tabValue === "active" && getActiveSMForms();
    tabValue === "inactive" && getInActiveSMForms();
  }, [tabValue]);

  const getActiveSMForms = async () => {
    try {
      const response = await GET(`${Constants.admin}/active_sm_form`);
      setActiveForms(response?.data?.data);
    } catch (error) {
      console.log("Error in fetching...", error);
    }
  };
  const getInActiveSMForms = async () => {
    try {
      const response = await GET(`${Constants.admin}/inactive_sm_form`);
      setInActiveForms(response?.data?.data);
    } catch (error) {
      console.log("Error in fetching...", error);
    }
  };

  let tabs = [
    {
      value: "active",
      label: "Tracking Enabled",
    },
    {
      value: "inactive",
      label: "Tracking Disabled",
    },
  ];

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleDelete = () => {
    setOpen((prev) => ({
      ...prev,
      modal: true,
    }));
  };

  const handleSubmit = async () => {
    if (!surveyId.trim()) {
      toastRef.current.showToastMessage("Survey id required", "error");
    } else if (!title.trim()) {
      toastRef.current.showToastMessage("Title required", "error");
    } else {
      try {
        let payload = {
          survey_id: surveyId.trim(),
          title,
        };
        setLoading(true);
        const response = await POST(`${Constants.admin}/create_update_sm_config`, payload);
        if (response.data.success) {
          setLoading(false);
          setActiveForms((prev) => {
            // Filter out surveys from the response that already exist in the current state
            const newSurveys = response.data.config.filter(
              (newSurvey) =>
                newSurvey.status === "active" &&
                !prev.some((existingSurvey) => existingSurvey.survey_id === newSurvey.survey_id),
            );

            // Combine the previous state with the filtered new surveys
            return [...prev, ...newSurveys];
          });

          setSurveyId("");
          setTitle("");
          toastRef.current.showToastMessage(response?.data?.message, "success");
        }
      } catch (error) {
        toastRef.current.showToastMessage(error?.response?.data?.message, "error");
        setLoading(false);
      }
    }
  };

  const handleEdit = async () => {
    try {
      let payload = {
        title: editModal.title,
        id: editModal.id,
      };
      const response = await PUT(`${Constants.admin}/edit_sm_form`, payload);

      setActiveForms((prev) => {
        return prev.map((form) =>
          form.survey_id === editModal.id ? { ...form, title: editModal.title } : form,
        );
      });
      setEditModal((prev) => ({ ...prev, open: false }));

      toastRef.current.showToastMessage(response?.data?.message, "success");
    } catch (error) {
      toastRef.current.showToastMessage("Failed to update the form", "error");
    }
  };

  const handleChangeStatusOfForm = async (survey_id, currentStatus) => {
    try {
      const newStatus = currentStatus === "active" ? "inactive" : "active";

      const payload = {
        status: newStatus,
        survey_id,
      };

      const response = await PUT(`${Constants.admin}/update_sm_form_status`, payload);

      if (newStatus === "active") {
        // Move the form to activeForms
        setActiveForms((prevActiveForms) => {
          const updatedActiveForms = prevActiveForms.map((form) =>
            form.survey_id === survey_id ? { ...form, status: newStatus } : form,
          );
          return updatedActiveForms;
        });

        // Remove the form from inactiveForms
        setInActiveForms((prevInActiveForms) =>
          prevInActiveForms.filter((form) => form.survey_id !== survey_id),
        );
      } else {
        // Move the form to inActiveForms
        setInActiveForms((prevInActiveForms) => {
          const updatedInActiveForms = prevInActiveForms.map((form) =>
            form.survey_id === survey_id ? { ...form, status: newStatus } : form,
          );
          return updatedInActiveForms;
        });

        // Remove the form from activeForms
        setActiveForms((prevActiveForms) =>
          prevActiveForms.filter((form) => form.survey_id !== survey_id),
        );
      }
      toastRef.current.showToastMessage(response?.data?.message, "success");
    } catch (error) {
      toastRef.current.showToastMessage("Failed to update the form status", "error");
    }
  };

  const handleConfDelete = async (survey_id) => {
    try {
      const payload = {
        survey_id,
      };
      setConfLoading(true);

      const response = await POST(`${Constants.admin}/delete_sm_form`, payload);

      if (response?.data?.success) {
        // Remove the form from activeForms if it exists there
        setActiveForms((prevActiveForms) =>
          prevActiveForms.filter((form) => form.survey_id !== survey_id),
        );

        // Remove the form from inActiveForms if it exists there
        setInActiveForms((prevInActiveForms) =>
          prevInActiveForms.filter((form) => form.survey_id !== survey_id),
        );
        setConfLoading(false);
        setOpen((prev) => ({
          ...prev,
          modal: false,
        }));
        toastRef.current.showToastMessage(response?.data?.message, "success");
      } else {
        toastRef.current.showToastMessage(response?.data?.message, "error");
        setConfLoading(false);
      }
    } catch (error) {
      toastRef.current.showToastMessage("Failed to delete the form", "error");
      console.log(error);
      setConfLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h5" mb={2} fontWeight={"bold"}>
        Manage SurveyMonkey Tracking{" "}
      </Typography>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        width="100%"
      >
        <Box sx={{ width: { xs: "100%", md: "70%", lg: "50%" } }}>
          <TextField
            id="outlined-basic"
            label="Enter SurveyMonkey survey id."
            value={surveyId}
            onChange={(e) => handleChange(e, setSurveyId)}
            variant="outlined"
            placeholder="Enter SurveyMonkey survey id."
            fullWidth
            size="small"
            autoFocus
          />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "70%", lg: "50%" } }}>
          <TextField
            id="outlined-basic"
            value={title}
            onChange={(e) => handleChange(e, setTitle)}
            label="Enter SurveyMonkey Title"
            variant="outlined"
            placeholder="Enter SurveyMonkey Title"
            fullWidth
            size="small"
          />
        </Box>
        <Box>
          <Button
            variant="outlined"
            sx={{ textTransform: "none", minWidth: 120 }}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={24} /> : "Add"}
          </Button>
        </Box>
      </Box>
      <Typography variant="body1" mt={2} mb={1}>
        List of survey id's for which tracking is active/inactive.
      </Typography>
      <ManagedTabs tabs={tabs} handleTabChange={handleTabChange} tabsValue={tabValue} />
      <ToastNotify ref={toastRef} duration={4000} />
      {tabValue === "active" && (
        <ActiveSMForms
          activeForms={activeForms}
          setEditModal={setEditModal}
          handleChangeStatusOfForm={handleChangeStatusOfForm}
          handleDelete={handleDelete}
          setOpen={setOpen}
        />
      )}
      {tabValue === "inactive" && (
        <InActiveSMForms
          inActiveForms={inActiveForms}
          setEditModal={setEditModal}
          handleChangeStatusOfForm={handleChangeStatusOfForm}
          handleDelete={handleDelete}
          setOpen={setOpen}
        />
      )}
      <EditSMFormModal
        title="Edit Form"
        editModal={editModal}
        setEditModal={setEditModal}
        handleEdit={handleEdit}
      />
      <ConfirmModal
        handleConfirm={() => handleConfDelete(open.surveyId)}
        confirmText={"Once Deleted Can't be restored!"}
        headerText={"Delete Survey Monkey Form"}
        showConfirmButton={true}
        handleClose={() =>
          setOpen((prev) => ({
            ...prev,
            modal: false,
          }))
        }
        confLoading={confLoading}
        confModal={open.modal}
      />
    </>
  );
};

export default SMAdminLayout;
