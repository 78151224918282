import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./FSComms.scss";
import HeaderContainer from "../HeaderContainer/HeaderContainer";
import { HeaderText } from "../Typography";

import ButtonMain from "../ButtonMain";
import { Button, Container, Grid } from "@mui/material";
import { Constants } from "../../config/Constants";
import { useNavigate } from "react-router-dom";
import { GET } from "../../config/Api";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FSCommsDatatable from "./FSCommsDatatable";
import CreateFSCommsModal from "./CreateFSCommsModal";

const FSComms = () => {
  let UserData = useSelector((state) => state.userLogin.data);

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create FSComms");
  const [fsCommsList, setFSCommsList] = useState([]);
  const [refetchList, setRefetchList] = useState(false);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    //get fscomms list
    GET(`${Constants.fsComms}`)
      .then((res) => {
        setFSCommsList(res.data.fsComms);
      })
      .catch((err) => console.log(err));
  }, [refetchList]);

  // const convertDateToISOString = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toISOString();
  // };

  const handleFSCommsEdit = async (data) => {
    setEditData((prev) => ({ ...prev, ...data }));
    setModalTitle("Edit FSComms");
    setOpenModal(true);
  };

  if (UserData?.user?.type !== "admin") {
    return navigate("/");
  }

  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <Grid container>
          <Grid item lg={10}>
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardBackspaceIcon />
            </Button>
            <HeaderText>Manage FSComms</HeaderText>
            {/* <ParagraphText>Description of the Course Goes here.</ParagraphText> */}
          </Grid>
          <Grid item lg={2} mt={2} className="fullwidth flex-end">
            <ButtonMain
              variant="primary"
              onClick={() => {
                setOpenModal(true);
                setEditData({});
                setModalTitle("Create FSComms");
              }}
            >
              Create FSComms
            </ButtonMain>
          </Grid>
        </Grid>
        <FSCommsDatatable
          fsCommsList={fsCommsList}
          handleFSCommsEdit={handleFSCommsEdit}
          // handleDeleteFSComms={handleDeleteFSComms}
          // handleAddFSComms={handleAddFSComms}
        />
      </HeaderContainer>

      {/* Cohort Create & Edit Modal Begins Here */}
      <CreateFSCommsModal
        modalTitle={modalTitle}
        modalOpen={openModal}
        refetchList={refetchList}
        editData={editData}
        setEditData={setEditData}
        setRefetchList={setRefetchList}
        modalClose={() => setOpenModal(false)}
      />
    </Container>
  );
};

export default FSComms;
