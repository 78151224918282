import { FormControl } from "@mui/material";
import React, { useState } from "react";
import swal from "sweetalert";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import ButtonMain from "../../ButtonMain";
import { InputBox } from "../../InputBox/InputBox";
import FMModal from "../CourseModals/FMModal";

import "./CopyCourse.scss";

const CopyCourseModal = ({
  modalOpen,
  modalClose,
  courseId,
  // setDataEdited,
}) => {
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [loading, settLoading] = useState(false);

  const handleCopy = () => {
    if (title === "") {
      setTitleError("Please enter a title");
    } else {
      settLoading(true);
      let payload = {
        title: title,
      };
      //copy course
      POST(`${Constants.courseManage}/copy/${courseId}`, payload)
        .then((res) => {
          modalClose();
          swal(res.data.message, { icon: "success" });
          window.open(
            `${`/course/${res?.data?.course?.slug}?cid=${res?.data?.course?._id}`}`,
            "_blank",
          );
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <FMModal
      modalOpen={modalOpen}
      modalClose={() => {
        modalClose();
        setTitleError("");
        setTitle("");
      }}
      backgroundImage={{ backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)" }}
      modalHeader="Copy Course"
      className="copyCourseContainer"
    >
      <FormControl className="copyCourseForm">
        <InputBox
          placeholder="Title for the copied Course"
          onChange={(e) => {
            setTitleError("");
            setTitle(e.target.value);
          }}
          errorMsg={titleError}
        />
        <ButtonMain className="copyButton" onClick={handleCopy} disabled={loading}>
          Create a Copy
        </ButtonMain>
      </FormControl>
    </FMModal>
  );
};

export default CopyCourseModal;
