class clickEvent {
  constructor() {
    this.event = "";
    this.cohort_name = "";
    this.course_name = "";
    this.user_unique_id = "";
    this.block_id = "";
    this.course_id = "";
    this.cohort_id = "";
    this.parent_container_id = "";
    this.timestamps = new Date();
    this.module_open_ref = ""; // menu_clicked, page_load, module_clicked, breadcrumb_clicked
    this.course_open_ref = ""; // course_clicked, page_load/refresh,
    this.course_type = "";
    this.cohort_type = "";
    this.carousel_scroll = ""; // left, right
    this.block_title = ""; //module-title
    this.block_type = ""; // container,carosuel,mcq,fills,response,match,
    this.block_action = ""; // save, update,started,paused,ended
    this.user_response = ""; // mcq_option_title, response_text,cert_email,whatsapp share-share certificate
    this.link_href = "";
    this.recording_type = ""; //audio,video
    this.module_locked_status = ""; //0,1 - module_locked
    this.certificate_generation_status = ""; //0,1 - efc_clicked
    // Assigning user agent details
    this.browser_useragent = navigator.userAgent; // Browser User Agent

    // Checking if the device is mobile or desktop
    this.device_useragent = /Mobi|Android/i.test(navigator.userAgent) ? "Mobile" : "Desktop"; // Device User Agent
    this.ip = localStorage.getItem("ip");
  }
  // Renamed method to reflect its purpose better
  validateAndSendEvent(sendDataToFirehose) {
    const eventArray = [
      "course_opened", //post
      "module_clicked", //pre
      "module_load", //post
      "carousel_opened", // post
      "carousel_scrolled", //post
      "user_input", //post
      "mad_clicked", //pre
      "efc_clicked", //post - after evaluation
      "regenerate_certificate", //post-confirm btn evnt if name matches
      "email_certificate", //post
      "enroll_course", //post
      "unlock_module", //post
      "module_locked", //post
      "download_certificate", // pre
      "share_certificate", // post
      "mad_reached", //pre
      "recording", //post
      "link_inside_content_clicked", //pre
      "switch_cohort", //post
    ];
    // If environment is 1 and cohort tracking is enabled, send data
    if (process.env.REACT_APP_USE_FIREHOSE === "1" && eventArray.includes(this.event)) {
      sendDataToFirehose(this);
      return;
    }
  }
}

export default clickEvent;
