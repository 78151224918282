import React from "react";
import CourseListing from "../../components/courses/CourseListing/CourseListing";
import PageHeader from "../../components/PageHeader";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import { Container } from "@mui/material";

const Courses = () => {
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <PageHeader
          headerText="Featured Courses"
          paragraphText={
            <>
              The Framerspace courses are designed to foster whole-brain learning by integrating
              content knowledge with social and emotional learning through digital pedagogies such
              as reflection, critical inquiry, storytelling, dialogue and play.
              {/* <br />
            Select the audience below to explore all our popular courses. */}
            </>
          }
        />
        <CourseListing />
      </HeaderContainer>
    </Container>
  );
};

export default Courses;
