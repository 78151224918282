import { Button, Grid, Modal } from "@mui/material";
import React from "react";
import { ParagraphText } from "../Typography";
import CloseIcon from "@mui/icons-material/Close";

import "./ModalMain.scss";

const ModalMain = ({
  modalOpen,
  modalClose,
  gradientLeft,
  gradientRight,
  children,
  imgUrl,
  rightBoxTitle,
  rightBoxBtn,
  onRightBoxBtnClick,
  modalFor,
}) => {
  return (
    <Modal
      open={modalOpen}
      onClose={modalClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className="modalContainer"
    >
      <Grid container className="modalMain">
        <Grid item className="modalLeft" xs={12} lg={6} md={6} sm={6} sx={gradientLeft}>
          <div className="iconMain2">
            <div className="iconContainer2">
              <CloseIcon onClick={modalClose} />
            </div>
          </div>
          {children}
        </Grid>
        <Grid item className="modalRight" xs={12} lg={6} md={6} sm={6} sx={gradientRight}>
          <div className="loginImageCont">
            {imgUrl && (
              <img
                src={imgUrl}
                alt="login"
                className={modalFor === "login" ? "hideLoginImage" : "loginImage"}
              />
            )}
            <div className="iconContainer">
              <CloseIcon onClick={modalClose} />
            </div>
          </div>
          {rightBoxBtn && (
            <div className="rightBtnMain">
              <ParagraphText className="rigthBtnText">{rightBoxTitle}</ParagraphText>
              <Button
                variant="contained"
                onClick={onRightBoxBtnClick}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#ffd370",
                  color: "black",
                  width: "160px",
                  "&:hover": {
                    backgroundColor: "#ffd370",
                  },
                }}
              >
                {rightBoxBtn}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalMain;
