import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LandingPageCard from "./LandingPageCard";
import "./landingPage.scss";
import { Each } from "../../../utils";

const CompletedCourses = ({
  completedCourses,
  completedCourseLength,
  setPage,
  disableCompletedLoadButton,
}) => {
  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      <Box
        sx={{
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          margin: "2rem 0rem 2rem 0rem",
          position: "relative",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            position: "absolute",
            left: 0,
            top: "-18px",
            background: "#ffffff",
            pr: 1,
            fontWeight: 600,
            color: "#555555",
          }}
        >
          Completed Courses
        </Typography>
      </Box>
      <div className="enrolled-container">
        <Each
          of={completedCourses}
          render={(item, index) => (
            <LandingPageCard
              isSwitchCohort={false}
              courseDetails={item?.lastrecord?.courseDetails[0]}
            />
          )}
        />
      </div>
      {completedCourseLength && (
        <Box sx={{ textAlign: "center", marginTop: "0.8rem" }}>
          <Button
            onClick={nextPage}
            sx={{ textTransform: "none" }}
            endIcon={<ArrowDropDownIcon />}
            disabled={disableCompletedLoadButton}
          >
            Load More
          </Button>
        </Box>
      )}
    </>
  );
};

export default CompletedCourses;
