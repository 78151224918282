import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Box } from "@mui/material";

export default function EditSMFormModal({ title, editModal, setEditModal, handleEdit }) {
  const handleChange = (e, setter, field) => {
    setter((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  return (
    <Dialog
      open={editModal.open}
      onClose={() => setEditModal((prev) => ({ ...prev, open: false }))}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ width: "100%", maxWidth: "500px", mx: "auto" }}
        >
          <TextField
            id="outlined-basic"
            label="SurveyMonkey survey id."
            value={editModal.id}
            onChange={(e) => handleChange(e, setEditModal, "id")}
            variant="outlined"
            placeholder="SurveyMonkey survey id."
            fullWidth
            size="small"
            autoFocus
            disabled={!!editModal.id}
            sx={{ mt: 1 }}
          />
          <TextField
            id="outlined-title"
            label="Edit Survey Title"
            value={editModal.title}
            onChange={(e) => handleChange(e, setEditModal, "title")}
            variant="outlined"
            placeholder="Edit Survey Title"
            fullWidth
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
          mr: { xs: 2, lg: 5 },
          mb: 1,
        }}
      >
        <Button
          onClick={handleEdit}
          variant="outlined"
          sx={{ textTransform: "none", minWidth: 120 }}
        >
          Edit
        </Button>
        <Button
          onClick={() => setEditModal((prev) => ({ ...prev, open: false }))}
          autoFocus
          variant="outlined"
          sx={{ textTransform: "none", minWidth: 120 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
