import { Chip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useOutletContext } from "react-router-dom";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import UploadImageBox from "../../../../Image/UploadImageBox";

const AddGrid = ({
  type,
  updateOrder,
  blockdetails,
  setblockModOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [grid, setGrid] = useState({
    title: blockdetails?.title || "",
    description: blockdetails?.text || "",
    optionText: "",
  });
  const [disbleButton, setdisbleButton] = useState(false);
  const [block, setBlock] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [urlPath, seturlPath] = useState({});
  const [options, setoptions] = useState([blockdetails] || {});
  const [blockToBeAdded, setBlockToBeAdded] = useState({});
  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setalertType] = useState("error");
  const [isImage, setIsimage] = useState(false);
  const toastRef = useRef();

  const handleChange = (event) => {
    setIsimage(event.target.checked);
  };

  const { setCourseOne } = useOutletContext();
  let blockid = block?._id || blockdetails?._id;

  let UserData = useSelector((state) => state.userLogin);
  const { cid, mid } = useQueryParams();

  const handleNextClick = async () => {
    if (grid.title === "") {
      setOpen(true);
      seterrText("Please Enter Question Title.");
    } else if (grid.description === "") {
      setOpen(true);
      seterrText("Please Enter Description.");
    } else {
      try {
        let body = {
          user_id: UserData?.data?.user?.id,
          course: cid,
          container: mid,
          order: blockModalOpen?.addBelow
            ? blockModalOpen?.currentBlockOrder + 1
            : updateOrder || 1,
          title: grid.title,
          text: grid.description,
          type: type,
        };
        //create grid block
        const response = await POST(`${Constants.addBlock}/grid?_id=${blockid}`, body);
        setBlock(response?.data);
        setCourseBlocks((prevBlocks) => {
          const updatedBlocks = prevBlocks.map((prevBlock) =>
            prevBlock._id === (blockdetails?._id || response?.data?._id)
              ? response?.data
              : prevBlock,
          );
          return updatedBlocks;
        });
        setCourseOne((prev) => ({ ...prev, published: false }));
        setalertType("success");
        seterrText("Block Created Successfully");
        return response?.data?._id;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  const handleAddOption = async () => {
    if (grid.optionText === "") {
      setOpen(true);
      setalertType("success");
      seterrText("Please Enter Option Text.");
    } else {
      try {
        if (!blockid) {
          blockid = await handleNextClick();
        }
        // If block ID is still not present after handleNextStep, do not proceed
        if (!blockid) {
          return;
        }

        // Check if image toggle is on but no image is uploaded
        if (isImage && !selectedFile) {
          toastRef.current.showToastMessage(
            "Please upload an image for the option or turn off the image toggle.",
            "error",
          );
          return;
        }

        let body = {
          user_id: UserData?.data?.user?.id,
          course: cid,
          bound: 236,
          image: isImage ? urlPath : {},
          order: 1,
          text: grid.optionText,
        };
        setdisbleButton(true);
        //add grid item
        await POST(`${Constants.updateBlock}/${blockid}/add_grid_item`, body).then((response) => {
          if (type !== "edit") {
            setBlockToBeAdded((prev) => ({ ...prev, ...response?.data[0] }));
          }
          setCourseOne((prev) => ({ ...prev, published: false }));
          seturlPath(null);
          setIsimage(false);
          setoptions(response.data);
          setdisbleButton(false);
          setOpen(true);
          setGrid({ ...grid, optionText: "" });
          setSelectedFile(null);
          setalertType("success");
          seterrText("Option Added Successfully");
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      setdisbleButton(true);
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      seturlPath(response?.data?.image);
      setdisbleButton(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveGridItem = async (item) => {
    let body = {
      item: item?._id,
      user_id: UserData?.data?.user?.id,
    };
    //remove grid item
    await POST(`${Constants.updateBlock}/${blockid}/remove_grid_item`, body)
      .then((response) => {
        setCourseOne((prev) => ({ ...prev, published: false }));
        setoptions(response.data);
        //setBlockToBeAdded((prev) => ({ ...prev, ...response?.data[0] }));
        setdisbleButton(false);
        setOpen(true);
        setalertType("success");
        seterrText("Option Removed Successfully");
        setGrid({ ...grid, optionText: "" });
        setSelectedFile(null);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Box>
        <SourceTextSwitcher
          checked={checked}
          handleChange={(event) => setChecked(event.target.checked)}
          srcText={blockdetails?.srcText}
          srcTitleText={blockdetails?.srcTitleText}
        />
        <InputBox
          placeholder="Title"
          value={grid.title}
          onChange={(e) => setGrid({ ...grid, title: e.target.value })}
        />{" "}
        {checked && (
          <Box my={2}>
            <strong>Source Text:</strong> {blockdetails?.srcTitleText}
          </Box>
        )}
        <br />
        <CustomTextArea
          placeholder="Description"
          value={grid.description}
          onChange={(e) => setGrid({ ...grid, description: e.target.value })}
        />{" "}
        {checked && (
          <Box my={2}>
            <strong>Source Text:</strong> {blockdetails?.srcText}
          </Box>
        )}
        <br />
        {/* {next && (
        <ButtonMain className="buttonGreen" disabled={disable} onClick={handleNextClick}>
          Next Step
        </ButtonMain>
      )}
      {!next && ( */}
        <b>Create new item:</b>
        <InputBox
          placeholder="Item Text"
          value={grid.optionText}
          onChange={(e) => setGrid({ ...grid, optionText: e.target.value })}
        />{" "}
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={isImage}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Add Image"
        />
        {isImage && (
          <UploadImageBox
            title={"Option Image"}
            icon={false}
            initialImage={urlPath?.m}
            accept="image/*"
            onChange={handleFileChange}
          />
        )}
        <br />
        <ButtonMain className="buttonGreen" onClick={handleAddOption} disabled={disbleButton}>
          Add Item
        </ButtonMain>{" "}
        <div style={{ marginTop: "4rem" }}>
          <b>Items:</b>
          {options[0]?.items?.map((item) => (
            <Box
              key={item?._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5px",
                marginBottom: "1rem",
              }}
            >
              <Box>{item?.text}</Box>
              <Box>
                <Chip
                  label="Remove"
                  variant="outlined"
                  onClick={() => handleRemoveGridItem(item)}
                />
              </Box>
              <Box>
                {item?.image && (
                  <img src={cloudfrontUrl + item?.image?.m} alt="img" height="50px" width="50px" />
                )}
              </Box>
            </Box>
          ))}
        </div>
      </Box>
      {blockid && (
        <ButtonMain
          className="buttonGreen"
          style={{ marginTop: "4rem" }}
          onClick={async () => {
            if (type === "edit") {
              const blockId = await handleNextClick(); // Wait for handleNextStep to complete
              if (!blockId) return;
              setCourseBlocks &&
                setCourseBlocks((prevBlocks) => {
                  const updatedBlocks = prevBlocks.map((prevBlock) =>
                    prevBlock._id === blockToBeAdded._id ? blockToBeAdded : prevBlock,
                  );
                  return updatedBlocks;
                });
            } else {
              if (blockModalOpen?.addBelow) {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    const updatedBlocks = prev.map((el) => {
                      if (el.order >= blockToBeAdded?.order) {
                        el.order += 1;
                      }
                      return el;
                    });
                    const elIndex = updatedBlocks.findIndex(
                      (el) => el._id === blockModalOpen?.currentBlockId,
                    );
                    updatedBlocks.splice(elIndex + 1, 0, blockToBeAdded);
                    return updatedBlocks;
                  });
              } else {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    prev.forEach((el) => {
                      el.order = el.order + 1;
                    });
                    return [blockToBeAdded, ...prev];
                  });
              }
            }
            setblockModOpen && setblockModOpen(false);
          }}
        >
          Save Block
        </ButtonMain>
      )}
      {/* )} */}
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
      <ToastNotify ref={toastRef} duration={3000} />
    </div>
  );
};

export default AddGrid;
