import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./EmbedView.scss";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const checkTypeForm = (embededCode, user) => {
  if (embededCode.includes("iframe")) {
    let iframeTag;
    if (embededCode.match(/<iframe[\s\S]*?<\/iframe>/i)) {
      iframeTag = embededCode.match(/<iframe[\s\S]*?<\/iframe>/i)[0];
    } else {
      return embededCode;
    }
    let srcValue = iframeTag.match(/src="([^"]+)"/)[1];

    let returnValue;
    if (srcValue.includes("typeform.com")) {
      if (srcValue?.includes("#uniqueid")) {
        let srcArray = srcValue?.split("#");
        returnValue = srcArray[0] + `#uniqueid=${user?.email}`;
        const parser = new DOMParser();
        const fullHtml = parser.parseFromString(iframeTag, "text/html");

        fullHtml.body.innerHTML =
          "<iframe src='" + returnValue + "' class='typeform-embed'></iframe>";

        return fullHtml.body.innerHTML;
      } else {
        returnValue = srcValue + `#uniqueid=${user?.email}`;
        const parser = new DOMParser();
        const fullHtml = parser.parseFromString(iframeTag, "text/html");

        fullHtml.body.innerHTML =
          "<iframe src='" + returnValue + "' class='typeform-embed'></iframe>";

        return fullHtml.body.innerHTML;
      }
    } else if (srcValue.includes(process.env.REACT_APP_FORM_LINK)) {
      if (srcValue?.includes("?uniqueid")) {
        let srcArray = srcValue?.split("?");
        returnValue = srcArray[0] + `?uniqueid=${user?.email}`;
        const parser = new DOMParser();
        const fullHtml = parser.parseFromString(iframeTag, "text/html");

        fullHtml.body.innerHTML =
          "<iframe src='" + returnValue + "' class='typeform-embed'></iframe>";

        return fullHtml.body.innerHTML;
      } else {
        returnValue = srcValue + `?uniqueid=${user?.email}`;
        const parser = new DOMParser();
        const fullHtml = parser.parseFromString(iframeTag, "text/html");

        fullHtml.body.innerHTML =
          "<iframe src='" + returnValue + "' class='typeform-embed'></iframe>";

        return fullHtml.body.innerHTML;
      }
    } else {
      return embededCode;
    }
  } else {
    return embededCode;
  }
};
const EmbedView = ({
  embed,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
  blockHeight,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(embed?.is_hidden);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && embed?.is_hidden ? (
        ""
      ) : (
        <div
          className={`embed-view 
      ${embed?.theme} ? ${embed?.theme} : "postBgColor"}
    `}
          style={{
            background: `${embed?.theme_type === "color" ? embed?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          <ArtView art={embed?.art} />
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={embed?._id}
              block={embed}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          {embed.title !== "" && <p className="block-title">{embed.title}</p>}
          <div
            className="block-embed"
            style={{ minHeight: `${embed.size.height}px` }}
            dangerouslySetInnerHTML={{
              __html: checkTypeForm(embed?.embed?.code, user),
            }}
          ></div>
          <style>
            {`
          .block-embed  iframe {
            height: ${embed.size.height}px ;
            min-height: inherit;
          }
          `}
          </style>
        </div>
      )}
    </>
  );
};

export default EmbedView;
