import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { CustomInputFile, InputBox } from "../../../../InputBox/InputBox";
import "./AddVideo.scss";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import { Box } from "@mui/material";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";

const AddVideo = ({
  type,
  updateOrder,
  block,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [text, setText] = useState(block?.title || "");
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [urlPath, seturlPath] = useState("");
  const { setCourseOne } = useOutletContext();

  useEffect(() => {
    if (block?.provider) {
      seturlPath(block?.provider?.url);
    }
  }, [block?.provider?.url]);

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();
  const toastRef = useRef();

  const uploadVideo = async (file) => {
    setSelectedFile(file);
    const formData = new FormData();
    formData.append("video", file);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload video
      const response = await POST(`${Constants.addBlock}/upload`, formData, header);
      seturlPath(response.data.urlPath);
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const validVideoTypes = ["video/mp4", "video/webm", "video/flv"];

    if (!validVideoTypes.includes(fileType)) {
      toastRef.current.showToastMessage("Invalid video format.", "error");
    } else {
      if (file.size >= 2048000) {
        toastRef.current.showToastMessage("File size too large.", "error");
      } else {
        uploadVideo(file);
      }
    }
  };
  const handleSubmit = async (event) => {
    if (!type) {
      if (selectedFile === null) {
        toastRef.current.showToastMessage("Please select the file", "error");
      }
    }
    if (text === "") {
      toastRef.current.showToastMessage("Please Enter Title", "error");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        file: {
          ext: selectedFile?.name?.split(".").pop(),
          size: selectedFile?.size,
        },
        type: type,
        provider: {
          name: "Framerspace",
          url: urlPath || block?.provider?.url,
        },
        title: text,
      };
      setDisable(true);
      //create video block
      await POST(`${Constants.addBlock}/file?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="videoBlock">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox placeholder="Title" value={text} onChange={(e) => setText(e.target.value)} />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Box>
      )}
      {!type && (
        <>
          <CustomInputFile
            title={selectedFile ? selectedFile.name : "Choose Video to upload"}
            icon={!selectedFile && true}
            className="fileUpload"
            onChange={handleFileChange}
            accept="video/mp4, video/webm, video/flv"
          />
          <p>
            Note : Accepted file format (MP4,WEBM,FLV) and File Size must be equal or less than 2MB.
          </p>
        </>
      )}

      {urlPath ? (
        <div className="video-preview">
          <h3>Video Preview:</h3>
          <video width="50%" controls>
            <source src={`${cloudfrontUrl}${urlPath}`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        ""
      )}

      <ButtonMain type="submit" className="buttonGreen" onClick={handleSubmit} disabled={disable}>
        Save Block
      </ButtonMain>
      <ToastNotify ref={toastRef} duration={4000} />
    </div>
  );
};

export default AddVideo;
