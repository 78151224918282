import React from "react";
import CourseBlock from "./CourseBlock";
import { CourseDetailsSkeleton } from "../Loader/Loader";
// import Navigate from "./Navigate/Navigate";

const CourseBlockView = ({
  completedModulesData,
  courseBlocks,
  loading,
  courseOne,
  userDetails,
  blockUserActivities,
}) => {
  return (
    <div>
      {loading ? (
        <CourseDetailsSkeleton />
      ) : (
        <>
          <CourseBlock
            courseOne={courseOne}
            blockDetails={courseBlocks}
            completedModulesData={completedModulesData}
            userDetails={userDetails}
            blockUserActivities={blockUserActivities}
          />
        </>
      )}
    </div>
  );
};

export default CourseBlockView;
