import { Box, Typography } from "@mui/material";
import React from "react";

const ScoreBubble = ({ obtainedMarks, maxMarks }) => {
  const CalculatePercentage = (obtained, maxMarks) => {
    let percentage = (obtained / maxMarks) * 100;
    if (isNaN(percentage)) {
      return 0;
    } else {
      return percentage.toFixed(1);
    }
  };

  const percentageColor = (percentage) => {
    if (percentage < 30) {
      return {
        bg: "#FFEBE6",
        font: "#951E00",
        border: "2px solid #a83a1f",
      };
    } else if (percentage >= 30 && percentage < 70) {
      return {
        bg: "#FFF7E0",
        font: "#8C6700",
        border: "2px solid #8C6700",
      };
    } else if (percentage >= 70) {
      return {
        bg: "#ECFAE1",
        font: "#006739",
        border: "2px solid #1b8354",
      };
    }
  };

  return (
    <Box className="moduleScoreCellWrapper">
      <Box className="moduletableCellWrapper">
        <Box
          className="moduleScoreWrapper"
          sx={{
            background: percentageColor(
              CalculatePercentage(obtainedMarks ? obtainedMarks : 0, maxMarks ? maxMarks : "-"),
            ).bg,
            // border: percentageColor(
            //   CalculatePercentage(obtainedMarks ? obtainedMarks : 0, maxMarks ? maxMarks : "-"),
            // ).border,
          }}
        >
          <Typography
            className="moduleScoreText"
            sx={{
              color: percentageColor(
                CalculatePercentage(obtainedMarks ? obtainedMarks : 0, maxMarks ? maxMarks : "-"),
              ).font,
            }}
          >
            {CalculatePercentage(obtainedMarks ? obtainedMarks : 0, maxMarks ? maxMarks : "-")}%
          </Typography>{" "}
          &nbsp;<span style={{ color: "#d8d8d8" }}>|</span> &nbsp;{" "}
          <Typography
            className="moduleScoreText"
            sx={{
              color: percentageColor(
                CalculatePercentage(obtainedMarks ? obtainedMarks : 0, maxMarks ? maxMarks : "-"),
              ).font,
            }}
          >
            ({obtainedMarks}/{maxMarks})
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ScoreBubble;
