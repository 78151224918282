import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getDialogTitle } from "../../../utils";
import { InputBox } from "../../InputBox/InputBox";

import "./EditBlock.scss";
import CustomizedSnackbars from "../../SnackBar/CustomizedSnackbars";
import AddRichText from "../CreateBlockModal/CreateBlocks/AddRichText/AddRichText";
import AddButton from "../CreateBlockModal/CreateBlocks/AddButton/AddButton";
import AddDivider from "../CreateBlockModal/CreateBlocks/AddDivider/AddDivider";
import AddToggleList from "../CreateBlockModal/CreateBlocks/AddToggleList/AddToggleList";
import AddImage from "../CreateBlockModal/CreateBlocks/AddImage/AddImage";
import AddWebBookmark from "../CreateBlockModal/CreateBlocks/AddWebBookmark/AddWebBookmark";
import AddGIF from "../CreateBlockModal/CreateBlocks/AddGIF/AddGIF";
import AddVideo from "../CreateBlockModal/CreateBlocks/AddVideo/AddVideo";
import AddDiscussion from "../CreateBlockModal/CreateBlocks/AddDiscussion/AddDiscussion";
import AddPasteABlock from "../CreateBlockModal/CreateBlocks/AddPasteABlock/AddPasteABlock";
import AddEmbedCode from "../CreateBlockModal/CreateBlocks/AddEmbededCode/AddEmbedCode";
import AddComicStrip from "../CreateBlockModal/CreateBlocks/AddComicStrip/AddComicStrip";
import AddGrid from "../CreateBlockModal/CreateBlocks/AddGrid/AddGrid";
import AddContainer from "../CreateBlockModal/CreateBlocks/AddContainer/AddContainer";
import AddList from "../CreateBlockModal/CreateBlocks/AddList/AddList";
import AddAudio from "../CreateBlockModal/CreateBlocks/AddAudio/AddAudio";
import {
  audioFileExtensions,
  fileExtensions,
  videoFileExtensions,
} from "../../../config/Constants";
import AddFile from "../CreateBlockModal/CreateBlocks/AddFile/AddFile";
import AddUploadFile from "../CreateBlockModal/CreateBlocks/AddUploadFile/AddUploadFile";
import AddAudioRecording from "../CreateBlockModal/CreateBlocks/AddAudioRecording/AddAudioRecording";
import AddVideoRecording from "../CreateBlockModal/CreateBlocks/AddVideoRecording/AddVideoRecording";
import AddTextResponse from "../CreateBlockModal/CreateBlocks/AddTextResponse/AddTextResponse";
import AddMCQ from "../CreateBlockModal/CreateBlocks/AddMCQ/AddMCQ";
import AddFillInTheBlanks from "../CreateBlockModal/CreateBlocks/AddFillInTheBlanks/AddFillInTheBlanks";
import AddMatch from "../CreateBlockModal/CreateBlocks/AddMatch/AddMatch";
import AddNativeEmbed from "../CreateBlockModal/CreateBlocks/AddNativeEmbed/AddNativeEmbed";
import FullScreenModal from "../CourseModals/FullScreenModal";
import { Container } from "@mui/material";
import AddBlockCarosal from "../CreateBlockModal/CreateBlocks/AddBlockCarosal/AddBlockCarosal";
import AddScormBlock from "../CreateBlockModal/CreateBlocks/AddScormBlock/AddScormBlock";
import AddSurveyMonkey from "../CreateBlockModal/CreateBlocks/AddSurveyMonkey/AddSurveyMonkey";
import AddFSForm from "../CreateBlockModal/CreateBlocks/AddFSForm/AddFSForm";

const EditBlock = ({ modalOpen, setmodalOpen, block, setCourseBlocks }) => {
  const savedCourseDetail = useSelector((state) => state.savedCourseDetail);
  let assessmentSettings = savedCourseDetail?.data?.assessmentDashboard;

  const [inputValue, setInputValue] = useState({
    order: block?.order || 1,
    checked: block?.is_hidden || false,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInputValue((prev) => ({
      ...prev,
      order: block?.order,
      checked: block?.is_hidden,
    }));
  }, [block]);
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(block?.slug);
      setOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <FullScreenModal
      open={modalOpen}
      setOpen={setmodalOpen}
      dialogTitle={getDialogTitle(block?.type, block)}
    >
      <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
        New block order:
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <InputBox
            value={inputValue.order}
            onChange={(e) => {
              setInputValue((prev) => ({ ...prev, order: e.target.value }));
            }}
            type="number"
          />
          <ContentCopyIcon
            onClick={handleCopy}
            sx={{
              "&:hover": {
                backgroundColor: "blue",
                color: "white",
                cursor: "pointer",
                borderRadius: "50%",
                height: "2.2rem",
                width: "2.2rem",
                padding: "5px",
              },
            }}
          />
        </Box>
        {/* Basic blocks */}
        {block?.type === "text" && (
          <AddRichText
            initialValue={block?.text}
            block_id={block?._id}
            blockSrcText={block?.srcText}
            type="edit"
            setCourseBlocks={setCourseBlocks}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "button" && (
          <AddButton
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "divider" && (
          <AddDivider
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "toggle_list" && (
          <AddToggleList
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {/* MEDIA */}
        {block?.type === "image" && (
          <AddImage
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "link" && (
          <AddWebBookmark
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "file" &&
          videoFileExtensions.includes(block?.provider?.url?.split(".").pop()) && (
            <AddVideo
              type="edit"
              setCourseBlocks={setCourseBlocks}
              block={block}
              updateOrder={inputValue.order}
              setblockModOpen={setmodalOpen}
            />
          )}
        {block?.type === "file" &&
          audioFileExtensions.includes(block?.provider?.url?.split(".").pop()) && (
            <AddAudio
              type="edit"
              setCourseBlocks={setCourseBlocks}
              block={block}
              updateOrder={inputValue.order}
              setblockModOpen={setmodalOpen}
            />
          )}
        {block?.type === "file" &&
          fileExtensions.includes(block?.provider?.url?.split(".").pop()) && (
            <AddFile
              type="edit"
              setCourseBlocks={setCourseBlocks}
              block={block}
              updateOrder={inputValue.order}
              setblockModOpen={setmodalOpen}
            />
          )}
        {block?.type === "gif" && (
          <AddGIF
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {/*USER INPUTS*/}
        {block?.type === "mcq" && (
          <AddMCQ
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            blockDetails={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "fill" && (
          <AddFillInTheBlanks
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            blockDetails={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "match" && (
          <AddMatch
            type="edit"
            setCourseBlocks={setCourseBlocks}
            blockDetails={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "response" && block?.response_type === "text" && (
          <AddTextResponse
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "response" && block?.response_type === "audio" && (
          <AddAudioRecording
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "response" && block?.response_type === "video" && (
          <AddVideoRecording
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "response" && block?.response_type === "file" && (
          <AddUploadFile
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "survey-monkey" && (
          <AddSurveyMonkey
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "fs-form" && (
          <AddFSForm
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "scorm" && (
          <AddScormBlock
            type="edit"
            assessmentSettings={assessmentSettings}
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {/* ADVANCE BLOCKS */}
        {block?.type === "list" && (
          <AddList
            type="edit"
            setCourseBlocks={setCourseBlocks}
            blockdetails={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "container" && (
          <AddContainer
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "carousel_container" && (
          <AddBlockCarosal
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "grid" && (
          <AddGrid
            type="edit"
            setCourseBlocks={setCourseBlocks}
            blockdetails={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "comic" && (
          <AddComicStrip
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "embed" && (
          <AddEmbedCode
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "native-embed" && (
          <AddNativeEmbed
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "copy" && (
          <AddPasteABlock
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        {block?.type === "discussion" && (
          <AddDiscussion
            type="edit"
            setCourseBlocks={setCourseBlocks}
            block={block}
            updateOrder={inputValue.order}
            setblockModOpen={setmodalOpen}
          />
        )}
        <CustomizedSnackbars
          AlertText="Block Copied Successfully"
          AlertType="success"
          open={open}
          onClose={() => setOpen(false)}
        />
      </Container>
    </FullScreenModal>
  );
};

export default EditBlock;
