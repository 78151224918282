import { ThemeProvider } from "@emotion/react";
import { Box, Button, Chip, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import "./FSComms.scss";

const FSCommsDatatable = ({ fsCommsList, handleFSCommsEdit }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableHead: {
          styleOverrides: {
            root: {
              // fontWeight: 600,
              // textAlign: "left",
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // Text: {
              // textAlign: "center",
              // },
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
      },
    });
  const columns = [
    {
      name: "Title",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={0} onClick={() => updateDirection(0)} className="fsComms_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "FSComms For",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={1} onClick={() => updateDirection(1)} className="fsComms_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Active",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={2} onClick={() => updateDirection(2)} className="fsComms_table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <Box display={"flex"} justifyContent="center" className="">
            {value ? (
              <Chip
                label="True"
                size="small"
                className="active_badge"
                color="info"
                variant="contained"
              />
            ) : (
              <Chip
                label="False"
                size="small"
                className="inactive_badge"
                color="info"
                variant="contained"
              />
            )}
          </Box>
        ),
      },
    },
    {
      name: "Call to Action Text",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={3} onClick={() => updateDirection(3)} className="fsComms_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Call to Action Link",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={4} onClick={() => updateDirection(4)} className="fsComms_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Activation",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={5} onClick={() => updateDirection(5)} className="fsComms_table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Box display={"flex"} justifyContent="start" className="">
            <Button variant="outlined" onClick={() => handleFSCommsEdit(value)}>
              Edit
            </Button>
          </Box>
        ),
      },
    },
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    filterType: "dropdown",
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    elevation: 5,
  };

  const data = fsCommsList?.map((el) => {
    return [el?.title, el?.fsCommsFor, el?.is_active, el?.cta_text, el?.cta_link, el];
  });

  return (
    <>
      <Box mt={5}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable title={""} data={data} columns={columns} options={options} />
        </ThemeProvider>
      </Box>
    </>
  );
};

export default FSCommsDatatable;
