import React from "react";
import UserCard from "./UserCard";
import EnrolledCourses from "./EnrolledCourses";
import { useEffect } from "react";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useState } from "react";
import { useSelector } from "react-redux";
import CompletedCourses from "./CompletedCourses";
import FeaturedCourses from "./FeaturedCourses";
import { Container } from "@mui/material";
import FSCommsModal from "../../FSComms/FSCommsModal";
import { EnrolledCoursesLoader } from "../../Loader/Loader";

const LandingPage = () => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [currentEnrolledPage, setCurrentEnrolledPage] = useState(1);
  const [currentCompletedPage, setCurrentCompletedPage] = useState(1);
  const [enrolledCourseLength, setEnrolledCourseLength] = useState(true);
  const [completedCourseLength, setCompletedCourseLength] = useState(true);
  const [disableEnrolledLoadButton, setDisableEnrolledLoadButton] = useState(false);
  const [disableCompletedLoadButton, setDisableCompletedLoadButton] = useState(false);
  const [enrolledLoader, setEnrolledLoader] = useState(true);
  const [completedLoader, setCompletedLoader] = useState(true);
  let UserData = useSelector((state) => state.userLogin);

  useEffect(() => {
    setDisableEnrolledLoadButton(true);
    //get enrolled courses with pagination
    GET(
      `${Constants.courseManage}/enrolled/${UserData?.data?.user?.id}?page=${currentEnrolledPage}`,
    )
      .then((res) => {
        res?.data?.courses?.length < 6 && setEnrolledCourseLength(false);
        setEnrolledLoader(false);
        setEnrolledCourses((prev) => [...prev, ...res?.data?.courses]);
        setDisableEnrolledLoadButton(false);
      })
      .catch((err) => {
        setDisableEnrolledLoadButton(false);
        setEnrolledCourseLength(false);
        setEnrolledLoader(false);
      });
  }, [UserData, currentEnrolledPage]);

  useEffect(() => {
    setDisableCompletedLoadButton(true);
    //get completed courses with pagination
    GET(
      `${Constants.courseManage}/completed/${UserData?.data?.user?.id}?page=${currentCompletedPage}`,
    )
      .then((res) => {
        res?.data?.courses?.length < 6 && setCompletedCourseLength(false);
        setCompletedCourses((prev) => [...prev, ...res?.data?.courses]);
        setCompletedLoader(false);
        setDisableCompletedLoadButton(false);
      })
      .catch((err) => {
        setCompletedCourseLength(false);
        setDisableCompletedLoadButton(false);
        setCompletedLoader(false);
      });
  }, [UserData, currentCompletedPage]);

  const fsCommsFor = ["logged_in_users", "all"];
  return (
    <div>
      <FSCommsModal fsCommsFor={fsCommsFor} />
      <UserCard />
      <div className={"enroll_completed_courses"}>
        <Container maxWidth="xl" sx={{ width: "97%" }}>
          {enrolledLoader ? (
            <EnrolledCoursesLoader />
          ) : (
            <>
              {enrolledCourses.length > 0 && (
                <EnrolledCourses
                  enrolledCourses={enrolledCourses}
                  setPage={setCurrentEnrolledPage}
                  enrolledCourseLength={enrolledCourseLength}
                  disableEnrolledLoadButton={disableEnrolledLoadButton}
                />
              )}
            </>
          )}
          {completedLoader ? (
            <EnrolledCoursesLoader />
          ) : (
            <>
              {completedCourses.length > 0 && (
                <CompletedCourses
                  completedCourses={completedCourses}
                  setPage={setCurrentCompletedPage}
                  completedCourseLength={completedCourseLength}
                  disableCompletedLoadButton={disableCompletedLoadButton}
                />
              )}
            </>
          )}
        </Container>
      </div>
      <Container maxWidth="xl" sx={{ width: "97%" }}>
        <FeaturedCourses />
      </Container>
    </div>
  );
};

export default LandingPage;
