import React, { useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const AddDiscussion = ({
  type,
  block,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [formData, setFormData] = useState({
    title: block?.title || "",
    description: block?.text || "",
  });
  const [errText, setErrText] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    if (formData.title === "") {
      setOpen(true);
      setErrText("Please Enter Title.");
    } else if (formData.description === "") {
      setOpen(true);
      setErrText("Please Enter Description.");
    } else {
      setDisable(true);
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        text: formData.description,
        title: formData.title,
        type: type,
      };
      // create discussion block
      await POST(`${Constants.addBlock}/discussion?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox name="title" placeholder="Title" value={formData.title} onChange={handleChange} />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Box>
      )}
      <br />
      <CustomTextArea
        name="description"
        placeholder="Description"
        value={formData.description}
        onChange={handleChange}
      />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcText}
        </Box>
      )}
      <br />
      <ButtonMain className="buttonGreen" disabled={disable} onClick={handleSave}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType="error"
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddDiscussion;
