import { ThemeProvider } from "@emotion/react";
import { Box, createTheme } from "@mui/material";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React from "react";

import "./PaymentDashboard.scss";

const PaymentDataTable = ({ paymentList, courseTitle }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableHead: {
          styleOverrides: {
            root: {
              fontWeight: 600,
              textAlign: "center",
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
              // border: "1px solid black",
            },
          },
        },
      },
    });
  const columns = [
    {
      name: "Payment ID",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={0} onClick={() => updateDirection(1)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Cohort Title",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={1} onClick={() => updateDirection(1)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Date",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={2} onClick={() => updateDirection(2)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Learner Unique ID",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={3} onClick={() => updateDirection(3)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Currency",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={4} onClick={() => updateDirection(4)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Price",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={5} onClick={() => updateDirection(5)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "Status",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={6} onClick={() => updateDirection(6)} className="payment_table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <Box display={"flex"} justifyContent="center" className="">
            <span
              className={`${
                value === "COMPLETED"
                  ? "completed_badge"
                  : value === "CREATED"
                  ? "created_badge"
                  : "cancelled_badge"
              } `}
            >
              {value}
            </span>
          </Box>
        ),
      },
    },
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    filterType: "dropdown",
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    elevation: 5,
  };

  const data = paymentList?.map((el) => {
    const updatedAt = moment(el?.updatedAt).format("DD MMM, YYYY");
    return [
      el?._id,
      `${el?.cohort_id?.title} (${el?.cohort_id?.type})`,
      updatedAt,
      el?.user_id?.email,
      el?.currency,
      el?.price_id?.price,
      el?.status,
    ];
  });

  return (
    <>
      <Box mt={5}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable title={courseTitle} data={data} columns={columns} options={options} />
        </ThemeProvider>
      </Box>
    </>
  );
};

export default PaymentDataTable;
