import React, { useState, useEffect } from "react";
import { Box, Button, Select, MenuItem, CircularProgress, Typography } from "@mui/material";
import { GET } from "../../config/Api";
import { Constants } from "../../config/Constants"; // Make sure this import path is correct
import FMModal from "../ModalMain/CourseModals/FMModal";

const ContainerSelectModal = ({
  open,
  handleClose,
  handleConfirm,
  cid,
  container,
  currentBlock,
}) => {
  const [containerBlocks, setContainerBlocks] = useState([]);
  const [selectedContainers, setSelectedContainers] = useState(
    currentBlock?.parentContainers ? currentBlock.parentContainers : [],
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchContainerBlocks();
    }
  }, [cid, open]);

  const fetchContainerBlocks = async () => {
    try {
      setLoading(true);
      const response = await GET(
        `${Constants.getModuleContainerByCourseOrContainer}/${cid}/${container}`,
      ); // Update the endpoint as needed
      const filteredBlocks = response.data.blocks.filter(
        (block) => block._id !== currentBlock?._id,
      );
      setContainerBlocks(filteredBlocks);
    } catch (error) {
      console.error("Failed to fetch container blocks", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    handleConfirm(selectedContainers);
  };

  const handleSelectChange = (event) => {
    setSelectedContainers(event.target.value);
  };

  return (
    <FMModal
      modalOpen={open}
      modalClose={handleClose}
      modalHeader={"Select the predecessor module's"}
      className="confirm_modal"
    >
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography>
              {" "}
              Note: The current module is enabled if and only if all the predecessor modules are
              completed and locked.
            </Typography>
            <Select
              multiple
              value={selectedContainers}
              onChange={handleSelectChange}
              fullWidth
              sx={{ my: 2, width: "80%" }}
            >
              {containerBlocks?.length > 0 ? (
                containerBlocks?.map((block) => (
                  <MenuItem key={block._id} value={block._id}>
                    {block.title}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>Nothing to Choose from</MenuItem>
              )}
            </Select>
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                //disabled={selectedContainers.length === 0}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </FMModal>
  );
};

export default ContainerSelectModal;
