import React, { useContext } from "react";
import Login from "./Login";
import Signup from "./Signup";
import Forgot from "./Forgot";
import { AuthContext } from "../../App";
import "./modal.scss";

const AuthModals = () => {
  const { authStates } = useContext(AuthContext);
  return (
    <>
      {authStates.loginOpen && <Login />}
      {authStates.signupOpen && <Signup />}
      {authStates.forgotOpen && <Forgot />}
    </>
  );
};

export default AuthModals;
