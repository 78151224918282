import React, { useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { useSelector } from "react-redux";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { POST } from "../../../../config/Api";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import { Constants } from "../../../../config/Constants";
import { isLearnerView, useQueryParams } from "../../../../utils";
import ButtonMain from "../../../ButtonMain";
import BlockActions from "../BlockActions/BlockActions";
import "./VideoView.scss";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import { CircularProgress } from "@mui/material";
import { useLocation, useOutletContext } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sendDataToFirehose from "../../../../utils/aws-utils";
import clickEvent from "../../../../utils/logging-event-utils";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const VideoView = ({
  video,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  block,
}) => {
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);

  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div
          className={` container-block   ${
            isLoggedIn ? `${video?.theme} postBgColor` : `${video?.theme} defaultStyle`
          }
      `}
          style={{
            background: `${video?.theme_type === "color" ? video?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={video?._id}
              block={video}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}
          {video?.title && <p className="block-title">{video?.title}</p>}
          <div className="video-block">
            <video className="video-js view-video" width="960" height="540" preload="auto" controls>
              <source src={video?.provider?.url} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </>
  );
};

const VideoRecord = ({ block, blockUserActivities, disableBlock }) => {
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [loading, setLoading] = useState(false);

  const toastRef = useRef();

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let moduleDetails = useSelector((state) => state.savedModuleDetail);
  const { courseOne, completedModulesData } = useOutletContext();
  let { cid } = useQueryParams();

  let location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/course/createblock")) {
      setDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    const video = blockUserActivities?.data?.find((el) => el.block_id === block._id);
    setVideoURL(`${cloudfrontUrl}${video?.attachments[0]?.url}`);
  }, [blockUserActivities?.data, block._id]);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    if (isLoggedIn) {
      setIsActive(false);
      setCounter(0);
      setSecond("00");
      setMinute("00");
      setVideoURL("");
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
    }
  }
  const { startRecording, stopRecording, pauseRecording, mediaBlobUrl } = useReactMediaRecorder({
    video: true,
    audio: true,
    echoCancellation: true,
  });

  const handleVideoSave = async () => {
    if (isLoggedIn) {
      if (mediaBlobUrl === undefined) {
        toastRef.current.showToastMessage("Please Record the Video .", "error");
      } else {
        const mediaBlob = await fetch(mediaBlobUrl).then((response) => response.blob());

        const myFile = new File([mediaBlob], "video_recording.mp3", {
          type: "video/mp4",
        });
        const formData = new FormData();
        formData.append("video", myFile);
        formData.append("user_id", user?.id);
        formData.append("course_id", cid);
        formData.append("cohort_id", "");
        setDisabled(true);
        setLoading(true);
        let header = "multipart/form-data";
        try {
          //post api to upload video file
          await POST(
            `${Constants.getPublicCourseBlock}/upload/file?block_id=${block._id}`,
            formData,
            header,
          );
          toastRef.current.showToastMessage("Video Uploaded Successfully", "success");
          triggerUserEvent(`${videoURL ? "save" : "update"}`);
          setDisabled(false);
          setLoading(false);
          //save user last activity
          POST(
            `${Constants.userActivity}?activity=${
              videoURL ? "blockActivityUpdate" : "blockActivitySave"
            }`,
            {
              user: user,
            },
          );
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
    }
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = "recording";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails?.data?.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response =
        blockUserActivities?.data?.find((el) => el.block_id === block._id)?.attachments[0]?.url ||
        "";
      eventData.block_type = block?.type;
      eventData.recording_type = block?.response_type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  return (
    <div className={disableBlock ? "disableBlock" : ""}>
      <video src={videoURL || mediaBlobUrl} controls loop className="video-tag" />

      <div className="video-recording-contents">
        <ButtonMain
          className="clear-btn"
          onClick={stopTimer}
          disabled={disableBlock || !isLoggedIn}
        >
          Clear
        </ButtonMain>
        <div className={`timer-contents ${isLoggedIn ? "postTimer" : ""}`}>
          <span className="minute">{minute}</span>
          <span>:</span>
          <span className="second">{second}</span>
        </div>

        <div>
          <label className="label-tag" htmlFor="icon-button-file">
            <h3>Press the Start to record</h3>

            <div>
              <ButtonMain
                className="active-button"
                disabled={disableBlock || !isLoggedIn}
                onClick={() => {
                  if (isLoggedIn) {
                    if (!isActive) {
                      startRecording();
                      triggerUserEvent("started");
                    } else {
                      pauseRecording();
                      triggerUserEvent("paused");
                    }
                    setIsActive(!isActive);
                  } else {
                    window.open(
                      `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
                    );
                  }
                }}
              >
                {isActive ? "Pause" : "Start"}
              </ButtonMain>
              <ButtonMain
                className="stop-button"
                disabled={disableBlock || !isLoggedIn}
                onClick={() => {
                  if (isLoggedIn) {
                    stopRecording();
                    stopTimer();
                    pauseRecording();
                    triggerUserEvent("ended");
                  } else {
                    window.open(
                      `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
                    );
                  }
                }}
              >
                Stop
              </ButtonMain>
              <ButtonMain
                className="save-button"
                onClick={handleVideoSave}
                disabled={disabled || disableBlock || !isLoggedIn}
                style={{ padding: `${loading ? "5px 25px" : ""}` }}
              >
                {loading ? <CircularProgress size={17} /> : "Save"}
              </ButtonMain>
            </div>
          </label>
        </div>
        <b></b>
      </div>
      <ToastNotify ref={toastRef} duration={3000} />
    </div>
  );
};

export { VideoView, VideoRecord };
