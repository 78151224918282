import React from "react";
import "./Breathing.scss";

const Breathing = () => {
  return (
    <div className="breathing-block">
      <div className="circle_all">
        <div className="circle_A"></div>
        <div className="circle_B"></div>
        <div className="circle_C"></div>
        <div className="circle_D"></div>
        <div className="circle_E"></div>
      </div>
    </div>
  );
};

export default Breathing;
