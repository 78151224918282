import React, { useState, useRef } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  IconButton,
} from "@mui/material";
import "./LearnersModal.scss";
import { useEffect } from "react";
import { GET, PUT } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { useSelector } from "react-redux";
import { ToastNotify } from "../SnackBar/ToastNotify";
import { Container } from "@mui/material";
import FullScreenModal from "../courses/CourseModals/FullScreenModal";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const LearnersModal = ({
  showLearnerModal,
  setShowLearnerModal,
  title,
  addLearnersData,
  cohortType,
}) => {
  let UserData = useSelector((state) => state.userLogin.data);
  const [learnersList, setLearnersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();

  useEffect(() => {
    setLoading(true);
    //get learner list
    UserData?.isLoggedIn &&
      addLearnersData?.courseId &&
      GET(
        `${Constants.getLearnerRequests}/${addLearnersData?.courseId}/${addLearnersData?.cohort_id}?cohort_type=${cohortType}`,
      )
        .then((res) => {
          setLoading(false);
          setLearnersList(res?.data?.data);
        })
        .catch((err) => {
          setLoading(false);
        });
  }, [addLearnersData]);

  const approveLearnerRequest = (userCourseActivityId) => {
    setLoading(true);
    //Approve learner request
    UserData?.isLoggedIn &&
      userCourseActivityId &&
      PUT(`${Constants.approveLearnerRequests}/${userCourseActivityId}`)
        .then((res) => {
          setLoading(false);
          console.log("res?.data", res?.data);
          toastRef.current.showToastMessage(res?.data?.message, "success");
          setLearnersList((prev) => {
            const index = prev.findIndex((el) => el._id === res?.data?._id);
            prev.splice(index, 1);
            return prev;
          });
        })
        .catch((err) => {
          setLoading(false);
          toastRef.current.showToastMessage(err?.data?.message, "success");
        });
  };
  return (
    <div className="fm_modal_container">
      <ToastNotify ref={toastRef} duration={4000} />

      <FullScreenModal
        open={showLearnerModal}
        setOpen={() => {
          setLearnersList([]);
          setShowLearnerModal(false);
        }}
        dialogTitle={
          <>
            <IconButton color="inherit">
              <HowToRegIcon />
            </IconButton>
            Approve learner request
          </>
        }
      >
        {/* <FMModal
        modalOpen={showLearnerModal}
        modalClose={() => {
          setLearnersList([]);
          setShowLearnerModal(false);
        }}
        modalHeader={"Approve learner request"}
        className="add_learner_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(90deg, #E3EEFF, #F3E7E9)",
        }}
      > */}
        <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
          {loading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: 999 }}
              open={loading}
              onClick={() => setLoading(false)}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <List
            sx={{ width: "100%", overflow: "auto", maxHeight: "400px" }}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <Typography variant="body1" component={"strong"}>
                  {title || "Requests"}
                </Typography>
              </ListSubheader>
            }
          >
            {learnersList?.length > 0 ? (
              learnersList.map((learner) => {
                return (
                  <ListItem
                    key={learner?.user_id?.email}
                    className="margin-top-2 bg-white"
                    disablePadding
                  >
                    <ListItem>
                      <ListItemIcon>
                        <Box
                          height={40}
                          width={40}
                          display="flex"
                          alignItems={"center"}
                          justifyContent="center"
                          bgcolor={"#f5f5f5"}
                          borderRadius={"50%"}
                        >{`${learner?.user_id?.initials}`}</Box>
                      </ListItemIcon>
                      <ListItemText primary={`${learner?.user_id?.email}`} />
                      <Button
                        color="info"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          approveLearnerRequest(learner?._id);
                        }}
                      >
                        Approve
                      </Button>
                    </ListItem>
                  </ListItem>
                );
              })
            ) : (
              <ListItem className="margin-top-2 bg-white">
                <Typography variant="subtitle1" sx={{ textAlign: "center", width: "100%" }}>
                  No pending requests.
                </Typography>
              </ListItem>
            )}
          </List>
        </Container>
      </FullScreenModal>
    </div>
  );
};

export default LearnersModal;
