import React from "react";
import { Box } from "@mui/material";
import ButtonMain from "../ButtonMain";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PreLoginHeader = () => {
  let navigate = useNavigate();
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  const location = useLocation();

  const handleScroll = () => {
    if (location.pathname === "/" && !isLoggedIn) {
      document.getElementById("support-section").scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/support");
    }
  };

  return (
    <Box className="navBar" sx={{ alignItems: "center", gap: "1rem" }}>
      {/* <ButtonMain variant="transparent help-btn" onClick={() => navigate("/")}>
        Home
      </ButtonMain> */}
      <ButtonMain variant="transparent help-btn" onClick={handleScroll}>
        Help
      </ButtonMain>
      {/* <HomeOutlinedIcon
        sx={{ display: { xs: "block", md: "none" }, color: "#f7f7f7" }}
        onClick={() => navigate("/")}
      /> */}
      <HelpOutlineIcon
        sx={{ display: { xs: "block", md: "none" }, color: "#f7f7f7" }}
        onClick={() => navigate("/support")}
      />
      <ButtonMain
        variant="secondary"
        onClick={
          () =>
            window.location.replace(
              `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location}`,
            )
          // setAuthStates((prev) => ({
          //   ...prev,
          //   loginOpen: true,
          //   modalOpen: true,
          //   forgotOpen: false,
          //   signupOpen: false,
          // }))
        }
      >
        LogIn
      </ButtonMain>
      <ButtonMain
        variant="primary"
        onClick={() =>
          // setAuthStates((prev) => ({
          //   ...prev,
          //   signupOpen: true,
          //   modalOpen: true,
          //   loginOpen: false,
          //   forgotOpen: false,
          // }))
          window.location.replace(
            `${process.env.REACT_APP_AUTH_URL_FE}signup?referrer=${window.location}`,
          )
        }
      >
        SignUp
      </ButtonMain>
    </Box>
  );
};

export default PreLoginHeader;
