import React from "react";
import { TextareaAutosize } from "@mui/material";
import "./CustomTextArea.scss";

const CustomTextArea = ({ placeholder, value, onChange, name, disabled }) => {
  return (
    <TextareaAutosize
      aria-label="minimum height"
      minRows={3}
      name={name}
      placeholder={placeholder}
      className="textArea"
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default CustomTextArea;
