import React, { useEffect, useState } from "react";
import HeaderContainer from "../../HeaderContainer/HeaderContainer";
import PageHeader from "../../PageHeader";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useParams } from "react-router-dom";

import "./PaymentDashboard.scss";
import PaymentDataTable from "./PaymentDataTable";
import { Container } from "@mui/material";

const PaymentDashboard = () => {
  const [paymentsData, setPaymentsData] = useState([]);
  const { courseID } = useParams();

  useEffect(() => {
    fetchPaymentsListByCourseID();
  }, []);

  const fetchPaymentsListByCourseID = async () => {
    try {
      //get payment list by course id
      const res = await GET(`${Constants.getPaymentDetails}/list/${courseID}`);
      setPaymentsData(res.data.paymentInfo);
    } catch (error) {
      console.log(error.res.data);
    }
  };
  return (
    <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
      <HeaderContainer>
        <PageHeader headerText="Payments Dashboard" paragraphText="" />
        <PaymentDataTable
          paymentList={paymentsData}
          courseTitle={paymentsData[0]?.course_id?.title}
        />
        {/* <TableContainer component={Paper} sx={{ mt: 2, whiteSpace: "nowrap" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight={600}>Date</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Payment ID</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Cohort Title</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Learner</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Currency</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Price</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={600}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsData.map((payment) => (
              <TableRow
                key={payment.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {moment(payment.updatedAt).format("DD MMM, YYYY")}
                </TableCell>
                <TableCell>{payment._id}</TableCell>
                <TableCell>{payment.cohort_id.title}</TableCell>
                <TableCell>{payment.user_id.email}</TableCell>
                <TableCell>{payment.currency}</TableCell>
                <TableCell>$ {payment.price_id.price}</TableCell>
                <TableCell>
                  <span
                    className={`${
                      payment.status === "COMPLETED"
                        ? "completed_badge"
                        : payment.status === "CREATED"
                        ? "created_badge"
                        : "cancelled_badge"
                    } `}
                  >
                    {payment.status}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      </HeaderContainer>
    </Container>
  );
};

export default PaymentDashboard;
