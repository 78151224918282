import { Box, Container, Grid } from "@mui/material";
import React from "react";
import VerifyCertificateModal from "../ModalMain/VerifyCertificateModal";
import { mgiepUrl } from "../../config/BaseUrl";
import "./footer.scss";
import { useNavigate } from "react-router-dom";
import mgieplogo from "../../assets/MGIEP_REVERSE WHITE LOGO.png";
import { useSelector } from "react-redux";

const Footer = () => {
  const [verifyCertOpen, setVerifyCertOpen] = React.useState(false);
  // const { setAuthStates } = useContext(AuthContext);
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  let navigate = useNavigate();
  return (
    <Box sx={{ background: "#355492", color: "#fff" }}>
      <Container maxWidth="xl" sx={{ width: "97%", py: 1 }}>
        <Grid container className="footerMain">
          <Grid
            item
            xs={12}
            md={6}
            className="footerMGIEP"
            onClick={() => window.open(mgiepUrl, "_blank")}
          >
            <Box className="footer-made">
              <img
                // src={`${Constants.imgPath}/mgiep-logo.png`}
                src={mgieplogo}
                alt="unesco_logo"
              />
            </Box>
            <Box>A product by UNESCO MGIEP</Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              marginTop: { xs: "1rem", md: "0" },
              marginBottom: { xs: "5rem", md: "0" },
            }}
            className="footerJustifyRight"
          >
            {!isLoggedIn && (
              <>
                <span
                  className="footerTxt"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
                    );
                    // setAuthStates((prev) => ({
                    //   ...prev,
                    //   loginOpen: true,
                    //   modalOpen: true,
                    //   forgotOpen: false,
                    //   signupOpen: false,
                    // }))
                  }}
                >
                  Login
                </span>
                <span
                  className="footerTxt"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
                    );

                    // setAuthStates((prev) => ({
                    //   ...prev,
                    //   signupOpen: true,
                    //   modalOpen: true,
                    //   loginOpen: false,
                    //   forgotOpen: false,
                    // }))
                  }}
                >
                  Sign Up
                </span>
              </>
            )}
            <span className="footerTxt" onClick={() => setVerifyCertOpen(true)}>
              Verify Certificate
            </span>
            <span className="footerTxt" onClick={() => navigate("/credits")}>
              Credits
            </span>
            <span className="footerTxt" onClick={() => navigate("/support")}>
              Contact Us
            </span>
          </Grid>
        </Grid>
        <VerifyCertificateModal
          verifyCertOpen={verifyCertOpen}
          verifyClose={() => setVerifyCertOpen(false)}
        />
      </Container>
    </Box>
  );
};

export default Footer;
