import React from "react";
import { useState } from "react";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import BlockActions from "../BlockActions/BlockActions";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const ComicView = ({
  text,
  image,
  courseDetails,
  block,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <Box
          position={"relative"}
          className="container-block comic-block"
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={image?._id}
              block={block}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          {(text !== "" || text !== null) && (
            <p className="block-text" dangerouslySetInnerHTML={{ __html: text }}></p>
          )}
          {image && (
            <div className="comic-image">
              <img
                src={image?.l?.startsWith(cloudfrontUrl) ? image?.l : cloudfrontUrl + image?.l}
                alt="img"
                width="100%"
              />
            </div>
          )}
        </Box>
      )}
    </>
  );
};

export default ComicView;
