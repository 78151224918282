import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import ButtonMain from "../../../../ButtonMain";
import "./AddList.scss";
import { CheckboxInput, CustomInputFile, InputBox } from "../../../../InputBox/InputBox";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import { useQueryParams } from "../../../../../utils";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import { Box, Chip } from "@mui/material";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useOutletContext } from "react-router-dom";
import swal from "sweetalert";

const AddList = ({
  type,
  updateOrder,
  blockdetails,
  setblockModOpen,
  setCourseBlocks,
  setmodalOpen,
  blockModalOpen,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [blockToBeAdded, setBlockToBeAdded] = useState({});
  const [list, setList] = useState({
    title: blockdetails?.title || "",
    description: blockdetails?.text || "",
    itemText: "",
    isRight: false,
  });
  const toastRef = useRef();
  const { setCourseOne } = useOutletContext();

  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [urlPath, seturlPath] = useState({});
  const [block, setBlock] = useState({});
  const [listItems, setlistItems] = useState(blockdetails?.items || []);
  const [isImage, setIsimage] = useState(false);

  const handleChange = (event) => {
    setIsimage(event.target.checked);
  };

  const blockidRef = useRef(block?._id || blockdetails?._id); // Use a ref to store block id
  const [disableButn, setDisableButton] = useState(false);
  let UserData = useSelector((state) => state.userLogin);
  const { cid, mid } = useQueryParams();

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload image
      const response = await POST(`${Constants.addBlock}/uploadImage`, formData, header);
      seturlPath(response?.data?.image);
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNextStep = async () => {
    if (list.title === "") {
      toastRef.current.showToastMessage("Please Enter Title.", "error");
    } else if (list.description === "") {
      toastRef.current.showToastMessage("Please Enter Description.", "error");
    } else {
      try {
        setDisableButton(true);
        setDisable(true);
        let body = {
          _id: blockidRef?.current ? blockidRef?.current : "",
          user_id: UserData?.data?.user?.id,
          course: cid,
          container: mid,
          order: blockModalOpen?.addBelow
            ? blockModalOpen?.currentBlockOrder + 1
            : updateOrder || 1,
          title: list.title,
          text: list.description,
          type: type,
        };
        //add list block
        const response = await POST(`${Constants.addBlock}/list?_id=${blockidRef.current}`, body);
        // setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));

        const newBlock = response?.data;
        setBlock(newBlock);
        setCourseBlocks((prevBlocks) => {
          const updatedBlocks = prevBlocks.map((prevBlock) =>
            prevBlock._id === (blockdetails?._id || response?.data?._id)
              ? response?.data
              : prevBlock,
          );
          return updatedBlocks;
        });
        setCourseOne((prev) => ({ ...prev, published: false }));
        blockidRef.current = newBlock?._id;
        return newBlock;
      } catch (error) {
        console.log(error);
        return null; // Return null if an error occurs
      } finally {
        setDisable(false);
        setDisableButton(false);
      }
    }
  };

  const handleAddItem = async () => {
    if (list.itemText === "") {
      toastRef.current.showToastMessage("Please Enter Text.", "error");
      return;
    }
    try {
      if (isImage && !selectedFile) {
        toastRef.current.showToastMessage(
          "Please upload an image for the option or turn off the image toggle.",
          "error",
        );
        return;
      }
      // Check if block or blockDetails IDs are not present, then call handleNext

      let currentBlock = blockidRef.current ? block : await handleNextStep();

      // If block ID is still not present after handleNext, do not proceed
      if (!currentBlock) {
        return;
      }
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        image: isImage ? urlPath : {},
        is_right: list.isRight,
        text: list.itemText,
        bound: 112,
        item_type: "text",
      };

      const response = await POST(
        `${Constants.updateBlock}/${blockidRef.current}/add_list_item`,
        body,
      );
      if (type !== "edit") {
        setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
      }
      setCourseOne((prev) => ({ ...prev, published: false }));

      // setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
      setList((prevList) => ({
        ...prevList,
        itemText: "",
      }));
      setlistItems(response?.data?.items);
      toastRef.current.showToastMessage("Item Added Successfully.", "success");

      setSelectedFile(null);
      seturlPath({});
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveItem = async (itemId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let payload = {
          user_id: UserData?.data?.user?.id,
          course: cid,
          option: itemId,
        };
        setDisable(true);
        // Remove the item from the block
        let response = await POST(
          `${Constants.updateBlock}/${blockidRef.current}/remove_option`,
          payload,
        );
        setlistItems(response.data.items);
        setDisable(false);
        //setBlockToBeAdded((prev) => ({ ...prev, ...response?.data }));
        setCourseOne((prev) => ({ ...prev, published: false }));
        swal("Item Removed Successfully", {
          icon: "success",
        });
      } else {
        swal("The item was not deleted.", {
          icon: "error",
        });
      }
    });
  };

  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={blockdetails?.srcText}
        srcTitleText={blockdetails?.srcTitleText}
      />
      <InputBox
        placeholder="Title"
        value={list.title}
        onChange={(e) => setList({ ...list, title: e.target.value })}
        disabled={disable}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockdetails?.srcTitleText}
        </Box>
      )}
      <br />
      <CustomTextArea
        placeholder="Description"
        value={list.description}
        disabled={disable}
        onChange={(e) => setList({ ...list, description: e.target.value })}
      />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockdetails?.srcText}
        </Box>
      )}
      <br />
      {/* {!show && (
        <ButtonMain className="buttonGreen" onClick={handleNextStep}>
          Next Step
        </ButtonMain>
      )}
      <br />
      {show && ( */}
      <>
        <b>Create New Item :</b> <br />
        <InputBox
          placeholder="Item Text"
          value={list.itemText}
          onChange={(e) => setList({ ...list, itemText: e.target.value })}
        />{" "}
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={isImage}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Add Image"
        />
        {isImage &&
          (Object?.keys(urlPath).length ? (
            <img src={cloudfrontUrl + urlPath?.m} height="150px" width="150px" alt="listImage" />
          ) : (
            <CustomInputFile
              title={selectedFile ? selectedFile.name.substring(0, 13) : "Optional Image"}
              onChange={handleFileChange}
              accept="image/*"
            />
          ))}
        <CheckboxInput
          className="checkBoxInputMain"
          label="Is right?"
          value={!list?.isRight}
          onChange={(e) => setList({ ...list, isRight: !list.isRight })}
        />
        <br />
        <ButtonMain className="buttonGreen" onClick={handleAddItem}>
          Add Item
        </ButtonMain>{" "}
        <br />
        {listItems && (
          <>
            <b>Items:</b> <br />
            {listItems?.map((item) => (
              <>
                <Box
                  key={item?._id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "10px 0px",
                  }}
                >
                  <Box sx={{ fontWeight: 600 }}>{item?.text}</Box>
                  <Box>
                    {item?.image && (
                      <img
                        src={cloudfrontUrl + item?.image?.m}
                        alt="img"
                        height="70px"
                        width="100px"
                      />
                    )}
                  </Box>
                  <Box sx={{ fontWeight: 600 }}>
                    <Chip
                      label="Remove"
                      variant="outlined"
                      disabled={disable}
                      onClick={() => handleRemoveItem(item?._id)}
                    />
                  </Box>
                </Box>
              </>
            ))}
          </>
        )}
        <br />
        <ButtonMain
          className="buttonGreen"
          disabled={disableButn}
          onClick={async () => {
            const blockId = await handleNextStep(); // Wait for handleNextStep to complete
            if (!blockId) return;
            if (type === "edit") {
              setCourseBlocks &&
                setCourseBlocks((prevBlocks) => {
                  const updatedBlocks = prevBlocks.map((prevBlock) =>
                    prevBlock._id === blockToBeAdded._id ? blockToBeAdded : prevBlock,
                  );
                  return updatedBlocks;
                });
            } else {
              if (blockModalOpen?.addBelow) {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    const updatedBlocks = prev.map((el) => {
                      if (el.order >= blockId?.order) {
                        el.order += 1;
                      }
                      return el;
                    });
                    const elIndex = updatedBlocks.findIndex(
                      (el) => el._id === blockModalOpen?.currentBlockId,
                    );
                    updatedBlocks.splice(elIndex + 1, 0, blockId);
                    return updatedBlocks;
                  });
              } else {
                setCourseBlocks &&
                  setCourseBlocks((prev) => {
                    prev.forEach((el) => {
                      el.order = el.order + 1;
                    });
                    return [blockId, ...prev];
                  });
              }
            }
            setblockModOpen && setblockModOpen(false);
            setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          }}
        >
          Save Block
        </ButtonMain>
      </>
      {/* )} */}
      <ToastNotify ref={toastRef} duration={3000} />
    </div>
  );
};

export default AddList;
