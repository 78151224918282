import React, { useState, useRef } from "react";
import FMModal from "../CourseModals/FMModal";
import { Grid, Typography, Button } from "@mui/material";
import { ToastNotify } from "../../SnackBar/ToastNotify";
import "./insightsModal.scss";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useCallback } from "react";
import { FullScreenLoader } from "../../Loader/Loader";

const InsightModal = ({ cohortData, insightOpen, insightModalClose, insightHeader }) => {
  const [insightData, setInsightData] = React.useState({
    enrollment: "",
    certification: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const toastRef = useRef();

  React.useEffect(() => {
    setIsLoading(true);
    getCohortInsights("enrollment", cohortData._id);
    getCohortInsights("certification", cohortData._id);
  }, []);

  const getCohortInsights = (type, cohort_id) => {
    //get cohort insights data
    GET(`${Constants.insights}/cohort/${type}/${cohort_id}`)
      .then((res) => {
        saveInsightsToState(type, res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);

        toastRef.current.showToastMessage(err.response.data, "error");
        setIsLoading(false);
        insightModalClose();
      });
  };

  const saveInsightsToState = useCallback((type, data) => {
    setInsightData((prevState) => ({
      ...prevState,
      [type]: data,
    }));
  }, []);

  const handleDownloadCSV = () => {
    //download csv
    GET(`${Constants.insights}/cohort/downloadCSV/${cohortData._id}`)
      .then((res) => {
        if (res.data.success) {
          window.open(res.data.downloadLink);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <FMModal
        modalOpen={insightOpen}
        modalClose={insightModalClose}
        modalHeader={insightHeader}
        className="insights_modal"
        headerTextColor={"header_text_black"}
        backgroundImage={{
          backgroundImage: "linear-gradient(90deg, #E3EEFF, #F3E7E9)",
        }}
      >
        <FullScreenLoader open={isLoading} />
        {!isLoading && (
          <Grid>
            <Grid>
              <Typography textAlign={"left"} fontWeight={600} variant="h6" marginBottom={1}>
                Total Learners - {insightData.enrollment}
              </Typography>
            </Grid>
            <Grid>
              <Typography textAlign={"left"} fontWeight={600} variant="h6" sx={{ mt: 2, mb: 1 }}>
                Total Certificates Generated - {insightData.certification}
              </Typography>
            </Grid>
            <Grid sx={{ mt: 3 }}>
              <Button variant="contained" onClick={handleDownloadCSV}>
                Download CSV
              </Button>
            </Grid>
          </Grid>
        )}
      </FMModal>
      <ToastNotify ref={toastRef} duration={2000} />
    </>
  );
};

export default InsightModal;
