import React from "react";

const RenderIFrame = ({ platform, url, title, email, blockHeight }) => {
  switch (platform) {
    case "typeform":
      return (
        <iframe
          width="100%"
          height={`${blockHeight}px`}
          src={`${url}#uniqueid=${email}`}
          title={title}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      );
    default:
      return (
        <iframe
          width="100%"
          height={`${blockHeight}px`}
          src={url}
          title={title}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      );
  }
};

export default RenderIFrame;
