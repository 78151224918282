import React, { useState } from "react";
import ButtonMain from "../../../../ButtonMain";
import { CustomInputFile, InputBox } from "../../../../InputBox/InputBox";
import "./AddFile.scss";
import { useSelector } from "react-redux";
import { Constants } from "../../../../../config/Constants";
import { POST } from "../../../../../config/Api";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import { useQueryParams } from "../../../../../utils";
import { Box, Typography } from "@mui/material";
import { cloudfrontUrl } from "../../../../../config/BaseUrl";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const AddFile = ({
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState(false);
  const [text, setText] = useState(block?.title || "");
  const [disable, setDisable] = useState(false);
  const [urlPath, seturlPath] = useState("");
  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [alertType, setalertType] = useState("error");
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);

  const { cid, mid } = useQueryParams();

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload file
      const response = await POST(`${Constants.addBlock}/upload`, formData, header);
      seturlPath(response.data.urlPath);
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    if (!type) {
      if (selectedFile === null) {
        setOpen(true);
        seterrText("Please select the file");
      }
    }
    if (text === "") {
      setOpen(true);
      seterrText("Please Enter text.");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        file: {
          ext: selectedFile?.name?.split(".").pop(),
          size: selectedFile?.size,
        },
        provider: {
          name: "Framerspace",
          url: urlPath || block?.provider?.url,
        },
        title: text,
        type: type,
      };
      setDisable(true);
      //create file block
      await POST(`${Constants.addBlock}/file?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setOpen(true);
          setalertType("success");
          seterrText("Block Created Successfully");
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div className="fileBlock">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={block?.srcText}
        srcTitleText={block?.srcTitleText}
      />

      <InputBox placeholder="Title" value={text} onChange={(e) => setText(e.target.value)} />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Box>
      )}
      {/* create file block */}
      {type !== "edit" && (
        <>
          <CustomInputFile
            title={selectedFile ? selectedFile.name : "Choose File to upload"}
            icon={!selectedFile && true}
            className="fileUpload"
            onChange={handleFileChange}
            accept="application/pdf, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <p>Note : File Size must be less than 1MB.</p>
        </>
      )}
      {/* edit file block */}
      {type === "edit" && (
        // <Box>
        //   <a
        //     href={
        //       block?.provider?.url?.includes(cloudfrontUrl)
        //         ? block?.provider?.url
        //         : `${cloudfrontUrl}${block?.provider?.url}`
        //     }
        //     target="_blank"
        //     rel="noopener noreferrer"
        //   >
        //     <Box height={"150px"} position={"relative"}>
        //       <svg
        //         xmlns="http://www.w3.org/2000/svg"
        //         fill="none"
        //         viewBox="0 0 24 24"
        //         strokeWidth={1}
        //         stroke="currentColor"
        //         className="w-6 h-6"
        //         style={{ height: "150px" }}
        //       >
        //         <path
        //           strokeLinecap="round"
        //           strokeLinejoin="round"
        //           d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
        //         />
        //       </svg>
        //       <Typography
        //         variant="h5"
        //         fontWeight={600}
        //         position={"absolute"}
        //         top={"50px"}
        //         left={"45px"}
        //       >
        //         {block?.provider?.url
        //           ?.match(/\.[0-9a-z]+$/i)[0]
        //           ?.toUpperCase()
        //           .split(".")}
        //       </Typography>
        //     </Box>
        //     <ButtonMain style={{ marginLeft: "25px" }}>View File</ButtonMain>
        //   </a>
        //   <ButtonMain
        //     style={{ marginLeft: "25px" }}
        //     onClick={() => {
        //       setFile(false);
        //       setSelectedFile(null);
        //     }}
        //   >
        //     Change File
        //   </ButtonMain>
        // </Box>
        <>
          {file ? (
            <form onSubmit={handleSubmit}>
              <div className="file-save-button">
                <CustomInputFile
                  title={
                    selectedFile ? selectedFile.name.substring(0, 20) : "Choose File to upload"
                  }
                  icon={!selectedFile && true}
                  className="fileUpload fileview"
                  onChange={handleFileChange}
                  accept=".pdf,.docx,.doc"
                />
              </div>
              {/* <ToastNotify ref={toastRef} duration={3000} /> */}
            </form>
          ) : (
            <Box>
              <a
                href={
                  block?.provider?.url?.includes(cloudfrontUrl)
                    ? block?.provider?.url
                    : `${cloudfrontUrl}${block?.provider?.url}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box height={"150px"} position={"relative"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    className="w-6 h-6"
                    style={{ height: "150px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    position={"absolute"}
                    top={"50px"}
                    left={"45px"}
                  >
                    {block?.provider?.url
                      ?.match(/\.[0-9a-z]+$/i)[0]
                      ?.toUpperCase()
                      .split(".")}
                  </Typography>
                </Box>
                <ButtonMain style={{ marginLeft: "25px" }}>View File</ButtonMain>
              </a>
              <ButtonMain
                style={{ marginLeft: "25px" }}
                onClick={() => {
                  setFile(true);
                  setSelectedFile(null);
                }}
              >
                Change File
              </ButtonMain>
            </Box>
          )}
        </>
      )}
      <br />
      <ButtonMain type="submit" className="buttonGreen" disabled={disable} onClick={handleSubmit}>
        Save Block
      </ButtonMain>
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={alertType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddFile;
