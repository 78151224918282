import React, { useState, useRef } from "react";
import { TextField, IconButton, Box, Popper, Paper, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";

const Search = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleSearchIconClick = () => {
    setSearchOpen((prev) => !prev);
  };

  const handleSearchInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleSearch = () => {
    navigate(`/search?search_text=${encodeURIComponent(searchTerm)}`);
    setSearchTerm("");
    setSearchOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchTerm.trim() !== "") {
      handleSearch();
    }
  };

  return (
    <Box ref={searchRef} sx={{ position: "relative" }}>
      <IconButton
        onClick={handleSearchIconClick}
        sx={{
          mr: 2,
          backgroundColor: "white",
          ":hover": {
            backgroundColor: "white",
          },
        }}
      >
        {searchOpen ? <CloseOutlined /> : <SearchIcon />}
      </IconButton>

      <Popper
        open={searchOpen}
        anchorEl={searchRef.current}
        placement="bottom-start"
        style={{ zIndex: 1300 }}
      >
        <Paper
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            mt: "1rem",
            marginRight: "4rem",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <TextField
            id="standard-search"
            label="Search"
            type="search"
            variant="standard"
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onKeyDown={handleKeyDown}
            onChange={handleSearchInputChange}
            autoFocus
            sx={{
              flex: 1,
              mr: 1,
              "& .MuiInput-underline:before": {
                borderBottomColor: "black",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "black",
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputLabel-root": {
                color: "black",
              },
            }}
          />
          <Button variant="contained" color="primary" onClick={handleSearch} disabled={!searchTerm}>
            Search
          </Button>
        </Paper>
      </Popper>
    </Box>
  );
};

export default Search;
