import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tooltip, useMediaQuery } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropdown from "./Dropdown";

import "./header.scss";
import { logoutAction, newUserCleanUp } from "../../redux/action";

import ShowUniqueID from "../courses/CourseModals/ShowUniqueID";
import { InputBox } from "../InputBox/InputBox";
import VerifyCertificateModal from "../ModalMain/VerifyCertificateModal";
import { cloudfrontUrl } from "../../config/BaseUrl";
import NewCourseModal from "../courses/CourseModals/NewCourseModal";
import ProfileAccordion from "./ProfileAccrodion";
import { GET } from "../../config/Api";
import { Constants } from "../../config/Constants";

const PostLogin = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let UserData = useSelector((state) => state.userLogin);
  let newUser = UserData?.data?.newUser;
  let userID = UserData?.data?.user?.email;

  const [openProfile, setOpenProfile] = React.useState(null);
  const [openAdvance, setOpenAdvance] = React.useState(null);
  const [openSearch, setOpenSearch] = React.useState(null);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openUniqueID, setOpenUniqueID] = React.useState(false);
  const [verifyCertOpen, setVerifyCertOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const profileOpen = Boolean(openProfile);
  const advanceOpen = Boolean(openAdvance);
  const searchOpen = Boolean(openSearch);
  const notificationOpen = Boolean(openNotification);
  const smallScreen = useMediaQuery("(max-width: 480px)");
  useEffect(() => {
    if (newUser) {
      setOpenUniqueID(true);
    }
  }, [newUser]);

  const isAdmin = UserData?.data?.user?.type === "admin";

  const advanceItems = [
    {
      title: "Managed Courses",
      onClick: () => navigate("/managed-courses"),
    },
    {
      title: "Verify Certificate",
      onClick: () => setVerifyCertOpen(true),
    },
    {
      title: "Certificate Configuration",
      onClick: () => navigate("/certificate-config"),
      isAdmin: true,
    },
    {
      title: "Create New Course",
      onClick: () => setModalOpen(true),
    },
  ];

  if (isAdmin) {
    advanceItems.push({
      title: "Manage FSComms ",
      onClick: () => navigate("/manage-fsComms"),
    });
  }
  // const adminItems = [
  //   {
  //     title: "Certificate Configuration",
  //     onClick: () => navigate("/certificate-config"),
  //   },
  // ];
  const profileItems = [
    {
      title: "Update Email",
      onClick: () => {
        setOpenUniqueID(true);
        setOpenProfile(false);
      },
    },
    {
      title: "Account",
      onClick: () => navigate("/user-profile"),
    },
    {
      title: "Logout",
      onClick: async () => {
        //save user last activity
        // await POST(`${Constants.userActivity}?activity=logout`, {
        //   user: UserData?.data?.user,
        // });
        await GET(`${Constants.userLogout}?userID=${UserData?.data?.user?.id}`);
        dispatch(logoutAction());
        localStorage.removeItem("fsCommsDisplayed");
        navigate("/");
      },
    },
  ];

  return (
    <>
      <Box className="navBar">
        <Box className="loggedInIconMain">
          {/* <div>
            <Box sx={{ borderLeft: "1px solid grey", borderRight: "1px solid grey" }}>
              <Box p={2} sx={{ display: "flex", color: "white", alignItems: "center" }}>
                <Tooltip title="Home">
                  <div onClick={() => navigate("/")} className="helpText">
                    Home
                  </div>
                </Tooltip>
              </Box>
            </Box>
          </div> */}
          <div>
            <Tooltip title="Framerspace support">
              <div onClick={() => navigate("/support")} className="helpText">
                Help
              </div>
            </Tooltip>
          </div>
          <div className="advanceOptionSection">
            <Tooltip title="Advanced">
              <div
                className="advancedOption"
                onClick={(e) => {
                  setOpenAdvance(e.currentTarget);
                }}
              >
                Advance <ExpandMoreIcon />
              </div>
            </Tooltip>
          </div>

          {/* <div>
            <Tooltip title="Notifications">
              <NotificationsNoneRoundedIcon
                className="headerIcon"
                onClick={(e) => setOpenNotification(e.currentTarget)}
                sx={{ display: { xs: "block", md: "block" } }}
              />
            </Tooltip>
          </div> */}
          {/* <div>
            <Tooltip title="Search">
              <SearchIcon
                className="headerIcon"
                onClick={(e) => setOpenSearch(e.currentTarget)}
                sx={{ display: { xs: "block", md: "block" } }}
              />
            </Tooltip>
          </div> */}
          <div>
            <Tooltip title="Profile">
              <Box
                className="profileHeader"
                onClick={(e) => {
                  setOpenProfile(e.currentTarget);
                }}
              >
                {UserData?.data?.user?.dp?.m ? (
                  <Box sx={{ height: "40px", width: "40px" }}>
                    <img
                      src={
                        UserData?.data?.user?.dp?.m?.includes(cloudfrontUrl)
                          ? UserData?.data?.user?.dp?.m
                          : cloudfrontUrl + UserData?.data?.user?.dp?.m
                      }
                      className="profileImage"
                      alt="dp"
                    />
                  </Box>
                ) : (
                  userID?.charAt(0)?.toUpperCase()
                )}
              </Box>
            </Tooltip>
          </div>
        </Box>
        <Dropdown
          open={profileOpen}
          anchorEl={openProfile}
          handleClose={() => setOpenProfile(null)}
          className="customDropdown"
        >
          {smallScreen ? (
            <div>
              <ProfileAccordion
                advanceOptions={advanceItems.map(
                  (item, index) =>
                    (!item.isAdmin || isAdmin) && (
                      <Box
                        key={index}
                        className="profileList"
                        onClick={() => {
                          item.onClick();
                          setOpenProfile(null);
                        }}
                      >
                        {item?.title}
                      </Box>
                    ),
                )}
                profileOptions={profileItems?.map((item, index) => (
                  <Box key={index} className="profileList" onClick={item.onClick}>
                    {item?.title}
                  </Box>
                ))}
              />
            </div>
          ) : (
            <div>
              {profileItems?.map((item, index) => (
                <Box key={index} className="profileList" onClick={item.onClick}>
                  {item?.title}
                </Box>
              ))}
            </div>
          )}
        </Dropdown>
        {/* Search Dropdown */}
        <Dropdown open={searchOpen} anchorEl={openSearch} handleClose={() => setOpenSearch(null)}>
          <Box className="" onClick={() => {}}>
            <InputBox
              className="headerSearchBox"
              type="text"
              placeholder="Search courses and containers"
            />
          </Box>
        </Dropdown>
        {/* Notification Dropdown */}
        <Dropdown
          open={notificationOpen}
          anchorEl={openNotification}
          handleClose={() => setOpenNotification(null)}
        >
          <Box className="" onClick={() => {}}>
            You dont have any notification yet.
          </Box>
        </Dropdown>
        {/* Advance Option */}
        <Dropdown
          className="customDropdown"
          open={advanceOpen}
          anchorEl={openAdvance}
          handleClose={() => setOpenAdvance(null)}
        >
          {advanceItems.map(
            (item, index) =>
              (!item.isAdmin || isAdmin) && (
                <Box
                  key={index}
                  className="profileList"
                  onClick={() => {
                    item.onClick();
                    setOpenAdvance(null);
                  }}
                >
                  {item?.title}
                </Box>
              ),
          )}
        </Dropdown>
      </Box>
      {/* Profile Dropdown */}
      <ShowUniqueID
        uniqueIDOpen={openUniqueID}
        uniqueIDClose={() => {
          setOpenUniqueID(false);
          if (newUser) dispatch(newUserCleanUp(UserData.data));
        }}
        uniqueID={userID}
      />
      <VerifyCertificateModal
        verifyCertOpen={verifyCertOpen}
        verifyClose={() => setVerifyCertOpen(false)}
      />
      <NewCourseModal modalOpen={modalOpen} modalClose={() => setModalOpen(false)} />
    </>
  );
};

export default PostLogin;
