import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import BlockActions from "../BlockActions/BlockActions";
import "./GifView.scss";
import ArtView from "../ArtView/ArtView";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const GifView = ({
  gif,
  courseDetails,
  block,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
  preview,
}) => {
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);
  const blockActionsRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (blockActionsRef.current && !blockActionsRef.current.contains(event.target)) {
        setShowActions(true);
      }
    };

    if (isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isMobile]);

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <div className="gif-view">
          <div
            className={` container-block gif-block 
        ${isLoggedIn ? `${block?.theme} postBgColor` : `${block?.theme} defaultStyle`}
      `}
            style={{
              background: `${block?.theme_type === "color" ? block?.color : ""}`,
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            {isModerator !== -1 && !preview && isLoggedIn && (isHovering || showActions) && (
              <div ref={blockActionsRef}>
                <BlockActions
                  blockId={gif?._id}
                  block={block}
                  setmodalOpen={setmodalOpen}
                  setblockModOpen={setblockModOpen}
                  hidden={hidden}
                  setHidden={setHidden}
                />
              </div>
            )}
            {isModerator !== -1 && gif?.carousel_container && (
              <Image>
                <Tooltip title="This Block Tagged to Carousel Block">
                  <LocalOfferRoundedIcon fontSize="large" color="primary" />
                </Tooltip>
              </Image>
            )}

            {isMobile && (
              <MenuIconWrapper onClick={handleMenuClick}>
                <MoreVertIcon fontSize="large" color="action" />
              </MenuIconWrapper>
            )}

            <video
              autoPlay
              loop
              muted
              width={gif?.width}
              height={gif?.height}
              src={gif?.embed}
            ></video>
            <ArtView art={(gif || block)?.art} />
          </div>
        </div>
      )}
    </>
  );
};

export default GifView;
