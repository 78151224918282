import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import ButtonMain from "../ButtonMain";
import { HeaderText } from "../Typography";
import { InputBox } from "../InputBox/InputBox";

// import "../CourseCommonStyle.scss";
import { POST, PUT } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { ToastNotify } from "../SnackBar/ToastNotify";
import QuillEditor from "../QuillEditor/QuillEditor";
const CreateFSCommsModal = ({
  modalOpen,
  modalClose,
  editData,
  modalTitle,
  setEditData,
  setRefetchList,
  refetchList,
}) => {
  const toastRef = useRef();
  const [fsCommsData, setFSCommsData] = useState({
    title: "",
    description: "",
    is_active: false,
    modal_freq: "",
  });
  const [disable, setDisable] = useState(false);
  const [fsCommsDataError, setFSCommsDataError] = useState({
    title: "",
    description: "",
    is_active: "",
    modal_freq: "",
  });

  useEffect(() => {
    setFSCommsData((prev) => ({ ...prev, ...editData }));
    setFSCommsDataError((prev) => ({
      title: "",
      description: "",
      is_active: "",
      modal_freq: "",
    }));
    return () => {
      setFSCommsData({
        title: "",
        description: "",
        is_active: false,
        modal_freq: "",
      });
    };
  }, [editData]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFSCommsDataError((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setFSCommsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleCreateFSComms = (e) => {
    e.preventDefault();
    if (fsCommsData?.title === "") {
      setFSCommsDataError({ ...fsCommsDataError, title: "Please enter a title" });
    } else if (fsCommsData?.modal_freq === 0) {
      setFSCommsDataError({
        ...fsCommsDataError,
        modal_freq: "Please enter the number of times this notice should be displayed",
      });
    } else if (fsCommsData?.is_cta === true && fsCommsData?.cta_text === "") {
      setFSCommsDataError({ ...fsCommsDataError, cta_text: "Please enter cta text" });
    } else if (fsCommsData?.is_cta === true && fsCommsData?.cta_link === "") {
      setFSCommsDataError({ ...fsCommsDataError, cta_link: "Please enter cta link" });
    } else if (fsCommsData?.description === "") {
      setFSCommsDataError({ ...fsCommsDataError, description: "Please enter a description" });
    } else {
      const body = { ...fsCommsData };
      setDisable(true);
      if (!fsCommsData?._id) {
        //Create FSComms
        POST(`${Constants.fsComms}`, body)
          .then((res) => {
            if (res?.data?.success) {
              toastRef.current.showToastMessage(res.data.message, "success");
            } else {
              toastRef.current.showToastMessage(res.data.message, "error");
            }
            setFSCommsData({
              title: "",
              description: "",
              is_active: false,
              cta_text: "",
              cta_link: "",
              is_cta: false,
              id: "",
              dont_show_again_opt: false,
            });
            setRefetchList(!refetchList);
            setDisable(false);
            modalClose();
          })
          .catch((err) => {
            toastRef.current.showToastMessage(err.data.message, "error");
          });
      } else {
        //Edit FSComms
        PUT(`${Constants.fsComms}/${fsCommsData?._id}`, body)
          .then((res) => {
            if (res?.data?.success) {
              toastRef.current.showToastMessage(res.data.message, "success");
            } else {
              toastRef.current.showToastMessage(res.data.message, "error");
            }
            setFSCommsData({
              title: "",
              description: "",
              is_active: false,
              fsCommsFor: "all",
              cta_text: "",
              cta_link: "",
              is_cta: false,
              id: "",
            });
            setRefetchList(!refetchList);
            setDisable(false);
            modalClose();
          })
          .catch((err) => {
            setDisable(false);
            toastRef.current.showToastMessage(err.data.message, "error");
          });
      }
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => {
          setFSCommsData({});
          setEditData({});
          modalClose();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="modalContainer"
      >
        <Grid container className="modalMain">
          <Grid
            item
            className="modalLeft"
            xs={12}
            lg={12}
            sx={{ backgroundImage: "linear-gradient(#a1c4fd, #c2e9fb)" }}
          >
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <HeaderText className="loginInputHeader">{modalTitle}</HeaderText>
              <IconButton
                onClick={() => {
                  setFSCommsData({});
                  setEditData({});
                  modalClose();
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>

            <Box my={3}>
              <form onSubmit={handleCreateFSComms} className="createCourseMainForm">
                <InputBox
                  name="title"
                  type="text"
                  placeholder="Title*"
                  value={fsCommsData?.title}
                  onChange={handleChange}
                  errorMsg={fsCommsDataError.title}
                />
                <InputBox
                  name="modal_freq"
                  type="text"
                  placeholder="No. of times to display the modal*"
                  value={fsCommsData?.modal_freq}
                  onChange={(e) => {
                    const isNumber = /^(?:0|\d+(\.\d+)?)?$/.test(e.target.value);
                    if (isNumber) {
                      handleChange(e);
                    }
                  }}
                  errorMsg={fsCommsDataError.modal_freq}
                />
                {/* <Box display={"flex"} className="">
                  <FormControlLabel
                    control={<Switch sx={{ m: 1 }} />}
                    label="Active"
                    name="is_active"
                    onChange={handleChange}
                    value={fsCommsData?.is_active}
                    checked={fsCommsData?.is_active}
                  />
                </Box> */}
                {/* <Box px={1}>
                  <FormControl>
                    <FormLabel id="fsCommsFor">FSComms For</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="fsCommsFor"
                      name="fsCommsFor"
                      value={fsCommsData?.fsCommsFor}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="logged_in_users"
                        checked={fsCommsData?.fsCommsFor === "logged_in_users"}
                        control={<Radio />}
                        label="Logged in users"
                      />
                      <FormControlLabel
                        value="not_logged_in_users"
                        checked={fsCommsData?.fsCommsFor === "not_logged_in_users"}
                        control={<Radio />}
                        label="Not logged in users "
                      />
                      <FormControlLabel
                        value="all"
                        checked={fsCommsData?.fsCommsFor === "all"}
                        control={<Radio />}
                        label="All"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box> */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={fsCommsData?.is_active}
                      value={fsCommsData?.is_active}
                      inputProps={{ "aria-label": "controlled-switch" }}
                    />
                  }
                  label="Active"
                  onChange={() =>
                    setFSCommsData({
                      ...fsCommsData,
                      is_active: !fsCommsData.is_active,
                    })
                  }
                /> */}
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Box display={"flex"}>
                    <input
                      type="checkbox"
                      name="is_active"
                      id="is_active"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      value={fsCommsData?.is_active}
                      checked={fsCommsData?.is_active}
                      onChange={() =>
                        setFSCommsData((prev) => ({
                          ...prev,
                          is_active: !fsCommsData?.is_active,
                        }))
                      }
                    />
                    <label for="is_active"> Active</label>
                  </Box>
                  <Box display={"flex"}>
                    <input
                      type="checkbox"
                      name="dont_show_again_opt"
                      id="dont_show_again_opt"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      value={fsCommsData?.dont_show_again_opt}
                      checked={fsCommsData?.dont_show_again_opt}
                      onChange={() =>
                        setFSCommsData((prev) => ({
                          ...prev,
                          dont_show_again_opt: !fsCommsData?.dont_show_again_opt,
                        }))
                      }
                    />
                    <label htmlFor="dont_show_again_opt"> Don't Show Again Option</label>
                  </Box>
                  <Box display={"flex"}>
                    <input
                      type="checkbox"
                      id="add_cta"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      label="Add Call to Action"
                      value={fsCommsData?.is_cta}
                      checked={fsCommsData?.is_cta}
                      onChange={() =>
                        setFSCommsData({
                          ...fsCommsData,
                          is_cta: !fsCommsData?.is_cta,
                          cta_text: "",
                          cta_link: "",
                        })
                      }
                    />
                    <label for="add_cta"> Add Call to Action</label>
                  </Box>
                </Box>
                {/* <CheckboxInput
                  label="Active"
                  value={fsCommsData?.is_active}
                  checked={fsCommsData?.is_active}
                  onChange={() =>
                    setFSCommsData((prev) => ({
                      ...prev,
                      is_active: !fsCommsData?.is_active,
                    }))
                  }
                /> */}
                {/* <CheckboxInput
                  label="Add Call to Action"
                  value={fsCommsData?.is_cta}
                  checked={fsCommsData?.is_cta}
                  onChange={() =>
                    setFSCommsData({
                      ...fsCommsData,
                      is_cta: !fsCommsData?.is_cta,
                      cta_text: "",
                      cta_link: "", 
                    })
                  }
                /> */}
                {fsCommsData?.is_cta && (
                  <>
                    <Box position={"relative"}>
                      <InputBox
                        name="cta_text"
                        type="text"
                        placeholder="Call to Action Text*"
                        value={fsCommsData?.cta_text}
                        onChange={handleChange}
                        errorMsg={fsCommsDataError.cta_text}
                      />
                    </Box>
                    <Box position={"relative"}>
                      <InputBox
                        name="cta_link"
                        type="text"
                        placeholder="Call to Action Link*"
                        value={fsCommsData?.cta_link}
                        onChange={handleChange}
                        errorMsg={fsCommsDataError.cta_link}
                      />
                    </Box>
                  </>
                )}
                <QuillEditor
                  placeholder="description"
                  value={fsCommsData?.description}
                  handleChange={handleChange}
                  height={250}
                />{" "}
                <br />
                {/* <Editor
                  apiKey="372n02d49wbdou8ee7fkwdna3rd3cyrzwfh6cubvlm4yl9d4"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // initialValue={""}
                  placeholder="description"
                  value={fsCommsData?.description}
                  onEditorChange={(newValue) => {
                    handleChange({
                      target: { name: "description", value: newValue },
                    });
                  }}
                  init={{
                    selector: "textarea#open-source-plugins",
                    plugins:
                      "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                    imagetools_cors_hosts: ["picsum.photos"],
                    menubar: "file edit view insert format tools table help",
                    toolbar:
                      "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                    toolbar_sticky: true,
                    autosave_ask_before_unload: true,
                    autosave_interval: "30s",
                    autosave_prefix: "{path}{query}-{id}-",
                    autosave_restore_when_empty: false,
                    autosave_retention: "2m",
                    image_advtab: true,
                    importcss_append: true,
                    height: 250,
                    image_caption: true,
                    quickbars_selection_toolbar:
                      "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                    noneditable_noneditable_class: "mceNonEditable",
                    toolbar_mode: "sliding",
                    contextmenu: "link image imagetools table",
                    skin: "oxide",
                    content_css: "default",
                    link_assume_external_targets: true,
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                /> */}
                {fsCommsDataError.description && (
                  <Typography fontSize={"14px"} color={"red"} px={1}>
                    {fsCommsDataError.description}
                  </Typography>
                )}
              </form>
            </Box>
            <ButtonMain
              className="saveNewCourseBtn"
              onClick={handleCreateFSComms}
              disabled={disable}
            >
              Save FSComms
            </ButtonMain>
          </Grid>
        </Grid>
      </Modal>
      <ToastNotify ref={toastRef} duration={4000} />
    </>
  );
};

export default CreateFSCommsModal;
