import React, { Fragment, useEffect, useState } from "react";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CommentCard from "./CommentCard";

const MyComment = ({ topicId, getRepliesByParentId, loading, setTabValue, setCommentId }) => {
  const [comments, setComments] = useState([]);
  const [noComments, setnoComments] = useState(false);
  const [page, setPage] = useState(2);

  const getAllComments = async (page) => {
    try {
      //get all comment by topic id with pagination
      const response = await GET(
        `${Constants.comment}/getMyComment/${topicId}?page=${page}&limit=5`,
      );
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  useEffect(() => {
    setComments([]);
    getAllComments(1)
      .then((res) => {
        res.comments && setComments((prev) => [...res.comments.reverse(), ...prev]);
        if (res.comments.length < 5) {
          setnoComments(true);
        }
      })
      .catch((err) => console.log(err));
    setPage(2);
    setnoComments(false);
  }, [topicId]);

  return (
    <>
      <div className="load-more-container">
        <div className="load-more-button">
          <Button
            variant="outlined"
            size="small"
            disabled={noComments}
            startIcon={noComments ? "" : <KeyboardArrowUpIcon />}
            sx={{
              textTransform: "none",
              border: "none",
              "&:hover": {
                border: "none",
              },
            }}
            onClick={async () => {
              const response = await getAllComments(page);
              !response.comments.length && setnoComments(true);
              response.comments && setComments((prev) => [...response.comments.reverse(), ...prev]);
              response.comments && setPage((prev) => prev + 1);
            }}
          >
            {noComments ? "No more comments to be loaded..." : "Load Older Comments..."}
          </Button>
        </div>
      </div>
      <div className="sent-messages">
        {comments?.map((comment) => (
          <Fragment key={comment?._id}>
            <CommentCard
              {...comment}
              type="root"
              setTabValue={setTabValue}
              getRepliesByParentId={getRepliesByParentId}
              setCommentId={setCommentId}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default MyComment;
