export const baseurl = process.env.REACT_APP_BACKEND_URL;
export const authUrl = process.env.REACT_APP_AUTH_URL;
// export const baseurl = process.env.REACT_APP_BASE_URL;
// export const baseurl = "http://localhost:3002/api";

export const mgiepUrl = "https://mgiep.unesco.org/";
export const cloudfrontUrl = "https://d1u3z33x3g234l.cloudfront.net";
export const feedbackFormUrl =
  "https://framerspace.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&submitTitle=Send&submitThanks=Thank+you+for+your+message.";
export const supportUrl = "framerspace@gmail.com";
export const chatBoxUrl =
  "https://console.dialogflow.com/api-client/demo/embedded/e5d3e575-d0fb-48f5-82f6-984223c9c6a7";
export const contactUsUrl = process.env.REACT_APP_CONTACT_US_URL;
export const environmentURL = process.env.REACT_APP_ENVIRONMENT;
