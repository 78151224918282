import { Box, Chip, Divider, FormControl, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants, creditPoints } from "../../../../../config/Constants";
import CustomAccordion from "../../../../Accrodion/Accordion";
import ButtonMain from "../../../../ButtonMain";
import { CheckboxInput, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomizedSnackbars from "../../../../SnackBar/CustomizedSnackbars";
import CustomTextArea from "../../../../TextArea/CustomTextArea";
import "./AddFillInTheBlanks.scss";
import { useQueryParams } from "../../../../../utils";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { useOutletContext } from "react-router-dom";

const AddFillInTheBlanks = ({
  assessmentSettings,
  courseDetails,
  setblockModOpen,
  blockDetails,
  type,
  updateOrder,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [inputValue, setInputValue] = useState({
    title: blockDetails?.title || "",
    description: blockDetails?.text || "",
    score: blockDetails?.typeOfBlock || "attempt",
    credit: blockDetails?.score || 5,
    checked: false,
    is_required: blockDetails?.is_required || false,
  });

  const [item, setItem] = useState({
    text: "",
    size: "",
  });
  const [block, setBlock] = useState({});
  const [errText, seterrText] = useState("");
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [data, setdata] = useState([]);
  const [assessment, setAssessment] = useState("");
  const { setCourseOne } = useOutletContext();

  const { cid, mid } = useQueryParams();

  const [disableButn, setDisableButton] = useState(false);

  let UserData = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (blockDetails) {
      setdata(blockDetails?.fills);
    }
  }, []);

  useEffect(() => {
    if (blockDetails) {
      setAssessment(blockDetails.assessmentType ? blockDetails.assessmentType : "");
    }
  }, [blockDetails]);

  const handleNextClick = async (item) => {
    if (inputValue.title === "") {
      setOpen(true);
      seterrText("Please Enter Question Title.");
    } else {
      setDisableButton(true);
      let body = {
        _id: block?._id || blockDetails?._id,
        user_id: UserData?.data?.user?.id,
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        highlightIfIncorrect: inputValue.checked,
        typeOfBlock: inputValue.score,
        score: inputValue.credit,
        title: inputValue.title,
        text: inputValue.description,
        type: type,
        is_required: inputValue.is_required,
        assessmentType: assessment,
      };
      try {
        const response = await POST(
          `${Constants.addBlock}/fill?_id=${block?._id || blockDetails?._id}`,
          body,
        );

        setBlock(response?.data);
        setDisableButton(false);
        return response?.data; // Return the response data if needed
      } catch (error) {
        console.log(error);
        setDisableButton(false);
        return null; // Return null if there's an error
      }
    }
  };

  const handleAddItem = async () => {
    const response = await handleNextClick();

    if (response) {
      setdata((prevData) => [...prevData, item]);
      setItem({ ...item, size: "", text: "" });
    }
  };

  const handleDeleteItem = (item) => {
    let filtereddata = data?.filter((i) => i !== item);
    setdata(filtereddata);
  };

  const handleFinalSubmit = async () => {
    setDisableButton(true);
    const blockId = await handleNextClick(); // Wait for handleNextStep to complete
    if (!blockId) return;

    let body = {
      user_id: UserData?.data?.user?.id,
      course: courseDetails?._id,
      item: data,
    };
    // if (type === "edit" || !) {
    //   await handleNextClick();
    // }

    try {
      const response = await POST(
        `${Constants.updateBlock}/${block?._id || blockDetails?._id}/add_fill`,
        body,
      );

      setDisableButton(false);
      setCourseOne((prev) => ({ ...prev, published: false }));

      setCourseBlocks((prev) => {
        if (type === "edit") {
          return prev.map((el) =>
            el._id === (block?._id || blockDetails?._id) ? response.data : el,
          );
        } else {
          let updatedBlocks = prev.map((el) => {
            if (el.order >= response.data.order) {
              el.order += 1;
            }
            return el;
          });

          if (blockModalOpen?.addBelow) {
            const elIndex = updatedBlocks.findIndex(
              (el) => el._id === blockModalOpen?.currentBlockId,
            );
            updatedBlocks.splice(elIndex + 1, 0, response.data);
          } else {
            updatedBlocks = [response.data, ...updatedBlocks];
          }

          return updatedBlocks;
        }
      });

      if (setmodalOpen) {
        setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
      }

      setblockModOpen(false);
    } catch (error) {
      console.log(error);
      setDisableButton(false);
    }
  };

  return (
    <div>
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcText={blockDetails?.srcText}
        srcTitleText={blockDetails?.srcTitleText}
      />
      <FormControl
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
        disabled={!assessmentSettings?.isAdvanceAssessment}
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>
      <InputBox
        placeholder="Question"
        onChange={(event) => setInputValue({ ...inputValue, title: event.target.value })}
        value={inputValue.title}
      />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcTitleText}
        </Box>
      )}
      <br />
      <CustomTextArea
        placeholder="Describe Your Question"
        onChange={(event) => setInputValue({ ...inputValue, description: event.target.value })}
        value={inputValue.description}
      />{" "}
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {blockDetails?.srcText}
        </Box>
      )}
      {/* {next && (
        <ButtonMain onClick={handleNextClick} disabled={disable} className="buttonGreen">
          Next Step
        </ButtonMain>
      )}
      {!next && ( */}
      <br />
      <>
        <b>Add text or blanks:</b>
        <InputBox
          name="text"
          placeholder="Text (or Leave empty for blanks)"
          onChange={(event) => setItem({ ...item, [event.target.name]: event.target.value })}
          value={item.text}
        />
        <br />
        <InputBox
          name="size"
          placeholder="Size of blank (Input 2 for 2x width, 3 for 3x width etc.)"
          onChange={(event) => setItem({ ...item, [event.target.name]: event.target.value })}
          value={item.size}
        />
        <br />
        <ButtonMain className="buttonGreen" onClick={handleAddItem}>
          Add Option
        </ButtonMain>{" "}
        <br />
        <br />
        {data.length ? (
          <>
            <b>Options:</b> <br />
            {data?.map((item) => (
              <Box
                key={item?._id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "1rem 0rem",
                }}
              >
                <Box sx={{ fontWeight: 600 }}>
                  {item?.text !== "" ? item?.text : "-----------------------------"}
                </Box>
                <Box sx={{ fontWeight: 600 }}>
                  {" "}
                  <Chip
                    label="Remove"
                    variant="outlined"
                    onClick={() => {
                      handleDeleteItem(item);
                    }}
                  ></Chip>
                </Box>
              </Box>
            ))}
          </>
        ) : (
          ""
        )}
        <Divider sx={{ borderColor: "black", margin: "10px 0px", mt: 3 }} />
        <CustomAccordion
          first={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, score: event.target.value })}
              value={inputValue.score}
            >
              {["attempt", "none"]?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item === "attempt" ? "Attempt and get credits" : "No credits for this question"}
                </MenuItem>
              ))}
            </Dropdown>
          }
          second={
            <Dropdown
              onChange={(event) => setInputValue({ ...inputValue, credit: event.target.value })}
              value={inputValue.credit}
            >
              {creditPoints?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Dropdown>
          }
          third={
            <CheckboxInput
              label="Highlight if responded incorrectlyinfo"
              onChange={(event) => setInputValue({ ...inputValue, checked: event.target.checked })}
              value={!inputValue.checked}
            />
          }
          fourth={
            <CheckboxInput
              label="Mark As Required"
              onChange={(event) =>
                setInputValue({
                  ...inputValue,
                  is_required: event.target.checked,
                })
              }
              value={!inputValue.is_required}
            />
          }
        />
        <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
        <br />
        {data.length !== 0 && (
          <ButtonMain className="buttonGreen" onClick={handleFinalSubmit} disabled={disableButn}>
            Save Block
          </ButtonMain>
        )}
      </>
      {/* )} */}
      <CustomizedSnackbars
        AlertText={errText}
        AlertType={"error"}
        open={open}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

export default AddFillInTheBlanks;
