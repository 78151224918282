import * as React from "react";
import Box from "@mui/material/Box";
import { Paper, TextField, IconButton, Button } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../courses/CourseListing/CourseListing.scss";
import { CourseListingSkeleton } from "../../Loader/Loader";
import KeywordsView from "../../Keywords/KeywordsView";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import bookImage from "../../../assets/Book-new.svg";
import { saveCourseDetailAction } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import SearchIcon from "@mui/icons-material/Search";
import NoCoursesFound from "../../../components/NoCourseFound/NoCoursesFound";

function stripHtmlTags(html) {
  return html.replace(/<[^>]*>/g, "");
}

export default function SearchData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const searchterm = new URLSearchParams(location.search).get("search_text") || "";
  const [searchData, setSearchData] = useState([]);
  const [term, setTerm] = useState(searchterm ? searchterm : "");

  useEffect(() => {
    if (searchterm) {
      handleSearch();
    }
  }, [searchterm]);

  const handleSearchInputChange = (e) => {
    const newTerm = e.target.value;
    setTerm(newTerm);
  };

  const handleSearch = (searchTerm = term) => {
    setLoading(true);
    GET(`${Constants.getCourseBySearch}/${searchTerm}`)
      .then((res) => {
        setSearchData(res.data.courses || []);
        setLoading(false);
      })
      .catch((err) => {
        setSearchData([]);
        setLoading(false);
      });
  };

  const handleClick = (item) => {
    navigate(`/course/${item?.slug}?cid=${item?._id}`);
    dispatch(saveCourseDetailAction(item));
  };

  const handleSearchClick = () => {
    navigate(`/search?search_text=${encodeURIComponent(term)}`);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && term.trim() !== "") {
      handleSearchClick();
    }
  };

  return (
    <Box className="box">
      <div>
        {loading ? (
          <CourseListingSkeleton />
        ) : (
          <>
            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                borderRadius: "2%",
                width: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <TextField
                id="standard-search"
                label="Search"
                type="search"
                variant="standard"
                size="small"
                // placeholder="Search..."
                value={term}
                onKeyDown={handleKeyDown}
                onChange={handleSearchInputChange}
                autoFocus
                sx={{
                  flex: 1,
                  mr: 1,
                }}
              />

              <IconButton onClick={handleSearchClick} disabled={!term}>
                <SearchIcon />
              </IconButton>
            </Paper>
            {searchData?.length > 0 ? (
              <>
                {searchData?.map((item) => (
                  <Paper
                    key={item?._id}
                    onClick={() => handleClick(item)}
                    className="course_card"
                    elevation={3}
                    id={"paperLight"}
                    sx={{ overflow: "hidden", mt: 2, cursor: "pointer" }}
                  >
                    <Box>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          lg={8}
                          sm={7}
                          sx={{ padding: "2.5rem" }}
                          className="grid-text-container"
                        >
                          <div className="course-title">{item?.title}</div>
                          <div className="course-tagline">
                            {item?.tagline ? (
                              <>
                                {stripHtmlTags(item.tagline)}
                                {stripHtmlTags(item.tagline).length > 400 && (
                                  <strong className="read-more">... &nbsp;Read more.</strong>
                                )}
                              </>
                            ) : null}
                          </div>
                          <KeywordsView keywords={item} />
                        </Grid>
                        <Grid item xs={12} sm={5} lg={4} className="img-container">
                          {item?.image?.m ? (
                            <img
                              src={
                                item?.image?.m?.startsWith(cloudfrontUrl)
                                  ? item?.image?.m
                                  : cloudfrontUrl + "/" + item?.image?.m
                              }
                              alt="course images"
                              className="course-img"
                            />
                          ) : (
                            <img src={bookImage} alt="course images" className="course-img" />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                ))}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <NoCoursesFound />
                <span variant="h4"> Oops! We couldn't find any courses matching your search.</span>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/")}
                  sx={{ display: "flex", alignItems: "center", mt: 2 }}
                >
                  Go Back
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
}
