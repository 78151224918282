import React from "react";
import "./DiscussionForum.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import { useQueryParams } from "../../utils";
import { useState } from "react";
import { GET, POST, PUT } from "../../config/Api";
import { Constants } from "../../config/Constants";
import FMModal from "../ModalMain/CourseModals/FMModal";
import ButtonMain from "../ButtonMain";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Dropdown from "../Header/Dropdown";
import ChatView from "./ChatView";
import { useSelector } from "react-redux";
import { ToastNotify } from "../SnackBar/ToastNotify";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { Fragment } from "react";
import noTopicSelectedImg from "../../assets/no_topics_selected.svg";

const DiscussionForum = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [id, setId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [topicDataError, setTopicDataError] = useState({
    title: "",
    description: "",
  });
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [searchItem, setsearchItem] = useState("");
  const [openAction, setOpenAction] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { cid, coid, mid } = useQueryParams();
  const toastRef = useRef();
  const location = useLocation();
  const topicOpen = Boolean(openAction);
  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  let navigate = useNavigate();

  useEffect(() => {
    cid && getTopics();
  }, [cid]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const getTopics = async () => {
    try {
      const cohortIdExst = coid ? `cohortId=${coid}` : "";
      //get discussion topics
      const response = await GET(`${Constants.discussionTopic}/getTopics/${cid}?${cohortIdExst}`);
      setTopics(response?.data?.topics);
    } catch (error) {
      console.log(error);
    }
  };
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    let queryParams = `?cid=${cid}&tid=${topic._id}`;

    if (coid) {
      queryParams += `&coid=${coid}`;
    }
    if (mid) {
      queryParams += `&mid=${mid}`;
    }

    navigate(queryParams);
  };
  const handleAddTopic = async () => {
    setTopicDataError((prev) => ({
      ...prev,
      title: "",
      description: "",
    }));
    if (title === "") {
      setTopicDataError((prev) => ({
        ...prev,
        title: "Please enter title.",
      }));
    } else if (description === "") {
      setTopicDataError((prev) => ({
        ...prev,
        description: "Please enter description.",
      }));
    } else {
      setDisable(true);
      try {
        let payload = {
          title: title,
          description: description,
          cohort_id: coid,
          course_id: cid,
        };
        //create and edit discussion topic
        const res = id
          ? await PUT(`${Constants.discussionTopic}/updateTopic/${id}`, payload)
          : await POST(`${Constants.discussionTopic}/createTopic`, payload);
        id && res?.data?.updatedTopic?._id === id && setSelectedTopic(res?.data?.updatedTopic);
        setTitle("");
        setDescription("");
        getTopics();
        setOpenAction(false);
        setModalOpen(false);
        toastRef.current.showToastMessage(`${res?.data?.message}`, "success");
        setDisable(false);
        setTopicDataError((prev) => ({
          ...prev,
          title: "",
          description: "",
        }));
      } catch (error) {
        setTopicDataError((prev) => ({
          ...prev,
          title: "",
          description: "",
        }));
        setDisable(false);
        toastRef.current.showToastMessage(`${error?.res?.data?.message}`, "error");
      }
    }
  };
  const handleDelete = async () => {
    try {
      setOpen(true);
      setLoading(true);
      //delete dicussion topic
      await POST(`${Constants.discussionTopic}/deleteTopic/${id}`);
      setLoading(false);
      setSelectedTopic({});
      setId("");
      setOpenAction(false);
      getTopics();
      setOpen(false);
    } catch (error) {
      setOpen(false);
      setLoading(false);
      console.error("Error deleting topic:", error);
    }
  };
  const handleSearchTopic = async (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setsearchItem(searchTerm);

    try {
      //search topic
      const response = await GET(`${Constants.discussionTopic}/getTopics/${cid}`);
      const allTopics = response?.data?.topics;

      // Filter the topics based on a case-insensitive partial match of the title.
      const filteredTopics = allTopics.filter((topic) =>
        topic.title.toLowerCase().includes(searchTerm),
      );

      setTopics(filteredTopics);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  const handleCopy = async () => {
    try {
      const src = `${window.location.origin}/discussion-forum/${selectedTopic?._id}`;
      const code = `<iframe src="${src}"></iframe>`;
      await navigator.clipboard.writeText(code);
      setModalOpen(false);
      toastRef.current.showToastMessage("Copied Successfully.", "success");
    } catch (err) {
      toastRef.current.showToastMessage("Failed to copy.", "success");
    }
  };
  const handleCopyLink = async () => {
    try {
      const link = `${window.location.origin}/discussion-forum/${selectedTopic?._id}`;
      await navigator.clipboard.writeText(link);
      setModalOpen(false);
      toastRef.current.showToastMessage("Copied Successfully.", "success");
    } catch (err) {
      toastRef.current.showToastMessage("Failed to copy.", "success");
    }
  };
  const isUnchanged =
    title?.trim() === selectedTopic?.title?.trim() &&
    description?.trim() === selectedTopic?.description?.trim();

  return (
    <div className="discussion_container">
      <div className="parent">
        <div className="left">
          <div className="left__header">
            {location.pathname?.includes("/create-discussion-forum") && (
              <div className="createTopicBox">
                <Typography color={"white"} component={"h4"} variant="body1" fontWeight={600}>
                  Create Topic
                </Typography>
                <IconButton
                  onClick={() => {
                    setId("");
                    setTitle("");
                    setDescription("");
                    setModalOpen(true);
                  }}
                >
                  <AddIcon sx={{ cursor: "pointer", color: "white" }} />
                </IconButton>
              </div>
            )}
          </div>
          <div>
            <List
              component="nav"
              sx={{ pb: 0 }}
              aria-label="Discussion Topics"
              subheader={
                <ListSubheader
                  component="div"
                  sx={{ bgcolor: "#365082", color: "white", py: 2, px: 3 }}
                >
                  <Typography component={"h4"} variant="body1" fontWeight={600}>
                    Discussion Topics
                  </Typography>
                </ListSubheader>
              }
            >
              <div className="topicSearch">
                <SearchOutlinedIcon />
                <input
                  type="text"
                  value={searchItem}
                  onChange={handleSearchTopic}
                  placeholder="Search Topic here..."
                />
                {/* <TextField
                  value={searchItem}
                  onChange={handleSearchTopic}
                  label="Search Topic here..."
                  variant="filled"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon
                          sx={{
                            fontSize: "1.5rem",
                            color: "black",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </div>
              {topics?.map((topic) => (
                <Fragment key={topic?._id}>
                  <ListItem
                    sx={{
                      bgcolor: "#365082",
                      color: "white",
                      borderLeft: "6px solid transparent",
                      "& >div>.MuiListItemText-secondary": {
                        color: "#ffffffa1",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },

                      "&:hover": {
                        bgcolor: "#4f6a9e",
                        color: "white",
                        cursor: "pointer",
                        "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
                      },
                      "&.Mui-selected": {
                        color: "black",
                        "& >div>.MuiListItemText-secondary": {
                          color: "#333333",
                        },
                        bgcolor: "#ffffff",
                        borderTop: "1px solid #365082 ",
                        borderLeft: "6px solid #01CE3E",
                      },
                    }}
                    selected={selectedTopic === topic}
                    onClick={() => {
                      handleTopicClick(topic);
                      setShowComments(true);
                    }}
                  >
                    <ListItemText primary={topic?.title} secondary={topic.description} />
                  </ListItem>
                  <Divider
                    sx={{
                      "&.MuiDivider-light": {
                        borderColor: "#ffffffa1",
                      },
                    }}
                    light={true}
                  />
                </Fragment>
              ))}
            </List>
          </div>
        </div>

        {!showComments ? (
          <>
            <Box
              // className="right"
              // height={"82vh"}
              // width={"80%"}
              sx={{
                width: {
                  xs: "100%",
                  md: "80%",
                },
                display: {
                  xs: "none",
                  md: "flex",
                },
              }}
              p={3}
              bgcolor={"white"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h5" textAlign={"center"}>
                Please select a topic to view discussions.
              </Typography>
              <img
                className="not_topics_selected_img"
                src={noTopicSelectedImg}
                alt="No Topics Selected"
              />
            </Box>
          </>
        ) : (
          <div className={`right ${showComments ? "visible" : "hidden"}`}>
            {Object?.keys(selectedTopic)?.length > 0 && (
              <>
                <div className="topicHeaderContainer">
                  <div className="topicHeaderLeft">
                    <IconButton
                      onClick={() => {
                        setShowComments(false);
                        setSelectedTopic({});
                      }}
                    >
                      <ArrowBackOutlinedIcon />
                    </IconButton>
                    <div>
                      <div className="topic__title">
                        {selectedTopic?.title.length > 100
                          ? selectedTopic?.title.substring(0, 100) + "..."
                          : selectedTopic?.title}
                      </div>
                    </div>
                    <IconButton
                      onClick={() => {
                        setShowDescription(!showDescription);
                      }}
                    >
                      {showDescription ? (
                        <KeyboardArrowUpOutlinedIcon />
                      ) : (
                        <KeyboardArrowDownOutlinedIcon />
                      )}
                    </IconButton>
                    {showDescription && (
                      <div className="topic__description">
                        <p>{selectedTopic?.description}</p>
                      </div>
                    )}
                  </div>
                  {Object.keys(selectedTopic).length > 0 && (
                    <>
                      {location?.pathname?.includes("/create-discussion-forum") && (
                        <MoreVertIcon
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setOpenAction(e.currentTarget);
                            setId(selectedTopic?._id);
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="commentBox">
                  <ChatView topicId={selectedTopic?._id} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <FMModal
        modalOpen={modalOpen}
        modalClose={() => {
          setModalOpen(false);
        }}
        modalHeader={id ? "Edit" : "Create"}
        headerTextColor={"header_text_black"}
        // backgroundImage={{
        //   backgroundImage: "inherit",
        // }}
      >
        <Grid container spacing={1} mt={2} mb={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Topic title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value.trimStart())}
              fullWidth
            />
            <div className="errorMsg">{topicDataError.title}</div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Topic description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value.trimStart())}
              fullWidth
            />
            <div className="errorMsg">{topicDataError.description}</div>
          </Grid>
          <Grid item xs={12}>
            <ButtonMain
              onClick={handleAddTopic}
              disabled={disable || isUnchanged}
              className="edit__button"
            >
              {id ? "Edit" : "Create"} Topic
            </ButtonMain>
          </Grid>
        </Grid>
      </FMModal>
      <Dropdown
        open={topicOpen}
        anchorEl={openAction}
        handleClose={() => setOpenAction(null)}
        className="customDropdown"
      >
        <div
          className="topicAction"
          onClick={() => {
            setModalOpen(true);
            setId(selectedTopic?._id);
            setTitle(selectedTopic?.title);
            setDescription(selectedTopic?.description);
          }}
        >
          Edit
        </div>
        <div className="topicAction" onClick={() => setOpen(true)}>
          Delete
        </div>
        <div className="topicAction" onClick={handleCopy}>
          Copy Embed Code
        </div>
        <div className="topicAction" onClick={handleCopyLink}>
          Copy Embed Link
        </div>
      </Dropdown>
      <ToastNotify ref={toastRef} duration={4000} />
      <ConfirmModal
        handleConfirm={handleDelete}
        confirmText={"Once Deleted Can't be restored!"}
        headerText={"Delete Topic"}
        showConfirmButton={true}
        handleClose={() => {
          setOpen(false);
          setOpenAction(false);
        }}
        confLoading={loading}
        confModal={open}
      />
    </div>
  );
};

export default DiscussionForum;
