import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import { ParagraphText } from "../Typography";
import { country_and_states } from "../../utils/country-states";
import { AuthContext } from "../../App";
import { validateEmail } from "../Validators";
import { POST } from "../../config/Api";
import { Constants } from "../../config/Constants";
import { userSignUpAction } from "../../redux/action";
import CloseIcon from "@mui/icons-material/Close";
import TermsModal from "../ModalMain/TermsModal/TermsModal";
import ButtonMain from "../ButtonMain";

const Signup = () => {
  let dispatch = useDispatch();
  const [disable, setdisable] = useState(false);
  const [signupData, setSignupData] = useState({
    age: "",
    password: "",
    cPassword: "",
    email: "",
    confirmEmail: "",
    termsAgreement: false,
    country: "",
    state: "",
    institute: "",
  });
  const [signupDataError, setSignupDataError] = useState({
    age: "",
    password: "",
    cPassword: "",
    email: "",
    confirmEmail: "",
    country: "",
    state: "",
    institute: "",
  });
  const [openTerms, setOpenTerms] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const { authStates, setAuthStates } = useContext(AuthContext);

  const handleSignup = (e) => {
    e.preventDefault();
    if (signupData.age === "") {
      setSignupDataError({ ...signupDataError, age: "Please enter your age" });
    } else if (signupData.age < 12) {
      setSignupDataError({
        ...signupDataError,
        age: "FramerSpace is only open to students above 12",
      });
    } else if (signupData.age > 100) {
      setSignupDataError({
        ...signupDataError,
        age: "FramerSpace is not open to people above 100",
      });
    } else if (signupData.password === "") {
      setSignupDataError({
        ...signupDataError,
        password: "Please enter your password.",
      });
    } else if (signupData.password.length < 8) {
      setSignupDataError({
        ...signupDataError,
        password: "Password should contain minimum of 8 characters.",
      });
    } else if (signupData.password !== signupData.cPassword) {
      setSignupDataError({
        ...signupDataError,
        cPassword: "Passwords do not match.",
      });
    } else if (signupData.email && !validateEmail(signupData.email)) {
      setSignupDataError({
        ...signupDataError,
        email: "Please enter a valid email.",
      });
    } else if (signupData.email && signupData.email !== signupData.confirmEmail) {
      setSignupDataError({
        ...signupDataError,
        confirmEmail: "Email does not match.",
      });
    } else if (signupData.country === "") {
      setSignupDataError({
        ...signupDataError,
        country: "Please select a country",
      });
    } else if (
      selectedCountryCode !== "OTH" &&
      selectedCountryCode !== "CBM" &&
      signupData.state === ""
    ) {
      setSignupDataError({
        ...signupDataError,
        state: "Please select a state",
      });
    } else if (signupData.institute === "") {
      setSignupDataError({
        ...signupDataError,
        institute: "Please enter your affiliation / institute",
      });
    } else {
      let body = {
        age: signupData.age,
        password: signupData.password,
        reset_email: signupData.email,
        country: signupData.country,
        state: signupData.state,
        institute: signupData.institute,
      };
      setdisable(true);
      //Signup API
      POST(Constants.userSignup, body)
        .then(async (res) => {
          if (!res?.data.success) {
            setSignupDataError({
              ...signupDataError,
              email: res?.data?.message,
            });
            setdisable(false);
          } else {
            dispatch(userSignUpAction(res?.data));
            // setSignupModalOpen(false);
            setAuthStates((prev) => ({
              ...prev,
              signupOpen: false,
              modalOpen: false,
            }));
            setdisable(false);
            // navigate("/");
            setSignupData("");
            //save user last activity
            await POST(`${Constants.userActivity}?activity=signup`, { user: res?.data?.user });
          }
        })
        .catch((err) => {
          setSignupDataError({
            ...signupDataError,
            email: err?.response?.data?.message,
          });
          setdisable(false);
        });
    }
  };

  const handleClose = () => {
    setdisable(false);
    setAuthStates((prev) => ({
      ...prev,
      modalOpen: false,
      signupOpen: false,
    }));
    setSignupData({
      age: "",
      password: "",
      cPassword: "",
      email: "",
      confirmEmail: "",
      termsAgreement: false,
      country: "",
      state: "",
      institute: "",
    });
    setSignupDataError("");
    setSelectedCountryCode("");
  };

  return (
    <>
      <Dialog onClose={handleClose} open={authStates.modalOpen}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "30px",
            fontWeight: 600,
            backgroundColor: "#F2F4F8",
          }}
        >
          Signup{" "}
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#F2F4F8" }}>
          <form className="signupMainContainer">
            <Grid container spacing={2} pt={1} pb={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  type="number"
                  label="Your age"
                  value={signupData.age}
                  onChange={(e) => {
                    setSignupData({ ...signupData, age: e.target.value });
                    setSignupDataError("");
                  }}
                  error={!!signupDataError.age}
                  helperText={signupDataError.age}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} className={"fullwidth"}>
                <TextField
                  type="password"
                  label="Password (min 8 characters)"
                  value={signupData.password}
                  onChange={(e) => {
                    setSignupData({ ...signupData, password: e.target.value });
                    setSignupDataError("");
                  }}
                  error={!!signupDataError.password}
                  helperText={signupDataError.password}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} className={"fullwidth"}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  value={signupData.cPassword}
                  onChange={(e) => {
                    setSignupData({ ...signupData, cPassword: e.target.value });
                    setSignupDataError("");
                  }}
                  error={!!signupDataError.cPassword}
                  helperText={signupDataError.cPassword}
                  fullWidth
                />
              </Grid>
            </Grid>
            <ParagraphText className="leftBtnTxt" style={{ marginBottom: "1rem" }}>
              Optional: Providing your email address will allow you to reset your password in the
              future. We will never share your email with anyone on FramerSpace.
            </ParagraphText>
            <Grid container spacing={2} pt={2} pb={2}>
              <Grid item sm={6} className="fullwidth">
                <TextField
                  className="signupEmailInput"
                  type="email"
                  label="Email"
                  value={signupData.email}
                  onChange={(e) => {
                    setSignupData({ ...signupData, email: e.target.value });
                    setSignupDataError("");
                  }}
                  error={!!signupDataError.email}
                  helperText={signupDataError.email}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} className="fullwidth">
                <TextField
                  className="signupEmailInput"
                  type="email"
                  label="Confirm Email"
                  value={signupData.confirmEmail}
                  onChange={(e) => {
                    setSignupData({
                      ...signupData,
                      confirmEmail: e.target.value,
                    });
                    setSignupDataError("");
                  }}
                  error={!!signupDataError.confirmEmail}
                  helperText={signupDataError.confirmEmail}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} pb={2}>
              <Grid item xs={12} lg={6} md={6}>
                <TextField
                  select
                  label="Country"
                  value={selectedCountryCode}
                  onChange={(e) => {
                    setSignupData((pre) => ({
                      ...pre,
                      country: country_and_states.country[e.target.value],
                      state: "",
                    }));
                    setSelectedCountryCode(e.target.value);
                    setSignupDataError("");
                  }}
                  fullWidth
                  error={!!signupDataError.country}
                  helperText={signupDataError.country}
                >
                  {Object.keys(country_and_states.country).map((item) => (
                    <MenuItem value={item} key={item}>
                      {country_and_states.country[item]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <TextField
                  select
                  label="State"
                  disabled={
                    signupData.country !== "" &&
                    selectedCountryCode !== "OTH" &&
                    selectedCountryCode !== "CBM"
                      ? false
                      : true
                  }
                  value={signupData.state}
                  onChange={(e) => {
                    setSignupData({
                      ...signupData,
                      state: e.target.value,
                    });
                    setSignupDataError("");
                  }}
                  fullWidth
                  error={!!signupDataError.state}
                  helperText={signupDataError.state}
                >
                  {selectedCountryCode !== "" &&
                    selectedCountryCode !== "OTH" &&
                    selectedCountryCode !== "CBM" &&
                    country_and_states.states[selectedCountryCode].map((item, i) => (
                      <MenuItem value={item.name} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                  <MenuItem value={"other"} key={"state_0"}>
                    {"Other"}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  type="text"
                  label="School Name / Institution Name"
                  value={signupData.institute}
                  onChange={(e) => {
                    setSignupData({ ...signupData, institute: e.target.value });
                    setSignupDataError("");
                  }}
                  error={!!signupDataError.institute}
                  helperText={signupDataError.institute}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div
              className="checkBoxSignup"
              onClick={() =>
                setSignupData({
                  ...signupData,
                  termsAgreement: !signupData.termsAgreement,
                })
              }
            >
              <input
                className="checkboxSignupCheck"
                type="checkbox"
                checked={signupData.termsAgreement}
                onChange={() =>
                  setSignupData({
                    ...signupData,
                    termsAgreement: !signupData.termsAgreement,
                  })
                }
              />
              <label className="checkboxLabelSignup">
                I agree to FramerSpace{" "}
                <span className="checkboxTermsSignup" onClick={() => setOpenTerms(true)}>
                  Terms and Data Policy
                </span>
              </label>
            </div>
            <div className="signupBtn">
              <ParagraphText className="leftBtnTxt">
                Note: The system will generate your User ID after you sign-up
              </ParagraphText>
              <div style={{ display: "flex", gap: "1rem" }}>
                <ButtonMain
                  className="leftBtn"
                  disabled={
                    !signupData.age ||
                    !signupData.password ||
                    !signupData.cPassword ||
                    !signupData.termsAgreement ||
                    disable
                  }
                  onClick={handleSignup}
                >
                  Sign up
                </ButtonMain>
                <Button
                  variant="contained"
                  // className="leftBtn"
                  sx={{ textTransform: "none" }}
                  fullWidth
                  onClick={() => {
                    setAuthStates((prev) => ({
                      ...prev,
                      signupOpen: false,
                      loginOpen: true,
                    }));
                    setSignupData("");
                    setSignupDataError("");
                  }}
                >
                  Login
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {openTerms && <TermsModal openTerms={openTerms} modalClose={() => setOpenTerms(false)} />}
    </>
  );
};

export default Signup;
