import { Breadcrumbs, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";

import { POP_BREADCRUMB } from "../../redux/constants";

import "./CustomizedBreadcrumbs.scss";

// const StyledBreadcrumb = styled(Chip)(({ theme }) => {
//   const backgroundColor = "#ddd";
//   return {
//     backgroundColor,
//     height: theme.spacing(3),
//     color: "black",
//     fontWeight: theme.typography.fontWeightRegular,
//     "&:hover, &:focus": {
//       backgroundColor: emphasize(backgroundColor, 0.06),
//     },
//     "&:active": {
//       boxShadow: theme.shadows[1],
//       backgroundColor: emphasize(backgroundColor, 0.2),
//     },
//   };
// });

const CustomizedBreadcrumbs = ({ breadcrumb }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userDetails } = useOutletContext();

  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  return (
    <div role="presentation" className={`breadcrumb ${isLoggedIn ? "greyBg" : "darkBg"}`}>
      <Breadcrumbs
        aria-label="breadcrumb"
        className={` ${
          isLoggedIn ? "post-breadcrumb-container-main" : "pre-breadcrumb-container-main"
        }`}
      >
        {breadcrumb?.map((item, i) => (
          <Link
            key={i}
            variant="text"
            underline="hover"
            color="inherit"
            sx={{ textTransform: "none", cursor: "pointer", color: isLoggedIn ? "black" : "white" }}
            onClick={() => {
              navigate(`${item.navigate}`, { state: { userData: userDetails } });
              dispatch({
                type: POP_BREADCRUMB,
                payload: { title: item.title, navigate: item.navigate },
              });
            }}
          >
            {item.title}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default CustomizedBreadcrumbs;
