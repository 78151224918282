import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useState } from "react";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { cloudfrontUrl } from "../../../config/BaseUrl";
import { Chip, Grid, Paper, useMediaQuery } from "@mui/material";
import "./FeaturedCourses.scss";
import { EnrolledCoursesLoader } from "../../Loader/Loader";
import { saveCourseDetailAction, setCourseEntrySourceAction } from "../../../redux/action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext } from "react";
import { AuthContext } from "../../../App";
import bookImage from "../../../assets/Book-new.svg";
import { getS3ApiJsonKey } from "../../../utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function stripHtmlTags(html) {
  return html.replace(/<[^>]*>/g, "");
}

export default function VerticalTabs({ type, headingText }) {
  const [value, setValue] = React.useState(0);
  const [filteredCourses, setfilteredCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const smallScreen = useMediaQuery("(max-width: 900px)");
  const { authStates } = useContext(AuthContext);
  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};

  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    if (type === "preferred") {
      getPreferredLanguageCourses();
    } else {
      getPublicCourses();
    }
  }, []);
  const getPreferredLanguageCourses = async () => {
    if (authStates.preferredLanguage) {
      try {
        // const response = await GET(
        //   `${Constants.getPublicCourses}?preferredLanguage=${authStates?.preferredLanguage?._id}`,
        // );
        const url = `${getS3ApiJsonKey}/${Constants.getS3PublicCourses}${
          type === "preferred" ? `_${authStates?.preferredLanguage?._id}` : ""
        }.json`;

        const response = await fetch(url);

        if (response.status === 200) {
          const data = await response.json();
          // console.log({ data });
          setfilteredCourses(data);
        } else {
          //get preferred language coursees
          const preferredCourses = await GET(
            `${Constants.getPublicCourses}?preferredLanguage=${authStates?.preferredLanguage?._id}`,
          );
          console.log({ preferredCourses });
          setfilteredCourses(JSON.parse(preferredCourses?.data?.data));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //get list of public courses
  const getPublicCourses = async () => {
    try {
      setLoading(true);
      const url = `${getS3ApiJsonKey}/${Constants.getS3PublicCourses}${
        type === "preferred" ? `_${authStates?.preferredLanguage?._id}` : ""
      }.json`;

      const response = await fetch(url);

      if (response.status === 200) {
        const data = await response.json();
        setfilteredCourses(data);
      } else {
        //get list of public courses
        const publicCourseRes = await GET(`${Constants.getPublicCourses}`);
        setfilteredCourses(JSON.parse(publicCourseRes?.data?.data));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (item) => {
    navigate(`/course/${item?.slug}?cid=${item?._id}`);
    dispatch(saveCourseDetailAction(item));
    isLoggedIn && dispatch(setCourseEntrySourceAction("course_clicked"));
  };

  return (
    <>
      {loading ? (
        <EnrolledCoursesLoader />
      ) : (
        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            position: "sticky",
            top: "200px",
          }}
          className="featuredContainer"
        >
          {filteredCourses?.length > 0 && !loading ? (
            <>
              <Tabs
                orientation={smallScreen ? "horizonal" : "vertical"}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className="featuredTabs"
              >
                {filteredCourses?.map((item, index) => (
                  <Tab
                    key={index}
                    label={item?.term?.title}
                    {...a11yProps(index)}
                    sx={{ textTransform: "capitalize", fontWeight: "bold" }}
                  />
                ))}
              </Tabs>
              <TabPanel value={value} index={value} className="tabPanel">
                <div>
                  {filteredCourses[value]?.docs?.length ? (
                    <>
                      {filteredCourses[value]?.docs?.map((item) => (
                        <Paper
                          elevation={2}
                          key={item?._id}
                          onClick={() => handleClick(item)}
                          className="featuredCourseCard"
                          sx={{ borderRadius: "15px 0px 0px 15px" }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={4} lg={3} className="img-grid-container">
                              {item?.image?.l ? (
                                <img
                                  src={
                                    item?.image?.l?.startsWith(cloudfrontUrl)
                                      ? item?.image?.l
                                      : cloudfrontUrl + "/" + item?.image?.l
                                  }
                                  alt="course images"
                                  className="course-image"
                                />
                              ) : (
                                // <div className="course-image"></div>
                                <img
                                  src={bookImage}
                                  alt="course images"
                                  className="course-image"
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              lg={9}
                              display={"flex"}
                              alignItems={"flex-start"}
                              justifyContent={"space-between"}
                              flexDirection={"column"}
                              sx={{ p: { lg: "0.8rem" }, pl: { xs: "1rem" } }}
                            >
                              <Box>
                                <h1 className="courseTitle">{item?.title}</h1>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: stripHtmlTags(item?.tagline),
                                  }}
                                ></p>
                              </Box>
                              <Box>
                                {(item?.tag?.core || item?.tags?.core) && (
                                  <Chip
                                    label={item?.tag?.core || item?.tags?.core}
                                    className="course-keywords"
                                  />
                                )}{" "}
                                {(item?.tag?.sdg || item?.tags?.sdg) && (
                                  <Chip
                                    label={item?.tag?.sdg || item?.tags?.sdg}
                                    className="course-keywords"
                                  />
                                )}{" "}
                                {(item?.tag?.sel || item?.tags?.sel) && (
                                  <Chip
                                    label={item?.tag?.sel || item?.tags?.sel}
                                    className="course-keywords"
                                  />
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Paper>
                      ))}
                    </>
                  ) : (
                    <EnrolledCoursesLoader />
                  )}
                </div>
              </TabPanel>
            </>
          ) : (
            <>
              <Box
                p={3}
                sx={{ width: "100%", bgcolor: "rgb(229, 246, 253)" }}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <InfoOutlinedIcon color="primary" fontSize="large" />
                <Typography textAlign={"center"} fontWeight={600} ml={2} color="rgb(1, 67, 97)">
                  No courses found in your preferred language
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
