import React from "react";
import "./Wave.scss";

const Wave = () => {
  return (
    <div className="wave-block">
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
  );
};

export default Wave;
