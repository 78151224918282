import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { POST } from "../../../../../config/Api";
import { Constants } from "../../../../../config/Constants";
import { useQueryParams } from "../../../../../utils";
import ButtonMain from "../../../../ButtonMain";
import { CustomInputFile, InputBox } from "../../../../InputBox/InputBox";
import "./AddAudio.scss";

import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import SourceTextSwitcher from "../../../../SourceTextSwitcher/SourceTextSwitcher";
import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const AddAudio = ({
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [text, setText] = useState(block?.title || "");
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = useState(false);
  const [urlPath, seturlPath] = useState("");
  const { setCourseOne } = useOutletContext();

  let UserData = useSelector((state) => state.userLogin);
  const toastRef = useRef();

  const { cid, mid } = useQueryParams();

  const uploadAudio = async (file) => {
    setSelectedFile(file);
    const formData = new FormData();
    formData.append("audio", file);
    let header = "multipart/form-data";
    try {
      setDisable(true);
      //upload audio
      const response = await POST(`${Constants.addBlock}/upload`, formData, header);
      seturlPath(response.data.urlPath);
      setDisable(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileType = file?.type;
    const validAudioTypes = ["audio/wav", "audio/wma", "audio/mpeg"];

    if (!validAudioTypes.includes(fileType)) {
      toastRef.current.showToastMessage("Invalid Audio format.", "error");
    } else {
      if (file.size > 1048576) {
        toastRef.current.showToastMessage("File size too large.", "error");
      } else {
        uploadAudio(file);
      }
    }
  };
  const handleSubmit = async (event) => {
    if (!type) {
      if (selectedFile === null) {
        toastRef.current.showToastMessage("Please select the file.", "error");
      }
    }
    if (text === "") {
      toastRef.current.showToastMessage("Please Enter text.", "error");
    } else {
      let body = {
        user_id: UserData?.data?.user?.id,
        course: cid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        container: mid,
        file: {
          ext: selectedFile?.name?.split(".").pop(),
          size: selectedFile?.size,
        },
        provider: {
          name: "Framerspace",
          url: urlPath || block?.provider?.url,
        },
        title: text,
        type: type,
      };
      setDisable(true);
      //submit file
      await POST(`${Constants.addBlock}/file?_id=${block?._id}`, body)
        .then((response) => {
          setDisable(false);
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div className="audioBlock">
      <SourceTextSwitcher
        checked={checked}
        handleChange={(event) => setChecked(event.target.checked)}
        srcTitleText={block?.srcTitleText}
      />
      <InputBox placeholder="Title" value={text} onChange={(e) => setText(e.target.value)} />
      {checked && (
        <Box my={2}>
          <strong>Source Text:</strong> {block?.srcTitleText}
        </Box>
      )}
      {!type && (
        <>
          <CustomInputFile
            title={selectedFile ? selectedFile.name : "Choose Audio to upload"}
            icon={!selectedFile && true}
            className="fileUpload"
            onChange={handleFileChange}
            accept="audio/mp3, audio/wav, audio/wma"
          />
          <p>Note : Accepted file format (MP3,WAV,WMA) & File Size must be less than 1MB.</p>
        </>
      )}
      <ButtonMain
        className="buttonGreen"
        onClick={handleSubmit}
        disabled={disable}
        style={{ marginTop: "2rem" }}
      >
        Save Block
      </ButtonMain>
      <ToastNotify ref={toastRef} duration={4000} />
    </div>
  );
};

export default AddAudio;
