import { createTheme, ThemeProvider, Typography } from "@mui/material";
import React from "react";

const HeaderText = ({ className, children }) => {
  const theme = createTheme({
    palette: {
      primaryYellow: "var(--primary-yellow)",
    },
    typography: {
      fontFamily: "Figtree, sans-serif ",
      fontSize: 35,
      fontWeightRegular: 600,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography className={className}>{children}</Typography>
    </ThemeProvider>
  );
};

const ParagraphText = ({ className, children, onClick }) => {
  const theme = createTheme({
    palette: {
      primaryYellow: "var(--primary-yellow)",
    },
    typography: {
      fontFamily: "Figtree, sans-serif",
      fontSize: 16,
      marginBottom: 5,
      maxWidth: 30,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography className={className} onClick={onClick}>
        {children}
      </Typography>
    </ThemeProvider>
  );
};
const LabeledText = ({ className, children, onClick }) => {
  const theme = createTheme({
    palette: {
      primaryYellow: "var(--primary-yellow)",
    },
    typography: {
      fontFamily: "Figtree, sans-serif ",
      fontSize: 12,
      marginBottom: 5,
      maxWidth: 30,
      fontWeightRegular: 600,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Typography className={className} onClick={onClick}>
        {children}
      </Typography>
    </ThemeProvider>
  );
};

export { HeaderText, ParagraphText, LabeledText };
